import {
    Card,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { ButtonSolid } from 'app/components';
import { delCategory, getCategory } from 'app/redux/actions/BimbelActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import ModalAddCategory from './ModalAddCategory';
import ModalEditCategory from './ModalEditCategory';

class Category extends Component {
    constructor() {
        super();
        this.state = {
            modalAdd: false,
            modalEdit: false,
            edit_bimble: {
                bimble_address: '',
                bimble_benefit: [],
                bimble_discount: 0,
                bimble_expired_month: 0,
                bimble_link: '',
                bimble_maps: {
                    latitude: '',
                    longitude: ''
                },
                bimble_name: '',
                bimble_platform: '',
                bimble_price: 0,
                bimble_schedule: '',
                bimble_tutor: '',
                thumbnail: '',
                type: '',
                doc_id: 0
            },
            rowsPerPage: 10,
            page: 0,
            category: 'twk'
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const { getCategory } = this.props;
        getCategory();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    renderData = () => {
        const { bimbel } = this.props;
        const { page, rowsPerPage, category } = this.state;
        return bimbel.length > 0 ? (
            bimbel
                .filter((item) => item.category == category)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                    return (
                        <TableRow hover key={index}>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                {index + 1}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                {item.id}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                {item.name}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                <IconButton
                                    title='edit'
                                    onClick={() => this.handleEdit(item)}
                                >
                                    <Icon color='primary'>border_color</Icon>
                                </IconButton>
                                <IconButton
                                    title='delete'
                                    onClick={() =>
                                        this.handleDelete(item.doc_id)
                                    }
                                >
                                    <Icon color='error'>delete</Icon>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    );
                })
        ) : (
            <TableRow hover>
                <TableCell colSpan={6} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };

    handleAdd = () => {
        const { modalAdd } = this.state;
        this.setState({
            modalAdd: !modalAdd
        });
        this.getData();
    };

    handleDelete = (doc_id) => {
        const { deleteBimbel } = this.props;
        Swal.fire({
            title: 'Peringatan',
            text: 'Apakah kamu yakin ingin menghapus paket bimbel ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            icon: 'warning'
        }).then((res) => {
            if (res.isConfirmed) {
                deleteBimbel(doc_id);
            } else {
                Swal.close();
            }
        });
    };

    handleEdit = (item) => {
        const { name, category, doc_id } = item;
        this.setState({
            edit_bimble: {
                name: name,
                category: category,
                doc_id: doc_id
            },
            modalEdit: true
        });
    };

    handleCloseEdit = () => {
        this.setState({
            modalEdit: false
        });
        this.getData();
    };

    handleChangeSelectPaket = (e) => {
        if (e.target.value == 'SEMUA') {
            this.setState({
                category: ''
            });
        } else {
            this.setState({
                category: e.target.value
            });
        }
    };

    render() {
        const {
            modalAdd,
            modalEdit,
            edit_bimble,
            rowsPerPage,
            page,
            category
        } = this.state;
        const { bimbel } = this.props;
        return (
            <div className='m-sm-30'>
                <div className='flex items-center justify-between mt-10 mb-3'>
                    <h2 style={{ fontSize: '25px' }}>
                        List Category & Sub Category
                    </h2>
                    <ButtonSolid
                        onClick={this.handleAdd}
                        variant='button-primary'
                        text='Tambah Subcategory'
                    />
                </div>
                <Grid container spacing={2} className='mb-3'>
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                        <label htmlFor='category'>
                            <small>Kategori</small>
                        </label>
                        <Select
                            labelId='demo-simple-select-label'
                            id='category'
                            value={category}
                            onChange={this.handleChangeSelectPaket}
                            className='w-full'
                        >
                            <MenuItem value={'twk'}>TWK</MenuItem>
                            <MenuItem value={'tiu'}>TIU</MenuItem>
                            <MenuItem value={'tkp'}>TKP</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        ID Import
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Nama
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>
                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={bimbel.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                    </div>
                </Card>
                <ModalAddCategory
                    open={modalAdd}
                    handleClose={this.handleAdd}
                />
                {modalEdit && (
                    <ModalEditCategory
                        name={edit_bimble.name}
                        category={edit_bimble.category}
                        doc_id={edit_bimble.doc_id}
                        open={modalEdit}
                        handleClose={this.handleCloseEdit}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userBimble: state.userPaket.bimbel,
        bimbel: state.bimbel.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCategory: () => dispatch(getCategory()),
        deleteBimbel: (doc_id) => dispatch(delCategory(doc_id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Category);
