import { Button, Card, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import rules from '../../../config/formValidation';
import { sendResetPassword } from '../../redux/actions/LoginActions';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem'
    }
}));

const ForgotPassword = ({ history }) => {
    const [state, setState] = useState({});
    const [Loading, setLoading] = useState(false);
    const { user } = useSelector((user) => user);

    const classes = useStyles();

    const handleChange = ({ target: { name, value } }) => {
        setState({
            ...state,
            [name]: value
        });
    };

    let { email } = state;

    const handleFormSubmit = async (event) => {
        await setLoading(true);
        sendResetPassword(email)
            .then((res) => {
                if (res.code == 'auth/user-not-found') {
                    Swal.fire({
                        title: 'Email belum terdaftar',
                        text:
                            'Pastikan alaman email yang Anda masukkan sudah terdaftar.',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    });
                    setLoading(false);
                } else {
                    Swal.fire({
                        title: 'Email berhasil dikirim',
                        text: 'Cek email kamu sekarang',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });
                    setLoading(false);
                    setState({});
                }
            })
            .catch((err) => {
                setLoading(false);
                Swal.fire({
                    title: 'Email gagal dikirim',
                    text: 'Coba beberapa saat lagi',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
            });
    };

    useEffect(() => {
        if (Object.keys(user).length > 0 && user.constructor == Object) {
            history.push('/');
        }
    }, []);

    return (
        <div
            className={
                'flex justify-center items-center  min-h-full-screen bg-session'
            }
        >
            <Grid container alignItems="center" justify="center">
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card className={clsx(classes.card, 'card-session')}>
                        <div className="p-8 h-full relative">
                            <div className="pb-3 flex justify-center items-center h-full">
                                <img
                                    className="w-200 mb-3"
                                    src="/assets/images/logos/logo_new_vertical2.svg"
                                    alt=""
                                />
                            </div>
                            <ValidatorForm onSubmit={handleFormSubmit}>
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    label="Email"
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                    value={email || ''}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[
                                        rules.required,
                                        rules.isEmail
                                    ]}
                                />
                                <div className="flex flex-column mt-2">
                                    <Button
                                        variant="contained"
                                        disabled={Loading}
                                        className={
                                            Loading
                                                ? 'button-purple opacity font-bold elevation-z0'
                                                : 'font-bold elevation-z0'
                                        }
                                        color="primary"
                                        type="submit"
                                    >
                                        {Loading ? (
                                            <CircularProgress
                                                color="primary"
                                                size={24}
                                            />
                                        ) : (
                                            'KIRIM'
                                        )}
                                    </Button>
                                    <Link
                                        to="/session/signin"
                                        className="w-full"
                                    >
                                        <Button className="capitalize w-full font-bold elevation-z0 bg-default mt-3">
                                            Sign in
                                        </Button>
                                    </Link>
                                </div>
                            </ValidatorForm>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default ForgotPassword;
