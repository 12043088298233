import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import RootReducer from './reducers/RootReducer';

// Import redux-persist modules
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {};
const middlewares = [thunk];
let devtools = (x) => x;

if (
    process.env.NODE_ENV !== 'production' &&
    process.browser &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

let store;
let persistor;

// Kondisi untuk mengaktifkan redux-persist
if (process.env.REACT_APP_ENABLE_PERSIST === 'true') {
    const persistConfig = {
        key: 'root',
        storage
    };
    const persistedReducer = persistReducer(persistConfig, RootReducer);

    store = createStore(
        persistedReducer,
        initialState,
        compose(applyMiddleware(...middlewares), devtools)
    );

    persistor = persistStore(store);
} else {
    store = createStore(
        RootReducer,
        initialState,
        compose(applyMiddleware(...middlewares), devtools)
    );
}

export { store, persistor };
