import EditPassword from './EditPassword';
import EditProfile from './EditProfile';
import Profile from './profile';

const profileRoutes = [
    {
        path: '/akun/edit_password',
        component: EditPassword
    },
    {
        path: '/akun/edit_profile',
        component: EditProfile
    },
    {
        path: '/akun',
        component: Profile
    }
];

export default profileRoutes;
