import {
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { ButtonSolid } from 'app/components';
import { delBimbel, getBimbel } from 'app/redux/actions/BimbelActions';
import { formatRupiah, formatTanggal } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import ModalAddBimbel from './ModalAddBimbel';
import ModalEditBimbel from './ModalEditBimbel';

class Kelas extends Component {
    constructor() {
        super();
        this.state = {
            modalAdd: false,
            modalEdit: false,
            edit_bimble: {
                bimble_address: '',
                bimble_benefit: [],
                bimble_discount: 0,
                bimble_expired_month: 0,
                bimble_link: '',
                bimble_maps: {
                    latitude: '',
                    longitude: ''
                },
                bimble_name: '',
                bimble_platform: '',
                bimble_price: 0,
                bimble_schedule: '',
                bimble_tutor: '',
                thumbnail: '',
                type: '',
                doc_id: 0
            },
            rowsPerPage: 10,
            page: 0
        };
    }

    componentDidMount() {
        const { getBimbel } = this.props;
        getBimbel();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    renderData = () => {
        const { bimbel } = this.props;
        const { page, rowsPerPage } = this.state;
        return bimbel.length > 0 ? (
            bimbel
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                    return (
                        <TableRow hover key={index}>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                {index + 1}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                {item.bimble_name}
                            </TableCell>
                            <TableCell className='px-2 py-2 text-center font-poppins'>
                                {formatRupiah(item.bimble_price, 'Rp')}
                            </TableCell>
                            <TableCell className='px-2 py-2 text-center font-poppins'>
                                {item.bimble_tutor}
                            </TableCell>
                            <TableCell className='px-2 py-2 text-center font-poppins'>
                                {formatTanggal(item.bimble_schedule)}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                <small
                                    className={`border-radius-4 text-white px-8 py-2 ${
                                        item.type == 'offline'
                                            ? 'bg-secondary'
                                            : 'bg-primary'
                                    }`}
                                >
                                    {item.type}
                                </small>
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                <IconButton
                                    title='edit'
                                    onClick={() => this.handleEdit(item)}
                                >
                                    <Icon color='primary'>border_color</Icon>
                                </IconButton>
                                <IconButton
                                    title='delete'
                                    onClick={() =>
                                        this.handleDelete(item.doc_id)
                                    }
                                >
                                    <Icon color='error'>delete</Icon>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    );
                })
        ) : (
            <TableRow hover>
                <TableCell colSpan={8} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };

    handleAdd = () => {
        const { modalAdd } = this.state;
        this.setState({
            modalAdd: !modalAdd
        });
    };

    handleDelete = (doc_id) => {
        const { deleteBimbel } = this.props;
        Swal.fire({
            title: 'Peringatan',
            text: 'Apakah kamu yakin ingin menghapus paket bimbel ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            icon: 'warning'
        }).then((res) => {
            if (res.isConfirmed) {
                deleteBimbel(doc_id);
            } else {
                Swal.close();
            }
        });
    };

    handleEdit = (item) => {
        const {
            bimble_address,
            bimble_benefit,
            bimble_discount,
            bimble_expired_month,
            bimble_link,
            bimble_maps,
            bimble_name,
            bimble_platform,
            bimble_price,
            bimble_schedule,
            bimble_tutor,
            doc_id,
            thumbnail,
            type
        } = item;
        this.setState({
            edit_bimble: {
                bimble_address: bimble_address,
                bimble_benefit: bimble_benefit,
                bimble_discount: bimble_discount,
                bimble_expired_month: bimble_expired_month,
                bimble_link: bimble_link,
                bimble_maps: bimble_maps,
                bimble_name: bimble_name,
                bimble_platform: bimble_platform,
                bimble_price: bimble_price,
                bimble_schedule: bimble_schedule,
                bimble_tutor: bimble_tutor,
                thumbnail: thumbnail,
                type: type,
                doc_id: doc_id
            },
            modalEdit: true
        });
    };

    handleCloseEdit = () => {
        this.setState({
            modalEdit: false
        });
    };

    render() {
        const {
            modalAdd,
            modalEdit,
            edit_bimble,
            rowsPerPage,
            page
        } = this.state;
        const { bimbel } = this.props;
        return (
            <div className='m-sm-30'>
                <div className='flex items-center justify-between mt-10 mb-3'>
                    <h2 style={{ fontSize: '25px' }}>List Paket Bimbel</h2>
                    <ButtonSolid
                        onClick={this.handleAdd}
                        variant='button-primary'
                        text='Tambah Bimbel'
                    />
                </div>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Judul
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Harga
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Tutor
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Jadwal
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Tipe
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>
                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={bimbel.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                    </div>
                </Card>
                <ModalAddBimbel open={modalAdd} handleClose={this.handleAdd} />
                {modalEdit && (
                    <ModalEditBimbel
                        bimble_address={edit_bimble.bimble_address}
                        bimble_benefit={edit_bimble.bimble_benefit}
                        bimble_discount={edit_bimble.bimble_discount}
                        bimble_expired_month={edit_bimble.bimble_expired_month}
                        bimble_link={edit_bimble.bimble_link}
                        bimble_maps={edit_bimble.bimble_maps}
                        bimble_name={edit_bimble.bimble_name}
                        bimble_platform={edit_bimble.bimble_platform}
                        bimble_price={edit_bimble.bimble_price}
                        bimble_schedule={edit_bimble.bimble_schedule}
                        bimble_tutor={edit_bimble.bimble_tutor}
                        thumbnail={edit_bimble.thumbnail}
                        type={edit_bimble.type}
                        doc_id={edit_bimble.doc_id}
                        open={modalEdit}
                        handleClose={this.handleCloseEdit}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userBimble: state.userPaket.bimbel,
        bimbel: state.bimbel.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBimbel: () => dispatch(getBimbel()),
        deleteBimbel: (doc_id) => dispatch(delBimbel(doc_id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Kelas);
