import LatihanSoal from './latihan/LatihanSoal';
import PPPKBimbelDetail from './PPPKBimbelDetail';
import PPPKDetail from './PPPKDetail';
import PPPKIndex from './PPPKIndex';
import PPPKTable from './PPPKTable';
import PPPKTableBimbel from './PPPKTableBimbel';
import TryoutRanking from './tryout/TryoutRanking';
import TryoutSoal from './tryout/TryoutSoal';

const PPPKRoutes = [
    {
        path: '/pppk/tryout/ranking/:id',
        component: TryoutRanking
    },
    {
        path: '/pppk/tryout/soal/:id/:type',
        component: TryoutSoal
    },
    {
        path: '/pppk/latihan/soal/:id',
        component: LatihanSoal
    },
    {
        path: '/pppk/:id/detail/:type',
        component: PPPKDetail
    },
    {
        path: '/pppkbimbel/:id/detail/:type',
        component: PPPKBimbelDetail
    },
    {
        path: '/pppk/mandiri',
        component: PPPKTable
    },
    {
        path: '/pppk/bimbel',
        component: PPPKTableBimbel
    },
    {
        path: '/pppk',
        component: PPPKIndex
    }
];

export default PPPKRoutes;
