import { Button, Dialog, Grid, MenuItem } from '@material-ui/core';
import { getTanggal } from 'app/redux/actions/UserActions';
import { addBatch } from 'app/redux/actions/BatchActions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    TextValidator,
    ValidatorForm,
    SelectValidator
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddVoucherDialog extends Component {
    state = {
        name: '',
        email: '',
        password: '',
        statusButton: false,
        buttonText: 'Tambah',
        // isActive: false,
        kode_batch: '',
        // is_active: 0,
        // paket: 5
    };

    handleChange = (event, source) => {
        event.persist();
        if (source === 'switch') {
            console.log(event.target);
            this.setState({ isActive: event.target.checked });
            return;
        }

        if (source === 'status') {
            if (event.target.value === 'SUSPEND') {
                this.setState({
                    users_status: 0,
                    users_status_value: 'SUSPEND'
                });
                return;
            } else {
                this.setState({
                    users_status: 1,
                    users_status_value: 'ACTIVE'
                });
                return;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = async () => {
        this.setState({
            statusButton: true,
            buttonText: 'Loading...'
        });
        let { discount, kode_batch, title, uid, is_active, paket } = this.state;
        let params = {
        // discount: discount,
            id: kode_batch,
            title: title,
            // is_active: is_active,
            // paket: paket,
            created_at: getTanggal()
        };
        await this.props.addBatch(params, kode_batch);
        setTimeout(() => {
            this.props.handleClose();
        }, 2000);
    };

    render() {
        let { discount, title, kode_batch, is_active, paket } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open}>
                <div className='p-5' style={{ minWidth: '500px' }}>
                    <h4 className='mb-5'>Tambah Batch</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid className='mb-4' container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Kode Batch *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='kode_batch'
                                    value={kode_batch}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                                <TextValidator
                                    className='mb-5'
                                    label='Nama Batch *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                                {/* <TextValidator
                                    className='mb-5'
                                    label='Diskon *'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='discount'
                                    value={discount}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                                <SelectValidator
                                    className='w-full mb-5'
                                    label='Status Voucher'
                                    onChange={this.handleChange}
                                    name='is_active'
                                    value={is_active}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Aktif</MenuItem>
                                    <MenuItem value='0'>Nonaktif</MenuItem>
                                </SelectValidator>
                                <SelectValidator
                                    className='w-full'
                                    label='Voucher dapat digunakan di'
                                    onChange={this.handleChange}
                                    name='paket'
                                    value={paket}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>SKD</MenuItem>
                                    <MenuItem value='2'>SKB</MenuItem>
                                    <MenuItem value='3'>UK</MenuItem>
                                    <MenuItem value='4'>PPPK</MenuItem>
                                    <MenuItem value='5'>Semua Paket</MenuItem>
                                </SelectValidator> */}
                            </Grid>
                        </Grid>

                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={this.state.statusButton}
                                color='primary'
                                type='submit'
                            >
                                {this.state.buttonText}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    addBatch: PropTypes.func.isRequired
    // getAllProyecto: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { addBatch })(AddVoucherDialog);
