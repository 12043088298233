import axios from 'axios';
import { LazyResult } from 'postcss';
import Swal from 'sweetalert2';

import FirebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_LIST_USER = 'GET_LIST_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_TRYOUT_SKB_USER = 'GET_TRYOUT_SKB_USER';
export const GET_TRYOUT_SKB_BUNDLE_USER = 'GET_TRYOUT_SKB_BUNDLE_USER';
export const GET_UK_USER = 'GET_UK_USER';
export const GET_UK_BUNDLE_USER = 'GET_UK_BUNDLE_USER';
export const GET_DETAIL_USER = 'GET_DETAIL_USER';

export const getListUser = () => {
    return async (dispatch) => {
        await FirebaseAuthService.firestore
            .collection('users')
            .get()
            .then((res) => {
                if (!res.empty) {
                    let data = [];
                    res.forEach(async function (doc) {
                        let item = doc.data();
                        item.doc_id = doc.id;
                        data.push(item);
                    });
                    dispatch({
                        type: GET_LIST_USER,
                        payload: data
                    });
                } else {
                    dispatch({
                        type: GET_LIST_USER,
                        payload: []
                    });
                }
            });
    };
};

export const deleteUser = (params) => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        axios.defaults.headers.common['Authorization'] = uid;
        await axios
            .post(`${process.env.REACT_APP_API_URL}scpns/user/delete`, params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getListUser());
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
                if (error.response) {
                    console.log(error.response.data);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    };
};

export const suspendUser = (params) => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        axios.defaults.headers.common['Authorization'] = uid;
        await axios
            .post(`${process.env.REACT_APP_API_URL}scpns/auth/suspend`, params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: params.status
                        ? 'Akun User berhasil disuspend'
                        : 'Akun User berhasil dipulihkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: params.status
                        ? 'Akun User gagal disuspend'
                        : 'Akun User gagal dipulihkan',
                    icon: 'error',
                    timer: 2000
                });
                if (error.response) {
                    console.log(error.response.data);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    };
};

export const getDetailUser = (uid) => {
    return FirebaseAuthService.firestore
        .collection('users')
        .doc(uid)
        .get()
        .then((res) => {
            if (res.exists) {
                return res.data();
            } else {
                return {};
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getTryoutSKBUser = (uid) => {
    return async (dispatch) => {
        let data = [];
        let aa = [];
        FirebaseAuthService.firestore
            .collection('users_tryout')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'skb')
            .get()
            .then(function (doc) {
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                for (let i = 0; i < data.length; i++) {
                    data[i].tryout.get().then(function (a) {
                        let my = a.data();
                        my.tryout_id = data[i].tryout_id;
                        my.parent_id = data[i].parent_id;
                        my.doc_id = data[i].doc_id;
                        aa.push(my);
                        dispatch({
                            type: GET_TRYOUT_SKB_USER,
                            payload: aa
                        });
                    });
                }
            });
    };
};

export const getSKBBundleUser = (uid) => {
    return async (dispatch) => {
        let data = [];
        FirebaseAuthService.firestore
            .collection('users_tryout')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'bundle_skb')
            .get()
            .then(function (doc) {
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_SKB_BUNDLE_USER,
                    payload: data
                });
            })
            .catch(function (error) {
                console.log('Error getting documents: ', error);
            });
    };
};

export const getTryoutUKUser = (uid) => {
    return async (dispatch) => {
        let data = [];
        let aa = [];
        FirebaseAuthService.firestore
            .collection('users_tryout')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'uk')
            .get()
            .then(function (doc) {
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                for (let i = 0; i < data.length; i++) {
                    data[i].tryout.get().then(function (a) {
                        let my = a.data();
                        my.tryout_id = data[i].tryout_id;
                        my.parent_id = data[i].parent_id;
                        my.doc_id = data[i].doc_id;
                        aa.push(my);
                        dispatch({
                            type: GET_UK_USER,
                            payload: aa
                        });
                    });
                }
            });
    };
};

export const getUKBundleUser = (uid) => {
    return async (dispatch) => {
        let data = [];
        FirebaseAuthService.firestore
            .collection('users_tryout')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'bundle_uk')
            .get()
            .then(function (doc) {
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_UK_BUNDLE_USER,
                    payload: data
                });
            })
            .catch(function (error) {
                console.log('Error getting documents: ', error);
            });
    };
};
