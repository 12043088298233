import axios from 'axios';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const LOADING = 'LOADING';
export const GET_UK = 'GET_UK';
export const DELETE_UK = 'DELETE_UK';
export const ADD_UK = 'ADD_UK';
export const EDIT_UK = 'EDIT_UK';
export const ADD_BUNDLE_UK = 'ADD_BUNDLE_UK';
export const GET_EXERCISE_UK = 'GET_EXERCISE_UK';

export const GET_DETAIL_UK = 'GET_DETAIL_UK';
export const GET_BUNDLE_UK = 'GET_BUNDLE_UK';
export const GET_TRYOUT_UK = 'GET_TRYOUT_UK';
export const GET_MY_TRYOUT_UK = 'GET_MY_TRYOUT_UK';
export const GET_MY_TRYOUT_UK_BUNDLE = 'GET_MY_TRYOUT_UK_BUNDLE';
export const GET_MY_EXERCISE_UK = 'GET_MY_EXERCISE_UK';
export const GET_MY_TRYOUT_UK_BUNDLE_DETAIL = 'GET_MY_TRYOUT_UK_BUNDLE_DETAIL';
export const GET_MY_TRYOUT_DETAIL = 'GET_MY_TRYOUT_DETAIL';
export const GET_MY_EXERCISE_DETAIL = 'GET_MY_EXERCISE_DETAIL';
export const GET_USERS_MATERI = 'GET_USERS_MATERI';
export const GET_USERS_MATERI_LIST = 'GET_USERS_MATERI_LIST';
export const GET_MATERI_UK_DETAIL = 'GET_MATERI_UK_DETAIL';
export const GET_USERS_SP = 'GET_USERS_SP';
export const GET_USERS_SP_LIST = 'GET_USERS_SP_LIST';
export const GET_SP_UK_DETAIL = 'GET_SP_UK_DETAIL';

// PAKETTT
export const getUK = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });
                dispatch({
                    type: GET_UK,
                    payload: data
                });
            });
    };
};

export const addPaketUK = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(id)
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Uji Kompetensi berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Uji Kompetensi gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updateUK = (params, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Paket berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: EDIT_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Paket gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteUK = (doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Uji Kompetensi berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_UK,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Uji Kompetensi gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// BUNDLEEE
export const getBundleUK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(kode)
            .collection('bundle')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_BUNDLE_UK,
                    payload: data.length > 0 ? data[0] : data
                });
            });
    };
};

export const addBundleUK = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(id)
            .collection('bundle')
            .doc(doc_id)
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Bundle berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_BUNDLE_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Bundle gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updateBundleUK = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(id)
            .collection('bundle')
            .doc(doc_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Bundle berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_BUNDLE_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// MATERII
export const getMateriList = (parent) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('materi')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });

                dispatch({
                    type: GET_USERS_MATERI_LIST,
                    payload: data
                });
            });
    };
};

export const addMateri = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi_uk/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getMateriList(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const editMateri = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi_uk/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil Diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getMateriList(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal Diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const deleteMateri = (parent, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('materi')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_UK,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// SOAL PEMBAHASAN
export const getSPList = (parent) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('soal_pembahasan')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });

                dispatch({
                    type: GET_USERS_SP_LIST,
                    payload: data
                });
            });
    };
};

export const addSP = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/soal_pembahasan_uk/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal Pembahasan berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSPList(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal Pembahasan gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const editSP = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/soal_pembahasan_uk/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal Pembahasan berhasil Diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSPList(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal Pembahasan gagal Diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const deleteSP = (parent, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('soal_pembahasan')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal Pembahasan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_UK,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal Pembahasan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// TRYOUT
export const getTryoutList = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(kode)
            .collection('tryout')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    // item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_UK,
                    payload: data
                });
            });
    };
};

export const addTryout = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(id)
            .collection('tryout')
            .doc()
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editTryout = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(id)
            .collection('tryout')
            .doc(doc_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Bundle berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteTryout = (parent, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('tryout')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_UK,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const addQuestionTryout = (params, parent, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('tryout')
            .doc(id)
            .collection('question')
            .doc()
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editQuestionTryout = (params, parent, id, question_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('tryout')
            .doc(id)
            .collection('question')
            .doc(question_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteQuestionTryout = (parent, doc_id, qt_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('tryout')
            .doc(doc_id)
            .collection('question')
            .doc(qt_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_UK,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// LATIHAN || EXERCISE
export const getExerciseList = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(kode)
            .collection('exercise')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });
                dispatch({
                    type: GET_EXERCISE_UK,
                    payload: data
                });
            });
    };
};

export const addExercise = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(id)
            .collection('exercise')
            .doc()
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan SKB berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan SKB gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editExercise = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(id)
            .collection('exercise')
            .doc(doc_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteExercise = (parent, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('exercise')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_UK,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const addQuestionLatihan = (params, parent, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('exercise')
            .doc(id)
            .collection('question')
            .doc()
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editQuestionExercise = (params, parent, id, question_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('exercise')
            .doc(id)
            .collection('question')
            .doc(question_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_UK,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteQuestionExercise = (parent, doc_id, qt_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('exercise')
            .doc(doc_id)
            .collection('question')
            .doc(qt_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_UK,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getDetailUK = (kode) => {
    return async (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(kode)
            .get()
            .then((res) => {
                if (res.exists) {
                    dispatch({
                        type: GET_DETAIL_UK,
                        payload: res.data()
                    });
                } else {
                    dispatch({
                        type: GET_DETAIL_UK,
                        payload: undefined
                    });
                }
                dispatch({
                    type: LOADING,
                    payload: false
                });
            });
    };
};

export const getTryoutUK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(kode)
            .collection('tryout')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_UK,
                    payload: data
                });
            });
    };
};

export const beliUKBundle = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return axios
        .post(`${process.env.REACT_APP_API_URL}scpns/bundle_uk/pay`, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getMyTryoutUK = () => {
    var uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        let aa = [];
        firebaseAuthService.firestore
            .collection('users_tryout')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'uk')
            .get()
            .then(function (doc) {
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                for (let i = 0; i < data.length; i++) {
                    data[i].tryout.get().then(function (a) {
                        let my = a.data();
                        my.tryout_id = data[i].tryout_id;
                        my.parent_id = data[i].parent_id;
                        my.doc_id = data[i].doc_id;
                        aa.push(my);
                        dispatch({
                            type: GET_MY_TRYOUT_UK,
                            payload: aa
                        });
                    });
                }
            });
    };
};

export const getMyTryoutUKBundle = () => {
    var uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        firebaseAuthService.firestore
            .collection('users_tryout')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'bundle_uk')
            .get()
            .then(function (doc) {
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_TRYOUT_UK_BUNDLE,
                    payload: data
                });
            })
            .catch(function (error) {
                console.log('Error getting documents: ', error);
            });
    };
};

export const getMyTryoutUKBundleDetail = (id_tryout) => {
    var uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_tryout')
            .where('buy_from', '==', 'sub_bundle_uk')
            .where('id_user', '==', uid)
            .where('parent_id', '==', id_tryout)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_TRYOUT_UK_BUNDLE_DETAIL,
                    payload: data
                });
            })
            .catch(function (error) {
                console.log('Error getting documents: ', error);
            });
    };
};

export const getDetailTryout = (id_tryout, parent_id) => {
    return async (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent_id)
            .collection('tryout')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    item.parent_id = as.parent_id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_TRYOUT_DETAIL,
                    payload: data.filter((item) => item.doc_id == id_tryout)
                });
            });
    };
};

export const getDetailExercise = (id_ex, parent_id) => {
    return async (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent_id)
            .collection('exercise')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    item.parent_id = as.parent_id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_EXERCISE_DETAIL,
                    payload: data.filter((item) => item.doc_id == id_ex)
                });
            });
    };
};

export const getMyExerciseUK = () => {
    var uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'uk')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_EXERCISE_UK,
                    payload: data
                });
            });
    };
};

// Fetch users Materi
export const getUsersMateri = () => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_materi')
            .where('id_user', '==', uid)
            .where('type', '==', 'uk')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_USERS_MATERI,
                    payload: data
                });
            });
    };
};

export const getMateriDetail = (id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_materi')
            .doc(id)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = id;
                dispatch({
                    type: GET_MATERI_UK_DETAIL,
                    payload: data
                });
            });
    };
};

// Fetch users Materi
export const getUsersSP = () => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_soal_pembahasan')
            .where('id_user', '==', uid)
            .where('type', '==', 'skb')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_USERS_SP,
                    payload: data
                });
            });
    };
};

export const getSPDetail = (id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_soal_pembahasan')
            .doc(id)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = id;
                dispatch({
                    type: GET_SP_UK_DETAIL,
                    payload: data
                });
            });
    };
};

// Fetch users Materi UK
export const getUsersSPUK = () => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_soal_pembahasan')
            .where('id_user', '==', uid)
            .where('type', '==', 'uk')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_USERS_SP,
                    payload: data
                });
            });
    };
};
