import News from "./News";


const NewsRoutes = [
    {
        path: '/news',
        component: News
    }
];

export default NewsRoutes;
