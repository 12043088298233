import { Card, Icon } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';

const CardKerjakan = ({
    children,
    title,
    subtitle,
    startWorking,
    expired,
    handleSelesaiAuto
}) => {
    const [Mulai, setMulai] = useState(null);
    const [ExpiredTime, setExpiredTime] = useState(null);
    useEffect(() => {
        setMulai(startWorking);
        setExpiredTime(expired);
    }, [startWorking, expired]);
    const [Countdown, setCountdown] = useState('');
    const handleCountdown = () => {
        let countDownDate =
            new Date(Mulai.replace(' ', 'T')).getTime() + ExpiredTime * 60000;
        let x = setInterval(async () => {
            let now = new Date().getTime();
            let distance = countDownDate - now;
            let hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
            let minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (
                hours != undefined &&
                minutes != undefined &&
                seconds != undefined
            ) {
                if (hours >= 0 && minutes >= 0 && seconds > 0) {
                    hours = hours < 10 ? '0' + hours : hours;
                    minutes = minutes < 10 ? '0' + minutes : minutes;
                    seconds = seconds < 10 ? '0' + seconds : seconds;
                    setCountdown(hours + ':' + minutes + ':' + seconds);
                }
            }
            if (distance <= 0 && Mulai != null && expired != undefined) {
                clearInterval(x);
                setCountdown('SELESAI');
                handleSelesaiAuto();
            }
        }, 1000);
    };
    handleCountdown();
    return (
        <Card
            elevation={0}
            className='px-6 py-5 h-full'
            style={{ background: 'transparent' }}
        >
            <div className='flex justify-between'>
                <h4 className='mb-4'>{title}</h4>
                {subtitle && (
                    <div className='card-subtitle mb-4'>{subtitle}</div>
                )}
                {expired && (
                    <div className='flex items-center'>
                        <Icon>access_time</Icon>
                        <h4 className='m-0 ml-2'>{Countdown}</h4>
                    </div>
                )}
            </div>
            <div className='bordered mb-5'></div>
            {children}
        </Card>
    );
};

export default CardKerjakan;
