import axios from 'axios';
import firebase from 'firebase';
import history from 'history.js';

import FirebaseAuthService from '../../services/firebase/firebaseAuthService';

export const SET_USER_DATA = 'USER_SET_DATA';
export const REMOVE_USER_DATA = 'USER_REMOVE_DATA';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';

export function setUserData(user) {
    return (dispatch) => {
        dispatch({
            type: SET_USER_DATA,
            data: user
        });
    };
}

export function logoutUser() {
    return async (dispatch) => {
        await dispatch({
            type: USER_LOGGED_OUT
        });

        await FirebaseAuthService.signOut();
        localStorage.clear();

        await history.push({
            pathname: '/session/signin'
        });
    };
}

export const formatRupiah = (angka, prefix, noPrefix) => {
    if (angka != undefined) {
        var number_string = angka.toString().replace(/[^,\d]/g, ''),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        if (!noPrefix) {
            return prefix === undefined
                ? 'Rp' + rupiah
                : rupiah
                ? prefix + rupiah
                : '';
        } else {
            return rupiah;
        }
    }
};

export function setEmailVerifed(status) {
    return (dispatch) => {
        dispatch({
            type: EMAIL_VERIFIED,
            data: status
        });
    };
}

export const changeEmail = async (params) => {
    const uid = await localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return await axios
        .post(`${process.env.REACT_APP_API_URL}scpns/auth/change_email`, params)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const changeProfile = async (params) => {
    return FirebaseAuthService.auth.currentUser
        .updateProfile(params)
        .then(() => {
            let uid = localStorage.getItem('uid');
            FirebaseAuthService.firestore
                .collection('users')
                .doc(uid)
                .update({
                    name: params.displayName,
                    phone: params.phoneNumber
                })
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    return err;
                });
        })
        .catch((err) => {
            return err;
        });
};

export const changePassword = async (params) => {
    const currentUser = FirebaseAuthService.auth.currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        params.password_lama
    );
    return currentUser
        .reauthenticateWithCredential(credential)
        .then(function () {
            currentUser
                .updatePassword(params.password)
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    return err;
                });
        })
        .catch(function (error) {
            return error;
        });
};

export const getSKDUser = (params) => {
    return FirebaseAuthService.firestore
        .collection('plan')
        .doc(params)
        .get()
        .then((res) => {
            if (res.exists) {
                let data = res.data();
                data.doc_id = res.id;
                return data;
            } else {
                return {};
            }
        });
};

export const getPPPKUser = (params) => {
    return FirebaseAuthService.firestore
        .collection('pppk')
        .doc(params)
        .get()
        .then((res) => {
            if (res.exists) {
                let data = res.data();
                data.doc_id = res.id;
                return data;
            } else {
                return {};
            }
        });
};

export const formatTanggal = (tanggal) => {
    const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember'
    ];

    if (tanggal !== undefined) {
        const d = new Date(tanggal.replace(' ', 'T'));
        // const ye = new Intl.DateTimeFormat('id', { year: 'numeric' }).format(d);
        const ye = d.getFullYear();
        // const mo = new Intl.DateTimeFormat('id', { month: 'long' }).format(d);
        const mo = d.getMonth();
        // const da = new Intl.DateTimeFormat('id', { day: '2-digit' }).format(d);
        const da = d.getDate();
        return `${da} ${monthNames[mo]} ${ye} ${tanggal.substr(
            11,
            tanggal.length - 3
        )}`;
    } else {
        return '';
    }
};

export const getTanggal = () => {
    let Tanggal = new Date();
    let tahun = Tanggal.getFullYear().toString();
    let bulan =
        Tanggal.getMonth() + 1 < 10
            ? '0' + (Tanggal.getMonth() + 1).toString()
            : (Tanggal.getMonth() + 1).toString();
    let tgl =
        Tanggal.getDate() < 10
            ? '0' + Tanggal.getDate().toString()
            : Tanggal.getDate().toString();
    let jam =
        Tanggal.getHours() < 10 ? '0' + Tanggal.getHours() : Tanggal.getHours();
    let menit =
        Tanggal.getMinutes() < 10
            ? '0' + Tanggal.getMinutes()
            : Tanggal.getMinutes();
    let detik =
        Tanggal.getSeconds() < 10
            ? '0' + Tanggal.getSeconds()
            : Tanggal.getSeconds();
    return (
        tahun + '-' + bulan + '-' + tgl + ' ' + jam + ':' + menit + ':' + detik
    );
};

export const checkExpiredTanggal = (tanggal) => {
    let now = new Date().getTime();
    let distance = new Date(tanggal?.replace(' ', 'T')).getTime();

    if (distance - now <= 0) {
        return false;
    } else {
        return true;
    }
};

export const updateProfile = async (params) => {
    const uid = await localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return await axios
        .post(
            `${process.env.REACT_APP_API_URL}scpns/admin/edit_profile`,
            params
        )
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
