import React, { Component } from 'react';
import {
    Dialog,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateSKB } from 'app/redux/actions/SKBActions';

class EditSKB extends Component {
    state = {
        id: '',
        diskon: '0',
        status: '1',
        name: '',
        statusButton: false,
        buttonText: 'Update',
        group_link: '',
        isActive: false
    };

    handleChange = (event, source) => {
        event.persist();
        if (source === 'switch') {
            console.log(event.target);
            this.setState({ isActive: event.target.checked });
            return;
        }

        if (source === 'status') {
            if (event.target.value === 'SUSPEND') {
                this.setState({
                    users_status: 0,
                    users_status_value: 'SUSPEND'
                });
                return;
            } else {
                this.setState({
                    users_status: 1,
                    users_status_value: 'ACTIVE'
                });
                return;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = async () => {
        this.setState({
            statusButton: true,
            buttonText: 'Loading...'
        });
        let { name, diskon, status, id, group_link } = this.state;
        let params = {
            is_discount: diskon,
            is_maintenance: status,
            name: name,
            group_link: group_link
        };
        await this.props.updateSKB(params, id);
        await this.props.handleClose();
    };

    componentWillMount() {
        this.setState({
            name: this.props.uid.name,
            diskon: this.props.uid.is_discount,
            status: this.props.uid.is_maintenance,
            id: this.props.uid.doc_id,
            group_link: this.props.uid.group_link
        });
    }

    handleChangeSelect = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render() {
        let { name, diskon, status, group_link } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open}>
                <div className='p-5' style={{ minWidth: '500px' }}>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid className='mb-10' container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Nama Paket *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='name'
                                    value={name}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Group Link *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='group_link'
                                    value={group_link}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <FormControl>
                                    <InputLabel id='demo-simple-select-helper-label'>
                                        Diskon *
                                    </InputLabel>
                                    <Select
                                        labelId='demo-simple-select-helper-label'
                                        id='demo-simple-select-helper'
                                        value={diskon}
                                        name='diskon'
                                        onChange={this.handleChangeSelect}
                                    >
                                        <MenuItem value={'1'}>Aktif</MenuItem>
                                        <MenuItem value={'0'}>
                                            NonAktif
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>
                                        Apakah Paket Terdapat Diskon ?
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item sm={6} xs={6}>
                                <FormControl>
                                    <InputLabel id='demo-simple-select-helper-label'>
                                        Status *
                                    </InputLabel>
                                    <Select
                                        labelId='demo-simple-select-helper-label'
                                        id='demo-simple-select-helper'
                                        name='status'
                                        value={status}
                                        onChange={this.handleChangeSelect}
                                    >
                                        <MenuItem value={'1'}>Aktif</MenuItem>
                                        <MenuItem value={'0'}>
                                            NonAktif
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>
                                        Apakah Paket Tersedia ?
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <p></p>
                            <div className='w-100 mb-16'>
                                <label color='gray'> * Required </label>
                            </div>
                        </Grid>

                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={this.state.statusButton}
                                color='primary'
                                type='submit'
                            >
                                {this.state.buttonText}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    updateSKB: PropTypes.func.isRequired
    // getAllProyecto: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { updateSKB })(EditSKB);
