import { Button, Fab, Icon } from '@material-ui/core';
import React, { Component } from 'react';

class UploadImageForm extends Component {
    state = {
        dragClass: '',
        files: [],
        statusList: [],
        queProgress: 0,
        pasar_picture_url: '',
        pasar_picture_preview: '',
        preview_img: ''
    };

    handleFileSelect = (event) => {
        let files = event.target.files;
        let list = [];

        let file = event.target.files || event.dataTransfer.files;
        if (!file.length) return;
        this.createImage(file[0]);
        this.setState(
            {
                preview_img: URL.createObjectURL(event.target.files[0])
                // pasar_picture_url: fileUploaded
            },
            () => {
                // this.createImage(file[0]);
                this.createMultipleImage(file);
            }
        );

        for (const iterator of files) {
            list.push({
                file: iterator,
                uploading: false,
                error: false,
                progress: 0
            });
        }

        this.setState({
            files: [...list]
        });
    };

    handleDragOver = (event) => {
        event.preventDefault();
        this.setState({ dragClass: 'drag-shadow' });
    };

    handleDrop = (event) => {
        event.preventDefault();
        event.persist();

        let files = event.dataTransfer.files;
        let list = [];

        let file = event.target.files || event.dataTransfer.files;
        if (!file.length) return;
        this.setState(
            {
                preview_img: URL.createObjectURL(event.dataTransfer.files[0])
                // pasar_picture_url: fileUploaded
            },
            () => {
                // this.createImage(file[0]);
                this.createMultipleImage(file);
            }
        );

        for (const iterator of files) {
            list.push({
                file: iterator,
                uploading: false,
                error: false,
                progress: 0
            });
        }

        this.setState(
            {
                dragClass: '',
                files: [...list]
            },
            () => {
                // this.createMultipleImage(this.state.files);
                // console.log(this.state.files);
            }
        );

        return false;
    };

    createMultipleImage(files) {
        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState(
                    {
                        pasar_picture_url: e.target.result
                    },
                    () => {
                        this.props.handleChange({
                            url: this.state.pasar_picture_url,
                            preview: this.state.preview_img,
                            name: files[i].name
                        });
                    }
                );
            };
            reader.readAsDataURL(files[i]);
        }
    }

    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState(
                {
                    pasar_picture_url: e.target.result
                },
                () => {
                    this.props.handleChange({
                        url: this.state.pasar_picture_url,
                        preview: this.state.preview_img
                    });
                }
            );
        };
        reader.readAsDataURL(file);
    }

    handleDragStart = (event) => {
        this.setState({ dragClass: 'drag-shadow' });
    };

    handleSingleRemove = (index) => {
        let files = [...this.state.files];
        files.splice(index, 1);
        this.setState({
            files: [...files]
        });
    };

    handleAllRemove = () => {
        this.setState({ files: [], preview_img: '' });
        this.props.handleChange({
            url: ''
        });
    };

    uploadSingleFile = (index) => {
        let allFiles = [...this.state.files];
        let file = this.state.files[index];

        allFiles[index] = { ...file, uploading: true, error: false };

        this.setState({
            files: [...allFiles]
        });
    };

    uploadAllFile = () => {
        let allFiles = [];

        this.state.files.map((item) => {
            allFiles.push({
                ...item,
                uploading: true,
                error: false
            });

            return item;
        });

        this.setState({
            files: [...allFiles],
            queProgress: 35
        });
    };

    handleSingleCancel = (index) => {
        let allFiles = [...this.state.files];
        let file = this.state.files[index];

        allFiles[index] = { ...file, uploading: false, error: true };

        this.setState({
            files: [...allFiles]
        });
    };

    handleCancelAll = () => {
        let allFiles = [];

        this.state.files.map((item) => {
            allFiles.push({
                ...item,
                uploading: false,
                error: true
            });

            return item;
        });

        this.setState({
            files: [...allFiles],
            queProgress: 0
        });
    };

    render() {
        let { dragClass, files, queProgress } = this.state;
        let isEmpty = files.length === 0;

        return (
            <div className='upload-form'>
                <div
                    className={`${dragClass} upload-drop-boxflex-center flex-middle`}
                    onDragEnter={this.handleDragStart}
                    onDragOver={this.handleDragOver}
                    onDrop={this.handleDrop}
                >
                    {isEmpty ? (
                        <div style={{ textAlign: 'center' }}>
                            <div className='flex-wrap'>
                                <label htmlFor='upload-single-file'>
                                    <Fab
                                        className='capitalize  elevation-z0'
                                        color='primary'
                                        component='span'
                                        variant='extended'
                                    >
                                        <div style={{ display: 'contents' }}>
                                            <Icon
                                                className='pr-8'
                                                style={{ fontSize: '40px' }}
                                            >
                                                cloud_upload
                                            </Icon>
                                            <span>Pilih File</span>
                                        </div>
                                    </Fab>
                                </label>
                                <input
                                    multiple
                                    className='display-none'
                                    onChange={this.handleFileSelect}
                                    id='upload-single-file'
                                    type='file'
                                    hidden={true}
                                />
                            </div>
                            <span>
                                <small>atau</small>
                            </span>
                            <br />
                            <span>Drop file kesini</span>
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <Icon className='pr-8' style={{ fontSize: '80px' }}>
                                insert_drive_file
                            </Icon>
                            <h5 className='m-0'>
                                {files.length} File Dipilih..
                            </h5>
                            <Button
                                variant='contained'
                                className='bg-error mt-3'
                                onClick={this.handleAllRemove}
                            >
                                Hapus
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default UploadImageForm;
