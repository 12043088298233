import {
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { getSKD } from 'app/redux/actions/SKDActions';
import { formatRupiah } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalEditPaket from './ModalEditPaket';

class SKDTable extends Component {
    constructor() {
        super();
        this.state = {
            modalEdit: false,
            edit_skd: {
                plan_benefit: [],
                plan_discount: 0,
                plan_expired_month: 0,
                plan_name: '',
                plan_price: 0,
                is_active: 0,
                doc_id: ''
            },
            rowsPerPage: 10,
            page: 0
        };
    }

    componentDidMount() {
        const { getSKD } = this.props;
        getSKD();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleDetail = (doc_id) => {
        const { history } = this.props;
        history.push('/skd/' + doc_id);
    };

    renderData = () => {
        const { data } = this.props.skd;
        const { page, rowsPerPage } = this.state;
        return data.length > 0 ? (
            data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                    return (
                        <TableRow hover key={index}>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                {index + 1}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                {item.plan_name}
                            </TableCell>
                            <TableCell className='px-2 py-2 text-center font-poppins'>
                                {formatRupiah(item.plan_price, 'Rp')}
                            </TableCell>
                            <TableCell className='px-2 py-2 text-center font-poppins'>
                                {formatRupiah(item.plan_discount, 'Rp')}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                <small
                                    className={`border-radius-4 text-white px-4 py-2 ${
                                        item.is_active
                                            ? 'bg-secondary'
                                            : 'bg-primary'
                                    }`}
                                >
                                    {item.is_active ? 'Aktif' : 'Tidak Aktif'}
                                </small>
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                <IconButton
                                    title='detail'
                                    onClick={() =>
                                        this.handleDetail(item.doc_id)
                                    }
                                >
                                    <Icon color='action'>visibility</Icon>
                                </IconButton>
                                <IconButton
                                    title='edit'
                                    onClick={() => this.handleEdit(item)}
                                >
                                    <Icon color='primary'>border_color</Icon>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    );
                })
        ) : (
            <TableRow hover>
                <TableCell colSpan={6} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };

    handleEdit = (item) => {
        const {
            plan_benefit,
            plan_discount,
            plan_expired_month,
            plan_name,
            plan_price,
            is_active,
            doc_id,
            plan_upgrade
        } = item;
        this.setState({
            edit_skd: {
                plan_benefit: plan_benefit,
                plan_discount: plan_discount,
                plan_expired_month: plan_expired_month,
                plan_name: plan_name,
                plan_price: plan_price,
                is_active: is_active,
                doc_id: doc_id,
                plan_upgrade: plan_upgrade
            },
            modalEdit: true
        });
    };

    handleCloseEdit = () => {
        this.setState({
            modalEdit: false
        });
    };

    render() {
        const { modalEdit, edit_skd, rowsPerPage, page } = this.state;
        const { skd } = this.props;
        return (
            <div className='m-sm-30'>
                <h2 style={{ fontSize: '25px' }} className='mb-3'>
                    List Paket SKD
                </h2>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Nama Paket
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Harga
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Diskon
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>
                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={skd.data.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                        {modalEdit && (
                            <ModalEditPaket
                                plan_benefit={edit_skd.plan_benefit}
                                plan_discount={edit_skd.plan_discount}
                                plan_expired_month={edit_skd.plan_expired_month}
                                plan_name={edit_skd.plan_name}
                                plan_price={edit_skd.plan_price}
                                is_active={edit_skd.is_active}
                                doc_id={edit_skd.doc_id}
                                plan_upgrade={edit_skd.plan_upgrade}
                                open={modalEdit}
                                handleClose={this.handleCloseEdit}
                            />
                        )}
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        skd: state.skd
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSKD: () => dispatch(getSKD())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SKDTable);
