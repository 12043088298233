import { Card, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { getDetailRankingSKD } from 'app/redux/actions/HasilSKDActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class TryoutRanking extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        const { getDetailRankingSKD } = this.props;
        getDetailRankingSKD(id);
    }

    render() {
        const { data, loading } = this.props.hasilSKD;
        return (
            <div className='m-sm-30'>
                <h2 style={{ fontSize: '25px' }} className='mb-3'>
                    Ranking Nasional
                </h2>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='crud-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Ranking
                                    </TableCell>
                                    <TableCell className='py-2 text-center font-poppins font-bold text-white'>
                                        Nama
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Skor Akhir
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow hover>
                                        <TableCell align='center' colSpan={3}>
                                            <CircularProgress
                                                size={25}
                                                color='primary'
                                            />
                                        </TableCell>
                                    </TableRow>
                                ) : data.length > 0 ? (
                                    data.map((item, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell
                                                className={`py-2 text-center font-poppins font-semibold ${
                                                    index < 9
                                                        ? 'text-green'
                                                        : 'text-error'
                                                }`}
                                                align='center'
                                            >
                                                {index == 0 ? (
                                                    <img
                                                        src='/assets/images/logos/medal-gold.svg'
                                                        className='img-medal'
                                                        alt='medal-gold'
                                                    />
                                                ) : index == 1 ? (
                                                    <img
                                                        src='/assets/images/logos/medal-silver.svg'
                                                        className='img-medal'
                                                        alt='medal-silver'
                                                    />
                                                ) : index == 2 ? (
                                                    <img
                                                        src='/assets/images/logos/medal-bronze.svg'
                                                        className='img-medal'
                                                        alt='medal-bronze'
                                                    />
                                                ) : (
                                                    index + 1
                                                )}
                                            </TableCell>
                                            <TableCell className='py-2 text-center font-poppins'>
                                                {item.user != null
                                                    ? item.user.name
                                                    : 'Sahabat CPNS'}
                                            </TableCell>
                                            <TableCell
                                                className='py-2 text-center font-poppins'
                                                align='center'
                                            >
                                                {item.point}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow hover>
                                        <TableCell
                                            className='py-2 text-center font-poppins'
                                            align='center'
                                            colSpan={3}
                                        >
                                            Data tidak ditemukan
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </Card>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        hasilSKD: state.hasilSKD
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailRankingSKD: (kode) => dispatch(getDetailRankingSKD(kode))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TryoutRanking);
