import { Button, Dialog, Grid, MenuItem } from '@material-ui/core';
import { addExercise, getTryoutList } from 'app/redux/actions/UKActions';
import React, { Component } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddLatihanDialog extends Component {
    state = {
        title: '',
        description: '',
        statusButton: false,
        buttonText: 'Tambah',
        isActive: false,
        formasi_id: Math.floor(Math.random() * Math.floor(3000)),
        duration: 0,
        question_count: 0,
        formasi_id: 0,
        is_shuffle: '1'
    };

    componentDidMount() {
        let id = window.location.pathname.split('/').pop();
        this.props.getTryoutList(id);
    }

    handleChange = (event, source) => {
        event.persist();
        if (source === 'switch') {
            // console.log(event.target);
            this.setState({ isActive: event.target.checked });
            return;
        }

        if (source === 'status') {
            if (event.target.value === 'SUSPEND') {
                this.setState({
                    users_status: 0,
                    users_status_value: 'SUSPEND'
                });
                return;
            } else {
                this.setState({
                    users_status: 1,
                    users_status_value: 'ACTIVE'
                });
                return;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeSelect = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = async () => {
        this.setState({
            statusButton: true,
            buttonText: 'Loading...'
        });
        let {
            title,
            description,
            question_count,
            formasi_id,
            duration,
            is_shuffle
        } = this.state;
        let parent_id = window.location.pathname.split('/').pop();
        let params = {
            title: title,
            description: description,
            question_count: question_count,
            duration: duration,
            formasi_id: formasi_id,
            id: formasi_id,
            created_at: new Date(),
            is_shuffle: is_shuffle
        };
        await this.props.addExercise(params, parent_id);
        await this.props.handleClose();
    };

    handleUploadThumbnail = (file, path) => {
        this.setState({
            materi_file: file,
            preview_file: path
        });
    };

    render() {
        let {
            title,
            description,
            formasi_id,
            duration,
            question_count,
            is_shuffle
        } = this.state;
        let { open, handleClose, tryout } = this.props;
        return (
            <Dialog onClose={handleClose} open={open}>
                <div className='p-5' style={{ minWidth: '500px' }}>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Latihan Untuk Tryout *'
                                    onChange={this.handleChange}
                                    name='formasi_id'
                                    value={formasi_id}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value={0} disabled>
                                        <em>Pilih</em>
                                    </MenuItem>
                                    {tryout.map((item) => (
                                        <MenuItem value={item.formasi_id}>
                                            {item.title}
                                        </MenuItem>
                                    ))}
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Random Soal *'
                                    onChange={this.handleChange}
                                    name='is_shuffle'
                                    value={is_shuffle}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Aktif</MenuItem>
                                    <MenuItem value='0'>Nonaktif</MenuItem>
                                </SelectValidator>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Nama Latihan *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Deskripsi Latihan *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Jumlah Soal *'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='question_count'
                                    value={question_count}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Durasi (Dalam Menit) *'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='duration'
                                    value={duration}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>

                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={this.state.statusButton}
                                color='primary'
                                type='submit'
                            >
                                {this.state.buttonText}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        tryout: state.uk.tryout
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addExercise: (params, id) => dispatch(addExercise(params, id)),
        getTryoutList: (id) => dispatch(getTryoutList(id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddLatihanDialog);
