import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import { addTryoutSKDPlatinum } from 'app/redux/actions/SKDActions';
import { getTanggal } from 'app/redux/actions/UserActions';
import React, { Component, Fragment } from 'react';
import {
    TextValidator,
    ValidatorForm,
    SelectValidator
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddTryoutSKDPlatinum extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            description: '',
            expires_time: '',
            jml_soal: '',
            title: '',
            submit: false,
            parent_id: parent,
            is_shuffle: '1',
            display: '1',
            tahunTo: '',
            rankingActive: '1'
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeTO = (val) => {
        let value = val.target.value;
        this.setState({
            tahunTo: value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const {
            description,
            expires_time,
            jml_soal,
            parent_id,
            title,
            is_shuffle,
            display,
            rankingActive,
            tahunTo
        } = this.state;
        let now = getTanggal();
        const params = {
            type: parent_id == 'free' ? 'gratis' : parent_id,
            description: description,
            expires_time: expires_time,
            title: title,
            jml_soal: jml_soal,
            created_at: now,
            is_shuffle: is_shuffle,
            display: display,
            tahun_tryout: tahunTo,
            ranking_active: rankingActive
        };
        const { handleClose, addTryoutSKD } = this.props;
        handleClose();
        addTryoutSKD(params, parent_id);
        this.setState({
            submit: false
        });
    };

    render() {
        let {
            submit,
            description,
            expires_time,
            jml_soal,
            title,
            is_shuffle,
            display,
            tahunTo,
            rankingActive
        } = this.state;
        let { open, handleClose, tahunTOPremium } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Tryout SKD Platinum</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                className='d-flex items-end'
                            >
                                <TextValidator
                                    label='Nama Tryout'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={[
                                        'required',
                                        'maxStringLength:100'
                                    ]}
                                    errorMessages={[
                                        'Field is Required',
                                        'Maximum 100 Character'
                                    ]}
                                    className='w-full'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-6 w-full'
                                    label='Deskripsi Tryout'
                                    multiline
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Jumlah Soal'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='jml_soal'
                                    value={jml_soal}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Durasi Pengerjaan (menit)'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='expires_time'
                                    value={expires_time}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='w-full'
                                    label='Random Soal'
                                    onChange={this.handleChange}
                                    name='is_shuffle'
                                    value={is_shuffle}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Aktif</MenuItem>
                                    <MenuItem value='0'>Nonaktif</MenuItem>
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='w-full'
                                    label='Tampilkan Tryout ?'
                                    onChange={this.handleChange}
                                    name='display'
                                    value={display}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Iya</MenuItem>
                                    <MenuItem value='0'>Tidak</MenuItem>
                                </SelectValidator>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={12} xs={12}>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Tahun Tryout'
                                    onChange={this.handleChangeTO}
                                    name='tahunTO'
                                    value={tahunTo}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    {tahunTOPremium.length > 0 ? (
                                        tahunTOPremium.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item.doc_id}
                                            >
                                                {item.title}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </SelectValidator>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={12} xs={12}>
                                <SelectValidator
                                    className='w-full'
                                    label='Sistem Ranking Ujian ?'
                                    onChange={this.handleChange}
                                    name='rankingActive'
                                    value={rankingActive}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Iya</MenuItem>
                                    <MenuItem value='0'>Tidak</MenuItem>
                                </SelectValidator>
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tahunTOPremium: state.tahunTOPremium.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTryoutSKD: (params, type) =>
            dispatch(addTryoutSKDPlatinum(params, type))
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTryoutSKDPlatinum);
