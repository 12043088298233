import Batch from './Batch';

const BatchRoutes = [
	{
		path: "/batch",
		component: Batch,
	},
];

export default BatchRoutes;
