import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
    CardTryoutAdmin,
    CardNomorSoalAdmin,
    CardSoalAdmin
} from 'app/components';
import {
    getDetailExerciseUK,
    getSoalExerciseUK
} from 'app/redux/actions/KerjakanLatihanUKActions';
import { deleteQuestionExercise } from 'app/redux/actions/UKActions';
import {
    setDefaultSettings,
    setLayoutSettings
} from 'app/redux/actions/LayoutActions';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isMdScreen } from 'utils';
import AddQuestionDialog from './AddQuestionDialog';
import EditQuestionDialog from './EditQuestionDialog';
import { ConfirmationDialog } from 'matx';
import ImportQuestionDialog from './ImportQuestionDialog';

const styles = (theme) => ({});
class LatihanKerjakan extends Component {
    constructor() {
        super();
        this.state = {
            no_soal: 0,
            openEditor: false,
            openCreator: false,
            openConfirm: false,
            openImport: false,
            editData: []
        };
    }

    handleSidenavToggle = () => {
        let {
            settings: {
                layout1Settings: {
                    leftSidebar: { mode }
                }
            }
        } = this.props;

        this.updateSidebarMode({
            mode: mode === 'compact' ? 'full' : 'compact'
        });
    };

    updateSidebarMode = (sidebarSettings) => {
        let { settings, setLayoutSettings, setDefaultSettings } = this.props;
        const updatedSettings = merge({}, settings, {
            layout1Settings: {
                leftSidebar: {
                    ...sidebarSettings
                }
            }
        });

        setLayoutSettings(updatedSettings);
        setDefaultSettings(updatedSettings);
    };

    componentDidMount() {
        this.getData();
        this.handleSidebar();
    }

    getData = async () => {
        const { doc_id, parent } = this.props.match.params;
        const { getDetailExerciseUK, getSoalExerciseUK } = this.props;
        await getDetailExerciseUK(parent, doc_id);
        await getSoalExerciseUK(parent, doc_id);
    };

    componentWillUnmount() {
        this.handleSidebar();
    }
    handleSidebar = () => {
        let sideMode;
        if (isMdScreen()) {
            sideMode = 'close';
        } else {
            sideMode = 'compact';
        }
        this.updateSidebarMode({ mode: sideMode });
    };
    handleChangeNo = (no_soal) => {
        this.setState({
            no_soal: no_soal
        });
    };

    handleEditSoal = (data) => {
        this.setState({
            openEditor: true,
            editData: data
        });
    };

    handleDeleteSoal = (data) => {
        this.setState({
            openConfirm: true,
            deleteData: data.doc_id
        });
    };

    handleAddSoal = () => {
        this.setState({
            openCreator: true
        });
    };

    handleImportSoal = () => {
        this.setState({
            openImport: true
        });
    };

    handleDialogClose = () => {
        this.setState({
            openEditor: false,
            openCreator: false,
            shouldOpenCreateDialog: false,
            openConfirm: false,
            openImport: false
        });
        this.getData();
    };

    handleConfirmationResponse = async () => {
        const { doc_id, parent } = this.props.match.params;
        const { no_soal } = this.state;
        if (no_soal > 0) {
            this.setState({
                no_soal: no_soal - 1
            });
        }
        await this.props.deleteQuestionExercise(
            parent,
            doc_id,
            this.state.deleteData
        );
        this.handleDialogClose();
    };

    render() {
        const {
            no_soal,
            openEditor,
            openCreator,
            openConfirm,
            openImport
        } = this.state;
        const {
            soalExercise,
            detailExercise: { title, duration }
        } = this.props;
        soalExercise.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
        return (
            <div className='m-sm-30'>
                <div id='top-element'></div>
                {soalExercise.length <= 0 ? (
                    <div className='py-4 w-full text-center'>
                        <h5 className='m-0'>Belum ada soal yang dibuat</h5>
                        <p className='m-0'>Buat atau import Soal Sekarang</p>
                        <div className='my-4 w-full text-center'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='button'
                                onClick={this.handleAddSoal}
                                className='m-2'
                            >
                                Buat Soal
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                type='button'
                                onClick={this.handleImportSoal}
                                className='m-2'
                            >
                                Import Soal
                            </Button>
                        </div>
                    </div>
                ) : (
                    <CardTryoutAdmin title={title} duration={duration}>
                        <Grid container spacing={3}>
                            <Grid item lg={9} m={9} sm={12} xs={12}>
                                <CardSoalAdmin
                                    title={`Soal Nomor ${no_soal + 1}`}
                                    jml_soal={soalExercise.length}
                                    no_soal={no_soal}
                                    soal={soalExercise[no_soal].question_text}
                                    soal_img={
                                        soalExercise[no_soal].question_img
                                    }
                                    jawaban={soalExercise[no_soal].option}
                                    handleChangeNo={this.handleChangeNo}
                                    handleEditSoal={() =>
                                        this.handleEditSoal(
                                            soalExercise[no_soal]
                                        )
                                    }
                                    handleDeleteSoal={() =>
                                        this.handleDeleteSoal(
                                            soalExercise[no_soal]
                                        )
                                    }
                                    id_soal={soalExercise[no_soal].doc_id}
                                    pembahasan={
                                        soalExercise[no_soal].pembahasan
                                    }
                                    pembahasan_img={
                                        soalExercise[no_soal].pembahasan_img
                                    }
                                    pembahasan_video={
                                        soalExercise[no_soal].video_pembahasan
                                    }
                                />
                            </Grid>
                            <Grid item lg={3} m={3} sm={12} xs={12}>
                                <CardNomorSoalAdmin
                                    handleChangeNo={this.handleChangeNo}
                                    title='Nomor Soal'
                                    soal={soalExercise}
                                    no_soal={no_soal}
                                    id_quis={
                                        soalExercise.length > 0
                                            ? soalExercise[no_soal].doc_id
                                            : ''
                                    }
                                    handleAddSoal={this.handleAddSoal}
                                    handleImportSoal={this.handleImportSoal}
                                />
                            </Grid>
                        </Grid>
                    </CardTryoutAdmin>
                )}
                {openEditor && (
                    <EditQuestionDialog
                        handleClose={this.handleDialogClose}
                        open={openEditor}
                        dataEdit={this.state.editData}
                        params={this.props.match.params}
                    />
                )}
                {openCreator && (
                    <AddQuestionDialog
                        handleClose={this.handleDialogClose}
                        open={openCreator}
                        uid={this.state.uid}
                        params={this.props.match.params}
                        totalSoal={soalExercise.length}
                    />
                )}
                {openConfirm && (
                    <ConfirmationDialog
                        open={openConfirm}
                        onConfirmDialogClose={this.handleDialogClose}
                        onYesClick={this.handleConfirmationResponse}
                        text='Hapus Data Ini ?'
                    />
                )}
                {openImport && (
                    <ImportQuestionDialog
                        open={openImport}
                        handleClose={this.handleDialogClose}
                        params={this.props.match.params}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    setDefaultSettings: PropTypes.func.isRequired,
    setLayoutSettings: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    user: state.user,
    settings: state.layout.settings,
    detailExercise: state.kerjakanLatUK.exercise,
    soalExercise: state.kerjakanLatUK.soal,
    jawabanExercise: state.kerjakanLatUK.jawaban,
    detailJawaban: state.kerjakanLatUK.detail_jawaban
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLayoutSettings: (data) => dispatch(setLayoutSettings(data)),
        setDefaultSettings: (data) => dispatch(setDefaultSettings(data)),
        getDetailExerciseUK: (parent, kode) =>
            dispatch(getDetailExerciseUK(parent, kode)),
        getSoalExerciseUK: (parent, kode) =>
            dispatch(getSoalExerciseUK(parent, kode)),
        deleteQuestionExercise: (parent, id, qt) =>
            dispatch(deleteQuestionExercise(parent, id, qt))
    };
};

export default withStyles(styles, { withTheme: true })(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(LatihanKerjakan))
);
