import axios from 'axios';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const LOADING = 'LOADING';
export const UPD_QUESTION_TRYOUT_BUNDLE = 'UPD_QUESTION_TRYOUT_BUNDLE';
export const GET_SOAL_TRYOUT_BUNDLE = 'GET_SOAL_TRYOUT_BUNDLE';
export const GET_DETAIL_TRYOUT_BUNDLE = 'GET_DETAIL_TRYOUT_BUNDLE';
export const DELETE_TRYOUT_FROM_PAKET_SUCCESS =
    'DELETE_TRYOUT_FROM_PAKET_SUCCESS';
export const DELETE_TRYOUT_FROM_PAKET_FAILURE =
    'DELETE_TRYOUT_FROM_PAKET_FAILURE';
export const UPDATE_TRYOUT_SUCCESS = 'UPDATE_TRYOUT_SUCCESS';
export const ADD_PAKET_TRYOUT = 'ADD_PAKET_TRYOUT';
export const UPDATE_TRYOUT_FAILURE = 'UPDATE_TRYOUT_FAILURE';
export const GET_PAKET_TRYOUT = 'GET_PAKET_TRYOUT';
export const DEL_PAKET_TRYOUT = 'DEL_PAKET_TRYOUT';
export const GET_LIST_PAKET_TRYOUT = 'GET_LIST_PAKET_TRYOUT';
export const ADD_NEW_TRYOUT_TO_PAKET_FAILURE =
    'ADD_NEW_TRYOUT_TO_PAKET_FAILURE';
export const ADD_NEW_TRYOUT_TO_PAKET_SUCCESS =
    'ADD_NEW_TRYOUT_TO_PAKET_SUCCESS';
export const rearrangeSoal = (array) => {
    let all = [];
    let datatwk = array
        .filter((item) => item.category == 'twk')
        // .sort((a, b) => (a.subcategory < b.subcategory ? -1 : 1))
        .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
    for (let i = 0; i < datatwk.length; i++) {
        all.push(datatwk[i]);
    }
    let datatiu = array
        .filter((item) => item.category == 'tiu')
        // .sort((a, b) => (a.subcategory < b.subcategory ? -1 : 1))
        .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
    for (let i = 0; i < datatiu.length; i++) {
        all.push(datatiu[i]);
    }
    let datatkp = array
        .filter((item) => item.category == 'tkp')
        // .sort((a, b) => (a.subcategory < b.subcategory ? -1 : 1))
        .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
    for (let i = 0; i < datatkp.length; i++) {
        all.push(datatkp[i]);
    }

    return all;
};
export const addPaketTryout = (params) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });

        // Buat ID dokumen baru
        const docId = firebaseAuthService.firestore
            .collection('paket_tryout')
            .doc().id;

        firebaseAuthService.firestore
            .collection('paket_tryout')
            .doc(docId) // Gunakan ID dokumen yang baru dibuat
            .set({ ...params, id: docId }) // Tambahkan ID ke dalam data dokumen
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Paket Tryout berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_PAKET_TRYOUT,
                    payload: { ...params, id: docId }
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Paket Tryout gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
export const editTryoutPaket = (params, docId) => {
    return async (dispatch) => {
        try {
            // Update Firestore document
            await firebaseAuthService.firestore
                .collection('paket_tryout')
                .doc(docId)
                .update(params);
            await dispatch(getPaketTryout());
            return params;
        } catch (err) {
            // Handle errors
            console.log('Error editing event tryout:', err);
            throw err; // Rethrow error to be handled elsewhere if needed
        }
    };
};

export const getPaketTryout = () => {
    return async (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });

        try {
            const res = await firebaseAuthService.firestore
                .collection('paket_tryout')
                .get();

            let data = [];
            res.forEach(function (doc) {
                let item = doc.data();
                item.doc_id = doc.id;
                data.push(item);
            });

            data.sort((a, b) =>
                a.title.localeCompare(b.title, undefined, {
                    sensitivity: 'base'
                })
            );
            dispatch({
                type: GET_PAKET_TRYOUT,
                payload: data
            });
        } catch (error) {
            console.error('Error fetching paket tryout: ', error);
            Swal.fire({
                title: 'Gagal',
                text: 'Gagal mengambil data paket tryout',
                showConfirmButton: false,
                icon: 'error',
                timer: 2000
            });
        } finally {
            dispatch({
                type: LOADING,
                payload: false
            });
        }
    };
};
export const delTryoutPaket = (doc_id) => {
    return async (dispatch) => {
        try {
            await firebaseAuthService.firestore
                .collection('paket_tryout')
                .doc(doc_id)
                .delete();

            Swal.fire({
                title: 'Berhasil',
                text: 'Tryout berhasil dihapus',
                icon: 'success',
                timer: 2000
            });
            // Memanggil kembali daftar paket event SKD
            await dispatch(getPaketTryout());

            // Dispatch aksi penghapusan tryout
            dispatch({
                type: DEL_PAKET_TRYOUT,
                payload: doc_id
            });
        } catch (error) {
            Swal.fire({
                title: 'Gagal',
                text: 'Tryout gagal dihapus',
                icon: 'error',
                timer: 2000
            });

            console.error('Error deleting tryout event:', error);
        }
    };
};
export const getTryoutPaketList = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('paket_tryout')
            .doc(doc_id)
            .get()
            .then(async (doc) => {
                if (doc.exists) {
                    const tryouts = doc.data().tryouts || []; // Mengambil daftar ID tryout atau inisialisasi sebagai array kosong jika tidak ada
                    let data = [];

                    // Jika perlu mengambil detail dari setiap tryout
                    for (let tryoutId of tryouts) {
                        const tryoutDoc = await firebaseAuthService.firestore
                            .collection('paket_tryout_list')
                            .doc(tryoutId)
                            .get();

                        if (tryoutDoc.exists) {
                            let item = tryoutDoc.data();
                            item.doc_id = tryoutDoc.id;
                            data.push(item);
                        }
                    }

                    // Sorting jika diperlukan
                    data.sort((a, b) =>
                        a.title.localeCompare(b.title, undefined, {
                            sensitivity: 'base'
                        })
                    );

                    // Dispatch data
                    dispatch({
                        type: GET_LIST_PAKET_TRYOUT,
                        payload: data
                    });
                } else {
                    console.log('No such document!');
                }
            })
            .catch((error) => {
                console.error('Error getting document: ', error);
            });
    };
};

export const addNewTryoutToPaket = (doc_id, tryoutData) => {
    return async (dispatch) => {
        if (!doc_id) {
            console.error('Error: doc_id tidak boleh kosong.');
            return;
        }

        try {
            // Simpan tryout baru ke koleksi `tryouts`
            const tryoutRef = await firebaseAuthService.firestore
                .collection('paket_tryout_list')
                .add({
                    ...tryoutData
                });

            const tryout_id = tryoutRef.id;

            if (!tryout_id) {
                console.error('Error: ID tryout tidak dihasilkan.');
                return;
            }

            // Ambil dokumen paket yang relevan berdasarkan `doc_id`
            const paketDoc = await firebaseAuthService.firestore
                .collection('paket_tryout')
                .doc(doc_id)
                .get();

            if (paketDoc.exists) {
                let tryouts = paketDoc.data().tryouts || [];

                // Tambahkan ID tryout baru ke array `tryouts` di dokumen paket
                if (!tryouts.includes(tryout_id)) {
                    tryouts.push(tryout_id);
                }

                await firebaseAuthService.firestore
                    .collection('paket_tryout')
                    .doc(doc_id)
                    .update({
                        tryouts: tryouts,
                        updated_at: new Date().toISOString()
                    });

                dispatch({
                    type: 'ADD_NEW_TRYOUT_TO_PAKET_SUCCESS',
                    payload: { doc_id, tryout_id }
                });
                await dispatch(getTryoutPaketList(doc_id));
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil ditambah',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                console.log('Tryout baru berhasil ditambahkan ke paket.');
            } else {
                console.log('Dokumen paket tidak ditemukan.');
            }
        } catch (error) {
            console.error('Error adding new tryout to paket: ', error);
            dispatch({
                type: 'ADD_NEW_TRYOUT_TO_PAKET_FAILURE',
                payload: { error }
            });
        }
    };
};

export const deleteTryoutFromPaket = (paket_id, tryout_id) => {
    return async (dispatch) => {
        if (!paket_id || !tryout_id) {
            console.error('Error: paket_id dan tryout_id tidak boleh kosong.');
            return;
        }

        try {
            // Hapus dokumen tryout dari koleksi `tryouts`
            await firebaseAuthService.firestore
                .collection('paket_tryout_list')
                .doc(tryout_id)
                .delete();

            // Ambil dokumen paket yang relevan berdasarkan `paket_id`
            const paketDoc = await firebaseAuthService.firestore
                .collection('paket_tryout')
                .doc(paket_id)
                .get();

            if (paketDoc.exists) {
                let tryouts = paketDoc.data().tryouts || [];

                // Hapus ID tryout dari array `tryouts` di dokumen paket
                tryouts = tryouts.filter((id) => id !== tryout_id);

                await firebaseAuthService.firestore
                    .collection('paket_tryout')
                    .doc(paket_id)
                    .update({
                        tryouts: tryouts,
                        updated_at: new Date().toISOString()
                    });

                dispatch({
                    type: DELETE_TRYOUT_FROM_PAKET_SUCCESS,
                    payload: { paket_id, tryout_id }
                });
                await dispatch(getTryoutPaketList(paket_id));
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                console.log('Tryout berhasil dihapus dari paket.');
            } else {
                console.log('Dokumen paket tidak ditemukan.');
            }
        } catch (error) {
            console.error('Error deleting tryout from paket: ', error);
            dispatch({
                type: DELETE_TRYOUT_FROM_PAKET_FAILURE,
                payload: { error }
            });
        }
    };
};

export const updateTryout = (paket_id, tryoutData) => {
    return async (dispatch) => {
        const { id, ...data } = tryoutData;

        if (!id) {
            console.error('Error: ID tryout tidak boleh kosong.');
            return;
        }

        try {
            await firebaseAuthService.firestore
                .collection('paket_tryout_list')
                .doc(id)
                .update({
                    ...data
                });

            dispatch({
                type: UPDATE_TRYOUT_SUCCESS,
                payload: tryoutData
            });
            await dispatch(getTryoutPaketList(paket_id));
            Swal.fire({
                title: 'Berhasil',
                text: 'Tryout berhasil diubah',
                showConfirmButton: false,
                icon: 'success',
                timer: 2000
            });
            console.log('Tryout berhasil diperbarui.');
        } catch (error) {
            console.error('Error updating tryout: ', error);
            dispatch({
                type: UPDATE_TRYOUT_FAILURE,
                payload: { error }
            });
        }
    };
};
export const getSoalTryoutPaketBundle = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('paket_tryout_list')
            .doc(kode)
            .collection('question')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL_TRYOUT_BUNDLE,
                    payload: rearrangeSoal(data)
                });
            });
    };
};
export const getDetailTryoutBundel = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('paket_tryout_list')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_TRYOUT_BUNDLE,
                    payload: data
                });
            });
    };
};
export const delQuestionTryoutBundle = (tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('paket_tryout_list')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutPaketBundle(tryout_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
export const addQuestionTryoutBundle = (params, doc_id, soalTryout) => {
    let length =
        soalTryout.filter((item) => item.category == params.category).length +
        1;
    params.id = length;
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('paket_tryout_list')
            .doc(doc_id)
            .collection('question')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutPaketBundle(doc_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
export const updQuestionTryoutBundel = (params, tryout_id, doc_id) => {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>', params);
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('paket_tryout_list')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: UPD_QUESTION_TRYOUT_BUNDLE,
                        payload: { params, doc_id }
                    });
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
