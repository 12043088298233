import { Card } from '@material-ui/core';
import React from 'react';
import { classList } from 'utils';

const SimpleCard = ({ children, title, subtitle, noPadding }) => {
    return (
        <Card
            elevation={6}
            className={classList({
                'h-full': true,
                'px-6 py-5': !noPadding
            })}
        >
            {title && (
                <div
                    className={classList({
                        'card-title font-bold': true,
                        'mb-4': !subtitle
                    })}
                >
                    {title}
                </div>
            )}
            {subtitle && <div className="card-subtitle mb-4">{subtitle}</div>}
            {/* {!noBorder && <div className="bordered mb-5"></div>} */}
            {children}
        </Card>
    );
};

export default SimpleCard;
