import {
    GET_TRYOUT_SKD_EVENT,
    RESET_REDUCER,
    DEL_TRYOUT_SKD_EVENT,
    GET_PAKET_EVENT,
    GET_DETAIL_TRYOUT_EVENT,
    GET_SOAL_EVENT
} from '../actions/EventSKDActions';

const initialState = {
    data: [],
    materi: [],
    latihan: [],
    soal: [],
    tryout: [],
    paket: [],
    detail_paket: null
};

const EventSKDReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PAKET_EVENT: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case GET_TRYOUT_SKD_EVENT: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case GET_DETAIL_TRYOUT_EVENT: {
            return {
                ...state,
                detail_paket: action.payload
            };
        }
        case GET_SOAL_EVENT: {
            return {
                ...state,
                soal: action.payload
            };
        }
        case DEL_TRYOUT_SKD_EVENT: {
            let tryout = state.tryout.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                tryout: tryout
            };
        }
        default: {
            return state;
        }
    }
};

export default EventSKDReducer;
