import axios from 'axios';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const LOADING = 'LOADING';
export const GET_GAMBAR = 'GET_GAMBAR';
export const ADD_GAMBAR = 'ADD_GAMBAR';
export const DELETE_GAMBAR = 'DELETE_GAMBAR';

export const getGambar = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('image_resource')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_GAMBAR,
                    payload: data
                });
            });
    };
};

export const deleteGambar = (id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('image_resource')
            .doc(id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Gambar berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_GAMBAR,
                    payload: []
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const addImages = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;

    Swal.fire({
        title: 'Submitting...',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
            Swal.showLoading();
        }
    });
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}scpns/images/add`, params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Gambar berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getGambar());
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gambar gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};
