import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import { addLatihanSKD } from 'app/redux/actions/SKDActions';
import { getTanggal } from 'app/redux/actions/UserActions';
import React, { Component, Fragment } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddLatihan extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            description: '',
            expires_time: '',
            jml_soal: '',
            title: '',
            category: '',
            submit: false,
            parent_id: parent,
            is_shuffle: '1'
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const {
            description,
            expires_time,
            jml_soal,
            parent_id,
            title,
            category
        } = this.state;
        let now = getTanggal();
        let parent = window.location.pathname.split('/').pop();
        const params = {
            category: category,
            description: description,
            expires_time: expires_time,
            title: title,
            jml_soal: jml_soal,
            created_at: now,
            type: parent
        };
        const { handleClose, addLatihanSKD } = this.props;
        handleClose();
        addLatihanSKD(params, parent_id);
        this.setState({
            submit: false
        });
    };

    render() {
        let {
            submit,
            description,
            expires_time,
            jml_soal,
            title,
            category,
            parent_id,
            is_shuffle
        } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Latihan SKD</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    label='Nama Latihan'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={[
                                        'required',
                                        'maxStringLength:100'
                                    ]}
                                    errorMessages={[
                                        'Field is Required',
                                        'Maximum 100 Character'
                                    ]}
                                    className='w-full'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Kategory Tryout'
                                    onChange={this.handleChange}
                                    name='category'
                                    value={category}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem
                                        value={
                                            parent_id == 'dinas'
                                                ? 'twk_dinas'
                                                : 'twk'
                                        }
                                    >
                                        TWK
                                    </MenuItem>
                                    <MenuItem
                                        value={
                                            parent_id == 'dinas'
                                                ? 'tiu_dinas'
                                                : 'tiu'
                                        }
                                    >
                                        TIU
                                    </MenuItem>
                                    <MenuItem
                                        value={
                                            parent_id == 'dinas'
                                                ? 'tkp_dinas'
                                                : 'tkp'
                                        }
                                    >
                                        TKP
                                    </MenuItem>
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-6 w-full'
                                    label='Deskripsi Latihan'
                                    multiline
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                className='flex items-end'
                            >
                                <Grid container spacing={2} className='mb-4'>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Jumlah Soal'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='jml_soal'
                                            value={jml_soal}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Durasi (menit)'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='expires_time'
                                            value={expires_time}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Random Soal'
                                    onChange={this.handleChange}
                                    name='is_shuffle'
                                    value={is_shuffle}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Aktif</MenuItem>
                                    <MenuItem value='0'>NonAktif</MenuItem>
                                </SelectValidator>
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addLatihanSKD: (params, type) => dispatch(addLatihanSKD(params, type))
    };
};
export default connect(null, mapDispatchToProps)(AddLatihan);
