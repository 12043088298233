import { Card } from '@material-ui/core';
import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

const ChartTransaksi = ({ skd, skb, uk, pppk }) => {
    let data = {
        labels: ['SKD', 'SKB', 'UK', 'PPPK'],
        datasets: [
            {
                data: [skd, skb, uk, pppk],
                backgroundColor: [
                    'rgba(0, 183, 194, 0.4)',
                    'rgba(22, 199, 154, 0.4)',
                    'rgba(255, 226, 39, 0.4)',
                    'rgba(255, 157, 59, 0.4)'
                ],
                borderColor: [
                    'rgba(0, 183, 194, 1)',
                    'rgba(22, 199, 154, 1)',
                    'rgba(255, 226, 39, 1)',
                    'rgba(255, 157, 59, 1)'
                ],
                borderWidth: 1
            }
        ]
    };
    let option = {
        legend: false
    };
    return (
        <Card
            className='text-center py-6 border-radius-4 h-full px-10'
            elevation={3}
        >
            <h5 className='font-bold mb-4'>Grafik Transaksi Berhasil</h5>
            <Bar data={data} options={option} />
            <div className='flex justify-between items-center mt-2'>
                <h5 className='font-medium'>SKD: {skd}</h5>
                <h5 className='font-medium'>SKB: {skb}</h5>
                <h5 className='font-medium'>UK: {uk}</h5>
                <h5 className='font-medium'>PPPK: {pppk}</h5>
            </div>
        </Card>
    );
};

export default ChartTransaksi;
