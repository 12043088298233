import Swal from 'sweetalert2';
import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_ALL_GROUP = 'GET_ALL_GROUP';
export const EDIT_GROUP = 'EDIT_GROUP';

export const getAllGroup = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('group_link')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_ALL_GROUP,
                    payload: data
                });
            });
    };
};

export const updateGroup = (params, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('group_link')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Link berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: EDIT_GROUP,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Link gagal diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
