import { Button, Dialog, Grid, InputAdornment } from '@material-ui/core';
import { updateWebSetting } from 'app/redux/actions/WebSettingActions';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class ModalEditKomisi extends Component {
    constructor() {
        super();
        this.state = {
            komisi: 0,
            doc_id: ''
        };
    }

    componentDidMount() {
        let { komisi, doc_id } = this.props;
        this.setState({
            komisi: komisi,
            doc_id: doc_id
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    submitForm = () => {
        let { komisi, doc_id } = this.state;
        const { updateWebSetting, handleClose } = this.props;
        let params = {
            komisi: komisi
        };
  
        updateWebSetting(params, doc_id);
         setTimeout(() => {
             handleClose();
         }, 1500);
    };

    render() {
        let { komisi } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth='xs'
                fullWidth={true}
            >
                <div className='p-5'>
                    <h4 className='mb-5'>Edit Presentase Komisi</h4>
                    <ValidatorForm ref='form' onSubmit={this.submitForm}>
                        <Grid container spacing={4} className='mb-4'>
                            <Grid item sm={12} xs={12} className='pb-0'>
                                <TextValidator
                                    className='w-full'
                                    label='Komisi'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='komisi'
                                    value={komisi}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                %
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle mt-5'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                Ubah
                            </Button>
                            <Button onClick={handleClose}>Batal</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateWebSetting: (params, id) => dispatch(updateWebSetting(params, id))
    };
};

export default connect(null, mapDispatchToProps)(ModalEditKomisi);
