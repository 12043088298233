import axios from 'axios';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_SOAL_PEMBAHASAN = 'GET_SOAL_PEMBAHASAN';
export const DEL_PEMBAHASAN_PPPK = 'DEL_PEMBAHASAN_PPPK';

export const getPembahasan = (parent) => {
    return async (dispatch) => {
        parent = parent == undefined ? '' : parent;
        firebaseAuthService.firestore
            .collection('soal_pembahasan_pppk')
            .where('category', '==', parent)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL_PEMBAHASAN,
                    payload: data
                });
            });
    };
};

export const addSoalPPPK = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(`https://materi.sahabatcpns.com/api/v1/scpns/soal_pppk/add`, params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getPembahasan(params.category));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const editSoalPPPK = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/soal_pppk/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getPembahasan());
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const delSoalPPPK = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('soal_pembahasan_pppk')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_PEMBAHASAN_PPPK,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
