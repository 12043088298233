import {
    Card,
    Grid,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { CardKomisi, CardKomisiBatal, CardKomisiBelum, CardKomisiSudah } from 'app/components';
import { getAllKomisiBelum } from 'app/redux/actions/KomisiActions';
import { formatRupiah, formatTanggal } from 'app/redux/actions/UserActions';
import { getWebSetting } from 'app/redux/actions/WebSettingActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalEditKomisi from './ModalEditKomisi';
import ModalEditWidraw from './ModalEditWidraw';

class Komisi extends Component {
    constructor() {
        super();
        this.state = {
            modalAdd: false,
            modalEdit: false,
            edit_widraw: {
                id_user: '',
                nominal: '',
                status: '',
                doc_id: '',
                user: null,
                alasan: '',
                bukti: ''
            },
            rowsPerPage: 10,
            page: 0,
            modalKomisiReferral: false
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const { getWebSetting } = this.props;

        getWebSetting();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleModalKomisi = () => {
        const { modalKomisiReferral } = this.state;
        this.setState({
            modalKomisiReferral: !modalKomisiReferral
        });
    };

    handleModalKomisi = () => {
        const { modalKomisiReferral } = this.state;
        this.setState({
            modalKomisiReferral: !modalKomisiReferral
        });
    };

    render() {
        const { modalEdit, edit_widraw, modalKomisiReferral } = this.state;
        const { webSetting } = this.props;
        let referral = webSetting.data?.filter(
            (item) => item.doc_id == 'referral'
        );
        return (
            <div className='m-sm-30'>
                <div className='flex items-center justify-between mt-10 mb-3'>
                    <h2 style={{ fontSize: '25px' }}>List Penarikan Komisi</h2>
                </div>
                <Grid container>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <CardKomisi
                            komisi={
                                referral.length > 0 ? referral[0].komisi : null
                            }
                            handleUbah={this.handleModalKomisi}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <CardKomisiBelum
                            handleUbah={'/penarikan_komisi/belum'}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <CardKomisiSudah
                            handleUbah={'/penarikan_komisi/sudah'}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <CardKomisiBatal
                            handleUbah={'/penarikan_komisi/gagal'}
                        />
                    </Grid>
                </Grid>

                {modalKomisiReferral && (
                    <ModalEditKomisi
                        komisi={referral[0].komisi}
                        doc_id={referral[0].doc_id}
                        open={modalKomisiReferral}
                        handleClose={this.handleModalKomisi}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        webSetting: state.webSetting
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
     
        getWebSetting: () => dispatch(getWebSetting())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Komisi);
