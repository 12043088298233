import {
    DEL_LATIHAN_SKD,
    DEL_MATERI_SKD,
    DEL_SOAL,
    DEL_TRYOUT_SKD,
    GET_LATIHAN_SKD,
    GET_MATERI_SKD,
    GET_SKD,
    GET_SOAL,
    GET_TRYOUT_SKD,
    RESET_REDUCER
} from '../actions/SKDActions';

const initialState = {
    data: [],
    materi: [],
    latihan: [],
    soal: [],
    tryout: []
};

const SKDReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_SKD: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_MATERI_SKD: {
            return {
                ...state,
                materi: action.payload
            };
        }
        case GET_LATIHAN_SKD: {
            return {
                ...state,
                latihan: action.payload
            };
        }
        case DEL_MATERI_SKD: {
            let materi = state.materi.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                materi: materi
            };
        }
        case GET_SOAL: {
            return {
                ...state,
                soal: action.payload
            };
        }
        case RESET_REDUCER: {
            return {
                ...state,
                soal: [],
                materi: [],
                latihan: [],
                tryout: []
            };
        }
        case DEL_SOAL: {
            let soal = state.soal.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                soal: soal
            };
        }
        case GET_TRYOUT_SKD: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case DEL_TRYOUT_SKD: {
            let tryout = state.tryout.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                tryout: tryout
            };
        }
        case DEL_LATIHAN_SKD: {
            let latihan = state.latihan.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                latihan: latihan
            };
        }
        default: {
            return state;
        }
    }
};

export default SKDReducer;
