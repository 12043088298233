import Promo from './Promo';

const promoRoutes = [
    {
        path: '/promo',
        component: Promo
    }
];

export default promoRoutes;
