import {
    Button,
    CircularProgress,
    Dialog,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { addPaketBumn } from 'app/redux/actions/BumnActions';
import React, { Component } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddBumnDialog extends Component {
    constructor() {
        super();
        this.state = {
            pppk_benefit: [],
            discount: 0,
            expired_month: 0,
            title: '',
            price: 0,
            is_active: 0,
            form_benefit: {
                status: 1,
                benefit: '',
                index: '',
                isNew: true,
                error: ''
            },
            submit: false,
            doc_id: '',
            description: '',
            group_link: ''
        };
    }

    componentDidMount() {}

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    submitForm = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        let {
            pppk_benefit,
            discount,
            expired_month,
            title,
            price,
            is_active,
            description,
            group_link
        } = this.state;
        const { addPaketBumn, handleClose } = this.props;
        let params = {
            benefit: pppk_benefit,
            discount: discount,
            expired_month: expired_month,
            title: title,
            price: price,
            is_active: is_active,
            description: description,
     
            group_link: group_link
        };
        handleClose();
        addPaketBumn(params);
    };

    handleChangeBenefit = (event) => {
        let value = event.target.value;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                benefit: value
            }
        }));
    };

    handleStatusBenefit = (event) => {
        let checked = event.target.checked;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                status: checked ? 1 : 0
            }
        }));
    };

    handleStatusPaket = (event) => {
        let checked = event.target.checked;
        this.setState({
            is_active: checked ? 1 : 0
        });
    };

    handleFormBenefit = () => {
        const {
            form_benefit: { benefit, isNew, index, status },
            pppk_benefit
        } = this.state;
        if (benefit.length > 0) {
            if (isNew) {
                let allBenefit = pppk_benefit.concat({
                    status: status,
                    benefit: benefit
                });
                this.setState({
                    pppk_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            } else {
                let allBenefit = pppk_benefit;
                allBenefit[index] = {
                    status: status,
                    benefit: benefit
                };
                this.setState({
                    pppk_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            }
        } else {
            this.setState((prefState) => ({
                form_benefit: {
                    ...prefState.form_benefit,
                    error: 'Tolong isi semua inputan'
                }
            }));
        }
    };

    handleEditBenefit = (item, index) => {
        let benefit = item.benefit;
        let status = item.status;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                benefit: benefit,
                status: status,
                isNew: false,
                index: index
            }
        }));
    };

    handleDeleteBenefit = (i) => {
        const { pppk_benefit } = this.state;
        let allBenefit = pppk_benefit.filter((item, index) => index != i);
        this.setState({
            pppk_benefit: allBenefit
        });
    };

    render() {
        let {
            is_active,
            pppk_benefit,
            discount,
            expired_month,
            title,
            price,
            form_benefit,
            submit,
            group_link
        } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth='md'
                fullWidth={true}
            >
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Paket PPPK</h4>
                    <ValidatorForm ref='form' onSubmit={this.submitForm}>
                        <Grid container spacing={2} className='mb-2'>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Judul Paket'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            
                        </Grid>
                        <Grid className='mb-2' container spacing={4}>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Harga Paket'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='price'
                                    value={price}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Diskon Paket'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='discount'
                                    value={discount}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Masa aktif paket (bulan)'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='expired_month'
                                    value={expired_month}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Grup Link'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='group_link'
                                    value={group_link}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={is_active == 1}
                                            name='Status'
                                            onChange={this.handleStatusPaket}
                                            color='primary'
                                        />
                                    }
                                    label='Status Paket'
                                    className='mt-1'
                                />
                            </Grid>
                        </Grid>
                        <h5 className='mt-0'>Benefit Paket</h5>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <div className='w-full overflow-auto bg-white'>
                                    <Table className='crud-table'>
                                        <TableHead className='bg-primary'>
                                            <TableRow>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    No
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    colSpan={2}
                                                >
                                                    Benefit
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                ></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pppk_benefit.length > 0 ? (
                                                pppk_benefit.map(
                                                    (item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                align='center'
                                                            >
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                colSpan={2}
                                                            >
                                                                {item.benefit}
                                                            </TableCell>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                align='center'
                                                            >
                                                                <div
                                                                    className={`badge-status-benefit ${
                                                                        item.status ==
                                                                        1
                                                                            ? 'bg-green'
                                                                            : 'bg-error'
                                                                    }`}
                                                                >
                                                                    {item.status ==
                                                                    1 ? (
                                                                        <Icon>
                                                                            check
                                                                        </Icon>
                                                                    ) : (
                                                                        <Icon>
                                                                            close
                                                                        </Icon>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                align='center'
                                                            >
                                                                <IconButton
                                                                    title='edit'
                                                                    onClick={() =>
                                                                        this.handleEditBenefit(
                                                                            item,
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon color='primary'>
                                                                        border_color
                                                                    </Icon>
                                                                </IconButton>
                                                                <IconButton
                                                                    title='delete'
                                                                    onClick={() =>
                                                                        this.handleDeleteBenefit(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon color='error'>
                                                                        delete
                                                                    </Icon>
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        className='px-2 py-2 text-center font-poppins font-bold'
                                                        align='center'
                                                        colSpan={5}
                                                    >
                                                        Tidak ada benefit paket
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <h6 className='mt-0'>
                                    {form_benefit.isNew
                                        ? 'Tambah Benfit'
                                        : 'Edit Benfit'}
                                </h6>
                                <TextValidator
                                    className='w-full'
                                    label='Benefit'
                                    onChange={this.handleChangeBenefit}
                                    type='text'
                                    name='benefit'
                                    value={form_benefit.benefit}
                                />
                                {form_benefit.error.length > 0 ? (
                                    <small className='text-error'>
                                        {form_benefit.error}
                                    </small>
                                ) : null}
                                <div className='flex items-center justify-between'>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    form_benefit.status == 1
                                                }
                                                name='Status'
                                                onChange={
                                                    this.handleStatusBenefit
                                                }
                                                color='primary'
                                            />
                                        }
                                        label='Status benefit'
                                        className='mt-1'
                                    />
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={this.handleFormBenefit}
                                        className='elevation-z0 mt-1 w-full max-w-195'
                                    >
                                        {form_benefit.isNew
                                            ? 'Tambah Benefit'
                                            : 'Edit Benefit'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='border-bottom-1 mt-3'></div>
                        <div className='flex flex-space-between flex-middle mt-3'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                {submit ? <CircularProgress /> : 'Simpan'}
                            </Button>
                            <Button onClick={handleClose}>Batal</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addPaketBumn: (params) => dispatch(addPaketBumn(params))
    };
};

export default connect(null, mapDispatchToProps)(AddBumnDialog);
