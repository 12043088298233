import { Button, Card, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { resetReducer } from '../../redux/actions/SKDActions';
import Latihan from './latihan/Latihan';
import Materi from './materi/Materi';
import RekamanVideo from './materi/RekamanVideo';
import Soal from './soal/Soal';
import Tryout from './tryout/Tryout';
import TahunTO from './materi/TahunTO';
import TryoutPremium from './tryout/TryoutPremium';
import TryoutPlatinum from './tryout/TryoutPlatinum';
import { getAllTahun } from 'app/redux/actions/TahunToPremium';
import TahunTOPlatinum from './materi/TahunTOPlatinum';
import LatihanPlatinum from './latihan/LatihanPlatinum';
import { setSKDinfo } from 'app/redux/actions/SKDinfoActions';
import TryoutDinas from './tryout/TryoutDinas';
import TryoutDinasPlatinum from './tryout/TryoutDinasPlatinum';

class SKDDetail extends Component {
    constructor() {
        super();
        this.state = {
            menu: 1
        };
    }

    componentDidMount() {
        const { resetReducer, getTahunTO, setSKDinfo } = this.props;
        let info_paket = window.location.pathname.split('/').pop();
        resetReducer();
        getTahunTO();
        setSKDinfo(info_paket);
    }

    handleChangeMenu = (menu) => {
        this.setState({
            menu
        });
    };

    render() {
        let { menu } = this.state;
        let parent = window.location.pathname.split('/').pop();
        return (
            <div className='m-sm-30'>
                <h2 className='mt-10 mb-5' style={{ fontSize: '25px' }}>
                    Detail Paket SKD
                </h2>
                <Card style={{ borderRadius: 5 }} className='mb-10'>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div className='flex items-center'></div>
                            <div className='flex items-center'>
                                <Button
                                    onClick={() => this.handleChangeMenu(1)}
                                    variant={menu == 1 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Materi
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(2)}
                                    variant={menu == 2 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Soal Pembahasan
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(3)}
                                    variant={menu == 3 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Tryout
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(4)}
                                    variant={menu == 4 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Latihan
                                </Button>
                                {parent === 'platinum' ? (
                                    <Button
                                        onClick={() => this.handleChangeMenu(5)}
                                        variant={menu == 5 ? 'contained' : null}
                                        color='primary'
                                        className='elevation-z0 no-border'
                                        style={{ borderRadius: 5 }}
                                    >
                                        Video Rekaman
                                    </Button>
                                ) : (
                                    <></>
                                )}
                                {parent === 'premium' ? (
                                    <Button
                                        onClick={() => this.handleChangeMenu(6)}
                                        variant={menu == 6 ? 'contained' : null}
                                        color='primary'
                                        className='elevation-z0 no-border'
                                        style={{ borderRadius: 6 }}
                                    >
                                        Tahun Tryout
                                    </Button>
                                ) : (
                                    <></>
                                )}
                                {parent === 'platinum' ? (
                                    <Button
                                        onClick={() => this.handleChangeMenu(6)}
                                        variant={menu == 6 ? 'contained' : null}
                                        color='primary'
                                        className='elevation-z0 no-border'
                                        style={{ borderRadius: 6 }}
                                    >
                                        Tahun Tryout
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Card>
                {menu === 1 ? (
                    <Materi />
                ) : menu === 2 ? (
                    <Soal />
                ) : menu === 3 ? (
                    parent === 'premium' ? (
                        <TryoutPremium />
                    ) : parent === 'platinum' ? (
                        <TryoutPlatinum />
                    ) : parent === 'dinas' ? (
                        <TryoutDinas />
                    ) : parent === 'dinas_platinum' ? (
                        <TryoutDinasPlatinum />
                    ) : (
                        <Tryout />
                    )
                ) : menu === 4 ? (
                    parent === 'premium' ? (
                        <LatihanPlatinum />
                    ) : (
                        <Latihan />
                    )
                ) : menu === 5 ? (
                    <RekamanVideo />
                ) : menu === 6 ? (
                    parent === 'premium' ? (
                        <TahunTO />
                    ) : parent === 'platinum' ? (
                        <TahunTOPlatinum />
                    ) : null
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetReducer: () => dispatch(resetReducer()),
        getTahunTO: () => dispatch(getAllTahun()),
        setSKDinfo: (params) => dispatch(setSKDinfo(params))
    };
};
export default connect(null, mapDispatchToProps)(SKDDetail);
