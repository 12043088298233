import {
    DEL_LATIHAN_PPPK,
    DEL_MATERI_PPPK,
    GET_LATIHAN_PPPK,
    GET_MATERI_PPPK,
    GET_MATERI_PPPK_DETAIL,
    GET_PPPK,
    GET_PPPK_BIMBEL,
    GET_REKAMAN,
    GET_BATCH_PPPK_BIMBEL,
    DEL_BATCH_PPPK_BIMBEL,
    DEL_REKAMAN_PPPK
} from '../actions/PPPKActions';

const initialState = {
    data: [],
    materi: [],
    detail: {},
    latihan: [],
    dataBimbel: [],
    rekaman: [],
    batch: []
};

const PPPKReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PPPK_BIMBEL: {
            return {
                ...state,
                dataBimbel: action.payload
            };
        }
        case GET_PPPK: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_BATCH_PPPK_BIMBEL: {
            return {
                ...state,
                batch: action.payload
            };
        }
        case GET_REKAMAN: {
            return {
                ...state,
                rekaman: action.payload
            };
        }
        case GET_MATERI_PPPK: {
            return {
                ...state,
                materi: action.payload
            };
        }
        case GET_MATERI_PPPK_DETAIL: {
            return {
                ...state,
                detail: action.payload
            };
        }
        case GET_LATIHAN_PPPK: {
            return {
                ...state,
                latihan: action.payload
            };
        }
        case DEL_MATERI_PPPK: {
            let materi = state.materi.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                materi: materi
            };
        }
        case DEL_MATERI_PPPK: {
            let rekaman = state.rekaman.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                rekaman: rekaman
            };
        }
        case DEL_LATIHAN_PPPK: {
            let latihan = state.latihan.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                latihan: latihan
            };
        }

        case DEL_REKAMAN_PPPK: {
            let rekaman = state.rekaman.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                rekaman: rekaman
            };
        }

        case DEL_BATCH_PPPK_BIMBEL: {
            let batch = state.batch.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                batch: batch
            };
        }
        default: {
            return state;
        }
    }
};

export default PPPKReducer;
