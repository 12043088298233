import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { ButtonUploadThumbnail } from 'app/components';
import { updQuestionLatihanSKD } from 'app/redux/actions/SKDActions';
import { getTanggal } from 'app/redux/actions/UserActions';
import { RichTextEditor } from 'matx';
import React, { Component } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

let subOptionTWK = [
    { label: 'Integritas', value: 'integritas' },
    { label: 'Bela Negara', value: 'bela_negara' },
    { label: 'Pilar Negara', value: 'pilar_negara' },
    { label: 'Bahasa Indonesia', value: 'bahasa_indonesia' }
];
let subOptionTIU = [
    { label: 'Verbal', value: 'verbal' },
    { label: 'Numerik', value: 'numerik' },
    { label: 'Figural', value: 'figural' }
];
let subOptionTKP = [
    { label: 'Pelayanan Publik', value: 'pelayanan_publik' },
    { label: 'Jejaring Kerja', value: 'jejaring_kerja' },
    { label: 'Teknologi Informasi', value: 'teknologi_informasi' },
    { label: 'Sosial Budaya', value: 'sosial_budaya' },
    { label: 'Profesionalisme', value: 'profesionalisme' }
];

class EditQuestion extends Component {
    constructor() {
        super();
        this.state = {
            submit: false,
            question_text: '',
            pembahasan: '',
            preview_file: '',
            question_img: '',
            answer: [],
            form_answer: {
                value: 0,
                text: '',
                index: '',
                isNew: true,
                error: '',
                isImg: false,
                img: ''
            },
            category: '',
            subcategory: '',
            subOption: [],
            doc_id: '',
            pembahasan_img: ''
        };
    }

    componentDidMount() {
        const {
            category,
            option,
            pembahasan,
            question_img,
            question_text,
            subcategory,
            doc_id,
            pembahasan_img,
            pembahasan_video
        } = this.props;
        if (category == 'twk' || category == 'twk_dinas') {
            this.setState({
                subOption: subOptionTWK
            });
        } else if (category == 'tiu' || category == 'tiu_dinas') {
            this.setState({
                subOption: subOptionTIU
            });
        } else if (category == 'tkp' || category == 'tkp_dinas') {
            this.setState({
                subOption: subOptionTKP
            });
        }
        this.setState({
            doc_id: doc_id,
            category: category,
            answer: option,
            pembahasan: pembahasan,
            question_img: question_img,
            question_text: question_text,
            subcategory: subcategory,
            preview_file: question_img,
            pembahasan_img: pembahasan_img,
            video_pembahasan: pembahasan_video
        });
    }

    handleChange = (event, source) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const {
            answer,
            category,
            pembahasan,
            question_img,
            question_text,
            subcategory,
            doc_id,
            pembahasan_img,
            video_pembahasan
        } = this.state;
        const {
            updQuestionLatihanSKD,
            handleClose,
            params: { id }
        } = this.props;
        let allAnswer = [];
        for (let i = 0; i < answer.length; i++) {
            const data = {
                opt: Math.abs((i + 10).toString()).toString(16),
                value: answer[i].value,
                text: answer[i].text,
                img: answer[i].img
            };
            allAnswer.push(data);
        }
        let now = getTanggal();
        let params = {
            category: category,
            option: allAnswer,
            pembahasan: pembahasan,
            question_img: question_img,
            question_text: question_text,
            subcategory: subcategory,
            pembahasan_img: pembahasan_img,
            video_pembahasan: video_pembahasan
        };
        updQuestionLatihanSKD(params, id, doc_id);
        handleClose();
        this.setState({
            submit: false
        });
    };

    handleUploadImage = (file, path) => {
        this.setState({
            question_img: file,
            preview_file: path
        });
    };

    handleEditAnswer = (item, index) => {
        let value = item.value;
        let text = item.text;
        let img = item.img;
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                value: value,
                text: text,
                isNew: false,
                index: index,
                isImg: img.length > 0,
                img: img
            }
        }));
    };

    handleChangeTextOpt = (event) => {
        let value = event.target.value;
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                text: value
            }
        }));
    };

    changeValueAnswer = (event) => {
        let value = event.target.value;
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                value: value
            }
        }));
    };

    handleDeleteAnswer = (idx) => {
        const { answer } = this.state;
        let allAnswer = answer.filter((item, index) => index != idx);
        this.setState({
            answer: allAnswer
        });
    };

    handleFormAnswer = () => {
        const {
            form_answer: { text, isNew, index, value, isImg, img },
            answer
        } = this.state;
        let opt = answer.length;
        let check = true;
        if (isImg && img.length <= 0) {
            check = false;
        } else if (!isImg && text.length <= 0) {
            check = false;
        }
        if (check) {
            if (isNew) {
                let allAnswer = answer.concat({
                    value: value,
                    text: text,
                    img: img,
                    opt: Math.abs((opt + 10).toString()).toString(16)
                });
                this.setState({
                    answer: allAnswer,
                    form_answer: {
                        value: 0,
                        text: '',
                        index: '',
                        isNew: true,
                        error: '',
                        img: '',
                        isImg: false
                    }
                });
            } else {
                let allAnswer = answer;
                allAnswer[index] = {
                    value: value,
                    text: text,
                    img: img,
                    opt: Math.abs((index + 10).toString()).toString(16)
                };
                this.setState({
                    answer: allAnswer,
                    form_answer: {
                        value: 0,
                        text: '',
                        index: '',
                        isNew: true,
                        error: '',
                        img: '',
                        isImg: false
                    }
                });
            }
        } else {
            this.setState((prefState) => ({
                form_answer: {
                    ...prefState.form_answer,
                    error: 'Tolong isi semua inputan'
                }
            }));
        }
    };

    handleDelImg = () => {
        this.setState({
            question_img: '',
            preview_file: ''
        });
    };

    handleUploadImgPembahasan = (file, path) => {
        this.setState({
            pembahasan_img: file
        });
    };

    handleDelImgPembahasan = () => {
        this.setState({
            pembahasan_img: ''
        });
    };

    changeOptionIsImg = () => {
        const { isImg } = this.state.form_answer;
        if (isImg) {
            this.setState((prefState) => ({
                form_answer: {
                    ...prefState.form_answer,
                    isImg: !isImg,
                    img: ''
                }
            }));
        } else {
            this.setState((prefState) => ({
                form_answer: {
                    ...prefState.form_answer,
                    isImg: !isImg
                }
            }));
        }
    };

    uploadImgOption = (file, path) => {
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                img: file
            }
        }));
    };

    delImgOption = () => {
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                img: ''
            }
        }));
    };

    handleChangeQuestion = (val) => {
        this.setState({
            question_text: val
        });
    };

    handleChangePembahasan = (val) => {
        this.setState({
            pembahasan: val
        });
    };

    render() {
        let {
            question_text,
            pembahasan,
            preview_file,
            answer,
            form_answer,
            submit,
            subcategory,
            subOption,
            question_img,
            pembahasan_img,
            video_pembahasan
        } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='md' fullWidth>
                <h5 className='m-5'>Tambah Pertanyaan</h5>
                <div className='p-5'>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={4} className='mb-4'>
                            <Grid item sm={12} xs={12}>
                                <h6>Pertanyaan</h6>
                                <RichTextEditor
                                    name='question_text'
                                    content={question_text || ''}
                                    handleContentChange={
                                        this.handleChangeQuestion
                                    }
                                    placeholder='Pertanyaan'
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <h6>Pembahasan</h6>
                                <RichTextEditor
                                    name='pembahasan'
                                    content={pembahasan || ''}
                                    handleContentChange={
                                        this.handleChangePembahasan
                                    }
                                    placeholder='Pembahasan'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Link Video Pembahasan'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='video_pembahasan'
                                    value={video_pembahasan}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Kategory Pertanyaan'
                                    onChange={this.handleChange}
                                    name='subcategory'
                                    value={subcategory}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    {subOption.map((item, index) => (
                                        <MenuItem
                                            value={item.value}
                                            key={index}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={6}>
                                        <small>Gambar Soal (optional)</small>
                                        <div
                                            style={{ width: 100, height: 100 }}
                                            className='relative mt-3'
                                        >
                                            {preview_file && (
                                                <img
                                                    src={preview_file}
                                                    width='100%'
                                                    height='100%'
                                                    style={{
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            )}
                                            <ButtonUploadThumbnail
                                                uploadFoto={
                                                    this.handleUploadImage
                                                }
                                                value={question_img}
                                                handleDelFile={
                                                    this.handleDelImg
                                                }
                                                maxWidth={500}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item sm={6} xs={6}>
                                        <small>
                                            Gambar Pembahasan (optional)
                                        </small>
                                        <div
                                            style={{ width: 100, height: 100 }}
                                            className='relative mt-3'
                                        >
                                            {pembahasan_img && (
                                                <img
                                                    src={pembahasan_img}
                                                    width='100%'
                                                    height='100%'
                                                    style={{
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            )}
                                            <ButtonUploadThumbnail
                                                uploadFoto={
                                                    this
                                                        .handleUploadImgPembahasan
                                                }
                                                value={pembahasan_img}
                                                handleDelFile={
                                                    this.handleDelImgPembahasan
                                                }
                                                maxWidth={500}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <h5 className='mt-0'>Jawaban</h5>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <div className='w-full overflow-auto bg-white'>
                                    <Table className='crud-table'>
                                        <TableHead className='bg-primary'>
                                            <TableRow>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    Pilihan
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    colSpan={2}
                                                >
                                                    Teks Jawaban
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    Nilai
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                ></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {answer.length > 0 ? (
                                                answer.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-medium'
                                                            align='center'
                                                        >
                                                            {Math.abs(
                                                                (
                                                                    index + 10
                                                                ).toString()
                                                            ).toString(16)}
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-medium'
                                                            colSpan={2}
                                                        >
                                                            {item.img.length >
                                                            0 ? (
                                                                <img
                                                                    src={
                                                                        item.img
                                                                    }
                                                                    alt='...'
                                                                    style={{
                                                                        maxWidth:
                                                                            '100px'
                                                                    }}
                                                                />
                                                            ) : (
                                                                item.text
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-medium'
                                                            align='center'
                                                        >
                                                            {item.value}
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            align='center'
                                                        >
                                                            <IconButton
                                                                title='edit'
                                                                onClick={() =>
                                                                    this.handleEditAnswer(
                                                                        item,
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Icon color='primary'>
                                                                    border_color
                                                                </Icon>
                                                            </IconButton>
                                                            <IconButton
                                                                title='delete'
                                                                onClick={() =>
                                                                    this.handleDeleteAnswer(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Icon color='error'>
                                                                    delete
                                                                </Icon>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        className='px-2 py-2 text-center font-poppins font-bold'
                                                        align='center'
                                                        colSpan={5}
                                                    >
                                                        Tidak ada jawaban
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <h6 className='mt-0'>
                                    {form_answer.isNew
                                        ? 'Tambah Option'
                                        : 'Edit Option'}
                                </h6>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <div className='flex items-center'>
                                            <Button
                                                onClick={this.changeOptionIsImg}
                                                variant={
                                                    form_answer.isImg == false
                                                        ? 'contained'
                                                        : null
                                                }
                                                color='primary'
                                                className='elevation-z0 no-border'
                                                style={{ borderRadius: 5 }}
                                            >
                                                Teks
                                            </Button>
                                            <Button
                                                onClick={this.changeOptionIsImg}
                                                variant={
                                                    form_answer.isImg == true
                                                        ? 'contained'
                                                        : null
                                                }
                                                color='primary'
                                                className='elevation-z0 no-border'
                                                style={{ borderRadius: 5 }}
                                            >
                                                Gambar
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} className='mb-3'>
                                    <Grid item sm={6} xs={12}>
                                        {form_answer.isImg ? (
                                            <Grid
                                                item
                                                sm={12}
                                                xs={12}
                                                className='pb-0'
                                            >
                                                <div
                                                    style={{
                                                        width: 100,
                                                        height: 100
                                                    }}
                                                    className='relative mt-3'
                                                >
                                                    {form_answer.img && (
                                                        <img
                                                            src={
                                                                form_answer.img
                                                            }
                                                            width='100%'
                                                            height='100%'
                                                            style={{
                                                                objectFit:
                                                                    'cover',
                                                                maxWidth:
                                                                    '100px'
                                                            }}
                                                        />
                                                    )}
                                                    <ButtonUploadThumbnail
                                                        uploadFoto={
                                                            this.uploadImgOption
                                                        }
                                                        value={form_answer.img}
                                                        handleDelFile={
                                                            this.delImgOption
                                                        }
                                                        maxWidth={400}
                                                    />
                                                </div>
                                            </Grid>
                                        ) : (
                                            <TextValidator
                                                className='w-full'
                                                label='Teks Jawaban'
                                                onChange={
                                                    this.handleChangeTextOpt
                                                }
                                                type='text'
                                                name='text'
                                                value={form_answer.text}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Nilai Jawaban'
                                            onChange={this.changeValueAnswer}
                                            type='number'
                                            name='value'
                                            value={form_answer.value}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                                {form_answer.error.length > 0 ? (
                                    <small className='text-error'>
                                        {form_answer.error}
                                    </small>
                                ) : null}
                                <div className='flex items-center justify-between'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={this.handleFormAnswer}
                                        className='elevation-z0 mt-1 w-full max-w-195'
                                        type='button'
                                    >
                                        {form_answer.isNew
                                            ? 'Tambah Option'
                                            : 'Edit Option'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <div className='flex flex-space-between flex-middle mt-4'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        detailExercise: state.kerjakanSKD.exercise
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updQuestionLatihanSKD: (params, id, doc_id) =>
            dispatch(updQuestionLatihanSKD(params, id, doc_id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditQuestion);
