import {
    Button,
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import { FormSearch } from 'app/components';
import {
    delTryoutSkd,
    getTryoutSKD,
    resetReducer
} from 'app/redux/actions/SKDActions';
import { formatTanggal } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import AddTryout from './AddTryout';
import EditTryout from './EditTryout';

class Tryout extends Component {
    constructor() {
        super();
        this.state = {
            rowsPerPage: 10,
            page: 0,
            modalAdd: false,
            modalEdit: false,
            search: '',
            parent: '',
            edit_tryout: {
                description: '',
                expires_time: '',
                jml_soal: '',
                title: '',
                type: '',
                doc_id: '',
                is_shuffle: ''
            }
        };
    }

    componentDidMount() {
        this.getData();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    getData() {
        const { getTryoutSKD, resetReducer } = this.props;
        resetReducer();
        let parent = window.location.pathname.split('/').pop();
        this.setState({ parent: parent });
        if (parent == 'free') {
            getTryoutSKD(['gratis']);
        } else {
            if (parent == 'platinum') {
                getTryoutSKD(['premium', 'platinum']);
            } else {
                getTryoutSKD(['dinas', 'dinas_platinum']);
            }
        }
    }

    handleDelete = (doc_id) => {
        const { delTryoutSkd } = this.props;
        Swal.fire({
            title: 'Peringatan',
            text: 'Apakah kamu yakin ingin menghapus tryout ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            icon: 'warning'
        }).then((res) => {
            if (res.isConfirmed) {
                delTryoutSkd(doc_id);
            } else {
                Swal.close();
            }
        });
    };

    renderData = () => {
        const { tryout = [] } = this.props;
        const { search, page, rowsPerPage, parent } = this.state;

        let dataTryout =
            tryout != undefined
                ? tryout
                      .filter((item) =>
                          Object.keys(item).some((key) =>
                              search.includes(key)
                                  ? false
                                  : item[key]
                                        .toString()
                                        .toLowerCase()
                                        .includes(search)
                          )
                      )
                      .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                      )
                : [];
        return dataTryout.length > 0 ? (
            dataTryout.map((item, index) => (
                <TableRow key={index} hover>
                    <TableCell className='px-0' align='center'>
                        {index + 1}
                    </TableCell>

                    <TableCell className='px-0' colSpan={2}>
                        {item.title}
                    </TableCell>

                    <TableCell className='px-0'>
                        {item.description.length > 20
                            ? item.description.substr(0, 20) + '...'
                            : item.description}
                    </TableCell>

                    <TableCell className='px-0'>
                        <p className='m-0 p-0'>
                            <b>Jml Soal</b> : {item.jml_soal}
                        </p>
                        <p className='m-0 p-0'>
                            <b>Durasi :</b> {item.expires_time}
                        </p>
                    </TableCell>

                    <TableCell className='px-0'>
                        {formatTanggal(item.created_at)}
                    </TableCell>

                    <TableCell className='px-0' align='center' colSpan={2}>
                        <Link
                            to={{
                                pathname: '/skd/tryout/soal/' + item.doc_id,
                                state: {
                                    SKDinfo: parent
                                }
                            }}
                        >
                            <IconButton title='List Soal'>
                                <Icon color='secondary'>view_list</Icon>
                            </IconButton>
                        </Link>
                        <Link to={'/skd/tryout/ranking/' + item.doc_id}>
                            <IconButton title='Ranking Nasional'>
                                <Icon color='secondary'>emoji_events</Icon>
                            </IconButton>
                        </Link>
                        <IconButton
                            title='Edit'
                            onClick={() => this.handleModalEdit(item)}
                        >
                            <Icon color='inherit'>edit</Icon>
                        </IconButton>
                        <IconButton
                            title='delete'
                            onClick={() => this.handleDelete(item.doc_id)}
                        >
                            <Icon color='error'>delete</Icon>
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))
        ) : (
            <TableRow hover>
                <TableCell colSpan={8} align='center'>
                    Not Have Data
                </TableCell>
            </TableRow>
        );
    };

    handleSearch = (val) => {
        this.setState({
            search: val.target.value
        });
    };

    handleModalAdd = () => {
        this.setState((prefState) => ({
            modalAdd: !prefState.modalAdd
        }));
    };

    handleCloseEdit = () => {
        this.setState({
            modalEdit: false
        });
    };

    handleModalEdit = (item) => {
        const {
            description,
            expires_time,
            jml_soal,
            title,
            type,
            doc_id,
            is_shuffle,
            display
        } = item;
        this.setState({
            edit_tryout: {
                type: type,
                description: description,
                title: title,
                doc_id: doc_id,
                expires_time: expires_time,
                jml_soal: jml_soal,
                is_shuffle: is_shuffle,
                display: display || '0'
            },
            modalEdit: true
        });
    };

    render() {
        const { tryout } = this.props;
        const { search, rowsPerPage, page, modalAdd, modalEdit, edit_tryout } =
            this.state;
        return (
            <Card className='p-5 overflow-auto' elevation={6}>
                <div className='flex justify-between'>
                    <Button
                        variant='outlined'
                        color='primary'
                        className='mb-5'
                        onClick={this.handleModalAdd}
                        title='Edit'
                    >
                        Tambah
                    </Button>
                    <FormSearch
                        value={search}
                        handleSearch={this.handleSearch}
                    />
                </div>
                <div className='w-full overflow-auto bg-white'>
                    <Table className='bimble-table'>
                        <TableHead className='bg-primary'>
                            <TableRow>
                                <TableCell
                                    className='text-white'
                                    align='center'
                                >
                                    No.
                                </TableCell>
                                <TableCell className='text-white' colSpan={2}>
                                    Judul
                                </TableCell>
                                <TableCell className='text-white'>
                                    Deskripsi
                                </TableCell>
                                <TableCell className='text-white'>
                                    Detail
                                </TableCell>
                                <TableCell className='text-white'>
                                    Tgl Dibuat
                                </TableCell>
                                <TableCell
                                    className='text-white'
                                    align='center'
                                    colSpan={2}
                                ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{this.renderData()}</TableBody>
                    </Table>

                    <TablePagination
                        className='px-16'
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={tryout.length}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage={'From'}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next page'
                        }}
                        backIconButtonText='Previous page'
                        nextIconButtonText='Next page'
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.setRowsPerPage}
                    />
                </div>
                <AddTryout open={modalAdd} handleClose={this.handleModalAdd} />
                {modalEdit && (
                    <EditTryout
                        open={modalEdit}
                        handleClose={this.handleCloseEdit}
                        description={edit_tryout.description}
                        expires_time={edit_tryout.expires_time}
                        jml_soal={edit_tryout.jml_soal}
                        title={edit_tryout.title}
                        type={edit_tryout.type}
                        doc_id={edit_tryout.doc_id}
                        is_shuffle={edit_tryout.is_shuffle}
                        display={edit_tryout.display}
                    />
                )}
            </Card>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        tryout: state.skd.tryout
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTryoutSKD: (params) => dispatch(getTryoutSKD(params)),
        delTryoutSkd: (doc_id) => dispatch(delTryoutSkd(doc_id)),
        resetReducer: () => dispatch(resetReducer())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tryout);
