import PassingGrade from './PassingGrade';

const PGRoutes = [
    {
        path: '/passing_grade',
        component: PassingGrade
    }
];

export default PGRoutes;
