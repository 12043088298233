import { Card, Icon, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';

const CardKomisiBelum = ({ komisi, handleUbah }) => {
    return (
        <Card
            className='flex items-center border-radius-4 py-4 px-4 mb-4  card-komisi-belum bg-gradient-danger'
            elevation={3}
        >
            {/* <div className='text-white'>
                <Icon>account_balance_wallet</Icon>
            </div> */}
            <div className='ml-5'>
                <h6 className='font-bold text-white'>
                    Daftar komisi yang belum di cairkan
                </h6>
                <p className='m-0 text-white'>
                    Kunjungi daftar komisi yang belum dicairkan.{' '}
                </p>
                <div className="mt-3">
                    <Link
                        to={handleUbah}
                        className='text-primary underline cursor-pointer'
                    >
                        <Button variant='contained' type='submit'>
                            Kunjungi
                        </Button>
                    </Link>
                </div>
            </div>
        </Card>
    );
};

export default CardKomisiBelum;
