import axios from 'axios';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_PPPK_BIMBEL = 'GET_PPPK_BIMBEL';

export const GET_PPPK = 'GET_PPPK';
export const GET_MATERI_PPPK = 'GET_MATERI_PPPK';
export const GET_MATERI_PPPK_DETAIL = 'GET_MATERI_PPPK_DETAIL';
export const GET_LATIHAN_PPPK = 'GET_LATIHAN_PPPK';
export const GET_DETAIL_LATIHAN_PPPK = 'GET_DETAIL_LATIHAN_PPPK';
export const GET_SOAL = 'GET_SOAL';
export const GET_DETAIL_JAWABAN = 'GET_DETAIL_JAWABAN';
export const DEL_MATERI_PPPK = 'DEL_MATERI_PPPK';
export const GET_REKAMAN = 'GET_REKAMAN';
export const GET_BATCH_PPPK_BIMBEL = 'GET_BATCH_PPPK_BIMBEL';
export const DEL_BATCH_PPPK_BIMBEL = 'DEL_BATCH_PPPK_BIMBEL';
export const DEL_LATIHAN_PPPK = 'DEL_LATIHAN_PPPK';
export const UPD_SOAL_LATIHAN_PPPK = 'UPD_SOAL_LATIHAN_PPPK';
export const DEL_REKAMAN_PPPK = 'DEL_REKAMAN_PPPK';

export const getPPPK = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_PPPK,
                    payload: data
                });
            });
    };
};

export const getPPPKBimbel = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk_bimbel')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_PPPK_BIMBEL,
                    payload: data
                });
            });
    };
};

export const getBatchBimbelPPPK = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk_bimbel')
            .doc(params)
            .collection('batch')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_BATCH_PPPK_BIMBEL,
                    payload: data
                });
            });
    };
};

export const addBatchBimbelPPPK = (params) => {
    return (dispatch) => {
         firebaseAuthService.firestore
             .collection('pppk_bimbel')
             .doc(params.parent_id)
             .collection('batch')
             .doc(params.doc_id)
             .set(params)
             .then(() => {
                 Swal.fire({
                     title: 'Berhasil',
                     text: 'Batch berhasil disimpan',
                     showConfirmButton: false,
                     icon: 'success',
                     timer: 2000
                 }).then(() => {
                     dispatch(getBatchBimbelPPPK(params.parent_id));
                 });
             })
             .catch((err) => {
                 console.log('error', err);
                 Swal.fire({
                     title: 'Gagal',
                     text: 'Batch gagal disimpan',
                     showConfirmButton: false,
                     icon: 'error',
                     timer: 2000
                 });
             });
    };
};

export const delBatchBimbelPPPK = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk_bimbel')
            .doc(params.parent_id)
            .collection('batch')
            .doc(params.doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Batch berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_BATCH_PPPK_BIMBEL,
                        payload: params.doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Batch gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getRekamanPPPK = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('rekaman_video')
            .where('category', '==', params)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_REKAMAN,
                    payload: data
                });
            });
    };
};

export const getMateriPPPK = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('materi_pppk')
            .where('category', '==', params)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_MATERI_PPPK,
                    payload: data
                });
            });
    };
};

export const getLatihanPPPK = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_pppk')
            .where('category', '==', params)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_LATIHAN_PPPK,
                    payload: data
                });
            });
    };
};

export const getDetailLatihanPPPK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_pppk')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_LATIHAN_PPPK,
                    payload: data
                });
            });
    };
};

export const getSoalLatihanPPPK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_pppk')
            .doc(kode)
            .collection('question')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL,
                    payload: shuffleArray(data)
                });
            });
    };
};

export const shuffleArray = (array) => {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const addPaketPPPKBimbel = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk_bimbel')
            .doc(params.title.replaceAll(' ', '_'))
            .set(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getPPPKBimbel());
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const addPaketPPPK = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk')
            .doc(params.title.replaceAll(' ', '_'))
            .set(params)
            // .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getPPPK());
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};


export const editPaketPPPK = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getPPPK());
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};


export const editPaketPPPKBimbel = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk_bimbel')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getPPPKBimbel());
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};


export const delRekamanPPPK = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('rekaman_video')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Rekaman berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_REKAMAN_PPPK,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Rekaman gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const delMateriPPPK = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('materi_pppk')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_MATERI_PPPK,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const addMateriPPPK = (params) => {
  console.log(params)
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi_pppk/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getMateriPPPK(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};



export const addRekamanPPPK = (params) => {
    const uid = localStorage.getItem('uid');
    console.log(params)
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/rekaman/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Rekaman berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getRekamanPPPK(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};


export const EditRekamanPPPK = (params) => {
  console.log(params);
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/rekaman/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Rekaman berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getRekamanPPPK(params.category));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const delMateriSkd = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('materi_pppk')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_MATERI_PPPK,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editBatchPPPK = (params) => {
     return async (dispatch) => {
         firebaseAuthService.firestore
             .collection('pppk_bimbel')
             .doc(params.parent_id)
             .collection('batch')
             .doc(params.doc_id)
             .update(params)
             .then(() => {
                 Swal.fire({
                     title: 'Berhasil',
                     text: 'Nama Batch berhasil diedit',
                     icon: 'success',
                     timer: 2000
                 }).then(() => {
                     dispatch(getBatchBimbelPPPK(params.parent_id));
                 });
             })
             .catch((error) => {
                 Swal.fire({
                     title: 'Gagal',
                     text: 'Nama Batch gagal diedit',
                     icon: 'error',
                     timer: 2000
                 });
                 return Promise.reject(error);
             });
     };
};


export const editMateriPPPK = (params) => {
  console.log(params)
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi_pppk/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getMateriPPPK(params.category));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const addLatihanPPPK = (params) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_pppk')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil disimpan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getLatihanPPPK(params.category));
                });
                return true;
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal menyimpan Latihan',
                    icon: 'error',
                    timer: 2000
                });
                return false;
            });
    };
};

export const delLatihanPPPK = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_pppk')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_LATIHAN_PPPK,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updLatihanPPPK = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_pppk')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getLatihanPPPK(params.category));
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const delQuestionLatihanPPPK = (tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_pppk')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalLatihanPPPK(tryout_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const addQuestionLatihanPPPK = (params, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_pppk')
            .doc(doc_id)
            .collection('question')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalLatihanPPPK(doc_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updQuestionLatihanPPPK = (params, tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_pppk')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: UPD_SOAL_LATIHAN_PPPK,
                        payload: { params, doc_id }
                    });
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const importSoalLatihanPPPK = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}scpns/question_skd/import`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil diimport',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalLatihanPPPK(params.doc_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};
