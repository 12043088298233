import DetailKomisi from './DetailKomisi';
import Penghasilan from './Penghasilan';

const PenghasilanRoutes = [
    {
        path: '/penghasilan',
        component: Penghasilan
    },
    {
        path: '/komisi/detail',
        component: DetailKomisi
    }
];

export default PenghasilanRoutes;
