import ForgotPassword from './ForgotPassword';
import NotFound from './NotFound';
import SignIn from './SignIn';
import SignUp from './SignUp';
import VerifikasiEmail from './VerifikasiEmail';

const sessionRoutes = [
	{
		path: "/session/signup",
		component: SignUp,
	},
	{
		path: "/session/signin",
		component: SignIn,
	},
	{
		path: "/session/forgot-password",
		component: ForgotPassword,
	},
	{
		path: "/session/404",
		component: NotFound,
	},
	{
		path: "/verifikasi_email",
		component: VerifikasiEmail,
	},
];

export default sessionRoutes;
