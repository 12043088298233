import {
    GET_INVOICE,
    GET_TOTAL_INVOICE_COUNT
} from '../actions/InvoiceActions';

const initialState = {
	data: [],
  invoiceData: {}
};

const DashboardReducer = function (state = initialState, action) {
	switch (action.type) {
        case GET_INVOICE: {
            return {
                data: action.payload
            };
        }
        case GET_TOTAL_INVOICE_COUNT: {
            return {
                invoiceData: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default DashboardReducer;
