import Category from './Category';

const categoryRoutes = [
    {
        path: '/category',
        component: Category
    }
];

export default categoryRoutes;
