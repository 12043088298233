import { Icon } from '@material-ui/core';
import TouchRipple from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import { checkExpiredTanggal } from 'app/redux/actions/UserActions';
import clsx from 'clsx';
import { MatxVerticalNavExpansionPanel } from 'matx';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { navigations } from '../../navigations';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    navItem: {
        '&.active': {
            background: palette.secondary.main,
            boxShadow: theme.shadows[3],
            color: '#fff',
            borderRadius: '0px 100px 100px 0px'
        },
        transition: 'all 250ms ease-in-out'
    },
    compactNavItem: {
        overflow: 'hidden',
        justifyContent: 'center !important',
        '& $itemText': {
            display: 'none'
        },
        '& $itemIcon': {
            display: 'none'
        }
    },
    itemIcon: {},
    itemText: {
        fontSize: '0.875rem',
        paddingLeft: '0.8rem'
    }
}));
const SKDMenu = () => {
    const { settings } = useSelector((state) => state.layout);
    const { plan, plan_expires_time } = useSelector((state) => state.user);
    const { mode } = settings.layout1Settings.leftSidebar;
    const classes = useStyles();

    const renderLevels = (data) => {
        return data.map((item, index) => {
            if (item.type === 'label')
                return (
                    <p
                        className={clsx({
                            'px-6 uppercase sidenavHoverShow label-sidenav font-semibold': true,
                            hidden: mode === 'compact'
                        })}
                        key={index}
                    >
                        {item.label}
                    </p>
                );
            if (item.children) {
                return (
                    <MatxVerticalNavExpansionPanel
                        mode={mode}
                        item={item}
                        key={index}
                    >
                        {renderLevels(item.children)}
                    </MatxVerticalNavExpansionPanel>
                );
            } else if (item.type === 'extLink') {
                return (
                    <a
                        key={index}
                        href={item.path}
                        className={clsx({
                            'flex justify-between h-44 mb-2 compactNavItem whitespace-pre overflow-hidden': true,
                            [classes.navItem]: true,
                            [classes.compactNavItem]: mode === 'compact',
                            'border-radius-4': mode === 'compact'
                        })}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <TouchRipple
                            key={item.name}
                            name="child"
                            className="w-full"
                        >
                            {(() => {
                                if (item.icon) {
                                    return (
                                        <Icon
                                            className={`text-18 align-middle ${
                                                mode === 'full'
                                                    ? 'px-6'
                                                    : 'px-4'
                                            }`}
                                        >
                                            {item.icon}
                                        </Icon>
                                    );
                                } else {
                                    return (
                                        <span className="item-icon icon-text">
                                            {item.iconText}
                                        </span>
                                    );
                                }
                            })()}
                            <span
                                className={clsx(
                                    'align-middle sidenavHoverShow font-medium',
                                    classes.itemText
                                )}
                            >
                                {item.name}
                            </span>
                            <div className="mx-auto"></div>
                            {item.badge && (
                                <div
                                    className={`rounded bg-${item.badge.color} px-1 py-1px`}
                                >
                                    {item.badge.value}
                                </div>
                            )}
                        </TouchRipple>
                    </a>
                );
            } else {
                return (
                    <NavLink
                        key={index}
                        to={item.path}
                        className={clsx({
                            'flex justify-between h-44 mb-2 compactNavItem whitespace-pre overflow-hidden': true,
                            [classes.navItem]: true,
                            [classes.compactNavItem]: mode === 'compact',
                            'border-radius-4': mode === 'compact'
                        })}
                    >
                        <TouchRipple
                            key={item.name}
                            name="child"
                            className="w-full"
                        >
                            {item?.icon ? (
                                <Icon
                                    className={`text-18 align-middle w-36 ${
                                        mode === 'full' ? 'px-6' : 'px-4'
                                    }`}
                                >
                                    {item.icon}
                                </Icon>
                            ) : item?.iconText != undefined ? (
                                <Fragment>
                                    <div
                                        className={clsx({
                                            'nav-bullet p-2px rounded ml-6 mr-4 bg-dark': true,
                                            hidden: mode === 'compact'
                                        })}
                                    ></div>
                                    <div
                                        className={clsx({
                                            'nav-bullet-text w-full text-center text-11': true,
                                            hidden: mode !== 'compact'
                                        })}
                                    >
                                        {item.iconText}
                                    </div>
                                </Fragment>
                            ) : (
                                ''
                            )}
                            <span
                                className={clsx(
                                    'align-middle text-left sidenavHoverShow font-medium',
                                    classes.itemText
                                )}
                            >
                                {item.name}
                            </span>
                            <div className="mx-auto"></div>
                            {item.badge && (
                                <div
                                    className={clsx(
                                        `rounded bg-${item.badge.color} px-1 py-1px mr-4`,
                                        'sidenavHoverShow',
                                        classes.itemIcon
                                    )}
                                >
                                    {item.badge.value}
                                </div>
                            )}
                        </TouchRipple>
                    </NavLink>
                );
            }
        });
    };
    if (
        plan &&
        plan == 'premium' &&
        checkExpiredTanggal(plan_expires_time) == true
    ) {
        return (
            <div className={`navigation ${mode == 'full' ? 'pr-5' : ''}`}>
                {renderLevels(navigations.skd_premium)}
            </div>
        );
    } else if (
        plan &&
        plan == 'platinum' &&
        checkExpiredTanggal(plan_expires_time) == true
    ) {
        return (
            <div className={`navigation ${mode == 'full' ? 'pr-5' : ''}`}>
                {renderLevels(navigations.skd_platinum)}
            </div>
        );
    } else {
        return (
            <div className={`navigation ${mode == 'full' ? 'pr-5' : ''}`}>
                {renderLevels(navigations.skd_free)}
            </div>
        );
    }
};

export default SKDMenu;
