import {
    Box,
    Card,
    FormControl,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import { getAllInvoice } from 'app/redux/actions/InvoiceActions';
import { formatRupiah } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class Transaksi extends Component {
    constructor() {
        super();
        this.state = {
            search: '',
            rowsPerPage: 20,
            page: 0,
            query: '',
            status: 'a',
            paket: 'a'
        };
    }
    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };
    componentDidMount() {
        const { getAllInvoice } = this.props;
        // getAllInvoice();
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    handleChangeSelect = (e) => {
        if (e.target.value == 'SEMUA') {
            this.setState({
                status: ''
            });
        } else {
            this.setState({
                status: e.target.value
            });
        }
    };
    handleChangeSelectPaket = (e) => {
        if (e.target.value == 'SEMUA') {
            this.setState({
                paket: ''
            });
        } else {
            this.setState({
                paket: e.target.value
            });
        }
    };
    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };
    renderData = () => {
        const { invoice } = this.props;
        const { rowsPerPage, page, query, status, paket } = this.state;

        return invoice.data?.length > 0 ? (
            invoice.data
                .sort((a, b) => (a.unique_id < b.unique_id ? 1 : -1))
                .filter((item) =>
                    Object.keys(item).some((key) =>
                        query.includes(key)
                            ? false
                            : item[key]
                                  ?.toString()
                                  .toLowerCase()
                                  .includes(query)
                    )
                )
                .filter((item) =>
                    status == 'a' ? true : item.status == status
                )
                .filter((item) =>
                    paket == 'a' ? true : item.external_id.includes(paket)
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                    return (
                        <TableRow hover key={index}>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                #{item.external_id}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                {item.user}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 font-poppins'
                                colSpan={2}
                            >
                                {item.description}
                            </TableCell>
                            <TableCell className='py-2 text-center font-poppins'>
                                {formatRupiah(item.amount, 'Rp')}
                            </TableCell>
                            <TableCell className='py-2 text-center font-poppins'>
                                {formatRupiah(item.discount, 'Rp')}
                            </TableCell>
                            <TableCell className='py-2 text-center font-poppins'>
                                {formatRupiah(item.final_amount, 'Rp')}
                            </TableCell>
                            <TableCell
                                className='py-2 text-center font-poppins'
                                align='center'
                                colSpan={2}
                            >
                                {item.status === 'EXPIRED' && (
                                    <small
                                        className={`border-radius-4 text-white px-8 py-2 bg-error`}
                                    >
                                        {item.status}
                                    </small>
                                )}
                                {item.status === 'PENDING' && (
                                    <small
                                        className={`border-radius-4 text-white px-8 py-2 bg-secondary`}
                                    >
                                        {item.status}
                                    </small>
                                )}
                                {item.status === 'SETTLED' && (
                                    <small
                                        className={`border-radius-4 text-white px-8 py-2 bg-primary`}
                                    >
                                        BERHASIL
                                    </small>
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })
        ) : (
            <TableRow hover>
                <TableCell colSpan={10} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };
    render() {
        const { query, status, paket } = this.state;
        return (
            <div className='m-sm-30'>
                <div className='flex items-center justify-between mt-10 mb-3'>
                    <h2 style={{ fontSize: '25px' }}>Daftar Transaksi</h2>
                    <FormControl
                        className='faqDesc'
                        style={{
                            boxShadow: '0px 0px 2px #43434359',
                            marginBottom: '15px',
                            borderRadius: '5px',
                            maxHeight: '45px'
                        }}
                    >
                        <Box
                            component='fieldset'
                            borderColor='transparent'
                            style={{ paddingLeft: '0px' }}
                            className='flex'
                        >
                            <IconButton title='Cari' style={{ padding: '5px' }}>
                                <Icon color='action'>search</Icon>
                            </IconButton>
                            <input
                                onChange={this.handleSearch}
                                placeholder='Cari..'
                                name='query'
                                value={query}
                                style={{
                                    minHeight: '25px',
                                    border: '0px',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    outline: 0
                                }}
                            ></input>
                        </Box>
                    </FormControl>
                </div>
                <Grid container spacing={2} className='mb-3'>
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                        <label htmlFor='paket'>
                            <small>Filter Paket</small>
                        </label>
                        <Select
                            labelId='demo-simple-select-label'
                            id='paket'
                            value={paket}
                            onChange={this.handleChangeSelectPaket}
                            className='w-full'
                        >
                            <MenuItem value={'a'}>SEMUA</MenuItem>
                            <MenuItem value={'SKD'}>SKD</MenuItem>
                            <MenuItem value={'SKB'}>SKB</MenuItem>
                            <MenuItem value={'UK'}>UK</MenuItem>
                            <MenuItem value={'P3K'}>PPPK</MenuItem>
                            <MenuItem value={'BMB'}>BIMBEL</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item lg={3} sm={3} sm={6} xs={6}>
                        <label htmlFor='status'>
                            <small>Filter Status Transaksi</small>
                        </label>
                        <Select
                            labelId='demo-simple-select-label'
                            id='status'
                            value={status}
                            onChange={this.handleChangeSelect}
                            className='w-full'
                        >
                            <MenuItem value={'a'}>SEMUA</MenuItem>
                            <MenuItem value={'SETTLED'}>BERHASIL</MenuItem>
                            <MenuItem value={'PENDING'}>PENDING</MenuItem>
                            <MenuItem value={'EXPIRED'}>EXPIRED</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='crud-table min-w-900'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        ID Transaksi
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Paket
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Harga Asli
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Diskon
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Harga Akhir
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                        align='center'
                                    >
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>
                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[20, 50, 100]}
                            component='div'
                            count={
                                this.props.invoice.data
                                    .sort((a, b) =>
                                        a.unique_id < b.unique_id ? 1 : -1
                                    )
                                    .filter((item) =>
                                        Object.keys(item).some((key) =>
                                            query.includes(key)
                                                ? false
                                                : item[key]
                                                      ?.toString()
                                                      .toLowerCase()
                                                      .includes(query)
                                        )
                                    )
                                    .filter((item) =>
                                        status == 'a'
                                            ? true
                                            : item.status == status
                                    )
                                    .filter((item) =>
                                        paket == 'a'
                                            ? true
                                            : item.external_id.includes(paket)
                                    ).length
                            }
                            rowsPerPage={this.state.rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        invoice: state.invoice
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllInvoice: () => dispatch(getAllInvoice())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaksi);
