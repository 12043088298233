import LatihanKerjakan from './latihan/LatihanKerjakan';
import LatihanRanking from './latihan/LatihanRanking';
import TryoutKerjakan from './tryout/TryoutKerjakan';
import TryoutRanking from './tryout/TryoutRanking';
import UKDetail from './UKDetail';
import UKTable from './UKTable';

const UKRoutes = [
    {
        path: '/uk',
        component: UKTable
    },
    {
        path: '/detail/uk/:id',
        component: UKDetail
    },
    {
        path: '/tryout/uk/:parent/:doc_id',
        component: TryoutKerjakan
    },
    {
        path: '/exercise/uk/:parent/:doc_id',
        component: LatihanKerjakan
    },
    {
        path: '/tryout/ranking/:tryout',
        component: TryoutRanking
    },
    {
        path: '/latihan/ranking/:tryout',
        component: LatihanRanking
    }
];

export default UKRoutes;
