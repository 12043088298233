import { GET_USER_BIMBEL, GET_USER_SKB, GET_USER_UK, GET_USER_BUNDLE_UK } from '../actions/UserPaketActions';

const initialState = {
	bimbel: [],
	skb: [],
	uk: [],
	buk: [],
};

const UserPaketReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_USER_BIMBEL: {
			return {
				...state,
				bimbel: action.payload,
			};
		}
		case GET_USER_SKB: {
			return {
				...state,
				skb: action.payload,
			};
		}
		case GET_USER_UK: {
			return {
				...state,
				uk: action.payload,
			};
		}
		case GET_USER_BUNDLE_UK: {
			return {
				...state,
				buk: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};

export default UserPaketReducer;
