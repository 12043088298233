import { Card } from '@material-ui/core';
import React from 'react';
import { Fragment } from 'react';

import { ButtonSolid } from '..';

const CardNomorSoalAdmin = ({
    title,
    soal,
    no_soal,
    handleChangeNo,
    handleAddSoal,
    handleImportSoal
}) => {
    return (
        <Fragment>
            <Card
                elevation={6}
                className='px-6 py-5 card-soal flex flex-column items-center justify-between flex-1'
            >
                <div className='w-full'>
                    <h5 className={'card-title pb-2 border-bottom-2 mb-5'}>
                        {title}
                    </h5>
                    <div className='flex justify-center flex-wrap'>
                        {soal.map((item, index) => (
                            <div className='item-card-nomor' key={index}>
                                <button
                                    onClick={() => handleChangeNo(index)}
                                    className={`btn-nomor-soal m-auto ${
                                        no_soal == index ? 'active' : ''
                                    }`}
                                >
                                    {index + 1}
                                </button>
                            </div>
                        ))}
                    </div>
                    <ButtonSolid
                        variant='button-purple'
                        width='w-full'
                        text='Tambah Soal'
                        onClick={handleAddSoal}
                    />
                </div>
            </Card>
        </Fragment>
    );
};

export default CardNomorSoalAdmin;
