import LatihanSoal from './latihan/LatihanSoal';
import SKDDetail from './SKDDetail';
import SKDTable from './SKDTable';
import TryoutRanking from './tryout/TryoutRanking';
import TryoutSoal from './tryout/TryoutSoal';
import TryoutSoalSkdEdit from './tryout/TryoutSoalSkdEdit';
import TryoutSoalBC from './tryout/TryoutSoalBC';
import TryoutSoalSkdTambah from './tryout/TryoutSoalSkdTambah';

const SKDRoutes = [
    {
        path: '/skd/tryout/ranking/:id',
        component: TryoutRanking
    },
    {
        path: '/skd/tryout/soal/tambah',
        component: TryoutSoalSkdTambah
    },
    {
        path: '/skd/tryout/soal/skd/edit',
        component: TryoutSoalSkdEdit
    },
    {
        path: '/skd/tryout/soalbc/:id',
        component: TryoutSoalBC
    },
    {
        path: '/skd/tryout/soal/:id',
        component: TryoutSoal
    },
    {
        path: '/skd/latihan/soal/:id',
        component: LatihanSoal
    },
    {
        path: '/skd/:id',
        component: SKDDetail
    },
    {
        path: '/skd',
        component: SKDTable
    }
];

export default SKDRoutes;
