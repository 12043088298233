import { Button, Card, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { resetReducer } from '../../redux/actions/SKDActions';
import Latihan from './latihan/Latihan';
import Materi from './materi/Materi';
import Soal from './soal/Soal';
import Tryout from './tryout/Tryout';

class PPPKDetail extends Component {
    constructor() {
        super();
        this.state = {
            menu: 1
        };
    }

    componentDidMount() {
        const { resetReducer } = this.props;
        resetReducer();
    }

    handleChangeMenu = (menu) => {
        this.setState({
            menu
        });
    };

    render() {
        let { menu } = this.state;
        const { match } = this.props;
        return (
            <div className='m-sm-30'>
                <h2 className='mt-10 mb-5' style={{ fontSize: '25px' }}>
                    Detail Paket PPPK
                </h2>
                <Card style={{ borderRadius: 5 }} className='mb-10'>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div className='flex items-center'>
                                <Button
                                    onClick={() => this.handleChangeMenu(1)}
                                    variant={menu == 1 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Materi
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(2)}
                                    variant={menu == 2 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Soal Pembahasan
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(3)}
                                    variant={menu == 3 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Tryout
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(4)}
                                    variant={menu == 4 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Latihan
                                </Button>
                                
                            </div>
                        </Grid>
                    </Grid>
                </Card>
                {menu == 1 ? (
                    <Materi params={match.params} />
                ) : menu == 2 ? (
                    <Soal params={match.params} />
                ) : menu == 3 ? (
                    <Tryout params={match.params} />
                ) : menu == 4 ? (
                    <Latihan params={match.params} />
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetReducer: () => dispatch(resetReducer())
    };
};
export default connect(null, mapDispatchToProps)(PPPKDetail);
