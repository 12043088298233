export const navigations = {
    guest: [
        {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'dashboard'
        },
        {
            name: 'Notifikasi',
            icon: 'label',
            path: '/notifikasi'
        },
        {
            name: 'Spotlight',
            icon: 'label',
            path: '/promo'
        },
        {
            name: 'News',
            icon: 'label',
            path: '/news'
        },
        {
            name: 'Informasi',
            icon: 'label',
            path: '/informasi'
        },
        {
            name: 'Management User',
            icon: 'supervisor_account',
            path: '/user'
        },
        {
            name: 'Management SKD',
            icon: 'label',
            path: '/skd'
        },
        {
            name: 'Management Event',
            icon: 'label',
            path: '/event'
        },
        {
            name: 'Management Bundle',
            icon: 'label',
            path: '/bundle'
        },
        {
            name: 'Management SKB',
            icon: 'label',
            path: '/skb'
        },
        {
            name: 'Management UK',
            icon: 'label',
            path: '/uk'
        },
        {
            name: 'Management PPPK',
            icon: 'label',
            path: '/pppk'
        },
        {
            name: 'Management BUMN',
            icon: 'label',
            path: '/bumn'
        },
        // {
        //     name: 'Management UTBK',
        //     icon: 'label',
        //     path: '/utbk'
        // },
        {
            name: 'Rekomendasi Buku',
            icon: 'book',
            path: '/rekomendasi_buku'
        },
        {
            name: 'Bimbel',
            icon: 'local_library',
            path: '/bimbel'
        },
        {
            name: 'Transaksi',
            path: '/transaksi',
            icon: 'payment'
        },
        {
            name: 'Passing Grade',
            path: '/passing_grade',
            icon: 'grade'
        },
        {
            name: 'Laporan Soal',
            path: '/laporan_soal',
            icon: 'label'
        },
        {
            name: 'Kategori',
            path: '/category',
            icon: 'list'
        },
        {
            name: 'Admin',
            path: '/admin',
            icon: 'assignment_ind'
        },
        {
            name: 'Kumpulan Gambar',
            path: '/image',
            icon: 'image'
        },
        {
            name: 'Voucher',
            path: '/voucher',
            icon: 'local_activity'
        },
        {
            name: 'Batch',
            path: '/batch',
            icon: 'local_activity'
        },
        {
            name: 'Link Grup Member',
            path: '/link',
            icon: 'link'
        },
        {
            name: 'Penghasilan',
            path: '/penghasilan',
            icon: 'account_balance_wallet'
        },
        {
            name: 'Penarikan Komisi',
            path: '/penarikan_komisi',
            icon: 'account_balance'
        }
    ],
    akun: [
        {
            name: 'Akun Saya',
            path: '/akun',
            icon: 'account_box'
        }
    ]
};
