import firebaseAuthService from '../../services/firebase/firebaseAuthService';
import Swal from 'sweetalert2';

export const GET_PASSING_GRADE = 'GET_PASSING_GRADE';
export const GET_PASSING_GRADE_PPPK = 'GET_PASSING_GRADE_PPPK';
export const GET_ALL_PASSING_GRADE = 'GET_ALL_PASSING_GRADE';
export const UPDATE_PG = 'UPDATE_PG';
export const DELETE_PG = 'DELETE_PG';
export const GET_DETAIL_PASSING_GRADE = 'GET_DETAIL_PASSING_GRADE';

export const getAllPasingGrade = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('passing_grade')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_ALL_PASSING_GRADE,
                    payload: data
                });
            });
    };
};

export const getDetailPasingGrade = (type) => {
    return async (dispatch) => {
        type = type == 'guru' ? 'pppk_guru' : 'pppk';
        firebaseAuthService.firestore
            .collection('passing_grade')
            .doc(type)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = type;
                dispatch({
                    type: GET_DETAIL_PASSING_GRADE,
                    payload: data
                });
            });
    };
};

export const getPassingGradeBumn = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('passing_grade')
            .doc('bumn')
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = 'bumn';
                dispatch({
                    type: GET_DETAIL_PASSING_GRADE,
                    payload: data
                });
            });
    };
};

export const updatePG = (params, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('passing_grade')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Berhasil',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: UPDATE_PG,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deletePassingGrade = (doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('passing_grade')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_PG,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getPassingGrade = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('passing_grade')
            .doc('skd')
            .get()
            .then((res) => {
                dispatch({
                    type: GET_PASSING_GRADE,
                    payload: res.data()
                });
            });
    };
};

export const getPassingGradeUKSKB = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('passing_grade')
            .doc('uk')
            .get()
            .then((res) => {
                dispatch({
                    type: GET_PASSING_GRADE,
                    payload: res.data()
                });
            });
    };
};

export const getPassingGradePPPK = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('passing_grade')
            .doc('pppk')
            .get()
            .then((res) => {
                if (res.exists) {
                    dispatch({
                        type: GET_PASSING_GRADE_PPPK,
                        payload: res.data()
                    });
                }
            });
    };
};
