import {
    Box,
    Button,
    Card,
    FormControl,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import { ConfirmationDialog } from 'matx';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import shortid from 'shortid';

import { deleteUK, getUK } from 'app/redux/actions/UKActions';
import AddUKDialog from './AddUKDialog';
import EditUK from './EditUK';

class UKTable extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        shouldOpenEditorDialog: false,
        shouldOpenCreateDialog: false,
        shouldOpenConfirmationDialog: false,
        query: ''
    };

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenConfirmationDialog: false,
            shouldOpenCreateDialog: false
        });
        this.updatePageData();
    };

    handleConfirmationResponse = async () => {
        await this.props.deleteUK(this.state.uid_delete);
        this.handleDialogClose();
    };

    componentDidMount() {
        this.updatePageData();
    }

    componentDidUpdate(prevProps, prevState) {}

    updatePageData = async () => {
        await this.props.getUK();
    };

    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    render() {
        let {
            rowsPerPage,
            page,
            shouldOpenCreateDialog,
            shouldOpenEditorDialog,
            shouldOpenConfirmationDialog,
            query
        } = this.state;
        let { uk } = this.props;
        return (
            <div className='m-sm-30'>
                <h2 className='mt-10 mb-5' style={{ fontSize: '25px' }}>
                    List Paket Uji Kompetensi
                </h2>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button
                        variant='outlined'
                        color='primary'
                        className='mb-5'
                        onClick={() =>
                            this.setState({
                                shouldOpenCreateDialog: true
                            })
                        }
                        title='Edit'
                    >
                        Tambah
                    </Button>
                    <FormControl
                        className='faqDesc'
                        style={{
                            boxShadow: '0px 0px 2px #43434359',
                            marginBottom: '15px',
                            borderRadius: '5px',
                            maxHeight: '45px'
                        }}
                    >
                        <Box
                            component='fieldset'
                            borderColor='transparent'
                            style={{ paddingLeft: '0px' }}
                            className='flex'
                        >
                            <IconButton title='Cari' style={{ padding: '5px' }}>
                                <Icon color='action'>search</Icon>
                            </IconButton>
                            <input
                                onChange={this.handleSearch}
                                placeholder='Cari..'
                                name='query'
                                value={query}
                                style={{
                                    minHeight: '25px',
                                    border: '0px',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    outline: 0
                                }}
                            ></input>
                        </Box>
                    </FormControl>
                </div>

                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No.
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Nama Paket
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='left'
                                        colSpan={2}
                                    >
                                        Diskon
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Status Paket
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Tgl Dibuat
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                        colSpan={2}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {uk.length > 0 ? (
                                    uk
                                        .filter((item) =>
                                            Object.keys(item).some((key) =>
                                                query.includes(key)
                                                    ? false
                                                    : item[key]
                                                          .toString()
                                                          .toLowerCase()
                                                          .includes(query)
                                            )
                                        )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((datUsers, index) => (
                                            <TableRow key={shortid.generate()}>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    align='center'
                                                >
                                                    {index + 1}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    align='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.name}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    align='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.is_discount ===
                                                        '1' && (
                                                        <small
                                                            className={`border-radius-4 text-white px-8 py-2 bg-primary`}
                                                        >
                                                            Aktif
                                                        </small>
                                                    )}
                                                    {datUsers.is_discount ===
                                                        '0' && (
                                                        <small
                                                            className={`border-radius-4 text-white px-5 py-2 bg-secondary`}
                                                        >
                                                            Non Aktif
                                                        </small>
                                                    )}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    align='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.is_maintenance ===
                                                        '1' && (
                                                        <small
                                                            className={`border-radius-4 text-white px-8 py-2 bg-primary`}
                                                        >
                                                            Aktif
                                                        </small>
                                                    )}
                                                    {datUsers.is_maintenance ===
                                                        '0' && (
                                                        <small
                                                            className={`border-radius-4 text-white px-5 py-2 bg-secondary`}
                                                        >
                                                            Non Aktif
                                                        </small>
                                                    )}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    align='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.created_at}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    align='center'
                                                    colSpan={2}
                                                >
                                                    <Link
                                                        to={
                                                            '/detail/uk/' +
                                                            datUsers.doc_id
                                                        }
                                                    >
                                                        <IconButton title='Lihat Detail'>
                                                            <Icon color='secondary'>
                                                                find_in_page
                                                            </Icon>
                                                        </IconButton>
                                                    </Link>
                                                    <IconButton
                                                        onClick={() =>
                                                            this.setState({
                                                                uid: datUsers,
                                                                shouldOpenEditorDialog: true
                                                            })
                                                        }
                                                        title='Edit'
                                                    >
                                                        <Icon color='primary'>
                                                            edit
                                                        </Icon>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() =>
                                                            this.setState({
                                                                uid_delete:
                                                                    datUsers.doc_id,
                                                                shouldOpenConfirmationDialog: true
                                                            })
                                                        }
                                                        title='delete'
                                                    >
                                                        <Icon color='error'>
                                                            delete
                                                        </Icon>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align='center'>
                                            Not Have Data
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={uk.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />

                        {shouldOpenEditorDialog && (
                            <EditUK
                                handleClose={this.handleDialogClose}
                                open={shouldOpenEditorDialog}
                                uid={this.state.uid}
                            />
                        )}
                        {shouldOpenCreateDialog && (
                            <AddUKDialog
                                handleClose={this.handleDialogClose}
                                open={shouldOpenCreateDialog}
                                uid={this.state.uid}
                            />
                        )}
                        {shouldOpenConfirmationDialog && (
                            <ConfirmationDialog
                                open={shouldOpenConfirmationDialog}
                                onConfirmDialogClose={this.handleDialogClose}
                                onYesClick={this.handleConfirmationResponse}
                                text='Hapus Data Ini ?'
                            />
                        )}
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        uk: state.uk.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUK: () => dispatch(getUK()),
        deleteUK: (params) => dispatch(deleteUK(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UKTable);
