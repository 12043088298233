import { Button, Card, CircularProgress, Grid, InputAdornment, MenuItem } from '@material-ui/core';
import React, { Component } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import rules from '../../../config/formValidation';
import { firebaseLoginEmailPassword, SignupBackend } from '../../redux/actions/LoginActions';

class SignUp extends Component {
    constructor() {
        super();
        this.state = {
            full_name: '',
            alamat: '',
            jenis_kelamin: 1,
            email: '',
            password: '',
            no_wa: '',
            loading: false,
            error: ''
        };
    }

    handleChange = ({ target: { name, value } }) => {
        this.setState((prefState) => ({
            ...prefState,
            [name]: value
        }));
    };

    componentDidMount() {
        const { user, history } = this.props;

        if (Object.keys(user).length > 0 && user.constructor == Object) {
            history.push('/');
        }
        this.setState({
            error: ''
        });
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('invalidPhoneNumber', (value) => {
            if (value.slice(0, 1) != 8) {
                return false;
            }
            return true;
        });
    }

    handleFormSubmit = () => {
        this.setState(
            {
                loading: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = async () => {
        const {
            email,
            full_name,
            no_wa,
            password,
            alamat,
            jenis_kelamin
        } = this.state;
        const params = {
            email: email,
            name: full_name,
            password: password,
            phone: no_wa,
            alamat: alamat,
            jenis_kelamin: jenis_kelamin
        };
        SignupBackend(params)
            .then((res) => {
                Swal.fire({
                    title: 'Daftar berhasil',
                    text: 'Anda dapat login sekarang',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    const { history } = this.props;
                    history.push('/session/signin');
                });
                this.setState({
                    loading: false
                });
            })
            .catch((err) => {
                let error = '';
                if (err.response.data.data.message) {
                    error = err.response.data.data.message;
                } else {
                    error = 'Coba beberapa saat lagi';
                }
                Swal.fire({
                    title: 'Gagal daftar',
                    text: error,
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
                this.setState({
                    loading: false
                });
            });
    };

    render() {
        let {
            full_name,
            email,
            password,
            no_wa,
            konfirmasi_password,
            loading,
            error,
            alamat,
            jenis_kelamin
        } = this.state;
        return (
            <div
                className={
                    'flex justify-center items-center  min-h-full-screen bg-session'
                }
            >
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    className="my-5"
                >
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Card className="card-session">
                            <div className="p-8 h-full relative">
                                <div className="pb-3 flex justify-center items-center h-full">
                                    <img
                                        className="w-200 mb-3"
                                        src="/assets/images/logos/logo-vertical.svg"
                                        alt=""
                                    />
                                </div>
                                <ValidatorForm onSubmit={this.handleFormSubmit}>
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-6 w-full"
                                        label="Nama Lengkap"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="full_name"
                                        value={full_name || ''}
                                        validators={[
                                            'required',
                                            'minStringLength: 3'
                                        ]}
                                        errorMessages={[
                                            rules.required,
                                            'minimal panjang karakter, 3 karakter'
                                        ]}
                                    />
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-6 w-full"
                                        label="Alamat Lengkap"
                                        multiline
                                        rows={4}
                                        onChange={this.handleChange}
                                        type="text"
                                        name="alamat"
                                        value={alamat || ''}
                                        validators={['required']}
                                        errorMessages={[rules.required]}
                                    />
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-6 w-full"
                                        label="Email"
                                        onChange={this.handleChange}
                                        type="email"
                                        name="email"
                                        value={email || ''}
                                        validators={['required', 'isEmail']}
                                        errorMessages={[
                                            rules.required,
                                            rules.isEmail
                                        ]}
                                    />
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-6 w-full"
                                        label="Nomor Whatsapp"
                                        onChange={this.handleChange}
                                        type="number"
                                        name="no_wa"
                                        value={no_wa || ''}
                                        validators={[
                                            'required',
                                            'minStringLength: 10',
                                            'invalidPhoneNumber'
                                        ]}
                                        errorMessages={[
                                            rules.required,
                                            'minimal panjang karakter, 10 karakter',
                                            'Format Nomor Salah'
                                        ]}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    +62
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <SelectValidator
                                        variant="outlined"
                                        className="mb-4 w-full"
                                        label="Jenis Kelamin"
                                        onChange={this.handleChange}
                                        name="jenis_kelamin"
                                        value={jenis_kelamin || ''}
                                        validators={['required']}
                                        errorMessages={[rules.required]}
                                    >
                                        <MenuItem value={1}>Laki-Laki</MenuItem>
                                        <MenuItem value={0}>Perempuan</MenuItem>
                                    </SelectValidator>
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-4 w-full"
                                        label="Password"
                                        onChange={this.handleChange}
                                        name="password"
                                        type="password"
                                        value={password || ''}
                                        validators={[
                                            'required',
                                            'minStringLength: 8'
                                        ]}
                                        errorMessages={[
                                            rules.required,
                                            'minimal panjang karakter, 8 karakter'
                                        ]}
                                    />
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-4 w-full"
                                        label="Konfirmasi Password"
                                        onChange={this.handleChange}
                                        name="konfirmasi_password"
                                        type="password"
                                        value={konfirmasi_password || ''}
                                        validators={[
                                            'required',
                                            'isPasswordMatch'
                                        ]}
                                        errorMessages={[
                                            rules.required,
                                            rules.isPasswordMatch
                                        ]}
                                    />
                                    {error == 'auth/email-already-in-use' ? (
                                        <p className="text-error font-medium m-0 mb-2">
                                            {rules.emailExist}
                                        </p>
                                    ) : null}
                                    <div className="flex flex-column mt-4">
                                        <Button
                                            className="capitalize font-semibold elevation-z0"
                                            disabled={loading}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            {loading ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                'Daftar Sekarang'
                                            )}
                                        </Button>
                                        <p className="text-center">
                                            Sudah punya akun?
                                        </p>
                                        <Link
                                            to="/session/signin"
                                            className="w-full block"
                                        >
                                            <Button className="capitalize font-semibold btn-blue text-white w-full">
                                                Masuk Sekarang
                                            </Button>
                                        </Link>
                                    </div>
                                </ValidatorForm>
                                <div className="border-bottom-1 w-full mt-4"></div>
                               
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        login: state.login
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        firebaseLoginEmailPassword: (params) =>
            dispatch(firebaseLoginEmailPassword(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
