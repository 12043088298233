import { Grid, Card, Icon } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SimpleCard, Breadcrumb } from 'matx';
import { Link } from 'react-router-dom';

const PPPKIndex = ({

}) => {

    useEffect(() => {
        initPembahasan();
    }, []);

    const initPembahasan = () => {
    
    };

   
    return (
        <div className='m-sm-30'>
         
            <SimpleCard title='Pilih Paket PPPK' className='mt-10'>
                <Grid container spacing={1} className='mt-5'>
                  

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Card
                            className=' flex justify-between  border-1 m-auto '
                            elevation={1}
                            style={{}}
                        >
                            <Link
                                to={`/pppk/mandiri`}
                                className='cursor-pointer'
                            >
                                <div className='py-4 px-4 flex justify-between align-items-center'>
                                    <div className='px-4 py-3 bg-primary text-white flex items-center justify-center border-radius-4 mr-3'>
                                        <Icon>assignment</Icon>
                                    </div>
                                </div>
                                <div className='w-full h-full flex flex-column border-radius-4 px-4 mb-4'>
                                    <h3>Paket Mandiri</h3>
                                    {/* <p className='m-0'>
                                        Belajar secara Mandiri dengan
                                        mengerjakan latihan soal yang tersedia
                                        di website
                                    </p> */}
                                </div>
                            </Link>
                        </Card>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Card
                            className=' flex justify-between  border-1 m-auto '
                            elevation={1}
                            style={{}}
                        >
                            <Link
                                to={`/pppk/bimbel`}
                                className='cursor-pointer'
                            >
                                <div className='py-4 px-4 flex justify-between align-items-center'>
                                    <div className='px-4 py-3 bg-primary text-white flex items-center justify-center border-radius-4 mr-3'>
                                        <Icon>assignment</Icon>
                                    </div>
                                </div>
                                <div className='w-full h-full flex flex-column border-radius-4 px-4 mb-4'>
                                    <h3>Paket Bimbel</h3>
                                    {/* <p className='m-0'>
                                        Belajar Interaktif bersama coach
                                        profesional secara virtual melalui zoom
                                        dan live streaming.
                                    </p> */}
                                </div>
                            </Link>
                        </Card>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    );
};



export default PPPKIndex;
