import {
    GET_WIDRAW,
    GET_INVOICE,
    GET_WIDRAW_BELUM,
    GET_WIDRAW_SUDAH,
    GET_WIDRAW_BATAL,
} from '../actions/KomisiActions';

const initialState = {
    belum: [],
    sudah: [],
    batal: [],
    data: [],
    invoice: []
};

const KomisiReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_WIDRAW_BELUM: {
            return {
                belum: action.payload
            };
        }
        case GET_WIDRAW_SUDAH: {
            return {
                sudah: action.payload
            };
        }
        case GET_WIDRAW_BATAL: {
            return {
                batal: action.payload
            };
        }
        case GET_INVOICE: {
            return {
                invoice: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default KomisiReducer;
