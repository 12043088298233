import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_WEB_SETTING = 'GET_WEB_SETTING';
export const EDIT_WEB_SETTING = 'EDIT_WEB_SETTING';

export const getWebSetting = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('web_setting')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_WEB_SETTING,
                    payload: data
                });
            });
    };
};

export const updateWebSetting = (params, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('web_setting')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: EDIT_WEB_SETTING,
                    payload: { params: params, doc_id: doc_id }
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data berhasil diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
