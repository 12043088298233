import { Button, Grid } from '@material-ui/core';
import {
    CardNomorSoalAdmin,
    CardSoalAdmin,
    CardTryoutAdmin
} from 'app/components';
import {
    delQuestionTryoutPPPK,
    getDetailTryoutPPPK,
    getSoalTryoutPPPK
} from 'app/redux/actions/KerjakanTryoutActions';
import {
    setDefaultSettings,
    setLayoutSettings
} from 'app/redux/actions/LayoutActions';
import { merge } from 'lodash';
import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { isMdScreen } from 'utils';

import AddQuestion from './AddQuestion';
import EditQuestion from './EditQuestion';
import ImportQuestionDialog from './ImportQuestionDialog';

class TryoutSoal extends Component {
    constructor() {
        super();
        this.state = {
            no_soal: 0,
            openEditor: false,
            openCreator: false,
            openConfirm: false,
            openImport: false,
            type: '',
            editData: {
                category: '',
                option: '',
                pembahasan: '',
                question_img: '',
                question_text: '',
                subcategory: '',
                doc_id: '',
                pembahasan_img: ''
            }
        };
    }

    handleSidenavToggle = () => {
        let {
            settings: {
                layout1Settings: {
                    leftSidebar: { mode }
                }
            }
        } = this.props;

        this.updateSidebarMode({
            mode: mode === 'compact' ? 'full' : 'compact'
        });
    };

    updateSidebarMode = (sidebarSettings) => {
        let { settings, setLayoutSettings, setDefaultSettings } = this.props;
        const updatedSettings = merge({}, settings, {
            layout1Settings: {
                leftSidebar: {
                    ...sidebarSettings
                }
            }
        });

        setLayoutSettings(updatedSettings);
        setDefaultSettings(updatedSettings);
    };

    componentDidMount() {
        this.getData();
        this.handleSidebar();
    }

    getData = () => {
        const { id, type } = this.props.match.params;
        const { getDetailTryoutPPPK, getSoalTryoutPPPK } = this.props;
        getDetailTryoutPPPK(id);
        getSoalTryoutPPPK(id);
        this.setState({
            type: type
        });
    };

    componentWillUnmount() {
        this.handleSidebar();
    }
    handleSidebar = () => {
        let sideMode;
        if (isMdScreen()) {
            sideMode = 'close';
        } else {
            sideMode = 'compact';
        }
        this.updateSidebarMode({ mode: sideMode });
    };
    handleChangeNo = (no_soal) => {
        this.setState({
            no_soal: no_soal
        });
    };

    handleEditSoal = (data) => {
        const {
            category,
            option,
            pembahasan,
            question_img,
            question_text,
            subcategory,
            doc_id,
            pembahasan_img,
            video_pembahasan
        } = data;
        this.setState({
            openEditor: true,
            editData: {
                category: category,
                option: option,
                pembahasan: pembahasan,
                question_img: question_img,
                question_text: question_text,
                subcategory: subcategory,
                doc_id: doc_id,
                pembahasan_img: pembahasan_img,
                video_pembahasan: video_pembahasan
            }
        });
    };

    handleDeleteSoal = (data) => {
        const { deleteQuestionTryout } = this.props;
        const { id } = this.props.match.params;
        const { doc_id } = data;
        Swal.fire({
            title: 'Peringatan',
            text: 'Apakah kamu yakin ingin menghapus pertanyaan ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            icon: 'warning'
        }).then((res) => {
            if (res.isConfirmed) {
                deleteQuestionTryout(id, doc_id);
                this.setState({
                    no_soal:
                        this.state.no_soal == 0 ? 0 : this.state.no_soal - 1
                });
            } else {
                Swal.close();
            }
        });
    };

    handleAddSoal = () => {
        this.setState({
            openCreator: true
        });
    };

    handleImportSoal = () => {
        this.setState({
            openImport: true
        });
    };

    handleDialogClose = () => {
        this.setState({
            openEditor: false,
            openCreator: false,
            shouldOpenCreateDialog: false,
            openConfirm: false,
            openImport: false
        });
    };

    handleConfirmationResponse = async () => {
        const { doc_id, parent } = this.props.match.params;
        await this.props.deleteQuestionTryout(
            parent,
            doc_id,
            this.state.deleteData
        );
        this.handleDialogClose();
    };

    render() {
        const { no_soal, openEditor, openCreator, openImport, editData } =
            this.state;
        const {
            soalTryout,
            detailTryout: { title, expires_time, passing_grade }
        } = this.props;
        return (
            <div className='m-sm-30'>
                <div id='top-element'></div>
                {soalTryout.length <= 0 ? (
                    <div className='py-4 w-full text-center'>
                        <h5 className='m-0'>Belum ada soal yang dibuat</h5>
                        <p className='m-0'>Buat atau import Soal Sekarang</p>
                        <div className='my-4 w-full text-center'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='button'
                                onClick={this.handleAddSoal}
                            >
                                Buat Soal
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                type='button'
                                onClick={this.handleImportSoal}
                            >
                                Import Soal
                            </Button>
                        </div>
                    </div>
                ) : (
                    <CardTryoutAdmin title={title} duration={expires_time}>
                        <Grid container spacing={3}>
                            <Grid item lg={9} m={9} sm={12} xs={12}>
                                <CardSoalAdmin
                                    title={`Soal Nomor ${
                                        no_soal + 1
                                    } | ${soalTryout[no_soal]?.category
                                        ?.replaceAll('_', ' ')
                                        .toUpperCase()}`}
                                    jml_soal={soalTryout.length}
                                    no_soal={no_soal}
                                    soal={soalTryout[no_soal]?.question_text}
                                    soal_img={soalTryout[no_soal]?.question_img}
                                    jawaban={soalTryout[no_soal]?.option}
                                    handleChangeNo={this.handleChangeNo}
                                    handleEditSoal={() =>
                                        this.handleEditSoal(soalTryout[no_soal])
                                    }
                                    handleDeleteSoal={() =>
                                        this.handleDeleteSoal(
                                            soalTryout[no_soal]
                                        )
                                    }
                                    id_soal={soalTryout[no_soal].doc_id}
                                    pembahasan={soalTryout[no_soal].pembahasan}
                                    pembahasan_img={
                                        soalTryout[no_soal].pembahasan_img
                                    }
                                    pembahasan_video={
                                        soalTryout[no_soal].video_pembahasan
                                    }
                                    passing_grade_list={passing_grade}
                                />
                            </Grid>
                            <Grid item lg={3} m={3} sm={12} xs={12}>
                                <CardNomorSoalAdmin
                                    handleChangeNo={this.handleChangeNo}
                                    title='Nomor Soal'
                                    soal={soalTryout}
                                    no_soal={no_soal}
                                    id_quis={
                                        soalTryout.length > 0
                                            ? soalTryout[no_soal].doc_id
                                            : ''
                                    }
                                    handleAddSoal={this.handleAddSoal}
                                    handleImportSoal={this.handleImportSoal}
                                />
                            </Grid>
                        </Grid>
                    </CardTryoutAdmin>
                )}
                {openEditor && (
                    <EditQuestion
                        handleClose={this.handleDialogClose}
                        open={openEditor}
                        category={editData.category}
                        option={editData.option}
                        pembahasan={editData.pembahasan}
                        question_img={editData.question_img}
                        question_text={editData.question_text}
                        subcategory={editData.subcategory}
                        doc_id={editData.doc_id}
                        params={this.props.match.params}
                        pembahasan_img={editData.pembahasan_img}
                        pembahasan_video={editData.video_pembahasan}
                        passing_grade={passing_grade}
                    />
                )}
                {openCreator && (
                    <AddQuestion
                        handleClose={this.handleDialogClose}
                        open={openCreator}
                        uid={this.state.uid}
                        params={this.props.match.params}
                        totalSoal={soalTryout.length}
                        passing_grade={passing_grade}
                    />
                )}
                {openImport && (
                    <ImportQuestionDialog
                        open={openImport}
                        handleClose={this.handleDialogClose}
                        params={this.props.match.params}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    settings: state.layout.settings,
    detailTryout: state.kerjakanPPPK.tryout,
    soalTryout: state.kerjakanPPPK.soal
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLayoutSettings: (data) => dispatch(setLayoutSettings(data)),
        setDefaultSettings: (data) => dispatch(setDefaultSettings(data)),
        deleteQuestionTryout: (tryout_id, doc_id) =>
            dispatch(delQuestionTryoutPPPK(tryout_id, doc_id)),
        getDetailTryoutPPPK: (kode) => dispatch(getDetailTryoutPPPK(kode)),
        getSoalTryoutPPPK: (kode) => dispatch(getSoalTryoutPPPK(kode))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TryoutSoal);
