import axios from 'axios';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_ALL_ADMIN = 'GET_ALL_ADMIN';
export const ADD_ADMIN = 'ADD_ADMIN';
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';

export const getAllAdmin = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('admin')
            .where('is_super', '==', '0')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_ALL_ADMIN,
                    payload: data
                });
            });
    };
};

export const addAdmin = (params) => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        axios.defaults.headers.common['Authorization'] = uid;
        await axios
            .post(`${process.env.REACT_APP_API_URL}scpns/admin/add`, params)
            .then((res) => {
                dispatch({
                    type: ADD_ADMIN
                });
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    };
};

export const updateAdmin = (params) => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        axios.defaults.headers.common['Authorization'] = uid;
        await axios
            .post(`${process.env.REACT_APP_API_URL}scpns/admin/edit`, params)
            .then((res) => {
                dispatch({
                    type: EDIT_ADMIN
                });
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    };
};

export const deleteAdmin = (params) => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        axios.defaults.headers.common['Authorization'] = uid;
        await axios
            .post(`${process.env.REACT_APP_API_URL}scpns/admin/delete`, params)
            .then((res) => {
                dispatch({
                    type: DELETE_ADMIN
                });
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    console.log(error.response.data);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    };
};

