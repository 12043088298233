import axios from 'axios';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_BIMBEL = 'GET_BIMBEL';
export const ADD_BIMBEL = 'ADD_BIMBEL';
export const DELETE_BIMBEL = 'DELETE_BIMBEL';

export const getBimbel = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('bimbel')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_BIMBEL,
                    payload: data
                });
            });
    };
};

export const beliBimbel = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return axios
        .post(`${process.env.REACT_APP_API_URL}scpns/bimble/pay`, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addBimbel = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}scpns/bimbel/add`, params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getBimbel());
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const delBimbel = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('bimbel')
            .doc(doc_id)
            .delete()
            .then(() => {
                dispatch({
                    type: DELETE_BIMBEL,
                    payload: doc_id
                });
            });
    };
};

export const editBimbel = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}scpns/bimbel/edit`, params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getBimbel());
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const getCategory = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('subcategory')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_BIMBEL,
                    payload: data
                });
            });
    };
};

export const delCategory = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('subcategory')
            .doc(doc_id)
            .delete()
            .then(() => {
                dispatch({
                    type: DELETE_BIMBEL,
                    payload: doc_id
                });
            });
    };
};

export const addCategory = (params, id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('subcategory')
            .doc(id)
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Category berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                getCategory();
                dispatch({
                    type: ADD_BIMBEL,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Category gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
