import {
    Button,
    Dialog,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { addPaketTryout } from 'app/redux/actions/PaketTryoutActions';
import { getTanggal } from 'app/redux/actions/UserActions';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

const AddPaketEventDialog = (props) => {
    const formRef = useRef(null);
    const [state, setState] = useState({
        name: '',
        status: 'aktif',
        diskon: '1',
        statusButton: false,
        buttonText: 'Tambah',
        group_link: '',
        isActive: false,
        plan_price: 0,
        plan_discount: 0,
        description: '',
        jenis_paket: 'tryout',
        level_paket: 'standart',
        benefit: [],
        expired_month: 0,
        plan_benefit: [],
        deskripsi: '',
        form_benefit: {
            status: 1,
            benefit: '',
            index: '',
            isNew: true,
            error: ''
        }
    });

    const handleChangeBenefit = (event) => {
        let value = event.target.value;
        setState((prevState) => ({
            ...prevState,
            form_benefit: {
                ...prevState.form_benefit,
                benefit: value
            }
        }));
    };

    const handleChange = (event, source) => {
        event.persist();
        if (source === 'switch') {
            setState((prevState) => ({
                ...prevState,
                isActive: event.target.checked
            }));
            return;
        }

        if (source === 'status') {
            setState((prevState) => ({
                ...prevState,
                users_status: event.target.value === 'SUSPEND' ? 0 : 1,
                users_status_value: event.target.value
            }));
            return;
        }

        setState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const handleChangeSelect = (event) => {
        event.persist();
        setState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const handleFormSubmit = async () => {
        let now = getTanggal();
        setState((prevState) => ({
            ...prevState,
            statusButton: true,
            buttonText: 'Loading...'
        }));
        let {
            name,
            status,
            plan_price,
            plan_discount,
            jenis_paket,
            level_paket,
            plan_benefit,
            deskripsi,
            group_link,
            expired_month
        } = state;
        let params = {
            title: name,
            harga: parseInt(plan_price, 10),
            harga_discount: parseInt(plan_discount, 10),
            jenis_paket: jenis_paket,
            level_paket: level_paket,
            plan_benefit: plan_benefit,
            deskripsi: deskripsi,
            status: status,
            expired_month: expired_month,
            group_link: group_link,
            created_at: now
        };
        await props.addPaketTryout(params);
        await props.handleClose();
    };

    const handleFormBenefit = () => {
        const {
            form_benefit: { benefit, isNew, index, status },
            plan_benefit
        } = state;
        if (benefit.length > 0) {
            if (isNew) {
                let allBenefit = plan_benefit.concat({
                    status: status,
                    benefit: benefit
                });
                setState((prevState) => ({
                    ...prevState,
                    plan_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                }));
            } else {
                let allBenefit = plan_benefit;
                allBenefit[index] = {
                    status: status,
                    benefit: benefit
                };
                setState((prevState) => ({
                    ...prevState,
                    plan_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                }));
            }
        } else {
            setState((prevState) => ({
                ...prevState,
                form_benefit: {
                    ...prevState.form_benefit,
                    error: 'Tolong isi semua inputan'
                }
            }));
        }
    };

    const handleEditBenefit = (item, index) => {
        let benefit = item.benefit;
        let status = item.status;
        setState((prevState) => ({
            ...prevState,
            form_benefit: {
                ...prevState.form_benefit,
                benefit: benefit,
                status: status,
                isNew: false,
                index: index
            }
        }));
    };

    const handleDeleteBenefit = (i) => {
        const { plan_benefit } = state;
        let allBenefit = plan_benefit.filter((item, index) => index !== i);
        setState((prevState) => ({
            ...prevState,
            plan_benefit: allBenefit
        }));
    };

    let {
        name,
        status,
        group_link,
        plan_price,
        plan_discount,
        deskripsi,
        jenis_paket,
        form_benefit,
        plan_benefit,
        expired_month
    } = state;
    let { open, handleClose } = props;

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth='md'
            fullWidth={true}
        >
            <div className='p-5'>
                <h4 className='mb-5'>Tambah Paket Tryout</h4>
                <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
                    <Grid className='mb-10' container spacing={1}>
                        <Grid item sm={12} xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Detail Paket
                            </p>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Nama Paket *'
                                onChange={handleChange}
                                type='text'
                                name='name'
                                value={name}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Group Link *'
                                onChange={handleChange}
                                type='text'
                                name='group_link'
                                value={group_link}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Deskripsi *'
                                onChange={handleChange}
                                type='text'
                                name='deskripsi'
                                value={deskripsi}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id='demo-simple-select-helper-label'>
                                    Jenis Paket
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-helper-label'
                                    id='demo-simple-select-helper'
                                    value={jenis_paket}
                                    name='jenis_paket'
                                    onChange={handleChangeSelect}
                                >
                                    <MenuItem value={'satuan'}>Satuan</MenuItem>
                                    <MenuItem value={'bundel'}>Bundel</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id='demo-simple-select-helper-label'>
                                    Status
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-helper-label'
                                    id='demo-simple-select-helper'
                                    value={status}
                                    name='status'
                                    onChange={handleChangeSelect}
                                >
                                    <MenuItem value={'aktif'}>Aktif</MenuItem>
                                    <MenuItem value={'tidak_aktif'}>
                                        Tidak Aktif
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Pengaturan Masa Berlaku
                            </p>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className='w-full'
                                label='Mssa Berlaku Dalam Bulan'
                                onChange={handleChange}
                                type='number'
                                name='expired_month'
                                value={expired_month}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Pengaturan Harga
                            </p>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextValidator
                                className='w-full'
                                label='Harga Paket'
                                onChange={handleChange}
                                type='number'
                                name='plan_price'
                                value={plan_price}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                            />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <TextValidator
                                className='w-full'
                                label='Discount'
                                onChange={handleChange}
                                type='number'
                                name='plan_discount'
                                value={plan_discount}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Benefit Paket
                            </p>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextValidator
                                className='w-full'
                                label='Benefit Paket'
                                onChange={handleChangeBenefit}
                                type='text'
                                name='benefit'
                                value={form_benefit.benefit}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Button
                                color='primary'
                                className='w-full mt-5'
                                variant='contained'
                                onClick={handleFormBenefit}
                            >
                                {form_benefit.isNew
                                    ? 'Tambah Benefit'
                                    : 'Edit Benefit'}
                            </Button>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Table className='crud-table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Benefit</TableCell>
                                        <TableCell className='text-center'>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {plan_benefit.map((benefit, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                className='capitalize'
                                                align='left'
                                            >
                                                {benefit.benefit}
                                            </TableCell>
                                            <TableCell className='text-center'>
                                                <IconButton
                                                    onClick={() =>
                                                        handleEditBenefit(
                                                            benefit,
                                                            index
                                                        )
                                                    }
                                                >
                                                    <Icon color='primary'>
                                                        edit
                                                    </Icon>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() =>
                                                        handleDeleteBenefit(
                                                            index
                                                        )
                                                    }
                                                >
                                                    <Icon color='error'>
                                                        delete
                                                    </Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>

                    <div className='flex flex-space-between flex-middle'>
                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={state.statusButton}
                        >
                            {state.buttonText}
                        </Button>
                        <Button variant='contained' onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

AddPaketEventDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    addPaketTryout: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { addPaketTryout })(
    AddPaketEventDialog
);
