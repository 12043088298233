import { Avatar, Button, CircularProgress, Grid, InputAdornment, MenuItem } from '@material-ui/core';
import { ButtonFotoProfile } from 'app/components';
import { getMyTryoutSKB, getMyTryoutSKBBundle } from 'app/redux/actions/SKBActions';
import { getMyTryoutUK, getMyTryoutUKBundle } from 'app/redux/actions/UKActions';
import { Breadcrumb, SimpleCard } from 'matx';
import React, { Component } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import rules from '../../../config/formValidation';
import { updateProfile } from '../../redux/actions/UserActions';

class EditProfile extends Component {
    constructor() {
        super();
        this.state = {
            menu: 1,
            full_name: '',
            email: '',
            no_wa: '',
            password: '',
            email: '',
            konfirmasi_password: '',
            loadingEditProfile: false,
            loadingEditPassword: false,
            password_lama: '',
            alamat: '',
            jenis_kelamin: '',
            foto_profile: '',
            foto_profile_preview: ''
        };
    }

    handleChangeMenu = (menu) => {
        this.setState({
            menu
        });
    };

    handleChange = ({ target: { name, value } }) => {
        this.setState((prefState) => ({
            ...prefState,
            [name]: value
        }));
    };

    componentDidMount() {
        const {
            name,
            email,
            phone,
            alamat,
            jenis_kelamin,
            foto_profile
        } = this.props.user;

        this.setState({
            full_name: name,
            email: email,
            no_wa: phone ? phone.slice(3, phone.length) : '',
            alamat: alamat,
            jenis_kelamin: jenis_kelamin,
            foto_profile_preview: foto_profile
        });
    }
    handleFormSubmit = () => {
        this.setState(
            {
                loadingEditProfile: true
            },
            this.sendEditProfile
        );
    };
    sendEditProfile = () => {
        const {
            full_name,
            foto_profile,
            foto_profile_preview,
            email
        } = this.state;

        const params = {
            name: full_name,
            email: email,
            foto_profile: foto_profile,
            old_foto_profile: foto_profile_preview
        };

        updateProfile(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil edit profile',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                this.setState({
                    loadingEditProfile: false
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: 'Gagal edit profile',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
                this.setState({
                    loadingEditProfile: false
                });
            });
    };
    handleChangeFoto = (file, path) => {
        this.setState({
            foto_profile_preview: path,
            foto_profile: file
        });
    };
    render() {
        const {
            full_name,
            email,
            foto_profile_preview,
            loadingEditProfile
        } = this.state;
        return (
            <div className="m-sm-30">
                <SimpleCard noPadding>
                    <div
                        className="thumbnail-profile w-full"
                        style={{
                            backgroundImage:
                                'url(/assets/images/thumbnail/thumbnail-profile.png)'
                        }}
                    ></div>
                    <div className="px-8 py-5" style={{ marginTop: -94 }}>
                        <div className="flex items-end">
                            <div className="relative">
                                <Avatar
                                    style={{
                                        width: 144,
                                        height: 144
                                    }}
                                    className="cursor-pointer"
                                    src={
                                        foto_profile_preview != undefined &&
                                        foto_profile_preview.length > 0
                                            ? foto_profile_preview
                                            : `https://ui-avatars.com/api/?name=${full_name}&background=D0CBFF&color=7066EB`
                                    }
                                />
                                <ButtonFotoProfile
                                    uploadFoto={this.handleChangeFoto}
                                />
                            </div>
                            <h4 className="mb-5">{full_name}</h4>
                        </div>
                        <ValidatorForm
                            onSubmit={this.handleFormSubmit}
                            className="mt-4"
                        >
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-6 w-full"
                                        label="Nama Lengkap"
                                        onChange={this.handleChange}
                                        type="text"
                                        name="full_name"
                                        value={full_name || ''}
                                        validators={[
                                            'required',
                                            'minStringLength: 3'
                                        ]}
                                        errorMessages={[
                                            rules.required,
                                            'minimal panjang karakter, 3 karakter'
                                        ]}
                                    />
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-6 w-full"
                                        label="Email"
                                        onChange={this.handleChange}
                                        type="email"
                                        name="email"
                                        value={email || ''}
                                        validators={['required', 'isEmail']}
                                        errorMessages={[
                                            rules.required,
                                            rules.isEmail
                                        ]}
                                    />
                                    <Button
                                        onClick={this.handleFormSubmit}
                                        variant="contained"
                                        disabled={loadingEditProfile}
                                        color="primary"
                                        type="submit"
                                        className={`elevation-z0 ${
                                            loadingEditProfile
                                                ? 'button-purple opacity'
                                                : ''
                                        }`}
                                    >
                                        {loadingEditProfile ? (
                                            <CircularProgress
                                                color="primary"
                                                size={24}
                                            />
                                        ) : (
                                            'Edit Akun'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </div>
                </SimpleCard>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        my_skb: state.skb.my_skb,
        my_skb_bundle: state.skb.my_skb_bundle,
        my_uk: state.uk.my_uk,
        my_uk_bundle: state.uk.my_uk_bundle
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMyTryoutSKB: () => dispatch(getMyTryoutSKB()),
        getMyTryoutSKBBundle: () => dispatch(getMyTryoutSKBBundle()),
        getMyTryoutUK: () => dispatch(getMyTryoutUK()),
        getMyTryoutUKBundle: () => dispatch(getMyTryoutUKBundle())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
