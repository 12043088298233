import { SET_SOAL_TRYOUT_EVENT } from '../actions/EventSKDActions';

const initialState = {
    dataTryout: {}
};

const SoalTryOutEventReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_SOAL_TRYOUT_EVENT: {
            console.log(action);
            return {
                dataTryout: action.data
            };
        }
        default: {
            return state;
        }
    }
};

export default SoalTryOutEventReducer;
