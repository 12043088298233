import { Button, Card, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { resetReducer } from '../../redux/actions/SKDActions';
import Latihan from './latihan/Latihan';
import Materi from './materi/Materi';
import RekamanVideo from './materi/RekamanVideo';
import Soal from './soal/Soal';
import Tryout from './tryout/Tryout';
import TahunTO from './materi/TahunTO';
import TryoutPremium from './tryout/TryoutPremium';
import TryoutPlatinum from './tryout/TryoutPlatinum';
import { getAllTahun } from 'app/redux/actions/TahunToPremium';
import TahunTOPlatinum from './materi/TahunTOPlatinum';
import LatihanPlatinum from './latihan/LatihanPlatinum';
import { setSKDinfo } from 'app/redux/actions/SKDinfoActions';
import TryoutDinas from './tryout/TryoutDinas';
import TryoutDinasPlatinum from './tryout/TryoutDinasPlatinum';
import TryoutBundle from '../BundleSKD/tryout/TryoutBundle';

class PaketBundleDetail extends Component {
    constructor() {
        super();
        this.state = {
            menu: 3
        };
    }

    componentDidMount() {
        const { resetReducer, getTahunTO, setSKDinfo } = this.props;
        // let info_paket = window.location.pathname.split('/').pop();
        resetReducer();
        // getTahunTO();
        // setSKDinfo(info_paket);
    }

    handleChangeMenu = (menu) => {
        this.setState({
            menu
        });
    };

    render() {
        let { menu } = this.state;
        let parent = window.location.pathname.split('/').pop();
        return (
            <div className='m-sm-30'>
                <h2 className='mt-10 mb-5' style={{ fontSize: '25px' }}>
                    Detail Paket Tryout
                </h2>

                {menu === 1 ? (
                    <Materi />
                ) : menu === 2 ? (
                    <Soal />
                ) : menu === 3 ? (
                    <TryoutBundle />
                ) : menu === 4 ? (
                    parent === 'premium' ? (
                        <LatihanPlatinum />
                    ) : (
                        <Latihan />
                    )
                ) : menu === 5 ? (
                    <RekamanVideo />
                ) : menu === 6 ? (
                    parent === 'premium' ? (
                        <TahunTO />
                    ) : parent === 'platinum' ? (
                        <TahunTOPlatinum />
                    ) : null
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetReducer: () => dispatch(resetReducer())
        // getTahunTO: () => dispatch(getAllTahun()),
        // setSKDinfo: (params) => dispatch(setSKDinfo(params))
    };
};
export default connect(null, mapDispatchToProps)(PaketBundleDetail);
