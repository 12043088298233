import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

import InfoKerjakan from './InfoKerjakan';

const CardDetailTryout = ({
    title,
    detailTryout,
    passingGrade,
    tipe,
    type
}) => {
    return (
        <>
            <h2 className="mb-3" style={{ fontSize: '18px' }}>
                {title}
            </h2>
            <div className="flex  no-flex">
                <Table className="mb-3">
                    <TableBody>
                        <TableRow>
                            <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                Tipe
                            </TableCell>
                            <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                :{' '}
                                {detailTryout.type == undefined
                                    ? type
                                    : detailTryout.type}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                Jumlah
                            </TableCell>
                            <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                :{' '}
                                {detailTryout.jml_soal == undefined
                                    ? detailTryout.question_count
                                    : detailTryout.jml_soal}{' '}
                                Soal
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                Waktu
                            </TableCell>
                            <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                :{' '}
                                {detailTryout.duration == undefined
                                    ? detailTryout.expires_time
                                    : detailTryout.duration}{' '}
                                Menit
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {tipe == 'skd' && (
                    <>
                        <Table className="mb-3">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                        TWK
                                    </TableCell>
                                    <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                        : {passingGrade.twk}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                        TIU
                                    </TableCell>
                                    <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                        : {passingGrade.tiu}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                        TKP
                                    </TableCell>
                                    <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                        : {passingGrade.tkp}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table className="mb-3">
                            <TableBody>
                                <TableRow>
                                    <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                        Passing Grade
                                    </TableCell>
                                    <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                        : 350 Poin
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                        Nilai Total
                                    </TableCell>
                                    <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                        : 500 Poin
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>
                )}
                {tipe != 'skd' && (
                    <Table className="mb-3">
                        <TableBody>
                            <TableRow>
                                <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                    Passing Grade
                                </TableCell>
                                <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                    : 100 Poin
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="px-0 py-0 font-semibold font-quicksand no-border">
                                    Nilai Total
                                </TableCell>
                                <TableCell className="px-0 py-0 capitalize font-quicksand no-border">
                                    : 180 Poin
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
            </div>

            {/* INFOO */}
            <InfoKerjakan />
        </>
    );
};

export default CardDetailTryout;
