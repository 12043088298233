import { Avatar, Button, Grid, Table } from '@material-ui/core';
import { getMyTryoutSKB, getMyTryoutSKBBundle } from 'app/redux/actions/SKBActions';
import { getMyTryoutUK, getMyTryoutUKBundle } from 'app/redux/actions/UKActions';
import { SimpleCard } from 'matx';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import {
    changePassword,
    changeProfile,
    checkExpiredTanggal,
    getPPPKUser,
    getSKDUser,
} from '../../redux/actions/UserActions';

class Profile extends Component {
    constructor() {
        super();
        this.state = {
            menu: 1,
            full_name: '',
            email: '',
            no_wa: '',
            password: '',
            konfirmasi_password: '',
            loadingEditProfile: false,
            loadingEditPassword: false,
            password_lama: '',
            skd_user: {},
            skd_kedinasan: {},
            pppk: {},
            alamat: '',
            jenis_kelamin: '',
            foto_profile: undefined
        };
    }
    handleChangeMenu = (menu) => {
        this.setState({
            menu
        });
    };

    handleChange = ({ target: { name, value } }) => {
        this.setState((prefState) => ({
            ...prefState,
            [name]: value
        }));
    };
    componentDidMount() {
        const {
            name,
            email,
            phone,
            plan,
            plan_expires_time,
            dinas,
            dinas_expires_time,
            alamat,
            jenis_kelamin,
            pppk_expires_time,
            foto_profile,
            pppk
        } = this.props.user;
        const {
            getMyTryoutSKB,
            getMyTryoutSKBBundle,
            getMyTryoutUK,
            getMyTryoutUKBundle
        } = this.props;

        if (checkExpiredTanggal(plan_expires_time)) {
            getSKDUser(plan).then((res) => {
                this.setState({
                    skd_user: res
                });
            });
        }
        if (checkExpiredTanggal(pppk_expires_time) && pppk != 'free') {
            getPPPKUser(pppk).then((res) => {
                this.setState({
                    pppk: res
                });
            });
        }

        getMyTryoutSKB();
        getMyTryoutSKBBundle();
        getMyTryoutUK();
        getMyTryoutUKBundle();

        if (dinas != '' && checkExpiredTanggal(dinas_expires_time)) {
            getSKDUser(dinas).then((res) => {
                this.setState({
                    skd_kedinasan: res
                });
            });
        }

        this.setState({
            full_name: name,
            email: email,
            no_wa: phone ? phone.slice(3, phone.length) : '',
            alamat: alamat,
            jenis_kelamin: jenis_kelamin,
            foto_profile: foto_profile
        });
    }
    handleFormSubmit = () => {
        this.setState(
            {
                loadingEditProfile: true
            },
            this.sendEditProfile
        );
    };
    sendEditProfile = () => {
        const { no_wa, full_name } = this.state;
        const params = {
            phoneNumber: '+62' + no_wa,
            displayName: full_name
        };
        changeProfile(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil edit profile',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                this.setState({
                    loadingEditProfile: false,
                    password: '',
                    password_lama: '',
                    konfirmasi_password: ''
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: 'Gagal edit profile',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
                this.setState({
                    loadingEditProfile: false
                });
            });
    };
    handleEditPassword = () => {
        this.setState(
            {
                loadingEditPassword: true
            },
            this.sendEditPassword
        );
    };
    sendEditPassword = () => {
        const { password, password_lama } = this.state;
        const params = {
            password_lama,
            password
        };
        changePassword(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil edit password',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                this.setState({
                    loadingEditPassword: false
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: 'Gagal edit password',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
                this.setState({
                    loadingEditPassword: false
                });
            });
    };
    handleUbahProfile = () => {
        const { history } = this.props;
        history.push('/akun/edit_profile');
    };
    handleUbahPassword = () => {
        const { history } = this.props;
        history.push('/akun/edit_password');
    };
    render() {
        const {
            full_name,
            email,
            no_wa,
            skd_user,
            skd_kedinasan,
            alamat,
            jenis_kelamin,
            pppk,
            foto_profile
        } = this.state;
        const {
            dinas_expires_time,
            plan_expires_time,
            pppk_expires_time
        } = this.props.user;
        const { my_skb, my_skb_bundle, my_uk, my_uk_bundle } = this.props;
        return (
            <div className='m-sm-30'>
                <SimpleCard noPadding>
                    <div
                        className='thumbnail-profile w-full'
                        style={{
                            backgroundImage:
                                'url(/assets/images/thumbnail/thumbnail-profile.png)'
                        }}
                    ></div>
                    <div className='px-8 py-5' style={{ marginTop: -94 }}>
                        <div className='flex items-end'>
                            <Avatar
                                style={{
                                    width: 144,
                                    height: 144
                                }}
                                className='cursor-pointer mx-2'
                                src={
                                    foto_profile != undefined &&
                                    foto_profile.length > 0
                                        ? foto_profile
                                        : `https://ui-avatars.com/api/?name=${full_name}&background=ffe0d5&color=FD591D`
                                }
                            />
                            <h4 className='mb-5'>{full_name}</h4>
                        </div>
                        <Grid container className='mt-5' direction='column'>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Table>
                                    <tr>
                                        <td className='font-medium valign-baseline py-1'>
                                            Email
                                        </td>
                                        <td className='valign-baseline py-1 whitespace-pre-wrap'>
                                            {email}
                                        </td>
                                    </tr>
                                </Table>
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                className='mt-3 d-flex flex'
                                style={{ width: '100%' }}
                            >
                                <Button
                                    color='primary'
                                    className='font-bold text-white border-radius-8 w-full bg-primary rounded'
                                    onClick={this.handleUbahProfile}
                                >
                                    Ubah Profile Saya
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    color='primary'
                                    variant='outlined'
                                    className='border-radius-8 w-full text-primary rounded'
                                    onClick={this.handleUbahPassword}
                                >
                                    Ganti Password
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            alignItems='center'
                            alignContent='center'
                            justify='center'
                            className='mb-4'
                        ></Grid>
                    </div>
                </SimpleCard>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        my_skb: state.skb.my_skb,
        my_skb_bundle: state.skb.my_skb_bundle,
        my_uk: state.uk.my_uk,
        my_uk_bundle: state.uk.my_uk_bundle
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMyTryoutSKB: () => dispatch(getMyTryoutSKB()),
        getMyTryoutSKBBundle: () => dispatch(getMyTryoutSKBBundle()),
        getMyTryoutUK: () => dispatch(getMyTryoutUK()),
        getMyTryoutUKBundle: () => dispatch(getMyTryoutUKBundle())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
