import { Button, CircularProgress, Dialog, Grid, Icon, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import rules from '../../../config/formValidation';

const useStyles = makeStyles(({ palette, ...theme }) => ({
	productTable: {
		"& thead": {
			"& th:first-child": {
				paddingLeft: 16,
			},
		},
		"& td": {
			borderBottom: "none",
		},
		"& td:first-child": {
			paddingLeft: "16px !important",
		},
	},
}));
const ModalChangeEmail = ({ handleClose, modal, handleSubmit, handleChange, email, loading }) => {
	const classes = useStyles();
	return (
		<Dialog onClose={handleClose} open={modal} maxWidth="xs" fullWidth={true}>
			<div className="flex border-bottom-1 px-4 w-full items-center justify-between">
				<h5 className="mb-0">Ganti Email</h5>
				<IconButton aria-label="Delete" onClick={handleClose}>
					<Icon fontSize="small">close</Icon>
				</IconButton>
			</div>
			<div className="px-4 pb-5">
				<ValidatorForm onSubmit={handleSubmit} className="flex items-center justify-between mt-2">
					<Grid container spacing={1}>
						<Grid item lg={9} md={9} sm={12} xs={12}>
							<TextValidator
								className="w-full mb-0"
								label="Email Terbaru"
								onChange={handleChange}
								type="email"
								name="email"
								value={email || ""}
								validators={["required", "isEmail"]}
								errorMessages={[rules.required, rules.isEmail]}
							/>
						</Grid>
						<Grid item lg={3} md={3} sm={12} xs={12} className="items-end justify-end flex">
							<Button variant="outlined" type="submit" color="primary" className="flex items-center w-full">
								{loading ? <CircularProgress color="primary" size={24} /> : <p className="m-0 text-primary font-semibold">Simpan</p>}
							</Button>
						</Grid>
					</Grid>
				</ValidatorForm>
			</div>
		</Dialog>
	);
};

export default ModalChangeEmail;
