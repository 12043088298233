import { Button, Dialog, Icon, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatRupiah } from 'app/redux/actions/UserActions';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    productTable: {
        '& thead': {
            '& th:first-child': {
                paddingLeft: 16
            }
        },
        '& td': {
            borderBottom: 'none'
        },
        '& td:first-child': {
            paddingLeft: '16px !important'
        }
    }
}));
const ModalSKBSatuan = ({
    paket,
    jumlah,
    durasi,
    masa_aktif,
    handleClose,
    modal,
    harga,
    handleSubmit
}) => {
    const classes = useStyles();
    return (
        <Dialog
            onClose={handleClose}
            open={modal}
            maxWidth="xs"
            fullWidth={true}
        >
            <div
                className="bg-primary flex items-center justify-center flex-column p-5 text-white"
                style={{ height: 95 }}
            >
                <h5 className="title-card text-white text-center">{paket}</h5>
                <h4 className="title-card">{formatRupiah(harga, 'Rp')}</h4>
            </div>
            <div className="px-8 py-5">
                <Table
                    className={clsx(
                        'whitespace-pre w-full crud-table',
                        classes.productTable
                    )}
                >
                    <TableBody>
                        <TableRow className="border-1">
                            <TableCell
                                className="py-4 font-poppins font-bold border-right-1"
                                colSpan={4}
                            >
                                Tryout
                            </TableCell>
                            <TableCell
                                colSpan={8}
                                className=" px-4 py-4 font-poppins font-semibold"
                            >
                                {paket}
                            </TableCell>
                        </TableRow>
                        <TableRow className="border-1">
                            <TableCell
                                className="py-4 font-poppins font-bold border-right-1"
                                colSpan={4}
                            >
                                Jumlah
                            </TableCell>
                            <TableCell
                                colSpan={8}
                                className=" px-4 py-4 font-poppins "
                            >
                                {jumlah + ' soal'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="border-1">
                            <TableCell
                                className="py-4 font-poppins font-bold border-right-1"
                                colSpan={4}
                            >
                                Durasi
                            </TableCell>
                            <TableCell
                                colSpan={8}
                                className=" px-4 py-4 font-poppins"
                            >
                                {durasi + ' menit'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="border-1">
                            <TableCell
                                className="py-4 font-poppins font-bold border-right-1"
                                colSpan={4}
                            >
                                Harga
                            </TableCell>
                            <TableCell
                                colSpan={8}
                                className=" px-4 py-4 font-poppins"
                            >
                                {formatRupiah(harga, 'Rp')}
                            </TableCell>
                        </TableRow>
                        <TableRow className="border-1">
                            <TableCell
                                className="py-4 font-poppins font-bold border-right-1"
                                colSpan={4}
                            >
                                Masa Aktif
                            </TableCell>
                            <TableCell
                                colSpan={8}
                                className=" px-4 py-4 font-poppins "
                            >
                                {masa_aktif >= 12
                                    ? masa_aktif / 12 + ' tahun'
                                    : masa_aktif + ' bulan'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className="flex justify-end items-center mt-5">
                    <Button
                        variant="contained"
                        color="primary"
                        className="flex items-center"
                        onClick={handleSubmit}
                    >
                        <Icon fontSize="small">shopping_cart</Icon>
                        <p className="m-0 text-white font-semibold">
                            Masukkan Keranjang
                        </p>
                    </Button>
                    <Button
                        variant="contained"
                        color="default"
                        className="flex items-center ml-4"
                        onClick={handleClose}
                    >
                        <p className="m-0 text-white font-semibold">Batal</p>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ModalSKBSatuan;
