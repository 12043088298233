import { Grid, Card } from '@material-ui/core';
import { ButtonSolid } from 'app/components';
import {
    deletePassingGrade,
    getAllPasingGrade
} from 'app/redux/actions/PassingGradeActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import EditPG from './EditPG';

class PassingGrade extends Component {
    constructor() {
        super();
        this.state = {
            search: '',
            rowsPerPage: 20,
            page: 0,
            query: '',
            shouldOpenCreateDialog: false,
            shouldOpenEditorDialog: false,
            editData: {},
            type: 'SKB'
        };
    }
    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };
    componentDidMount() {
        this.updatePageData();
    }
    updatePageData = async () => {
        const { getAllPasingGrade } = this.props;
        await getAllPasingGrade();
    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    handleChangeSelect = (e) => {
        if (e.target.value == 'SEMUA') {
            this.setState({
                status: ''
            });
        } else {
            this.setState({
                status: e.target.value
            });
        }
    };
    handleChangeSelectPaket = (e) => {
        if (e.target.value == 'SEMUA') {
            this.setState({
                paket: ''
            });
        } else {
            this.setState({
                paket: e.target.value
            });
        }
    };
    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenCreateDialog: false
        });
        this.updatePageData();
    };
    handleEdit(data, type) {
        this.setState({
            shouldOpenEditorDialog: true,
            editData: data,
            type: type
        });
    }

    handleDelete = (uid) => {
        const { deletePassingGrade } = this.props;
        Swal.fire({
            title: 'Hapus PassingGrade?',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonText: 'Iya',
            showCancelButton: true,
            cancelButtonText: 'Batal'
        }).then(async (res) => {
            if (res.isConfirmed) {
                await deletePassingGrade(uid);
                await this.updatePageData();
            } else {
                Swal.close();
            }
        });
    };

    render() {
        const { query, shouldOpenCreateDialog, shouldOpenEditorDialog } =
            this.state;
        const { passing_grade } = this.props;
        console.log(passing_grade   )
        return (
            <div className='m-sm-30'>
                <h2 style={{ fontSize: '25px' }} className='mb-5'>
                    Passing Grade
                </h2>
                <Grid container spacing={2}>
                    {passing_grade.map((data) => (
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <>
                                {data.name == 'BUMN' ? (
                                    <Card elevation={3}>
                                        <h4 className='text-center mt-5 mb-5'>
                                            <strong>{data.name}</strong>
                                        </h4>
                                        {data.data.map((item) => (
                                            <div
                                                className='flex flex-row flex-space-between items-center p-5'
                                                style={{
                                                    justifyContent:
                                                        'space-between'
                                                }}
                                            >
                                                <p className='m-0'>
                                                    {item.name}
                                                </p>
                                                {/* <p className='m-0'>
                                                    {item.passing_grade}
                                                </p> */}
                                            </div>
                                        ))}

                                        <div className='text-center m-5'>
                                            <ButtonSolid
                                                variant='button-primary'
                                                text='Edit'
                                                onClick={() =>
                                                    this.handleEdit(
                                                        data,
                                                        'BUMN'
                                                    )
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Card>
                                ) : null}
                                {data.name == 'SKB' ? (
                                    <Card elevation={3}>
                                        <h4 className='text-center mt-5 mb-5'>
                                            <strong>{data.name}</strong>
                                        </h4>
                                        <div
                                            className='flex flex-row flex-space-between items-center p-5'
                                            style={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <p className='m-0'>Minimum Benar</p>
                                            <p className='m-0'>
                                                {data.min_correct}
                                            </p>
                                        </div>
                                        <div
                                            className='flex flex-row flex-space-between items-center p-5'
                                            style={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <p className='m-0'>Total Soal</p>
                                            <p className='m-0'>
                                                {data.total_soal}
                                            </p>
                                        </div>
                                        <div className='text-center m-5'>
                                            <ButtonSolid
                                                variant='button-primary'
                                                text='Edit'
                                                onClick={() =>
                                                    this.handleEdit(data, 'SKB')
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Card>
                                ) : (
                                    ''
                                )}
                                {data.name == 'PPPK' ? (
                                    <Card elevation={3}>
                                        <h4 className='text-center mt-5 mb-5'>
                                            <strong>{data.name}</strong>
                                        </h4>
                                        {data.data.map((item) => (
                                            <div
                                                className='flex flex-row flex-space-between items-center p-5'
                                                style={{
                                                    justifyContent:
                                                        'space-between'
                                                }}
                                            >
                                                <p className='m-0'>
                                                    {item.name}
                                                </p>
                                                {/* <p className='m-0'>
                                                    {item.passing_grade}
                                                </p> */}
                                            </div>
                                        ))}

                                        <div className='text-center m-5'>
                                            <ButtonSolid
                                                variant='button-primary'
                                                text='Edit'
                                                onClick={() =>
                                                    this.handleEdit(
                                                        data,
                                                        'PPPK'
                                                    )
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Card>
                                ) : null}
                                {data.name == 'PPPK Guru' ? (
                                    <Card elevation={3}>
                                        <h4 className='text-center mt-5 mb-5'>
                                            <strong>{data.name}</strong>
                                        </h4>
                                        {data.data.map((item) => (
                                            <div
                                                className='flex flex-row flex-space-between items-center p-5'
                                                style={{
                                                    justifyContent:
                                                        'space-between'
                                                }}
                                            >
                                                <p className='m-0'>
                                                    {item.name}
                                                </p>
                                                {/* <p className='m-0'>
                                                    {item.passing_grade}
                                                </p> */}
                                            </div>
                                        ))}

                                        <div className='text-center m-5'>
                                            <ButtonSolid
                                                variant='button-primary'
                                                text='Edit'
                                                onClick={() =>
                                                    this.handleEdit(
                                                        data,
                                                        'PPPK Guru'
                                                    )
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Card>
                                ) : null}
                                {data.name == 'SKD' ? (
                                    <Card elevation={3}>
                                        <h4 className='text-center mt-5 mb-5'>
                                            <strong>{data.name}</strong>
                                        </h4>
                                        <div
                                            className='flex flex-row flex-space-between items-center p-5'
                                            style={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <p className='m-0'>Kategory TIU</p>
                                            <p className='m-0'>{data.tiu}</p>
                                        </div>
                                        <div
                                            className='flex flex-row flex-space-between items-center p-5'
                                            style={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <p className='m-0'>Kategory TKP</p>
                                            <p className='m-0'>{data.tkp}</p>
                                        </div>
                                        <div
                                            className='flex flex-row flex-space-between items-center p-5'
                                            style={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <p className='m-0'>Kategory TWK</p>
                                            <p className='m-0'>{data.twk}</p>
                                        </div>
                                        <div className='text-center m-5'>
                                            <ButtonSolid
                                                variant='button-primary'
                                                text='Edit'
                                                onClick={() =>
                                                    this.handleEdit(data, 'SKD')
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Card>
                                ) : null}
                                {data.name == 'UK' ? (
                                    <Card elevation={3}>
                                        <h4 className='text-center mt-5 mb-5'>
                                            <strong>{data.name}</strong>
                                        </h4>
                                        <div
                                            className='flex flex-row flex-space-between items-center p-5'
                                            style={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <p className='m-0'>Minimum Benar</p>
                                            <p className='m-0'>
                                                {data.min_correct}
                                            </p>
                                        </div>
                                        <div
                                            className='flex flex-row flex-space-between items-center p-5'
                                            style={{
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <p className='m-0'>Total Soal</p>
                                            <p className='m-0'>
                                                {data.total_soal}
                                            </p>
                                        </div>
                                        <div className='text-center m-5'>
                                            <ButtonSolid
                                                variant='button-primary'
                                                text='Edit'
                                                onClick={() =>
                                                    this.handleEdit(data, 'SKB')
                                                }
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Card>
                                ) : null}
                            </>
                        </Grid>
                    ))}
                </Grid>
                {shouldOpenEditorDialog && (
                    <EditPG
                        open={shouldOpenEditorDialog}
                        handleClose={this.handleDialogClose}
                        handleChange={this.handleChange}
                        type={this.state.type}
                        uid={this.state.editData}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        passing_grade: state.passingGrade.all
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllPasingGrade: () => dispatch(getAllPasingGrade()),
        deletePassingGrade: (id) => dispatch(deletePassingGrade(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PassingGrade);
