import Komisi from './Komisi';
import KomisiBatal from './KomisiBatal';
import KomisiBelum from './KomisiBelum';
import KomisiSudah from './KomisiSudah';


const komisiRoutes = [
    {
        path: '/penarikan_komisi',
        exact: true,
        component: Komisi
    },
    {
        path: '/penarikan_komisi/belum',
        exact: true,
        component: KomisiBelum
    },
    {
        path: '/penarikan_komisi/sudah',
        exact: true,
        component: KomisiSudah
    },
    {
        path: '/penarikan_komisi/gagal',
        exact: true,
        component: KomisiBatal
    }
];

export default komisiRoutes;
