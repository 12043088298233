import axios from 'axios';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_INVOICE = 'GET_INVOICE';
export const GET_TOTAL_INVOICE_COUNT = 'GET_TOTAL_INVOICE_COUNT';

export const getInvoice = () => {
    return async (dispatch) => {
        let uid = localStorage.getItem('uid');
        firebaseAuthService.firestore
            .collection('invoice')
            .where('id_user', '==', uid)
            .onSnapshot(function (res) {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_INVOICE,
                    payload: data
                });
            });
    };
};

export const getTotalInvoice = () => {
    return async (dispatch) => {
        try {
            const invoiceDoc = await firebaseAuthService.firestore
                .collection('invoice_total')
                .doc('invoices')
                .get();

            const invoiceData = invoiceDoc.exists ? invoiceDoc.data() : {};
            dispatch({
                type: GET_TOTAL_INVOICE_COUNT,
                payload: invoiceData
            });
        } catch (error) {
            console.error('Error fetching total invoice count:', error);
        }
    };
};

export const getAllInvoice = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('invoice')
            .onSnapshot(function (res) {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                let all = data.sort((a, b) =>
                    a.unique_id < b.unique_id ? 1 : -1
                );
                for (let i = 0; i < all.length; i++) {
                    firebaseAuthService.firestore
                        .collection('users')
                        .doc(data[i].id_user)
                        .get()
                        .then((res) => {
                            let item = res.data();
                            data[i].user = item ? item.name : '-';

                            dispatch({
                                type: GET_INVOICE,
                                payload: data
                            });
                        });
                }
            });
    };
};

export const delInvoice = (params) => {
    let uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return axios
        .post(`${process.env.REACT_APP_API_URL}scpns/cancel_payment`, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err;
        });
};
