import {
    Button,
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    FormControl,
    Box
} from '@material-ui/core';
import shortid from 'shortid';
import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { ConfirmationDialog } from 'matx';
import { Link } from 'react-router-dom';

import { getTryoutList, deleteTryout } from 'app/redux/actions/SKBActions';
import { formatRupiah } from 'app/redux/actions/UserActions';
import AddTryoutDialog from './AddTryoutDialog';
import EditTryoutDialog from './EditTryoutDialog';

class TryoutList extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            rowsPerPage: 10,
            page: 0,
            shouldOpenEditorDialog: false,
            shouldOpenCreateDialog: false,
            shouldOpenConfirmationDialog: false,
            query: ''
        };
    }
    componentDidMount() {
        this.updatePageData();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    updatePageData() {
        const { getTryoutList } = this.props;
        let parent = window.location.pathname.split('/').pop();
        getTryoutList(parent);
    }

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenConfirmationDialog: false,
            shouldOpenCreateDialog: false
        });
        this.updatePageData();
    };

    handleConfirmationResponse = async () => {
        let parent_id = window.location.pathname.split('/').pop();
        await this.props.deleteTryout(parent_id, this.state.doc_id);
        this.handleDialogClose();
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    render() {
        const { tryout } = this.props;
        const {
            query,
            rowsPerPage,
            page,
            shouldOpenEditorDialog,
            shouldOpenCreateDialog,
            shouldOpenConfirmationDialog
        } = this.state;
        let parent = window.location.pathname.split('/').pop();
        console.log(this.props);
        return (
            <div>
                <Card className='p-5 overflow-auto' elevation={6}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Button
                            variant='outlined'
                            color='primary'
                            className='mb-5'
                            onClick={() =>
                                this.setState({
                                    shouldOpenCreateDialog: true
                                })
                            }
                            title='Edit'
                        >
                            Tambah
                        </Button>
                        <FormControl
                            className='faqDesc'
                            style={{
                                boxShadow: '0px 0px 2px #43434359',
                                marginBottom: '15px',
                                borderRadius: '5px',
                                maxHeight: '45px'
                            }}
                        >
                            <Box
                                component='fieldset'
                                borderColor='transparent'
                                style={{ paddingLeft: '0px' }}
                                className='flex'
                            >
                                <IconButton
                                    title='Cari'
                                    style={{ padding: '5px' }}
                                >
                                    <Icon color='error'>search</Icon>
                                </IconButton>
                                <input
                                    onChange={this.handleSearch}
                                    placeholder='Cari..'
                                    name='query'
                                    value={query}
                                    style={{
                                        minHeight: '25px',
                                        border: '0px',
                                        backgroundColor: 'transparent',
                                        width: '100%',
                                        outline: 0
                                    }}
                                ></input>
                            </Box>
                        </FormControl>
                    </div>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        ID
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Judul
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Deskripsi
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={3}
                                    >
                                        Detail
                                    </TableCell>
                                    {/* <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Tgl Dibuat
                                    </TableCell> */}
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={3}
                                        align='center'
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tryout.length > 0 ? (
                                    tryout
                                        .filter((item) =>
                                            Object.keys(item).some((key) =>
                                                query.includes(key)
                                                    ? false
                                                    : item[key]
                                                          .toString()
                                                          .toLowerCase()
                                                          .includes(query)
                                            )
                                        )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((datUsers, index) => (
                                            <TableRow key={shortid.generate()}>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                >
                                                    {datUsers.formasi_id}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.title}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.description}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={3}
                                                >
                                                    <p className='m-0 p-0'>
                                                        <b>Jml Soal</b> :{' '}
                                                        {
                                                            datUsers.question_count
                                                        }
                                                    </p>
                                                    <p className='m-0 p-0'>
                                                        <b>Durasi :</b>{' '}
                                                        {datUsers.duration}
                                                    </p>
                                                    <p className='m-0 p-0'>
                                                        <b>Harga :</b>{' '}
                                                        {formatRupiah(
                                                            datUsers.price
                                                        )}
                                                    </p>
                                                </TableCell>

                                                {/* <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.created_at}
                                                </TableCell> */}

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    colSpan={3}
                                                    align='center'
                                                >
                                                    <Link
                                                        to={
                                                            '/tryout/skb/' +
                                                            parent +
                                                            '/' +
                                                            datUsers.doc_id
                                                        }
                                                    >
                                                        <IconButton title='List Soal'>
                                                            <Icon color='secondary'>
                                                                view_list
                                                            </Icon>
                                                        </IconButton>
                                                    </Link>
                                                    <Link
                                                        to={
                                                            '/tryout/ranking/' +
                                                            datUsers.doc_id
                                                        }
                                                    >
                                                        <IconButton title='Ranking Nasional'>
                                                            <Icon color='secondary'>
                                                                emoji_events
                                                            </Icon>
                                                        </IconButton>
                                                    </Link>
                                                    <IconButton
                                                        onClick={() =>
                                                            this.setState({
                                                                uid: datUsers,
                                                                shouldOpenEditorDialog: true
                                                            })
                                                        }
                                                        title='Edit'
                                                    >
                                                        <Icon color='primary'>
                                                            edit
                                                        </Icon>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() =>
                                                            this.setState({
                                                                doc_id: datUsers.doc_id,
                                                                shouldOpenConfirmationDialog: true
                                                            })
                                                        }
                                                        title='delete'
                                                    >
                                                        <Icon color='error'>
                                                            delete
                                                        </Icon>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={10} align='center'>
                                            Not Have Data
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={tryout.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />

                        {shouldOpenEditorDialog && (
                            <EditTryoutDialog
                                handleClose={this.handleDialogClose}
                                open={shouldOpenEditorDialog}
                                uid={this.state.uid}
                            />
                        )}
                        {shouldOpenCreateDialog && (
                            <AddTryoutDialog
                                handleClose={this.handleDialogClose}
                                open={shouldOpenCreateDialog}
                                uid={this.state.uid}
                            />
                        )}
                        {shouldOpenConfirmationDialog && (
                            <ConfirmationDialog
                                open={shouldOpenConfirmationDialog}
                                onConfirmDialogClose={this.handleDialogClose}
                                onYesClick={this.handleConfirmationResponse}
                                text='Hapus Data Ini ?'
                            />
                        )}
                    </div>
                </Card>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        tryout: state.skb.tryout,
        st: state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTryoutList: (parent) => dispatch(getTryoutList(parent)),
        deleteTryout: (parent, id) => dispatch(deleteTryout(parent, id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TryoutList);
