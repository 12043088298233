import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import { addSoalPPPK } from 'app/redux/actions/PembahasanPPPKActions';
import React, { Component, Fragment } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

class AddSoal extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            created_at: '',
            description: '',
            materi_file: '',
            title: '',
            type: 'kesehatan',
            submit: false,
            parent_id: parent
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        const { materi_file } = this.state;
        if (materi_file.length <= 0) {
            Swal.fire({
                title: 'Error',
                text: 'Tolong upload file soal dan pembahasan',
                timer: 2000,
                icon: 'error'
            });
        } else {
            this.setState(
                {
                    submit: true
                },
                this.sendSubmit
            );
        }
    };

    sendSubmit = async () => {
        const { description, materi_file, title, parent_id, type } = this.state;
         const { match } = this.props;
        const params = {
            description: description,
            materi_file: materi_file,
            title: title,
            parent_id: parent_id,
            type: type,
            category: match.id
        };
        const { handleClose, addSoalPPPK } = this.props;
        await addSoalPPPK(params);
        await handleClose();
        this.setState({
            submit: false
        });
    };

    getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    };

    isPDF = (filename) => {
        var ext = this.getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'pdf':
                return true;
        }
        return false;
    };

    handleChangeFile = (e) => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        if (this.isPDF(e.target.files[0].name)) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = () => {
                this.setState({
                    materi_file: reader.result
                });
            };
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid File Format',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    handleChangeType = (event) => {
        let value = event.target.value;
        this.setState({
            type: value
        });
    };

    render() {
        let { title, description, submit, type } = this.state;
        let { open, handleClose, match } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Soal dan Pembahasan PPPK</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    label='Nama Soal dan Pembahasan'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={[
                                        'required',
                                        'maxStringLength:25'
                                    ]}
                                    errorMessages={[
                                        'Field is Required',
                                        'Maximum 25 Character'
                                    ]}
                                    className='w-full'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-6 w-full'
                                    label='Deskripsi Soal dan Pembahasan'
                                    multiline
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            {/* <Grid item sm={12} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Tipe Bimbel'
                                    onChange={this.handleChangeType}
                                    name='type'
                                    value={type || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='kesehatan'>
                                        Kesehatan
                                    </MenuItem>
                                    <MenuItem value='perawat'>
                                        Keperawatan
                                    </MenuItem>
                                    <MenuItem value='pgsd'>PGSD</MenuItem>
                                    <MenuItem value='guru'>Guru</MenuItem>
                                    <MenuItem value='guru_binggris'>
                                        Guru B Inggris
                                    </MenuItem>
                                    <MenuItem value='guru_ipa'>
                                        Guru IPA
                                    </MenuItem>
                                    <MenuItem value='guru_mtk'>
                                        Guru MTK
                                    </MenuItem>
                                </SelectValidator>
                            </Grid> */}

                            <Grid item sm={6} xs={12}>
                                <small>File soal dan pembahasan</small>
                                <input
                                    type='file'
                                    name='materi_file'
                                    id='materi_file'
                                    className='mb-6'
                                    accept='application/pdf'
                                    onChange={this.handleChangeFile}
                                />
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addSoalPPPK: (params) => dispatch(addSoalPPPK(params))
    };
};
export default connect(null, mapDispatchToProps)(AddSoal);
