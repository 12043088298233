import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_KERANJANG = 'GET_KERANJANG';
export const ADD_KERANJANG = 'ADD_KERANJANG';
export const DEL_KERANJANG = 'DEL_KERANJANG';

export const getKeranjang = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('keranjang')
            .where('user_id', '==', uid)
            .get()
            .then((res) => {
                let data = [];
                let harga = 0;
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    harga += parseInt(item.price);
                    data.push(item);
                });
                dispatch({
                    type: GET_KERANJANG,
                    payload: {
                        data,
                        harga
                    }
                });
            });
    };
};

export const addKeranjang = (params) => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('keranjang')
            .where('tryout_id', '==', params.tryout_id)
            .where('user_id', '==', uid)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                if (data.length <= 0) {
                    firebaseAuthService.firestore
                        .collection('keranjang')
                        .add(params)
                        .then((doc) => {
                            Swal.fire({
                                title:
                                    'Berhasil memasukkan tryout ke dalam keranjang',
                                icon: 'success',
                                timer: 2000,
                                showConfirmButton: false
                            });
                            params.doc_id = doc.id;
                            dispatch({
                                type: ADD_KERANJANG,
                                payload: params
                            });
                        })
                        .catch((err) => {
                            Swal.fire({
                                title:
                                    'Gagal memasukan tryout ke dalam keranjang',
                                text: 'Coba beberapa saat lagi',
                                icon: 'warning',
                                timer: 2000,
                                showConfirmButton: false
                            });
                        });
                } else {
                    Swal.fire({
                        title: 'Anda sudah membeli paket ini',
                        icon: 'warning',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
            });
    };
};

export const delKeranjang = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('keranjang')
            .doc(kode)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil menghapus tryout',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                dispatch({
                    type: DEL_KERANJANG,
                    payload: kode
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: 'Gagal menghapus tryout',
                    text: 'Coba beberapa saat lagi',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
            });
    };
};
