import axios from 'axios';
import firebase from 'firebase';
import history from 'history.js';
import Swal from 'sweetalert2';

import FirebaseAuthService from '../../services/firebase/firebaseAuthService';
import jwtAuthService from '../../services/jwtAuthService';
import { SET_USER_DATA, logoutUser } from './UserActions';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SIGN_UP = 'SIGN_UP';

export const setAuthLoadingStatus = (status = false) => {
    return (dispatch) =>
        dispatch({
            type: LOGIN_LOADING,
            data: status
        });
};

export function loginWithEmailAndPassword({ email, password }) {
    return (dispatch) => {
        return jwtAuthService
            .loginWithEmailAndPassword(email, password)
            .then((user) => {
                dispatch(setUserData(user));

                return dispatch({
                    type: LOGIN_SUCCESS
                });
            })
            .catch((error) => {
                return dispatch({
                    type: LOGIN_ERROR,
                    payload: error
                });
            });
    };
}

export function resetPassword({ email }) {
    return (dispatch) => {
        dispatch({
            payload: email,
            type: RESET_PASSWORD
        });
    };
}

export function firebaseLoginEmailPassword({ email, password }) {
    return (dispatch) => {
        dispatch({
            type: LOGIN_LOADING,
            data: true
        });

        FirebaseAuthService.signInWithEmailAndPassword(email, password)
            .then((data) => {
                FirebaseAuthService.checkAuthStatus(async (user) => {
                    if (user) {
                        const tokenResult = await user?.getIdTokenResult();
                        const isAdmin = tokenResult.claims.admin;
                        if (isAdmin) {
                            return dispatch({
                                type: LOGIN_SUCCESS
                            });
                        } else {
                            dispatch(logoutUser());
                            return dispatch({
                                type: LOGIN_ERROR,
                                data: true
                            });
                        }
                    }
                });
            })
            .catch((error) => {
                return dispatch({
                    type: LOGIN_ERROR,
                    data: error
                });
            });
    };
}

export const SignupBackend = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}auth/register`, params)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const startSignup = (status) => {
    return (dispatch) => {
        dispatch({
            type: SIGN_UP,
            payload: status
        });
    };
};

export const SigninBackend = (params) => {
    startSignup(true);
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}auth/login`, params)
            .then((res) => {
                setUserData(res.data.data);

                localStorage.setItem('uid', params.uid);
                localStorage.setItem(
                    'auth_user',
                    JSON.stringify(res.data.data)
                );
                resolve(res);
                startSignup(false);
            })
            .catch((err) => {
                reject(err);
                startSignup(false);
            });
    });
};

export function setUserData(user) {
    return (dispatch) => {
        dispatch({
            type: SET_USER_DATA,
            data: user
        });
    };
}

export const sendResetPassword = async (params) => {
    const auth = FirebaseAuthService.auth;
    return auth
        .sendPasswordResetEmail(params)
        .then(function () {
            return true;
        })
        .catch(function (error) {
            return error;
        });
};
