import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Document, Page, pdfjs } from "react-pdf";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { MdAdd, MdRemove, MdChevronLeft, MdChevronRight } from "react-icons/md";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  header: {
    marginBottom: theme.spacing.unit,
  },
  document: {},
  page: {},
  paper: {
    width: "100%",
    height: "50vh",
    backgroundColor: "#666",
  },
  pageControl: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing.unit,
  },
});

const PDFViewer = ({ classes, file, initialScale = 10, iconsTopRight }) => {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [numPages, setNumPages] = React.useState(1);
  const [scale, setScale] = React.useState(initialScale);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages || 1);
  };

  const onPageChange = (sign) => () => {
    setPageNumber((prePageNumber) => {
      const nextPageNumber = prePageNumber + sign;
      return nextPageNumber < 1 || nextPageNumber > numPages
        ? prePageNumber
        : nextPageNumber;
    });
  };

  const onScaleChange = (sign) => () => {
    setScale((preScale) => {
      const nextScale = preScale + 1 * sign;
      return nextScale < 1 || nextScale > 20 ? preScale : nextScale;
    });
  };

  const onDownload = () => {};

  return (
    <div className={classes.root}>
      <div></div>
      <Grid className={classes.header} container justify="space-between">
        <Grid item>
          <Grid container spacing={8} alignItems="center"></Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={8} alignItems="center">
            {typeof iconsTopRight === "function" && iconsTopRight({ scale })}
            <Grid item>
              <IconButton
                title="Zoom in"
                color="secondary"
                onClick={onScaleChange(1)}
                disabled={scale >= 20}
              >
                <MdAdd size={20} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                title="Zoom out"
                color="secondary"
                onClick={onScaleChange(-1)}
                disabled={scale <= 1}
              >
                <MdRemove size={20} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper className={classes.paper} style={{ overflow: "auto" }}>
        <center>
          <Document
            className={classes.document}
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              className={classes.page}
              pageNumber={pageNumber}
              scale={scale / 10}
            />
          </Document>
        </center>
      </Paper>
      <div className={classes.pageControl}>
        <IconButton
          color="secondary"
          onClick={onPageChange(-1)}
          disabled={pageNumber <= 1}
        >
          <MdChevronLeft size={20} />
        </IconButton>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <IconButton
          color="secondary"
          onClick={onPageChange(1)}
          disabled={pageNumber >= numPages}
        >
          <MdChevronRight size={20} />
        </IconButton>
      </div>
    </div>
  );
};

PDFViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  file: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]).isRequired,
  fileName: PropTypes.string,
};

export default React.memo(withStyles(styles)(PDFViewer));
