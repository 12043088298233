import { DELETE_BIMBEL, GET_NOTIFIKASI } from '../actions/NotifikasiActions';

const initialState = {
    data: [],
    loading: false
};

const NotifikasiReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_NOTIFIKASI: {
            console.log(action);
            return {
                ...state,
                data: action.payload
            };
        }
        case DELETE_BIMBEL: {
            let bimbel = state.data.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                data: bimbel
            };
        }
        default: {
            return state;
        }
    }
};

export default NotifikasiReducer;
