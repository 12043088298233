import { Card, Icon } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { Link } from 'react-router-dom';

const CardBeranda = ({
    title,
    total,
    image,
    href,
    loading,
    icon,
    iconColor
}) => {
    return (
        <Card elevation={2} className={`px-4 py-2 flex bg-white`}>
            {icon && (
                <Icon
                    style={{
                        fontSize: 60,
                        color: iconColor ? iconColor : '#000'
                    }}
                >
                    {icon}
                </Icon>
            )}
            {image && (
                <img
                    src={image}
                    alt='overview'
                    width={60}
                    height={60}
                    style={{ maxWidth: '60px' }}
                />
            )}
            <Link to={href} className='w-100 ml-3 my-auto flex flex-column'>
                <h3 className='w-full m-0' style={{ fontSize: '22px' }}>
                    {loading ? <Skeleton /> : total}
                </h3>
                <p
                    className='w-full m-0'
                    style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        minWidth: '130px'
                    }}
                >
                    {loading ? <Skeleton /> : title}
                </p>
            </Link>
        </Card>
    );
};

export default CardBeranda;
