import {
    Card,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { CardPenghasilan } from 'app/components';
import { getInvoice } from 'app/redux/actions/KomisiActions';
import { getListUser } from 'app/redux/actions/ManagementUserActions';
import { formatRupiah, formatTanggal } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class Penghasilan extends Component {
    constructor() {
        super();
        this.state = {
            modalAdd: false,
            modalEdit: false,
            edit_widraw: {
                id_user: '',
                nominal: '',
                status: '',
                doc_id: '',
                user: null
            },
            rowsPerPage: 10,
            page: 0,
            modalKomisiReferral: false,
            sumKomisi: 0,
            filter: 'SEMUA'
        };
    }

    componentDidMount() {
        this.getData();
    }

    componentWillReceiveProps(nextProps) {}

    getData() {
        const { getInvoice, getListUser } = this.props;
        // getInvoice();
        // getListUser();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    getUser(id) {
        const { dataUser } = this.props;
        let newData = dataUser.filter((a) => a.id == id);

        if (newData.length > 0) {
            return newData[0].name;
        } else {
            return '-';
        }
    }

    renderData = (invoice) => {
        return invoice?.length > 0 ? (
            invoice.map((item, index) => {
                return (
                    <TableRow hover key={index}>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins'
                            align='center'
                        >
                            {index + 1}
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins'>
                            {this.getUser(item.id_user)}
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins'>
                            {formatRupiah(item.final_amount, 'Rp')}
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins'>
                            {formatRupiah(item.komisi, 'Rp')}
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins'>
                            {formatTanggal(item.created_at)}
                        </TableCell>
                    </TableRow>
                );
            })
        ) : (
            <TableRow hover>
                <TableCell colSpan={5} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };

    handleEdit = (item) => {
        const { id_user, nominal, status, doc_id, user } = item;
        this.setState({
            edit_widraw: {
                id_user: id_user,
                nominal: nominal,
                status: status,
                doc_id: doc_id,
                user: user
            },
            modalEdit: true
        });
    };

    handleCloseEdit = () => {
        this.setState(
            {
                modalEdit: false
            },
            () => this.getData()
        );
    };

    handleModalKomisi = () => {
        const { modalKomisiReferral } = this.state;
        this.setState({
            modalKomisiReferral: !modalKomisiReferral
        });
    };

    handleChangeSelectPaket = (e) => {
        this.setState({
            filter: e.target.value
        });
    };

    formatBulanIni() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }

        return [year, month].join('-');
    }

    formatHariIni() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    render() {
        const { filter, page, rowsPerPage } = this.state;
        const { invoice, dataUser } = this.props;

        // TOTAL KOMISI & OMSET
        let newFilter = filter === 'SEMUA' ? '' : filter;
        let newInvoice = invoice
            .filter((item) => item.created_at?.includes(newFilter))
            .sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
        let sumKomisi = 0;
        let sumOmset = 0;
        if (newInvoice.length > 0) {
            for (let i = 0; i < newInvoice.length; i++) {
                let komisi = parseInt(newInvoice[i].komisi) || 0;
                sumKomisi += komisi;
                let omset = parseInt(newInvoice[i].final_amount) || 0;
                sumOmset += omset;
            }
        }
        newInvoice = newInvoice.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );

        // TOTAL LEAD
        let flags = [],
            allLead = [],
            l = dataUser.length,
            i;
        for (i = 0; i < l; i++) {
            if (
                dataUser[i].registerReferral == undefined ||
                dataUser[i].registerReferral == '' ||
                flags[dataUser[i].registerReferral]
            )
                continue;
            flags[dataUser[i].registerReferral] = true;
            allLead.push(dataUser[i].registerReferral);
        }

        // TOTAL SALES
        let allSales = invoice
            .filter((item) => item.created_at?.includes(newFilter))
            .filter((item) => item.referral != '')
            .filter((item) => item.referral != undefined).length;

        return (
            <div className='m-sm-30'>
                <div className='flex items-center justify-between mt-10 mb-3'>
                    <h2 style={{ fontSize: '25px' }}>Penghasilan</h2>
                </div>
                <Grid container spacing={2} className='mb-3'>
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                        <label htmlFor='category'>
                            <small>Filter</small>
                        </label>
                        <Select
                            labelId='demo-simple-select-label'
                            id='Filter'
                            value={filter}
                            onChange={this.handleChangeSelectPaket}
                            className='w-full'
                        >
                            <MenuItem value={'SEMUA'}>SEMUA</MenuItem>
                            <MenuItem value={this.formatBulanIni()}>
                                BULAN INI
                            </MenuItem>
                            <MenuItem value={this.formatHariIni()}>
                                HARI INI
                            </MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <CardPenghasilan
                            nominal={allLead.length}
                            handleUbah={this.handleModalKomisi}
                            title='Total Lead'
                            // url='/komisi/detail'
                            color='warning'
                            icon='face'
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <CardPenghasilan
                            nominal={allSales}
                            handleUbah={this.handleModalKomisi}
                            title='Total Sales'
                            // url='/komisi/detail'
                            color='danger'
                            icon='loyalty'
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <CardPenghasilan
                            nominal={formatRupiah(sumOmset, 'Rp')}
                            handleUbah={this.handleModalKomisi}
                            title='Total Omset'
                            color='primary'
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <CardPenghasilan
                            nominal={formatRupiah(sumKomisi, 'Rp')}
                            handleUbah={this.handleModalKomisi}
                            title='Total Komisi'
                            color='info'
                            url='/komisi/detail'
                        />
                    </Grid>
                </Grid>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <h5>Transaksi Terakhir</h5>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Nama
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Harga Pembelian
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Komisi
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Dibuat Tanggal
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData(newInvoice)}</TableBody>
                        </Table>
                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[10, 50, 100]}
                            component='div'
                            count={
                                invoice.filter((item) =>
                                    item.created_at?.includes(newFilter)
                                ).length
                            }
                            rowsPerPage={this.state.rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        invoice: state.invoice.data,
        dataUser: state.managementUser.list
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoice: () => dispatch(getInvoice()),
        getListUser: () => dispatch(getListUser())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Penghasilan);
