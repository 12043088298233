import { Button, Card, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    firebaseLoginEmailPassword,
    loginWithGoogle
} from 'app/redux/actions/LoginActions';
import clsx from 'clsx';
import history from 'history.js';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import rules from '../../../config/formValidation';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem'
    }
}));

const SignIn = ({ history }) => {
    const [userInfo, setUserInfo] = useState({});

    const dispatch = useDispatch();
    const { state } = useLocation();
    const classes = useStyles();
    const { user, login } = useSelector((state) => state);

    const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo };
        temp[name] = value;
      
        setUserInfo(temp);
    };

    const handleFormSubmit = (event) => {
        dispatch(firebaseLoginEmailPassword(userInfo));
    };

    return (
        <div
            className={
                'flex justify-center items-center  min-h-full-screen bg-session'
            }
        >
            <Grid container alignItems="center" justify="center">
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card className={clsx(classes.card, 'card-session')}>
                        <div className="p-8 h-full relative">
                            <div className="pb-3 flex justify-center items-center h-full">
                                <img
                                    className="w-200 mb-3"
                                    src="/assets/images/logos/logo_new_vertical2.png"
                                    alt=""
                                />
                            </div>
                            <ValidatorForm onSubmit={handleFormSubmit}>
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    label="Email"
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                    value={userInfo.email || ''}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[
                                        rules.required,
                                        rules.isEmail
                                    ]}
                                />
                                <TextValidator
                                    className="mb-3 w-full"
                                    label="Password"
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="password"
                                    type="password"
                                    value={userInfo.password || ''}
                                    validators={['required']}
                                    errorMessages={[rules.required]}
                                />
                                {login.error && (
                                    <p className="text-error m-0 font-medium mb-2">
                                        Kombinasi Email dan Password yang anda
                                        masukan salah.
                                    </p>
                                )}
                                <div className="w-full text-right">
                                    <Link
                                        to="/session/forgot-password"
                                        className="font-medium"
                                    >
                                        Lupa Password?
                                    </Link>
                                </div>
                                <div className="flex flex-column mt-4">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={login.loading}
                                        type="submit"
                                        className="font-bold elevation-z0"
                                    >
                                        {login.loading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            'Masuk'
                                        )}
                                    </Button>
                                    {/* <p className="text-center">
                                        Atau belum punya akun?
                                    </p>
                                    <Button
                                        className="capitalize font-bold btn-blue text-white"
                                        onClick={() =>
                                            history.push('/session/signup')
                                        }
                                    >
                                        Daftar Sekarang
                                    </Button> */}
                                </div>
                                {/* <div className="border-bottom-1 w-full mt-2"></div>
                                <Button
                                    color="primary"
                                    onClick={loginWithGoogle}
                                    component="span"
                                    className="mt-2 w-full bg-default"
                                >
                                    <img
                                        src="/assets/images/logos/google.svg"
                                        style={{ width: 20 }}
                                        alt="google"
                                    />
                                    <span
                                        className="font-poppins ml-2 font-bold text-black"
                                        style={{ fontSize: 15 }}
                                    >
                                        Masuk dengan Google
                                    </span>
                                </Button> */}
                            </ValidatorForm>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default SignIn;
