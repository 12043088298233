import {
    Card,
    Grid,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import {
    CardKomisi,
    CardKomisiBatal,
    CardKomisiBelum,
    CardKomisiSudah
} from 'app/components';
import { getAllKomisiBelum } from 'app/redux/actions/KomisiActions';
import { formatRupiah, formatTanggal } from 'app/redux/actions/UserActions';
import { getWebSetting } from 'app/redux/actions/WebSettingActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb } from 'matx';
import ModalEditKomisi from './ModalEditKomisi';
import ModalEditWidraw from './ModalEditWidraw';

class KomisiBelum extends Component {
    constructor() {
        super();
        this.state = {
            modalAdd: false,
            modalEdit: false,
            edit_widraw: {
                id_user: '',
                nominal: '',
                status: '',
                doc_id: '',
                user: null,
                alasan: '',
                bukti: ''
            },
            rowsPerPage: 10,
            page: 0,
            modalKomisiReferral: false
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const { getAllKomisiBelum } = this.props;
        getAllKomisiBelum();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    renderData = () => {
        const { komisiBelum } = this.props;
        return komisiBelum?.length > 0 ? (
            komisiBelum.map((item, index) => {
                return (
                    <TableRow hover key={index}>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins'
                            align='center'
                        >
                            {index + 1}
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins'>
                            {item.user?.name}
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins'>
                            {formatRupiah(item.nominal, 'Rp')}
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins'>
                            {formatTanggal(item.created_at)}
                        </TableCell>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins'
                            align='center'
                        >
                            <div
                                className={`border-radius-4 px-4 py-2 w-max-content m-auto ${
                                    item.status == 3
                                        ? 'bg-blue text-white'
                                        : item.status == 1
                                        ? 'bg-default'
                                        : item.status == 2
                                        ? 'bg-error text-white'
                                        : null
                                }`}
                            >
                                {item.status == 3
                                    ? 'Sukses'
                                    : item.status == 1
                                    ? 'Pending'
                                    : item.status == 2
                                    ? 'Ditolak'
                                    : null}
                            </div>
                        </TableCell>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins'
                            align='center'
                        >
                            {item.status != 3 ? (
                                <IconButton
                                    title='edit'
                                    onClick={() => this.handleEdit(item)}
                                >
                                    <Icon color='primary'>border_color</Icon>
                                </IconButton>
                            ) : null}
                        </TableCell>
                    </TableRow>
                );
            })
        ) : (
            <TableRow hover>
                <TableCell colSpan={6} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };

    handleEdit = (item) => {
        // console.log(item);
        const { id_user, nominal, status, doc_id, user, alasan, bukti } = item;
        this.setState({
            edit_widraw: {
                id_user: id_user,
                nominal: nominal,
                status: status,
                doc_id: doc_id,
                user: user,
                alasan: alasan,
                bukti: bukti
            },
            modalEdit: true
        });
    };

    handleCloseEdit = () => {
        this.setState(
            {
                modalEdit: false
            },
            () => this.getData()
        );
    };


    render() {
        const { modalEdit, edit_widraw } = this.state;
        const { webSetting } = this.props;
        return (
            <div className='m-sm-30'>
                <div className='mb-sm-30'>
                    <Breadcrumb
                        routeSegments={[
                            { name: 'Penarikan Komisi', path: '/penarikan_komisi' },
                            { name: 'Belum' }
                        ]}
                    />
                </div>
                <div className='flex items-center justify-between mt-10 mb-3'>
                    <h2 style={{ fontSize: '25px' }}>List Penarikan Komisi</h2>
                </div>

                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Nama
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Nominal
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Dibuat Tanggal
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>
                    </div>
                </Card>
                {modalEdit && (
                    <ModalEditWidraw
                        id_user={edit_widraw.id_user}
                        nominal={edit_widraw.nominal}
                        doc_id={edit_widraw.doc_id}
                        status={edit_widraw.status}
                        user={edit_widraw.user}
                        open={modalEdit}
                        alasan={edit_widraw.alasan}
                        bukti={edit_widraw.bukti}
                        handleClose={this.handleCloseEdit}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        komisi: state.komisi.data,
        komisiBelum: state.komisi.belum,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllKomisiBelum: () => dispatch(getAllKomisiBelum()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(KomisiBelum);
