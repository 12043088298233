import { Card, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { Fragment } from 'react';

import { ButtonSolid } from '..';

const CardSoal = ({
    title,
    subtitle,
    soal,
    jawaban,
    jml_soal,
    no_soal,
    handleChangeNo,
    soal_img,
    handleJawab,
    jawaban_user,
    id_soal,
    handleDelJawaban,
    handleSelesai,
    pembahasan
}) => {
    let jawabanBenar;
    for (let i = 0; i < jawaban.length; i++) {
        if (i == 0) {
            jawabanBenar = jawaban[i].opt;
        } else {
            jawabanBenar =
                jawaban[i].value > jawaban[i - 1].value
                    ? jawaban[i].opt
                    : jawabanBenar;
        }
    }
    return (
        <Card elevation={6} className='px-6 py-5 h-full card-soal'>
            <h5 className='m-0'>{title}</h5>
            {subtitle && <div className='card-subtitle mb-4'>{subtitle}</div>}
            <div className='bordered mb-5 mt-4'></div>
            {soal_img && <img src={soal_img} className='img-soal max-w-600' />}
            <p className='soal'>{soal}</p>
            {pembahasan ? (
                <Fragment>
                    {jawaban &&
                        jawaban.map((item, index) => (
                            <p
                                key={index}
                                className={`MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 py-3
								font-medium
								h5
								${
                                    jawaban_user.length > 0
                                        ? jawaban_user[0].answer == item.opt
                                            ? 'text-primary'
                                            : ''
                                        : ''
                                }
							`}
                            >{`${item.opt.toUpperCase()}. ${item.text}`}</p>
                        ))}
                </Fragment>
            ) : (
                <FormControl component='fieldset' className='w-full'>
                    <RadioGroup
                        aria-label='jawaban'
                        name='jawaban'
                        value={
                            jawaban_user.length > 0
                                ? jawaban_user[0].answer
                                : null
                        }
                        onChange={(val) => handleJawab(val)}
                    >
                        {jawaban &&
                            jawaban.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    className='font-poppins'
                                    value={item.opt}
                                    control={<Radio color='primary' />}
                                    label={`${item.opt.toUpperCase()}. ${
                                        item.text
                                    }`}
                                />
                            ))}
                    </RadioGroup>
                </FormControl>
            )}
            <div className='border-bottom-1 mt-4 mb-3'></div>
            {pembahasan && (
                <Card className='my-4 border-radius-4 px-8 py-4' elevation={3}>
                    <p className='m-0 h5 font-medium mb-1'>
                        Jawaban Anda:{' '}
                        <span className='text-primary'>
                            {jawaban_user.length > 0
                                ? jawaban_user[0].answer.toUpperCase()
                                : null}
                        </span>
                    </p>
                    <p className='m-0 h5 font-medium mb-3'>
                        Kunci Jawaban:{' '}
                        <span className='text-primary'>
                            {jawabanBenar.toUpperCase()}
                        </span>
                    </p>
                    <h5 className='py-4 border-top-1 border-bottom-1'>
                        Pembahasan
                    </h5>
                    <div
                        className='black-text'
                        dangerouslySetInnerHTML={{ __html: pembahasan }}
                    ></div>
                </Card>
            )}
            {!pembahasan && jawaban_user.length > 0 ? (
                <>
                    <p className='m-0 font-medium'>Ragu dengan jawaban anda?</p>
                    <p className='m-0 mb-2 font-medium'>
                        lewati atau klik tombol kosongkan jawaban di bawah ini.
                    </p>
                    <ButtonSolid
                        variant='button-orange'
                        text='Kosongkan Jawaban'
                        onClick={() => handleDelJawaban(id_soal, jawaban_user)}
                    />
                    <div className='mb-8'></div>
                </>
            ) : null}
            <div className='flex items-center'>
                {no_soal != 0 ? (
                    <Fragment>
                        <ButtonSolid
                            variant='button-blue'
                            icon='chevron_left'
                            text='Sebelumnya'
                            onClick={() => handleChangeNo(no_soal - 1)}
                        />
                        <div className='mr-5'></div>
                    </Fragment>
                ) : null}
                {no_soal + 1 < jml_soal ? (
                    <Fragment>
                        <ButtonSolid
                            variant='button-purple'
                            iconRight='chevron_right'
                            text='Selanjutnya'
                            onClick={() => handleChangeNo(no_soal + 1)}
                        />
                    </Fragment>
                ) : null}
                {!pembahasan && no_soal + 1 == jml_soal ? (
                    <Fragment>
                        <ButtonSolid
                            variant='button-purple'
                            iconRight='chevron_right'
                            text='Selesai'
                            onClick={handleSelesai}
                        />
                    </Fragment>
                ) : null}
            </div>
        </Card>
    );
};

export default CardSoal;
