import LatihanKerjakan from './latihan/LatihanKerjakan';
import SKBDetail from './SKBDetail';
import SKBTable from './SKBTable';
import TryoutKerjakan from './tryout/TryoutKerjakan';

const SKBRoutes = [
    {
        path: '/skb',
        component: SKBTable
    },
    {
        path: '/detail/skb/:id',
        component: SKBDetail
    },
    {
        path: '/tryout/skb/:parent/:doc_id',
        component: TryoutKerjakan
    },
    {
        path: '/exercise/skb/:parent/:doc_id',
        component: LatihanKerjakan
    }
];

export default SKBRoutes;
