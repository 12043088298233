import { Button, Dialog, Grid, MenuItem } from '@material-ui/core';
import { editMateri, getTryoutSKB } from 'app/redux/actions/SKBActions';
import React, { Component } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

class EditMateriDialog extends Component {
    state = {
        title: '',
        description: '',
        materi_file: '',
        materi_file_before: '',
        preview_file: '',
        statusButton: false,
        buttonText: 'Update',
        isActive: false,
        formasi_id: 0,
        format_materi: 'file',
        materi_video: ''
    };

    componentDidMount() {
        let id = window.location.pathname.split('/').pop();
        this.props.getTryoutSKB(id);
        let { uid } = this.props;
        let format_materi = uid.materi_file != null ? 'file' : 'video';
        this.setState({
            parent_id: id,
            doc_id: uid.doc_id,
            title: uid.title,
            description: uid.description,
            materi_file_before: uid.materi_file,
            formasi_id: uid.formasi_id,
            format_materi: format_materi,
            materi_video: uid.materi_video
        });
    }

    handleChange = (event, source) => {
        event.persist();
        if (source === 'switch') {
            console.log(event.target);
            this.setState({ isActive: event.target.checked });
            return;
        }

        if (source === 'status') {
            if (event.target.value === 'SUSPEND') {
                this.setState({
                    users_status: 0,
                    users_status_value: 'SUSPEND'
                });
                return;
            } else {
                this.setState({
                    users_status: 1,
                    users_status_value: 'ACTIVE'
                });
                return;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeSelect = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = async () => {
        this.setState({
            statusButton: true,
            buttonText: 'Loading...'
        });
        let {
            title,
            description,
            materi_file,
            formasi_id,
            doc_id,
            materi_file_before,
            materi_video
        } = this.state;
        let parent_id = window.location.pathname.split('/').pop();
        let params = {
            title: title,
            description: description,
            materi_file: materi_file,
            materi_file_before: materi_file_before,
            formasi_id: formasi_id,
            created_at: new Date(),
            parent_id: parent_id,
            doc_id: doc_id,
            materi_video: materi_video
        };
        await this.props.editMateri(params);
        await this.props.handleClose();
    };

    getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    };

    isPDF = (filename) => {
        var ext = this.getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'pdf':
                return true;
        }
        return false;
    };

    handleUploadMateri = (e) => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        if (this.isPDF(e.target.files[0].name)) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = () => {
                this.setState({
                    materi_file: reader.result
                });
            };
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid File Format',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    handleChangeFormat = (val) => {
        let value = val.target.value;
        this.setState({
            format_materi: value,
            materi_file: '',
            materi_video: ''
        });
    };

    handleChangeVideo = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render() {
        let {
            title,
            description,
            formasi_id,
            materi_video,
            format_materi
        } = this.state;
        let { open, handleClose, tryout } = this.props;
        return (
            <Dialog onClose={handleClose} open={open}>
                <div className='p-5' style={{ minWidth: '500px' }}>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={4} className='mb-7'>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    label='Nama Materi *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    label='Deskripsi Materi *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='w-full'
                                    label='Materi Untuk Tryout'
                                    onChange={this.handleChange}
                                    name='formasi_id'
                                    value={formasi_id}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value={0} disabled>
                                        <em>Pilih</em>
                                    </MenuItem>
                                    {tryout.map((item) => (
                                        <MenuItem value={item.formasi_id}>
                                            {item.title}
                                        </MenuItem>
                                    ))}
                                </SelectValidator>
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='mb-4 w-full pb-0'
                                    label='Format Materi'
                                    onChange={this.handleChangeFormat}
                                    name='format_materi'
                                    value={format_materi}
                                >
                                    <MenuItem value='file'>File</MenuItem>
                                    <MenuItem value='video'>Video</MenuItem>
                                </SelectValidator>
                            </Grid>
                            {format_materi == 'file' ? (
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    className='pb-0 pt-0'
                                >
                                    <small>File materi</small>
                                    <br />
                                    <input
                                        type='file'
                                        name='materi_file'
                                        id='materi_file'
                                        className='mb-6'
                                        accept='application/pdf'
                                        onChange={this.handleUploadMateri}
                                        className='mt-2'
                                    />
                                </Grid>
                            ) : (
                                <Grid item sm={12} xs={12} className=' pt-0'>
                                    <TextValidator
                                        label='Materi Video'
                                        onChange={this.handleChangeVideo}
                                        type='text'
                                        name='materi_video'
                                        value={materi_video}
                                        validators={['required']}
                                        errorMessages={['Field is Required']}
                                        className='w-full'
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={this.state.statusButton}
                                color='primary'
                                type='submit'
                            >
                                {this.state.buttonText}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        tryout: state.skb.tryout
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTryoutSKB: (params) => dispatch(getTryoutSKB(params)),
        editMateri: (params) => dispatch(editMateri(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditMateriDialog);
