// import LatihanKerjakan from './latihan/LatihanKerjakan';
import BumnDetail from './BumnDetail';
import TryoutSoal from './tryout/TryoutSoal';
import BumnIndex from './BumnIndex';
import BumnTable from './BumnTable';
import LatihanSoal from './latihan/LatihanSoal';
import BumnBimbelDetail from './BumnBimbelDetail';
import BumnTableBimbel from './BumnTableBimbel';

const BumnRoutes = [
    {
        path: '/bumn/tryout/soal/:id',
        component: TryoutSoal
    },
    {
        path: '/bumn/latihan/soal/:id',
        component: LatihanSoal
    },
     {
        path: '/bumn/bimbel',
        component: BumnTableBimbel
    },
    {
        path: '/bumn/mandiri',
        component: BumnTable
    },
    {
        path: '/bumn',
        component: BumnIndex
    },
    {
        path: '/bumnbimbel/:id/detail',
        component: BumnBimbelDetail
    },
    {
        path: '/detail/bumn/:id',
        component: BumnDetail
    },
   

    // {
    //     path: '/exercise/skb/:parent/:doc_id',
    //     component: LatihanKerjakan
    // }
];

export default BumnRoutes;
