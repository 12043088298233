import { Button, CircularProgress, Dialog, Grid, MenuItem } from '@material-ui/core';
import { addCategory, delCategory } from 'app/redux/actions/BimbelActions';
import React, { Component } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class ModalEditCategory extends Component {
    constructor() {
        super();
        this.state = {
            category: 'twk',
            name: '',
            doc_id: ''
        };
    }

    componentDidMount() {
        let { name, category, doc_id } = this.props;

        this.setState({
            name: name,
            category: category,
            doc_id: doc_id
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    submitForm = () => {
        let { category, name, doc_id } = this.state;
        const { addCategory, delCategory, handleClose } = this.props;
        let id = name.toLowerCase().replace(/_/g, ' ');
        let params = {
            category: category,
            name: name,
            id: id
        };
        addCategory(params, id);
        delCategory(doc_id);
        setTimeout(() => {
            handleClose();
        }, 1500);
    };

    render() {
        let { category, name } = this.state;
        let { open, handleClose, loadingBimbel } = this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth='sm'
                fullWidth={true}
            >
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Subcategory</h4>
                    <ValidatorForm ref='form' onSubmit={this.submitForm}>
                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Kategori'
                                    onChange={this.handleChange}
                                    name='category'
                                    value={category || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='twk'>TWK</MenuItem>
                                    <MenuItem value='tiu'>TIU</MenuItem>
                                    <MenuItem value='tkp'>TKP</MenuItem>
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <TextValidator
                                    className='w-full'
                                    label='Sub Kategori'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='name'
                                    value={name}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle mt-3'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                {loadingBimbel ? (
                                    <CircularProgress />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Batal</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingBimbel: state.bimbel.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addCategory: (params, id) => dispatch(addCategory(params, id)),
        delCategory: (id) => dispatch(delCategory(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditCategory);
