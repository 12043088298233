import { Card, Icon, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { getAllGroup } from 'app/redux/actions/GroupActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalEditGroup from './ModalEditGroup';

class Group extends Component {
    constructor() {
        super();
        this.state = {
            modalAdd: false,
            modalEdit: false,
            edit_link: {
                name: '',
                link: '',
                doc_id: ''
            },
            rowsPerPage: 10,
            page: 0
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const { getAllGroup } = this.props;
        getAllGroup();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    renderData = () => {
        const { group_link } = this.props;
        return group_link.length > 0 ? (
            group_link.map((item, index) => {
                return (
                    <TableRow hover key={index}>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins'
                            align='center'
                        >
                            {index + 1}
                        </TableCell>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins'
                            colSpan={2}
                        >
                            {item.name}
                        </TableCell>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins'
                            colSpan={2}
                        >
                            <a
                                href={item.link}
                                target='_blank'
                                style={{ color: '#fd591d' }}
                            >
                                Lihat
                            </a>
                        </TableCell>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins'
                            align='center'
                        >
                            <IconButton
                                title='edit'
                                onClick={() => this.handleEdit(item)}
                            >
                                <Icon color='primary'>border_color</Icon>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            })
        ) : (
            <TableRow hover>
                <TableCell colSpan={6} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };

    handleEdit = (item) => {
        const { name, link, doc_id } = item;
        this.setState({
            edit_link: {
                name: name,
                link: link,
                doc_id: doc_id
            },
            modalEdit: true
        });
    };

    handleCloseEdit = () => {
        this.setState({
            modalEdit: false
        });
        this.getData();
    };

    render() {
        const { modalEdit, edit_link } = this.state;
        return (
            <div className='m-sm-30'>
                <div className='flex items-center justify-between mt-10 mb-3'>
                    <h2 style={{ fontSize: '25px' }}>List Link Group Member</h2>
                </div>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Nama
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Link
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>
                    </div>
                </Card>
                {modalEdit && (
                    <ModalEditGroup
                        name={edit_link.name}
                        link={edit_link.link}
                        doc_id={edit_link.doc_id}
                        open={modalEdit}
                        handleClose={this.handleCloseEdit}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        group_link: state.link.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllGroup: () => dispatch(getAllGroup())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Group);
