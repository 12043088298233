import { makeStyles } from '@material-ui/core/styles';

export const borderStyles = makeStyles(({ palette, ...theme }) => ({
	"@global": {
		".border-radius-0": { borderRadius: "0px !important", overflow: "hidden" },
		".border-radius-4": { borderRadius: "4px !important", overflow: "hidden" },
		".border-radius-8": { borderRadius: "8px !important", overflow: "hidden" },
		".border-radius-12": {
			borderRadius: "12px !important",
			overflow: "hidden",
		},
		".border-radius-circle": { borderRadius: "50% !important" },
		".border-none": { border: "none !important" },
		".border-transparent": { border: "1px solid transparent !important" },
		".rounded": {
			borderRadius: "300px !important",
			overflow: "hidden !important",
		},
		".rounded-l": {
			borderTopLeftRadius: "300px !important",
			borderBottomLeftRadius: "300px !important",
			overflow: "hidden !important",
		},
		".rounded-r": {
			borderTopRightRadius: "300px !important",
			borderBottomRightRadius: "300px !important",
			overflow: "hidden !important",
		},
		".border-bottom-1": {
			borderBottom: "1px solid #efefef",
		},
		".border-bottom-2": {
			borderBottom: "2px solid #efefef",
		},
		".border-1": {
			border: "1px solid #efefef",
		},
		".border-2": {
			border: "2px solid #efefef",
		},
		".border-purple": {
			borderColor: "#7467ef",
		},
		".border-right-1": {
			borderRight: "1px solid #efefef",
		},
		".border-left-1": {
			borderLeft: "1px solid #efefef",
		},
		".border-top-1": {
			borderTop: "1px solid #efefef",
		},
		".border-top-2": {
			borderTop: "2px solid #efefef",
		},
	},
}));
