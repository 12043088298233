import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Dialog,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CalendarIcon } from 'app/components/Icons';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { addEventTryout } from 'app/redux/actions/EventSKDActions';
import { getTanggal } from 'app/redux/actions/UserActions';
const AddTryoutEventDialog = ({ open, handleClose }) => {
    const tomorrow = dayjs().add(1, 'day');
    const [name, setName] = useState('');
    const [jumlahSoal, setJumlahSoal] = useState('');
    const [expiresTime, setExpiresTime] = useState('');
    const [shuffle, setShuffle] = useState('0');
    const [status, setStatus] = useState('tidak_aktif');
    const [groupLink, setGroupLink] = useState('');
    const [buttonText, setButtonText] = useState('Tambah');
    const [deskripsi, setDeskripsi] = useState('');
    const [jumlahKuota, setjumlahKuota] = useState();
    const [tanggalMulai, setTanggalMulai] = useState(tomorrow.toISOString());
    const [waktuMulai, setWaktuMulai] = useState(dayjs('12:30', 'HH:mm'));
    const [waktuSelesai, setWaktuSelesai] = useState(dayjs()); // Adjust this to your needs
    const [tanggalSelesai, setTanggalSelesai] = useState(
        tomorrow.toISOString()
    );
    const isMounted = useRef(true);
    const [dateError, setDateError] = useState(false);
    const [statusButton, setStatusButton] = useState(false);

    const formRef = useRef(null);

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'name') setName(value);
        if (name === 'group_link') setGroupLink(value);
        if (name === 'deskripsi') setDeskripsi(value);
        if (name === 'jumlahSoal') setJumlahSoal(value);
        if (name === 'expiresTime') setExpiresTime(value);
        if (name === 'jumlahKuota') setjumlahKuota(value);
    };

    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        if (name === 'status') setStatus(value);
        if (name === 'shuffle') setShuffle(value);
    };

    const handleFormSubmit = async () => {
        setStatusButton(true);
        setButtonText('Loading...');
        let now = getTanggal();

        let params = {
            title: name,
            status,
            deskripsi,
            expires_time: parseInt(expiresTime, 10),
            is_shuffle: parseInt(shuffle, 10),
            jml_soal: parseInt(jumlahSoal, 10),
            jml_kuota: parseInt(jumlahKuota, 10),
            group_link: groupLink,
            tanggal_mulai: dayjs(tanggalMulai).format('DD-MM-YYYY'),
            tanggal_selesai: dayjs(tanggalSelesai).format('DD-MM-YYYY'),
            created_at: now,
            waktu_mulai: dayjs(waktuMulai).format('HH:mm'),
            waktu_selesai: dayjs(waktuSelesai).format('HH:mm')
        };
        try {
            await dispatch(addEventTryout(params));

            if (isMounted.current) {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Event berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                handleClose();
            }
        } catch (err) {
            console.error('Error adding event tryout:', err);
            if (isMounted.current) {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Event gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            }
        } finally {
            if (isMounted.current) {
                setStatusButton(false);
                setButtonText('Tambah');
            }
        }
    };
    const handleChangeWaktuMulai = (newValue) => {
        setWaktuMulai(newValue);
    };
    const handleChangeWaktuSelesai = (newValue) => {
        setWaktuSelesai(newValue);
    };
    const handleSearchChangeTgl = (value) => {
        setTanggalMulai(value);
    };

    const handleSearchChangeTglSelesai = (value) => {
        setTanggalSelesai(value);
    };
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth='md'
            disablePortal
            disableEnforceFocus
            fullWidth={true}
        >
            <div className='p-5'>
                <h4 className='mb-5'>Tambah Tryout Event</h4>
                <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
                    <Grid className='mb-10' container spacing={1}>
                        <Grid item sm={12} xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Detail Paket
                            </p>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Nama Tryout *'
                                onChange={handleChange}
                                type='text'
                                name='name'
                                value={name}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Link Group Tryout *'
                                onChange={handleChange}
                                type='text'
                                name='group_link'
                                value={groupLink}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Deskripsi Tryout*'
                                onChange={handleChange}
                                type='text'
                                name='deskripsi'
                                value={deskripsi}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Jumlah Soal*'
                                onChange={handleChange}
                                type='number'
                                name='jumlahSoal'
                                value={jumlahSoal}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Duras Tryout (Dalam Menit)*'
                                onChange={handleChange}
                                type='number'
                                name='expiresTime'
                                value={expiresTime}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id='demo-simple-select-helper-label'>
                                    Soal Acak
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-helper-label'
                                    id='demo-simple-select-helper'
                                    value={shuffle}
                                    name='shuffle'
                                    onChange={handleChangeSelect}
                                >
                                    <MenuItem value={'1'}>Acak</MenuItem>
                                    <MenuItem value={'0'}>Tidak Acak</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Pengaturan Kuota
                            </p>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Jumlah Kuota*'
                                onChange={handleChange}
                                type='number'
                                name='jumlahKuota'
                                value={jumlahKuota}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Pengaturan Waktu Ujian Tryout
                            </p>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['DatePicker']}
                                    disablePortal
                                    sx={{
                                        p: 0,
                                        overflow: 'none',
                                        width: '100%'
                                    }}
                                >
                                    <DatePicker
                                        sx={{
                                            width: '100%'
                                        }}
                                        label='Tanggal Mulai'
                                        size='small'
                                        required={true}
                                        disablePast
                                        inputFormat='YYYY/MM/DD'
                                        variant='outlined'
                                        onChange={handleSearchChangeTgl}
                                        components={{
                                            OpenPickerIcon: CalendarIcon
                                        }}
                                        views={['year', 'month', 'day']}
                                        slotProps={{
                                            textField: {
                                                helperText: dateError ? (
                                                    <span
                                                        style={{
                                                            color: '#D32F2F'
                                                        }}
                                                    >
                                                        Enter a valid Date
                                                    </span>
                                                ) : (
                                                    'MM/DD/YYYY'
                                                )
                                            }
                                        }}
                                        format='YYYY/MM/DD'
                                        value={dayjs(tanggalMulai)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['TimePicker']}
                                    disablePortal
                                    sx={{
                                        p: 0,
                                        overflow: 'none',
                                        width: '100%'
                                    }}
                                >
                                    <TimePicker
                                        sx={{
                                            width: '100%'
                                        }}
                                        variant='outlined'
                                        ampm={false}
                                        inputFormat='HH:mm'
                                        onChange={handleChangeWaktuMulai}
                                        dateFormat='HH:mm'
                                        label='Waktu Mulai'
                                        value={waktuMulai}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['DatePicker']}
                                    disablePortal
                                    sx={{
                                        p: 0,
                                        overflow: 'none',
                                        width: '100%'
                                    }}
                                >
                                    <DatePicker
                                        sx={{
                                            width: '100%'
                                        }}
                                        label='Tanggal Selesai'
                                        size='small'
                                        required={true}
                                        disablePast
                                        inputFormat='YYYY/MM/DD'
                                        variant='outlined'
                                        onChange={handleSearchChangeTglSelesai}
                                        components={{
                                            OpenPickerIcon: CalendarIcon
                                        }}
                                        views={['year', 'month', 'day']}
                                        slotProps={{
                                            textField: {
                                                helperText: dateError ? (
                                                    <span
                                                        style={{
                                                            color: '#D32F2F'
                                                        }}
                                                    >
                                                        Enter a valid Date
                                                    </span>
                                                ) : (
                                                    'MM/DD/YYYY'
                                                )
                                            }
                                        }}
                                        format='YYYY/MM/DD'
                                        value={dayjs(tanggalSelesai)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['TimePicker']}
                                    disablePortal
                                    sx={{
                                        p: 0,
                                        overflow: 'none',
                                        width: '100%'
                                    }}
                                >
                                    <TimePicker
                                        sx={{
                                            width: '100%'
                                        }}
                                        variant='outlined'
                                        ampm={false}
                                        inputFormat='HH:mm'
                                        onChange={handleChangeWaktuSelesai}
                                        dateFormat='HH:mm'
                                        label='Waktu Selesai'
                                        value={waktuSelesai}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id='demo-simple-select-helper-label'>
                                    Status
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-helper-label'
                                    id='demo-simple-select-helper'
                                    value={status}
                                    name='status'
                                    onChange={handleChangeSelect}
                                >
                                    <MenuItem value={'aktif'}>Aktif</MenuItem>
                                    <MenuItem value={'tidak_aktif'}>
                                        Tidak Aktif
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className='flex flex-space-between flex-middle'>
                        <Button
                            variant='contained'
                            disabled={statusButton}
                            color='primary'
                            type='submit'
                        >
                            {buttonText}
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default AddTryoutEventDialog;
