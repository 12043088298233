import {
    GET_DETAIL_TRYOUT_BUMN,
    GET_SOAL_BUMN,
    RESET_KERJAKAN_TRYOUT,
    UPD_QUESTION_TRYOUT_BUMN,
} from '../actions/KerjakanTryoutActions';

const initialState = {
    tryout: {
        description: '',
        duration: '',
        jml_soal: '',
        title: ''
    },
    soal: []
};

const KerjakanBumnReducers = function (state = initialState, action) {
    switch (action.type) {
        case GET_DETAIL_TRYOUT_BUMN: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case GET_SOAL_BUMN: {
            return {
                ...state,
                soal: action.payload
            };
        }
        case RESET_KERJAKAN_TRYOUT: {
            return {
                tryout: {
                    description: '',
                    duration: '',
                    jml_soal: '',
                    title: ''
                },
                soal: [],
                detail_jawaban: {},
                jawaban: []
            };
        }
        case UPD_QUESTION_TRYOUT_BUMN: {
            let updSoal = action.payload.params;
            let soal = state.soal;
            let indexSoal = soal.findIndex(
                (item) => item.doc_id == action.payload.doc_id
            );
            soal[indexSoal] = {
                ...soal[indexSoal],
                ...updSoal
            };
            return {
                ...state,
                soal: soal
            };
        }
        default: {
            return state;
        }
    }
};

export default KerjakanBumnReducers;
