import { Button, Icon } from '@material-ui/core';
import history from 'history.js';
import React from 'react';

const ButtonOpacity = ({ text, icon, href, className, iconSize, onClick, variant, width, iconRight, target }) => {
	const handleClick = () => {
		if (onClick == undefined) {
			handleHref();
		} else {
			onClick();
		}
	};
	const handleHref = () => {
		if (target != undefined) {
			window.open(href, target);
		} else {
			history.push(href);
		}
	};
	return (
		<Button variant="contained" onClick={handleClick} className={`${variant} px-4 opacity ${className} ${width}`} target={target}>
			{icon && (
				<Icon className={text != undefined ? "mr-4" : ""} fontSize={iconSize}>
					{icon}
				</Icon>
			)}
			{text && <span className="text-button">{text}</span>}
			{iconRight && <Icon fontSize={iconSize}>{iconRight}</Icon>}
		</Button>
	);
};

export default ButtonOpacity;
