import { Button, Card, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteUK, getUK } from '../../redux/actions/UKActions';
import ListBundle from './bundle/ListBundle';
import LatihanList from './latihan/LatihanList';
import MateriList from './materi/MateriList';
import SoalPembahasanList from './soal_pembahasan/SoalPembahasanList';
import TryoutList from './tryout/TryoutList';

// import LatihanList from './latihan/LatihanList';

class UKDetail extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        shouldOpenEditorDialog: false,
        shouldOpenCreateDialog: false,
        shouldOpenConfirmationDialog: false,
        query: '',
        menu: 1
    };

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenConfirmationDialog: false,
            shouldOpenCreateDialog: false
        });
        this.updatePageData();
    };

    handleConfirmationResponse = async () => {
        await this.props.deleteUK(this.state.uid_delete);
        this.handleDialogClose();
    };

    componentDidMount() {
        this.updatePageData();
    }

    componentDidUpdate(prevProps, prevState) {}

    updatePageData = async () => {
        await this.props.getUK();
    };

    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    handleChangeMenu = (menu) => {
        this.setState({
            menu
        });
    };

    render() {
        let { menu } = this.state;
        return (
            <div className='m-sm-30'>
                <h2 className='mt-10 mb-5' style={{ fontSize: '25px' }}>
                    Detail Paket Formasi UK
                </h2>
                <Card style={{ borderRadius: 5 }} className='mb-10'>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div className='flex items-center'>
                                <Button
                                    onClick={() => this.handleChangeMenu(1)}
                                    variant={menu == 1 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Bundle
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(2)}
                                    variant={menu == 2 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Materi
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(3)}
                                    variant={menu == 3 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Soal Pembahasan
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(4)}
                                    variant={menu == 4 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Tryout
                                </Button>
                                <Button
                                    onClick={() => this.handleChangeMenu(5)}
                                    variant={menu == 5 ? 'contained' : null}
                                    color='primary'
                                    className='elevation-z0 no-border'
                                    style={{ borderRadius: 5 }}
                                >
                                    Latihan
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
                {menu == 1 && <ListBundle />}
                {menu == 2 && <MateriList />}
                {menu == 3 && <SoalPembahasanList />}
                {menu == 4 && <TryoutList />}
                {menu == 5 && <LatihanList />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        uk: state.uk.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUK: () => dispatch(getUK()),
        deleteUK: (params) => dispatch(deleteUK(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UKDetail);
