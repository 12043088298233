import {
    Button,
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import { FormSearch } from 'app/components';
import { delBatchBimbelPPPK, getBatchBimbelPPPK } from 'app/redux/actions/PPPKActions';
import { formatTanggal } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import AddRekaman from './AddRekaman';
import EditMateri from './EditMateri';

class Batch extends Component {
    constructor() {
        super();
        this.state = {
            rowsPerPage: 10,
            page: 0,
            modalAdd: false,
            modalEdit: false,
            search: '',
            edit_materi: {
                bg_color: '',
                category: '',
                description: '',
                title: '',
                category_desc: '',
                doc_id: '',
                materi_file: '',
                materi_video: ''
            }
        };
    }

    componentDidMount() {
        this.getData();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    getData() {
        const { getMateri, params } = this.props;
        // console.log(params);
        let parent = params.id;
        getMateri(parent);
    }

    handleDelete = (doc_id) => {
        const { delBatchBimbelPPPK, params } = this.props;
        const data = {
            parent_id: params.id,
            doc_id: doc_id
        };
        Swal.fire({
            title: 'Peringatan',
            text: 'Apakah kamu yakin ingin menghapus Rekaman ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            icon: 'warning'
        }).then((res) => {
            if (res.isConfirmed) {
                delBatchBimbelPPPK(data);
            } else {
                Swal.close();
            }
        });
    };

    renderData = () => {
        const { materi } = this.props;
        const { search, page, rowsPerPage } = this.state;
        let dataMateri = materi
            .filter((item) =>
                Object.keys(item).some((key) =>
                    search.includes(key)
                        ? false
                        : item[key]?.toString().toLowerCase().includes(search)
                )
            )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        return dataMateri.length > 0 ? (
            dataMateri.map((item, index) => (
                <TableRow key={index}>
                    <TableCell className='px-0' align='center'>
                        {index + 1}
                    </TableCell>

                    <TableCell className='px-0' colSpan={2}>
                        {item.title}
                    </TableCell>
                    <TableCell className='px-0' align='center'>
                        <IconButton
                            title='Edit'
                            onClick={() => this.handleModalEdit(item)}
                        >
                            <Icon color='primary'>edit</Icon>
                        </IconButton>
                        <IconButton
                            title='delete'
                            onClick={() => this.handleDelete(item.doc_id)}
                        >
                            <Icon color='error'>delete</Icon>
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))
        ) : (
            <TableRow>
                <TableCell colSpan={7} align='center'>
                    Not Have Data
                </TableCell>
            </TableRow>
        );
    };

    handleSearch = (val) => {
        this.setState({
            search: val.target.value
        });
    };

    handleModalAdd = () => {
        this.setState((prefState) => ({
            modalAdd: !prefState.modalAdd
        }));
    };

    handleCloseEdit = () => {
        this.setState({
            modalEdit: false
        });
    };

    handleModalEdit = (item) => {
        const {
            bg_color,
            category,
            description,
            title,
            category_desc,
            doc_id,
            materi_file,
            materi_video
        } = item;
        this.setState({
            edit_materi: {
                bg_color: bg_color,
                category: category,
                description: description,
                title: title,
                category_desc: category_desc,
                doc_id: doc_id,
                materi_file: materi_file,
                materi_video: materi_video
            },
            modalEdit: true
        });
    };

    render() {
         const { materi, params } = this.props;
        const { search, rowsPerPage, page, modalAdd, modalEdit, edit_materi } =
            this.state;
        return (
            <div>
                <Card className='p-5 overflow-auto' elevation={6}>
                    <div className='flex justify-between'>
                        <Button
                            variant='outlined'
                            color='primary'
                            className='mb-5'
                            onClick={this.handleModalAdd}
                            title='Edit'
                        >
                            Tambah
                        </Button>
                        <FormSearch
                            value={search}
                            handleSearch={this.handleSearch}
                        />
                    </div>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='text-white'
                                        align='center'
                                    >
                                        No.
                                    </TableCell>
                                    <TableCell
                                        className='text-white'
                                        colSpan={2}
                                    >
                                        Judul
                                    </TableCell>
                                   
                                    <TableCell
                                        className='text-white'
                                        align='center'
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>

                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={materi.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                    </div>
                </Card>
                <AddRekaman
                    open={modalAdd}
                    handleClose={this.handleModalAdd}
                    match={params}
                />
                {modalEdit && (
                    <EditMateri
                        open={modalEdit}
                        handleClose={this.handleCloseEdit}
                        bg_color={edit_materi.bg_color}
                        category={edit_materi.category}
                        description={edit_materi.description}
                        title={edit_materi.title}
                        category_desc={edit_materi.category_desc}
                        doc_id={edit_materi.doc_id}
                        materi_file={edit_materi.materi_file}
                        materi_video={edit_materi.materi_video}
                    />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        materi: state.pppk.batch
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        delBatchBimbelPPPK: (doc_id) => dispatch(delBatchBimbelPPPK(doc_id)),
        getMateri: (params) => dispatch(getBatchBimbelPPPK(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Batch);
