import axios from 'axios';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const LOADING = 'LOADING';
export const GET_SKB = 'GET_SKB';
export const ADD_SKB = 'ADD_SKB';
export const EDIT_SKB = 'EDIT_SKB';
export const DELETE_SKB = 'DELETE_SKB';
export const GET_DETAIL_SKB = 'GET_DETAIL_SKB';
export const GET_BUNDLE_SKB = 'GET_BUNDLE_SKB';
export const GET_TRYOUT_SKB = 'GET_TRYOUT_SKB';
export const GET_MY_TRYOUT = 'GET_MY_TRYOUT';
export const GET_MY_TRYOUT_BUNDLE = 'GET_MY_TRYOUT_BUNDLE';
export const GET_MY_TRYOUT_BUNDLE_DETAIL = 'GET_MY_TRYOUT_BUNDLE_DETAIL';
export const GET_MY_TRYOUT_DETAIL = 'GET_MY_TRYOUT_DETAIL';
export const GET_MY_EXERCISE_DETAIL = 'GET_MY_EXERCISE_DETAIL';
export const GET_MY_EXERCISE = 'GET_MY_EXERCISE';
export const GET_MATERI_SKB_DETAIL = 'GET_MATERI_SKB_DETAIL';
export const GET_USERS_MATERI_LIST = 'GET_USERS_MATERI_LIST';
export const GET_USERS_MATERI = 'GET_USERS_MATERI';
export const GET_USERS_SP = 'GET_USERS_SP';
export const GET_USERS_SP_LIST = 'GET_USERS_SP_LIST';
export const GET_SP_SKB_DETAIL = 'GET_SP_SKB_DETAIL';
export const GET_HASIL_LAT_SKB = 'GET_HASIL_LAT_SKB';
export const GET_EXERCISE_SKB = 'GET_EXERCISE_SKB';

// PAKETTT
export const getSKB = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });
                dispatch({
                    type: GET_SKB,
                    payload: data
                });
            });
    };
};

export const addPaketSKB = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(id)
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'SKB berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'SKB gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updateSKB = (params, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Paket berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: EDIT_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Paket gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteSKB = (doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'SKB berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_SKB,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'SKB gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// BUNDLEEE
export const getBundleSKB = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(kode)
            .collection('bundle')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_BUNDLE_SKB,
                    payload: data.length > 0 ? data[0] : data
                });
            });
    };
};

export const addBundleSKB = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(id)
            .collection('bundle')
            .doc(doc_id)
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Bundle berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Bundle gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updateBundleSKB = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(id)
            .collection('bundle')
            .doc(doc_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Bundle berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// MATERII
export const getMateriList = (parent) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('materi')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });

                dispatch({
                    type: GET_USERS_MATERI_LIST,
                    payload: data
                });
            });
    };
};

export const addMateri = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getMateriList(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const editMateri = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil Diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getMateriList(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal Diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const deleteMateri = (parent, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('materi')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_SKB,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// SOAL PEMBAHASAN
export const getSPList = (parent) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('soal_pembahasan')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });

                dispatch({
                    type: GET_USERS_SP_LIST,
                    payload: data
                });
            });
    };
};

export const addSP = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/soal_pembahasan/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal Pembahasan berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSPList(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal Pembahasan gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const editSP = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/soal_pembahasan/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal Pembahasan berhasil Diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSPList(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal Pembahasan gagal Diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const deleteSP = (parent, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('soal_pembahasan')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal Pembahasan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_SKB,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal Pembahasan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// TRYOUT
export const getTryoutList = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(kode)
            .collection('tryout')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_SKB,
                    payload: data
                });
            });
    };
};

export const addTryout = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(id)
            .collection('tryout')
            .doc()
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout SKB berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout SKB gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editTryout = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(id)
            .collection('tryout')
            .doc(doc_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Bundle berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteTryout = (parent, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('tryout')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_SKB,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const addQuestionTryout = (params, parent, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('tryout')
            .doc(id)
            .collection('question')
            .doc()
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editQuestionTryout = (params, parent, id, question_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('tryout')
            .doc(id)
            .collection('question')
            .doc(question_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteQuestionTryout = (parent, doc_id, qt_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('tryout')
            .doc(doc_id)
            .collection('question')
            .doc(qt_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_SKB,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const downloadTemplateQuestion = () => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .get(`${process.env.REACT_APP_API_URL}scpns/question/download`, {
                responseType: 'blob'
            })
            .then((res) => {
                saveAs(res.data, 'template_skb_uk_pppk.csv');
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Berhasil Download',
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: res.data
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal download',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const importQuestion = (params, parent, doc_id) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}scpns/question/import/${parent}/${doc_id}`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil diimport',
                    icon: 'success',
                    timer: 2000
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

// LATIHAN || EXERCISE
export const getExerciseList = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(kode)
            .collection('exercise')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });
                dispatch({
                    type: GET_EXERCISE_SKB,
                    payload: data
                });
            });
    };
};

export const addExercise = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(id)
            .collection('exercise')
            .doc()
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan SKB berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan SKB gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editExercise = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(id)
            .collection('exercise')
            .doc(doc_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteExercise = (parent, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('exercise')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_SKB,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const addQuestionLatihan = (params, parent, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('exercise')
            .doc(id)
            .collection('question')
            .doc()
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editQuestionExercise = (params, parent, id, question_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('exercise')
            .doc(id)
            .collection('question')
            .doc(question_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_SKB,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteQuestionExercise = (parent, doc_id, qt_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('exercise')
            .doc(doc_id)
            .collection('question')
            .doc(qt_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_SKB,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getDetailSKB = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(kode)
            .get()
            .then((res) => {
                if (res.exists) {
                    dispatch({
                        type: GET_DETAIL_SKB,
                        payload: res.data()
                    });
                } else {
                    dispatch({
                        type: GET_DETAIL_SKB,
                        payload: undefined
                    });
                }
            });
    };
};

export const getTryoutSKB = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(kode)
            .collection('tryout')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    item.created_at = item.created_at.toDate().toString();
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_SKB,
                    payload: data
                });
            });
    };
};

export const beliSKB = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return axios
        .post(`${process.env.REACT_APP_API_URL}scpns/skb/pay`, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((error) => {
            console.log('eerrrrrrr', error);
            return Promise.reject(error);
        });
};

export const beliSKBBundle = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return axios
        .post(`${process.env.REACT_APP_API_URL}scpns/bundle/pay`, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getMyTryoutSKB = () => {
    var uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        let aa = [];
        firebaseAuthService.firestore
            .collection('users_tryout')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'skb')
            .get()
            .then(function (doc) {
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                for (let i = 0; i < data.length; i++) {
                    data[i].tryout.get().then(function (a) {
                        let my = a.data();
                        my.tryout_id = data[i].tryout_id;
                        my.parent_id = data[i].parent_id;
                        my.doc_id = data[i].doc_id;
                        aa.push(my);
                        dispatch({
                            type: GET_MY_TRYOUT,
                            payload: aa
                        });
                    });
                }
            });
    };
};

export const getMyTryoutSKBBundle = () => {
    var uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        firebaseAuthService.firestore
            .collection('users_tryout')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'bundle_skb')
            .get()
            .then(function (doc) {
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_TRYOUT_BUNDLE,
                    payload: data
                });
            })
            .catch(function (error) {
                console.log('Error getting documents: ', error);
            });
    };
};

export const getMyTryoutSKBBundleDetail = (id_tryout) => {
    var uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_tryout')
            .where('buy_from', '==', 'sub_bundle_skb')
            .where('id_user', '==', uid)
            .where('parent_id', '==', id_tryout)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_TRYOUT_BUNDLE_DETAIL,
                    payload: data
                });
            })
            .catch(function (error) {
                console.log('Error getting documents: ', error);
            });
    };
};

export const getDetailTryout = (id_tryout, parent_id) => {
    return async (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent_id)
            .collection('tryout')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    item.parent_id = as.parent_id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_TRYOUT_DETAIL,
                    payload: data.filter((item) => item.doc_id == id_tryout)
                });
            });
    };
};

export const getDetailExercise = (id_ex, parent_id) => {
    return async (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent_id)
            .collection('exercise')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    item.parent_id = as.parent_id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_EXERCISE_DETAIL,
                    payload: data.filter((item) => item.doc_id == id_ex)
                });
            });
    };
};

export const getMyExerciseSKB = () => {
    var uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise')
            .where('id_user', '==', uid)
            .where('buy_from', '==', 'skb')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MY_EXERCISE,
                    payload: data
                });
            });
    };
};

// Fetch users Materi
export const getUsersMateri = () => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_materi')
            .where('id_user', '==', uid)
            .where('type', '==', 'skb')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_USERS_MATERI,
                    payload: data
                });
            });
    };
};

// export const getMateriDetail = (parent, id) => {
// 	return async (dispatch) => {
// 		firebaseAuthService.firestore
// 			.collection("formasi_skb")
// 			.doc(parent)
// 			.collection("materi")
// 			.doc(id)
// 			.get()
// 			.then((res) => {
// 				let data = res.data();
// 				data.doc_id = id;
// 				dispatch({
// 					type: GET_MATERI_SKB_DETAIL,
// 					payload: data,
// 				});
// 			});
// 	};
// };

export const getMateriDetail = (id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_materi')
            .doc(id)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = id;
                dispatch({
                    type: GET_MATERI_SKB_DETAIL,
                    payload: data
                });
            });
    };
};

// Fetch users Materi
export const getUsersSP = () => {
    const uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_soal_pembahasan')
            .where('id_user', '==', uid)
            .where('type', '==', 'skb')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_USERS_SP,
                    payload: data
                });
            });
    };
};

export const getSPDetail = (id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_soal_pembahasan')
            .doc(id)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = id;
                dispatch({
                    type: GET_SP_SKB_DETAIL,
                    payload: data
                });
            });
    };
};
