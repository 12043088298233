import { Card } from '@material-ui/core';
import { formatRupiah } from 'app/redux/actions/UserActions';
import React from 'react';

const CardBerandaBuku = ({ image, title, price }) => {
    return (
        <Card elevation={2} className={`px-4 py-3 flex bg-white h-full`}>
            {image && (
                <img
                    src={image}
                    alt='overview'
                    style={{
                        width: '80px',
                        height: '110px',
                        objectFit: 'cover',
                        borderRadius: 4
                    }}
                />
            )}
            <div className='ml-2'>
                <h6 className='mb-2'>{title}</h6>
                <p className='m-0'>{formatRupiah(price)}</p>
            </div>
        </Card>
    );
};

export default CardBerandaBuku;
