import {
    Button,
    Dialog,
    Grid,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { updatePG } from 'app/redux/actions/PassingGradeActions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class EditPG extends Component {
    state = {
        uid: '',
        discount: '',
        description: '',
        kode_voucher: '',
        statusButton: false,
        buttonText: 'Update',
        isActive: false,
        min_correct: 0,
        total_soal: 0,
        tiu: 0,
        tkp: 0,
        twk: 0,
        doc_id: '',
        dataPPPK: [],
        form_answer: {
            name: '',
            passing_grade: 0,
            index: '',
            isNew: true,
            error: ''
        }
    };

    handleFormSubmit = async () => {
        this.setState({
            statusButton: true,
            buttonText: 'Loading...'
        });
        let { min_correct, total_soal, tiu, tkp, twk, doc_id, dataPPPK } =
            this.state;
        let { type } = this.props;
        let params = {};
        if (type == 'SKB' || type == 'UK') {
            params = {
                min_correct: min_correct,
                total_soal: total_soal
            };
        } else if (type == 'SKD') {
            params = {
                tiu: tiu,
                tkp: tkp,
                twk: twk
            };
        } else if (type == 'PPPK' || type == 'PPPK Guru') {
            params = {
                data: dataPPPK
            };
        } else if (type == 'BUMN' || type == 'UTBK') {
            params = {
                data: dataPPPK
            };
        }
        await this.props.updatePG(params, doc_id);
        await this.props.handleClose();
    };

    handleChange = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    componentWillMount() {
        let { uid } = this.props;
        this.setState({
            min_correct: uid.min_correct,
            total_soal: uid.total_soal,
            tiu: uid.tiu,
            tkp: uid.tkp,
            twk: uid.twk,
            doc_id: uid.doc_id,
            dataPPPK: uid.data
        });
    }

    handleEditAnswer = (item, index) => {
        let name = item.name;
        let passing_grade = item.passing_grade;
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                name: name,
                passing_grade: passing_grade,
                isNew: false,
                index: index
            }
        }));
        window.scrollTo(0, 0);
    };

    handleDeleteAnswer = (idx) => {
        const { dataPPPK } = this.state;
        let allAnswer = dataPPPK.filter((item, index) => index != idx);
        this.setState({
            dataPPPK: allAnswer
        });
    };

    handleChangeTextOpt = (event) => {
        let value = event.target.value;
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                name: value
            }
        }));
    };

    changeValueAnswer = (event) => {
        let value = event.target.value;
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                passing_grade: value
            }
        }));
    };

    handleFormAnswer = () => {
        const {
            form_answer: { passing_grade, isNew, index, name },
            dataPPPK
        } = this.state;
        let check = true;
        if (name.length <= 0 || passing_grade.length <= 0) {
            check = false;
        }
        if (check) {
            if (isNew) {
                let allAnswer = dataPPPK.concat({
                    passing_grade: passing_grade,
                    name: name,
                    id: name.replaceAll(' ', '_').toLowerCase()
                });
                this.setState({
                    dataPPPK: allAnswer,
                    form_answer: {
                        passing_grade: 0,
                        name: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            } else {
                let allAnswer = dataPPPK;
                allAnswer[index] = {
                    passing_grade: passing_grade,
                    name: name,
                    id: name.replace(' ', '_').toLowerCase()
                };
                this.setState({
                    dataPPPK: allAnswer,
                    form_answer: {
                        passing_grade: 0,
                        name: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            }
        } else {
            this.setState((prefState) => ({
                form_answer: {
                    ...prefState.form_answer,
                    error: 'Tolong isi semua inputan'
                }
            }));
        }
    };

    render() {
        let { min_correct, total_soal, tiu, tkp, twk, dataPPPK, form_answer } =
            this.state;
        let { open, handleClose, type } = this.props;
        let max = type == 'PPPK' ? 900 : '';
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Edit Passing Grade {type}</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid className='mb-4' container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                {type == 'SKB' || type == 'UK' ? (
                                    <>
                                        <TextValidator
                                            className='mb-5'
                                            label='Minimum Benar *'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='min_correct'
                                            value={min_correct}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                            style={{ width: '100%' }}
                                        />
                                        <TextValidator
                                            className='mb-5'
                                            label='Total Soal *'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='total_soal'
                                            value={total_soal}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                            style={{ width: '100%' }}
                                        />
                                    </>
                                ) : (
                                    ''
                                )}
                                {type == 'SKD' ? (
                                    <>
                                        <TextValidator
                                            className='mb-5'
                                            label='Minimum TIU *'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='tiu'
                                            value={tiu}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                            style={{ width: '100%' }}
                                        />
                                        <TextValidator
                                            className='mb-5'
                                            label='Minimum TWK *'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='twk'
                                            value={twk}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                            style={{ width: '100%' }}
                                        />
                                        <TextValidator
                                            className='mb-5'
                                            label='Minimum TKP *'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='tkp'
                                            value={tkp}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                            style={{ width: '100%' }}
                                        />
                                    </>
                                ) : (
                                    ''
                                )}
                                {type == 'PPPK' ? (
                                    <>
                                        <Grid spacing={2}>
                                            <Grid item sm={12} xs={12}>
                                                <h5 className='mt-0'>
                                                    {form_answer.isNew
                                                        ? 'Tambah Option'
                                                        : 'Edit Option'}
                                                </h5>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className='mb-3'
                                                >
                                                    <Grid item sm={6} xs={12}>
                                                        <TextValidator
                                                            className='w-full'
                                                            label='Nama Kategori'
                                                            onChange={
                                                                this
                                                                    .handleChangeTextOpt
                                                            }
                                                            type='text'
                                                            name='name'
                                                            value={
                                                                form_answer.name
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <TextValidator
                                                            className='w-full'
                                                            label='Passing Grade'
                                                            onChange={
                                                                this
                                                                    .changeValueAnswer
                                                            }
                                                            type='number'
                                                            name='passing_grade'
                                                            value={
                                                                form_answer.passing_grade
                                                            }
                                                            validators={[
                                                                'required'
                                                            ]}
                                                            errorMessages={[
                                                                'Field is Required'
                                                            ]}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {form_answer.error.length >
                                                0 ? (
                                                    <small className='text-error'>
                                                        {form_answer.error}
                                                    </small>
                                                ) : null}
                                                <div className='flex items-center justify-between'>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={
                                                            this
                                                                .handleFormAnswer
                                                        }
                                                        className='elevation-z0 mt-1 w-full max-w-195'
                                                        type='button'
                                                    >
                                                        {form_answer.isNew
                                                            ? 'Tambah Kategori'
                                                            : 'Edit Kategori'}
                                                    </Button>
                                                </div>
                                            </Grid>
                                            <h5 className='mt-10 mb-5'>
                                                Passing Grade PPPK
                                            </h5>
                                            <Grid item sm={12} xs={12}>
                                                <div className='w-full overflow-auto bg-white'>
                                                    <Table className='crud-table'>
                                                        <TableHead className='bg-primary'>
                                                            <TableRow>
                                                                <TableCell
                                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                    align='center'
                                                                >
                                                                    Nama
                                                                    Kategori
                                                                </TableCell>
                                                                {/* <TableCell
                                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                    align='center'
                                                                >
                                                                    Passing
                                                                    Grade
                                                                </TableCell> */}
                                                                <TableCell
                                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                    align='center'
                                                                ></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {dataPPPK.length >
                                                            0 ? (
                                                                dataPPPK.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <TableRow
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <TableCell
                                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                                align='center'
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </TableCell>
                                                                            {/* <TableCell
                                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                                align='center'
                                                                            >
                                                                                {
                                                                                    item.passing_grade
                                                                                }
                                                                            </TableCell> */}
                                                                            <TableCell
                                                                                className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                                align='center'
                                                                            >
                                                                                <IconButton
                                                                                    title='edit'
                                                                                    onClick={() =>
                                                                                        this.handleEditAnswer(
                                                                                            item,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Icon color='primary'>
                                                                                        border_color
                                                                                    </Icon>
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    title='delete'
                                                                                    onClick={() =>
                                                                                        this.handleDeleteAnswer(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Icon color='error'>
                                                                                        delete
                                                                                    </Icon>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-bold'
                                                                        align='center'
                                                                        colSpan={
                                                                            5
                                                                        }
                                                                    >
                                                                        Tidak
                                                                        ada
                                                                        passing
                                                                        grade
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    ''
                                )}
                                {type == 'PPPK Guru' ? (
                                    <>
                                        <Grid spacing={2}>
                                            <Grid item sm={12} xs={12}>
                                                <h5 className='mt-0'>
                                                    {form_answer.isNew
                                                        ? 'Tambah Option'
                                                        : 'Edit Option'}
                                                </h5>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className='mb-3'
                                                >
                                                    <Grid item sm={6} xs={12}>
                                                        <TextValidator
                                                            className='w-full'
                                                            label='Nama Kategori'
                                                            onChange={
                                                                this
                                                                    .handleChangeTextOpt
                                                            }
                                                            type='text'
                                                            name='name'
                                                            value={
                                                                form_answer.name
                                                            }
                                                        />
                                                    </Grid>
                                                    {/* <Grid item sm={6} xs={12}>
                                                        <TextValidator
                                                            className='w-full'
                                                            label='Passing Grade'
                                                            onChange={
                                                                this
                                                                    .changeValueAnswer
                                                            }
                                                            type='number'
                                                            name='passing_grade'
                                                            value={
                                                                form_answer.passing_grade
                                                            }
                                                            validators={[
                                                                'required'
                                                            ]}
                                                            errorMessages={[
                                                                'Field is Required'
                                                            ]}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                                {form_answer.error.length >
                                                0 ? (
                                                    <small className='text-error'>
                                                        {form_answer.error}
                                                    </small>
                                                ) : null}
                                                <div className='flex items-center justify-between'>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={
                                                            this
                                                                .handleFormAnswer
                                                        }
                                                        className='elevation-z0 mt-1 w-full max-w-195'
                                                        type='button'
                                                    >
                                                        {form_answer.isNew
                                                            ? 'Tambah Kategori'
                                                            : 'Edit Kategori'}
                                                    </Button>
                                                </div>
                                            </Grid>
                                            <h5 className='mt-10 mb-5'>
                                                Passing Grade PPPK
                                            </h5>
                                            <Grid item sm={12} xs={12}>
                                                <div className='w-full overflow-auto bg-white'>
                                                    <Table className='crud-table'>
                                                        <TableHead className='bg-primary'>
                                                            <TableRow>
                                                                <TableCell
                                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                    align='center'
                                                                >
                                                                    Nama
                                                                    Kategori
                                                                </TableCell>
                                                                {/* <TableCell
                                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                    align='center'
                                                                >
                                                                    Passing
                                                                    Grade
                                                                </TableCell> */}
                                                                <TableCell
                                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                    align='center'
                                                                ></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {dataPPPK.length >
                                                            0 ? (
                                                                dataPPPK.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <TableRow
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <TableCell
                                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                                align='center'
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </TableCell>
                                                                            {/* <TableCell
                                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                                align='center'
                                                                            >
                                                                                {
                                                                                    item.passing_grade
                                                                                }
                                                                            </TableCell> */}
                                                                            <TableCell
                                                                                className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                                align='center'
                                                                            >
                                                                                <IconButton
                                                                                    title='edit'
                                                                                    onClick={() =>
                                                                                        this.handleEditAnswer(
                                                                                            item,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Icon color='primary'>
                                                                                        border_color
                                                                                    </Icon>
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    title='delete'
                                                                                    onClick={() =>
                                                                                        this.handleDeleteAnswer(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Icon color='error'>
                                                                                        delete
                                                                                    </Icon>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-bold'
                                                                        align='center'
                                                                        colSpan={
                                                                            5
                                                                        }
                                                                    >
                                                                        Tidak
                                                                        ada
                                                                        passing
                                                                        grade
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    ''
                                )}
                                {type == 'BUMN' ? (
                                    <>
                                        <Grid spacing={2}>
                                            <Grid item sm={12} xs={12}>
                                                <h5 className='mt-0'>
                                                    {form_answer.isNew
                                                        ? 'Tambah Option'
                                                        : 'Edit Option'}
                                                </h5>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className='mb-3'
                                                >
                                                    <Grid item sm={6} xs={12}>
                                                        <TextValidator
                                                            className='w-full'
                                                            label='Nama Kategori'
                                                            onChange={
                                                                this
                                                                    .handleChangeTextOpt
                                                            }
                                                            type='text'
                                                            name='name'
                                                            value={
                                                                form_answer.name
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <TextValidator
                                                            className='w-full'
                                                            label='Passing Grade'
                                                            onChange={
                                                                this
                                                                    .changeValueAnswer
                                                            }
                                                            type='number'
                                                            name='passing_grade'
                                                            value={
                                                                form_answer.passing_grade
                                                            }
                                                            validators={[
                                                                'required'
                                                            ]}
                                                            errorMessages={[
                                                                'Field is Required'
                                                            ]}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {form_answer.error.length >
                                                0 ? (
                                                    <small className='text-error'>
                                                        {form_answer.error}
                                                    </small>
                                                ) : null}
                                                <div className='flex items-center justify-between'>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={
                                                            this
                                                                .handleFormAnswer
                                                        }
                                                        className='elevation-z0 mt-1 w-full max-w-195'
                                                        type='button'
                                                    >
                                                        {form_answer.isNew
                                                            ? 'Tambah Kategori'
                                                            : 'Edit Kategori'}
                                                    </Button>
                                                </div>
                                            </Grid>
                                            <h5 className='mt-10 mb-5'>
                                                Passing Grade BUMN
                                            </h5>
                                            <Grid item sm={12} xs={12}>
                                                <div className='w-full overflow-auto bg-white'>
                                                    <Table className='crud-table'>
                                                        <TableHead className='bg-primary'>
                                                            <TableRow>
                                                                <TableCell
                                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                    align='center'
                                                                >
                                                                    Nama
                                                                    Kategori
                                                                </TableCell>
                                                                {/* <TableCell
                                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                    align='center'
                                                                >
                                                                    Passing
                                                                    Grade
                                                                </TableCell> */}
                                                                <TableCell
                                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                    align='center'
                                                                ></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {dataPPPK.length >
                                                            0 ? (
                                                                dataPPPK.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <TableRow
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <TableCell
                                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                                align='center'
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </TableCell>
                                                                            {/* <TableCell
                                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                                align='center'
                                                                            >
                                                                                {
                                                                                    item.passing_grade
                                                                                }
                                                                            </TableCell> */}
                                                                            <TableCell
                                                                                className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                                align='center'
                                                                            >
                                                                                <IconButton
                                                                                    title='edit'
                                                                                    onClick={() =>
                                                                                        this.handleEditAnswer(
                                                                                            item,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Icon color='primary'>
                                                                                        border_color
                                                                                    </Icon>
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    title='delete'
                                                                                    onClick={() =>
                                                                                        this.handleDeleteAnswer(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Icon color='error'>
                                                                                        delete
                                                                                    </Icon>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-bold'
                                                                        align='center'
                                                                        colSpan={
                                                                            5
                                                                        }
                                                                    >
                                                                        Tidak
                                                                        ada
                                                                        passing
                                                                        grade
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    ''
                                )}
                            </Grid>
                        </Grid>

                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={this.state.statusButton}
                                color='primary'
                                type='submit'
                            >
                                {this.state.buttonText}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    updatePG: PropTypes.func.isRequired
    // getAllProyecto: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { updatePG })(EditPG);
