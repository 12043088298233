import { combineReducers } from 'redux';

import AdminReducer from './AdminReducer';
import BimbelReducer from './BimbelReducer';
import BukuReducer from './BukuReducer';
import DashboardReducer from './DashboardReducer';
import EcommerceReducer from './EcommerceReducer';
import GambarReducer from './GambarReducer';
import GroupReducer from './GroupReducer';
import HasilSKDReducer from './HasilSKDReducer';
import InformasiReducer from './InformasiReducer';
import InvoiceReducer from './InvoiceReducer';
import KeranjangReducer from './KeranjangReducer';
import KerjakanLatihanSKBReducers from './KerjakanLatihanSKBReducers';
import KerjakanLatihanUKReducers from './KerjakanLatihanUKReducers';
import KerjakanPPPKReducers from './KerjakanPPPKReducers';
import KerjakanSKBReducers from './KerjakanSKBReducers';
import KerjakanSKDReducers from './KerjakanSKDReducers';
import KerjakanUKReducers from './KerjakanUKReducers';
import KomisiReducer from './KomisiReducer';
import LaporanReducer from './LaporanReducer';
import LayoutReducer from './LayoutReducer';
import LoginReducer from './LoginReducer';
import ManagementUserReducer from './ManagementUserReducer';
import NavigationReducer from './NavigationReducer';
import NotificationReducer from './NotificationReducer';
import PassingGradeReducer from './PassingGradeReducer';
import PembahasanPPPKReducer from './PembahasanPPPKReducer';
import PembahasanSKDReducer from './PembahasanSKDReducer';
import BumnReducer from './BumnReducer';
import PPPKReducer from './PPPKReducer';
import PromoReducer from './PromoReducer';
import ScrumBoardReducer from './ScrumBoardReducer';
import SKBReducer from './SKBReducer';
import SKDReducer from './SKDReducer';
import UKReducer from './UKReducer';
import UserPaketReducer from './UserPaketReducer';
import UserReducer from './UserReducer';
import UserTryoutReducer from './UserTryoutReducer';
import VoucherReducer from './VoucherReducer';
import WebSettingReducer from './WebSettingReducer';
import PembahasanBumnReducer from './PembahasanBumnReducer';
import KerjakanBumnReducers from './KerjakanBumnReducers';
import BatchReducer from './BatchReducer';
import TahunToPremiumReducer from './TahunToPremiumReducer';
import TahunToPPPKBimbelReducer from './TahunToPPPKBimbelReducer';
import SKDinfoReducer from './SKDinfoReducer';
import BundleSKDReducer from './BundleSKDReducer';
import EventSKDReducer from './EventSKDReducer';
import SoalTryOutSKDReducer from './SoalTryoutSKDReducer';
import SoalTryOutEventReducer from './SoalTryOutEventReducer';
import NewsReducer from './NewsReducer';
import TryoutBundleReducer from './TryoutBundleReducer';
import NotifikasiReducer from './NotifikasiReducer';

const RootReducer = combineReducers({
    login: LoginReducer,
    user: UserReducer,
    bumn: BumnReducer,
    skd: SKDReducer,
    layout: LayoutReducer,
    scrumboard: ScrumBoardReducer,
    notifications: NotificationReducer,
    ecommerce: EcommerceReducer,
    navigations: NavigationReducer,
    dashboard: DashboardReducer,
    skd: SKDReducer,
    invoice: InvoiceReducer,
    skb: SKBReducer,
    bimbel: BimbelReducer,
    keranjang: KeranjangReducer,
    buku: BukuReducer,
    pppk: PPPKReducer,
    uk: UKReducer,
    userPaket: UserPaketReducer,
    userTryout: UserTryoutReducer,
    kerjakanSKD: KerjakanSKDReducers,
    kerjakanSKB: KerjakanSKBReducers,
    kerjakanPPPK: KerjakanPPPKReducers,
    kerjakanBUMN: KerjakanBumnReducers,
    kerjakanUK: KerjakanUKReducers,
    kerjakanLatUK: KerjakanLatihanUKReducers,
    kerjakanLatSKB: KerjakanLatihanSKBReducers,
    hasilSKD: HasilSKDReducer,
    passingGrade: PassingGradeReducer,
    login: LoginReducer,
    user: UserReducer,
    skd: SKDReducer,
    laporanSoal: LaporanReducer,
    layout: LayoutReducer,
    scrumboard: ScrumBoardReducer,
    notifications: NotificationReducer,
    ecommerce: EcommerceReducer,
    navigations: NavigationReducer,
    dashboard: DashboardReducer,
    skd: SKDReducer,
    invoice: InvoiceReducer,
    skb: SKBReducer,
    bimbel: BimbelReducer,
    keranjang: KeranjangReducer,
    buku: BukuReducer,
    pppk: PPPKReducer,
    uk: UKReducer,
    userPaket: UserPaketReducer,
    userTryout: UserTryoutReducer,
    kerjakanSKD: KerjakanSKDReducers,
    kerjakanPPPK: KerjakanPPPKReducers,
    kerjakanUK: KerjakanUKReducers,
    kerjakanLatUK: KerjakanLatihanUKReducers,
    hasilSKD: HasilSKDReducer,
    passingGrade: PassingGradeReducer,
    pembahasanSKD: PembahasanSKDReducer,
    pembahasanPPPK: PembahasanPPPKReducer,
    pembahasanBumn: PembahasanBumnReducer,
    kerjakanSKB: KerjakanSKBReducers,
    admin: AdminReducer,
    managementUser: ManagementUserReducer,
    voucher: VoucherReducer,
    batch: BatchReducer,
    gambar: GambarReducer,
    link: GroupReducer,
    komisi: KomisiReducer,
    webSetting: WebSettingReducer,
    newsPromo: PromoReducer,
    newsInformasi: NewsReducer,
    notifikasiInformasi: NotifikasiReducer,
    informasi: InformasiReducer,
    tahunTOPremium: TahunToPremiumReducer,
    tahunToPPPKBimbel: TahunToPPPKBimbelReducer,
    SKDinfo: SKDinfoReducer,
    TryoutBundle: TryoutBundleReducer,
    BundleSKD: BundleSKDReducer,
    EventTryout: EventSKDReducer,
    SoalTryoutSKD: SoalTryOutSKDReducer,
    SoalTryoutEvent: SoalTryOutEventReducer
});

export default RootReducer;
