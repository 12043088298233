import AppContext from 'app/appContext';
import { MatxLoading } from 'matx';
import React, { useContext, useEffect, useState } from 'react';
import Scrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import FirebaseAuthService from '../services/firebase/firebaseAuthService';

const getAuthStatus = () => {
    let check = FirebaseAuthService.auth.currentUser;
    return check;
};

const AuthGuard = ({ component: Component, isPrivate = true, ...rest }) => {
    const [previouseRoute, setPreviousRoute] = useState(null);

    const { routes } = useContext(AppContext);
    const { pathname } = useLocation();
    const {
        user,
        login: { loading, success, signup }
    } = useSelector((state) => state);

    let authenticated = getAuthStatus(pathname, user, routes);

    useEffect(() => {
        setPreviousRoute(pathname);
    }, []);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (loading && isPrivate) return <MatxLoading />;
                else if (
                    typeof user.emailVerified == 'boolean' &&
                    user.emailVerified == true &&
                    pathname == '/verifikasi_email'
                )
                    return (
                        <Redirect
                            to={{
                                pathname: '/dashboard',
                                state: { redirectUrl: previouseRoute }
                            }}
                        />
                    );
                else if (
                    !signup &&
                    pathname != '/session/404' &&
                    pathname != '/verifikasi_email' &&
                    authenticated != null &&
                    !isPrivate
                )
                    return (
                        <Redirect
                            to={{
                                pathname: '/dashboard',
                                state: { redirectUrl: previouseRoute }
                            }}
                        />
                    );
                else if (authenticated || !isPrivate) {
                    if (pathname == '/session/signup') {
                        return <Component {...props} />;
                    } else {
                        return (
                            <Scrollbar
                                className='h-full-screen'
                                options={{ suppressScrollX: true }}
                            >
                                <Component {...props} />
                            </Scrollbar>
                        );
                    }
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/session/signin',
                                state: { redirectUrl: previouseRoute }
                            }}
                        />
                    );
                }
            }}
        />
    );
};

export default AuthGuard;
