import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import { updateDefaultBatch, getDefaultBatch } from 'app/redux/actions/BatchActions';
import React, { Component } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class ModalChangeBatch extends Component {
    constructor() {
        super();
        this.state = {
            category: '',
            name: ''
        };
    }

    handleChange = (event) => {
        const { batch } = this.props;

        const clickedOption = batch.find(
            (item) => item.id === event.target.value
        );
        this.setState({
            [event.target.name]: event.target.value,
            name: clickedOption.title
        });
    };

    submitForm = async () => {
        let { category, name } = this.state;
        const {
            addCategory,
            handleClose,
            batchDefault,
            batch,
            getDefaultBatch,
            updateDefaultBatch
        } = this.props;
        let params = {
            batch: category,
            name: name,
            komisi:'16'
        };
        await updateDefaultBatch(params);
        getDefaultBatch();
        setTimeout(() => {
            handleClose();
        }, 1500);
    };

    render() {
        let { category, name } = this.state;
        let { open, handleClose, loadingBimbel, batchDefault, batch } =
            this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth='sm'
                fullWidth={true}
            >
                <div className='p-5'>
                    <h4 className='mb-5'>Ubah Default Batch</h4>
                    <ValidatorForm ref='form' onSubmit={this.submitForm}>
                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Batch'
                                    onChange={this.handleChange}
                                    name='category'
                                    value={category || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    {batch.length > 0 ? (
                                        batch.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item.id}
                                            >
                                                {item.title}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </SelectValidator>
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle mt-3'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                {loadingBimbel ? (
                                    <CircularProgress />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Batal</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        batch: state.batch.data,
        loadingBimbel: state.bimbel.loading,
        batchDefault: state.webSetting
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateDefaultBatch: (params) => dispatch(updateDefaultBatch(params)),
        getDefaultBatch: () => dispatch(getDefaultBatch())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangeBatch);
