import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ModalChangeEmail } from 'app/components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { changeEmail, logoutUser } from '../../redux/actions/UserActions';
import firebaseAuthService from '../../services/firebase/firebaseAuthService';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    wrapper: {
        width: '100%',
        height: '100vh'
    },
    inner: {
        flexDirection: 'column',
        maxWidth: '320px'
    }
}));

const VerifikasiEmail = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { user } = useSelector((user) => user);
    const { login } = useSelector((login) => login);
    const [Countdown, setCountdown] = useState(false);
    const [Modal, setModal] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [LoadingCheck, setLoadingCheck] = useState(false);
    const [Email, setEmail] = useState('');

    useEffect(() => {
        let last_verification = localStorage.getItem('last_verification');
        if (last_verification) {
            handleCountdown();
        } else {
            setCountdown(true);
        }
    }, []);

    const handleCountdown = () => {
        let last_verification = localStorage.getItem('last_verification');
        var countDownDate =
            new Date(last_verification?.replace(' ', 'T')).getTime() +
            5 * 60000;
        var x = setInterval(function () {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setCountdown(minutes + ':' + seconds);
            if (distance < 0) {
                clearInterval(x);
                setCountdown(true);
            }
        }, 1000);
    };

    const handleKirimUlang = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (Countdown == true) {
            firebaseAuthService.auth.currentUser.sendEmailVerification();
            localStorage.setItem('last_verification', new Date());
            handleCountdown();
        }
    };

    const handleCheckUser = async () => {
        window.location.reload();
    };

    const handleModal = (e) => {
        if (e) {
            e.preventDefault();
        }
        setModal(!Modal);
        setEmail('');
    };

    const handleSubmit = async () => {
        await setLoading(true);
        const params = {
            email: Email
        };
        changeEmail(params)
            .then(async (res) => {
                setLoading(false);
                await setModal(false);
                await Swal.fire({
                    title: 'Email berhasil diganti',
                    text: 'Kamu harus login ulang menggunakan email terbaru.',
                    icon: 'success',
                    timer: 3000,
                    showConfirmButton: false
                });
                handleLogout();
            })
            .catch((err) => {
                setLoading(false);
                localStorage.setItem('last_verification', new Date());
                handleCountdown();
                Swal.fire({
                    title: 'Email gagal diganti',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
            });
    };

    const handleChange = ({ target: { name, value } }) => {
        setEmail(value);
    };

    const handleLogout = () => {
        dispatch(logoutUser());
    };
    return (
        <div className='flex justify-center items-center h-full-screen w-full'>
            <div className='flex-column justify-center items-center max-w-450 px-4'>
                {login.loading ? (
                    <p>loading...</p>
                ) : (
                    <>
                        <img
                            className='mb-8 max-h-300'
                            src='/assets/images/illustrations/verifikasi_email.svg'
                            alt=''
                        />
                        <h5 className='font-bold text-center'>
                            Verifikasi email Kamu.
                        </h5>
                        <p className='max-w-450 text-center'>
                            Hai {user.name}, Kami sudah mengirim email
                            verifikasi ke {user.email}. Kamu perlu verifikasi
                            email terlebih dahulu.{' '}
                            <span className='text-primary'>
                                {typeof Countdown === 'string'
                                    ? 'Kirim ulang dalam ' + Countdown + ' '
                                    : ''}
                            </span>
                            {Countdown == true ? (
                                <a
                                    href='#'
                                    onClick={handleKirimUlang}
                                    className='text-primary text-underline'
                                >
                                    Kirim ulang
                                </a>
                            ) : null}{' '}
                            <a
                                href='#'
                                onClick={handleModal}
                                className='text-primary text-underline'
                            >
                                Ganti Email
                            </a>
                        </p>
                        <div className='flex items-center'>
                            <Button
                                className='capitalize elevation-z0 '
                                variant='contained'
                                color='default'
                                onClick={handleLogout}
                            >
                                Keluar
                            </Button>
                            <Button
                                className={`capitalize ml-2 ${
                                    LoadingCheck ? 'button-purple opacity' : ''
                                }`}
                                disabled={LoadingCheck}
                                variant='contained'
                                color='primary'
                                onClick={handleCheckUser}
                            >
                                {LoadingCheck ? (
                                    <CircularProgress
                                        color='primary'
                                        size={24}
                                    />
                                ) : (
                                    'Sudah'
                                )}
                            </Button>
                        </div>
                        <ModalChangeEmail
                            handleClose={handleModal}
                            modal={Modal}
                            handleSubmit={handleSubmit}
                            handleChange={handleChange}
                            email={Email}
                            loading={Loading}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default VerifikasiEmail;
