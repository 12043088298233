import {
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Button,
    Box,
    FormControl
} from '@material-ui/core';
import { formatRupiah } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalEditPaket from './ModalEditPaket';
import { getPaketEventSKD } from 'app/redux/actions/EventSKDActions';
import CardTable from './Component/CardTable';
import AddTryoutEventDialog from './Component/AddTryoutEventDialog';

class EventSKDTable extends Component {
    constructor() {
        super();
        this.state = {
            rowsPerPage: 10,
            page: 0,
            shouldOpenEditorDialog: false,
            shouldOpenCreateDialog: false,
            shouldOpenConfirmationDialog: false,
            itemToEdit: null,
            query: ''
        };
    }

    componentDidMount() {
        const { getPaketEvent } = this.props;
        getPaketEvent();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleDetail = (doc_id) => {
        const { history } = this.props;
        history.push('/skd/' + doc_id);
    };
    handleTambahPaket = () => {
        this.setState({
            shouldOpenCreateDialog: true
        });
    };
    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenCreateDialog: false
        });
        // this.updatePageData();
    };

    handleEdit = (item) => {
        this.setState({
            shouldOpenEditorDialog: true,
            itemToEdit: item
        });
    };

    handleCloseEdit = () => {
        this.setState({
            shouldOpenEditorDialog: false
        });
    };

    render() {
        const {
            query,
            shouldOpenCreateDialog,
            shouldOpenEditorDialog,
            itemToEdit
        } = this.state;
        const { eventPaket } = this.props;
        return (
            <div className='m-sm-30'>
                <h2 style={{ fontSize: '25px' }} className='mb-3'>
                    List Paket Event Tryout
                </h2>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button
                        variant='outlined'
                        color='primary'
                        className='mb-5'
                        onClick={this.handleTambahPaket}
                        title='Edit'
                    >
                        Tambah
                    </Button>
                    <FormControl
                        className='faqDesc'
                        style={{
                            boxShadow: '0px 0px 2px #43434359',
                            marginBottom: '15px',
                            borderRadius: '5px',
                            maxHeight: '45px'
                        }}
                    >
                        <Box
                            component='fieldset'
                            borderColor='transparent'
                            style={{ paddingLeft: '0px' }}
                            className='flex'
                        >
                            <IconButton title='Cari' style={{ padding: '5px' }}>
                                <Icon color='action'>search</Icon>
                            </IconButton>
                            <input
                                onChange={this.handleSearch}
                                placeholder='Cari..'
                                name='query'
                                value={query}
                                style={{
                                    minHeight: '25px',
                                    border: '0px',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    outline: 0
                                }}
                            ></input>
                        </Box>
                    </FormControl>
                </div>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <CardTable
                            data={eventPaket}
                            handleModalEdit={this.handleEdit}
                            query={query}
                        />
                        {shouldOpenEditorDialog && (
                            <ModalEditPaket
                                params={itemToEdit}
                                handleClose={this.handleCloseEdit}
                                open={shouldOpenEditorDialog}
                            />
                        )}
                        {shouldOpenCreateDialog && (
                            <AddTryoutEventDialog
                                handleClose={this.handleDialogClose}
                                open={shouldOpenCreateDialog}
                            />
                        )}
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventPaket: state.EventTryout.tryout
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPaketEvent: () => dispatch(getPaketEventSKD())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EventSKDTable);
