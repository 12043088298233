import { GET_ALL_ADMIN } from '../actions/AdminActions';

const initialState = {
	data: [],
};

const AdminReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_ALL_ADMIN: {
			return {
				data: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};

export default AdminReducer;
