import { GET_GAMBAR, LOADING } from '../actions/GambarActions';

const initialState = {
    data: [],
    loading: false
};

const GambarReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case GET_GAMBAR: {
            return {
                ...state,
                data: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default GambarReducer;
