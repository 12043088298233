import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import { addLatihanPPPK } from 'app/redux/actions/PPPKActions';
import { getAllPasingGrade } from 'app/redux/actions/PassingGradeActions';
import { getTanggal } from 'app/redux/actions/UserActions';
import React, { Component, Fragment } from 'react';
import {
    TextValidator,
    ValidatorForm,
    SelectValidator
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddLatihan extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            description: '',
            expires_time: '',
            jml_soal: '',
            title: '',
            submit: false,
            parent_id: parent,
            is_shuffle: '1',
            category: ''
        };
    }

    componentDidMount() {
        const { getAllPasingGrade } = this.props;
        getAllPasingGrade();
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
            const { handleClose, addLatihanPPPK, id } = this.props;
        const {
            description,
            expires_time,
            jml_soal,
            parent_id,
            title,
            is_shuffle,
            category
        } = this.state;
        let now = getTanggal();
        const params = {
            category: id,
            description: description,
            expires_time: expires_time,
            title: title,
            jml_soal: jml_soal,
            created_at: now,
            is_shuffle: is_shuffle,
            passing_grade: category,
            // id: id
        };
  
        handleClose();
        addLatihanPPPK(params);
        this.setState({
            submit: false
        });
    };

    render() {
        let {
            submit,
            description,
            expires_time,
            jml_soal,
            title,
            is_shuffle,
            category
        } = this.state;
        let { open, handleClose, passing_grade } = this.props;
        const dp = passing_grade.filter((item) => item.name == 'PPPK');
       
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Latihan PPPK</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    label='Nama Latihan'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={[
                                        'required',
                                        'maxStringLength:100'
                                    ]}
                                    errorMessages={[
                                        'Field is Required',
                                        'Maximum 100 Character'
                                    ]}
                                    className='w-full'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-6 w-full'
                                    label='Deskripsi Latihan'
                                    multiline
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Jumlah Soal'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='jml_soal'
                                    value={jml_soal}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Durasi (menit)'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='expires_time'
                                    value={expires_time}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Random Soal *'
                                    onChange={this.handleChange}
                                    name='is_shuffle'
                                    value={is_shuffle}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Aktif</MenuItem>
                                    <MenuItem value='0'>Nonaktif</MenuItem>
                                </SelectValidator>
                            </Grid>
                            {passing_grade.length > 0 ? (
                                <Grid item sm={6} xs={12} className='pb-0'>
                                    <SelectValidator
                                        className='mb-4 w-full'
                                        label='Kategori Tryout'
                                        onChange={this.handleChange}
                                        name='category'
                                        value={category}
                                        validators={['required']}
                                        errorMessages={['Field is Required']}
                                    >
                                        {dp[0].data.map((da) => (
                                            <MenuItem value={da.id}>
                                                {da.name}
                                            </MenuItem>
                                        ))}
                                    </SelectValidator>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        passing_grade: state.passingGrade.all
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addLatihanPPPK: (params, type) =>
            dispatch(addLatihanPPPK(params, type)),
        getAllPasingGrade: () => dispatch(getAllPasingGrade())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddLatihan);
