import {
    GET_TRYOUT_SKD_EVENT,
    RESET_REDUCER,
    DEL_TRYOUT_SKD_EVENT,
    GET_DETAIL_TRYOUT_EVENT,
    GET_SOAL_EVENT
} from '../actions/EventSKDActions';
import {
    GET_PAKET_TRYOUT,
    ADD_PAKET_TRYOUT,
    DEL_PAKET_TRYOUT,
    GET_LIST_PAKET_TRYOUT,
    GET_SOAL_TRYOUT_BUNDLE,
    GET_DETAIL_TRYOUT_BUNDLE
} from '../actions/PaketTryoutActions';

const initialState = {
    data: [],
    materi: [],
    latihan: [],
    soal: [],
    tryout: [],
    paket: [],
    detail_paket: null,
    tryout_detail: {}
};

const TryoutBundleReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_PAKET_TRYOUT: {
            return {
                ...state,
                paket: [...state.paket, action.payload]
            };
        }
        case GET_DETAIL_TRYOUT_BUNDLE: {
            return {
                ...state,
                tryout_detail: action.payload
            };
        }
        case GET_PAKET_TRYOUT: {
            return {
                ...state,
                paket: action.payload
            };
        }
        case GET_LIST_PAKET_TRYOUT: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case GET_DETAIL_TRYOUT_EVENT: {
            return {
                ...state,
                detail_paket: action.payload
            };
        }
        case GET_SOAL_TRYOUT_BUNDLE: {
            return {
                ...state,
                soal: action.payload
            };
        }
        case DEL_PAKET_TRYOUT: {
            let paket = state.paket.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                paket: paket
            };
        }
        default: {
            return state;
        }
    }
};

export default TryoutBundleReducer;
