import {
    SET_SKD_INFO
} from '../actions/SKDinfoActions';

const initialState = {
    type_skd:''
};

const SKDinfoReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_SKD_INFO: {
            return {
                type_skd: action.data
            };
        }
        default: {
            return state;
        }
    }
};

export default SKDinfoReducer;
