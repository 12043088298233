import { DEL_PEMBAHASAN_PPPK, GET_SOAL_PEMBAHASAN } from '../actions/PembahasanPPPKActions';

const initialState = {
    data: []
};

const PembahasanPPPKReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_SOAL_PEMBAHASAN: {
            return {
                ...state,
                data: action.payload
            };
        }
        case DEL_PEMBAHASAN_PPPK: {
            let data = state.data.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                data: data
            };
        }
        default: {
            return state;
        }
    }
};

export default PembahasanPPPKReducer;
