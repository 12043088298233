import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import { updTryoutBUMN } from 'app/redux/actions/UserTryoutActions';
import {
    getAllPasingGrade,
    getPassingGradeBumn
} from 'app/redux/actions/PassingGradeActions';
import React, { Component } from 'react';
import {
    TextValidator,
    ValidatorForm,
    SelectValidator
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class EditTryout extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            submit: false,
            parent_id: parent,
            doc_id: '',
            description: '',
            expires_time: '',
            jml_soal: '',
            title: '',
            category: '',
            is_shuffle: '1',
            pg: '',
            display: '0',
            data_passing_grade: 0,
            pass_grade: [
                {
                    passing_grade: 0,
                    name: ''
                },
                {
                    passing_grade: 0,
                    name: ''
                },
                {
                    passing_grade: 0,
                    name: ''
                },
                {
                    passing_grade: 0,
                    name: ''
                }
            ],
        };
    }

    componentDidMount() {
        const { getAllPasingGrade, getPassingGradeBumn, params } = this.props;
        getAllPasingGrade();
        getPassingGradeBumn('BUMN');
        const {
            description,
            expires_time,
            jml_soal,
            title,
            category,
            doc_id,
            is_shuffle,
            pg,
            display,
            type
        } = this.props;

        let newPass = [];
        if (Array.isArray(pg)) {
            newPass = pg;
            if (pg.length < 4) {
                for (let i = 0; i < 3; i++) {
                    let da = [
                        {
                            passing_grade: 0,
                            name: ''
                        }
                    ];
                    newPass.push(da);
                }
            }
        } else {
            newPass = [
                {
                    passing_grade: 0,
                    name: ''
                },
                {
                    passing_grade: 0,
                    name: ''
                },
                {
                    passing_grade: 0,
                    name: ''
                },
                {
                    passing_grade: 0,
                    name: ''
                }
            ];
        }

        this.setState({
            category: category,
            description: description,
            title: title,
            doc_id: doc_id,
            expires_time: expires_time,
            jml_soal: jml_soal,
            is_shuffle: is_shuffle,
            pg: pg,
            data_passing_grade: pg,
            display: display || '0',
            pass_grade: newPass
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const {
            description,
            expires_time,
            jml_soal,
            title,
            category,
            doc_id,
            is_shuffle,
            pg,
            display,
            pass_grade,
            type
        } = this.state;
        const params = {
            category: category,
            description: description,
            title: title,
            expires_time: expires_time,
            jml_soal: jml_soal,
            is_shuffle: is_shuffle,
            passing_grade: pg,
            display: display,
            passing_grade: pass_grade,
        };
        const { handleClose, editTryout } = this.props;
        handleClose();
        editTryout(params, doc_id);
    };

    handleChangePassGrade = (event, index) => {
        let { pass_grade } = this.state;
        pass_grade[index].passing_grade = event.target.value;
        let newPassGrade = pass_grade;
        this.setState({
            pass_grade: newPassGrade
        });
    };

    handleChangeNamePass = (event, index) => {
        let { pass_grade } = this.state;
        pass_grade[index].name = event.target.value;
        let newPassGrade = pass_grade;
        this.setState({
            pass_grade: newPassGrade
        });
    };

    render() {
        let {
            submit,
            description,
            expires_time,
            jml_soal,
            title,
            is_shuffle,
            display,
            data_passing_grade,
            pass_grade
        } = this.state;
        let { open, handleClose, passing_grade } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Edit Tryout BUMN</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                className='d-flex items-end'
                            >
                                <TextValidator
                                    label='Nama Tryout'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={[
                                        'required',
                                        'maxStringLength:100'
                                    ]}
                                    errorMessages={[
                                        'Field is Required',
                                        'Maximum 100 Character'
                                    ]}
                                    className='w-full'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-6 w-full'
                                    label='Deskripsi Tryout'
                                    multiline
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Jumlah Soal'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='jml_soal'
                                    value={jml_soal}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Durasi Pengerjaan (menit)'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='expires_time'
                                    value={expires_time}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Random Soal'
                                    onChange={this.handleChange}
                                    name='is_shuffle'
                                    value={is_shuffle}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Aktif</MenuItem>
                                    <MenuItem value='0'>Nonaktif</MenuItem>
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Tampilkan Tryout ?'
                                    onChange={this.handleChange}
                                    name='display'
                                    value={display}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Iya</MenuItem>
                                    <MenuItem value='0'>Tidak</MenuItem>
                                </SelectValidator>
                            </Grid>
                            {pass_grade?.map((item, index) => (
                                <>
                                    <Grid item sm={6} xs={12}>
                                        <SelectValidator
                                            className='mb-4 w-full'
                                            label='Kategori Tryout'
                                            onChange={(e) =>
                                                this.handleChangeNamePass(
                                                    e,
                                                    index
                                                )
                                            }
                                            name='name'
                                            value={item.name}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        >
                                            {passing_grade?.data?.map((da) => (
                                                <MenuItem value={da.id}>
                                                    {da.name}
                                                </MenuItem>
                                            ))}
                                        </SelectValidator>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Passing Grade'
                                            onChange={(e) =>
                                                this.handleChangePassGrade(
                                                    e,
                                                    index
                                                )
                                            }
                                            type='number'
                                            name='passing_grade'
                                            value={item.passing_grade}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        passing_grade: state.passingGrade.detail
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editTryout: (params, doc_id) => dispatch(updTryoutBUMN(params, doc_id)),
        getAllPasingGrade: () => dispatch(getAllPasingGrade()),
        getPassingGradeBumn: () => dispatch(getPassingGradeBumn())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTryout);
