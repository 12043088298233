import { Box, FormControl, Icon, IconButton } from '@material-ui/core';
import React from 'react';

const FormSearch = ({ value, handleSearch }) => {
    return (
        <FormControl className='form-search'>
            <Box
                component='fieldset'
                borderColor='transparent'
                className='flex pl-0'
            >
                <IconButton title='Cari' style={{ padding: '5px' }}>
                    <Icon color='action'>search</Icon>
                </IconButton>
                <input
                    onChange={handleSearch}
                    placeholder='Cari..'
                    name='query'
                    value={value}
                    className='input-search'
                ></input>
            </Box>
        </FormControl>
    );
};

export default FormSearch;
