import {
    Button,
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import { FormSearch } from 'app/components';
import { delMateriSkd, getMateriSkd } from 'app/redux/actions/SKDActions';
import { getAllBatch } from 'app/redux/actions/BatchActions';
import { formatTanggal } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import AddMateri from './AddMateri';
import EditMateri from './EditMateri';

class Materi extends Component {
    constructor() {
        super();
        this.state = {
            materi: [],
            page: 0,
            limit: 10,
            rowsPerPage: 10,
            modalAdd: false,
            modalEdit: false,
            search: '',
            edit_materi: {
                bg_color: '',
                category: '',
                description: '',
                title: '',
                category_desc: '',
                doc_id: '',
                materi_file: '',
                materi_video: '',
                batch_materi: ''
            }
        };
    }

    componentDidMount() {
        this.getData();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    getData() {
        const { getMateriSKD, getAllBatch } = this.props;
        let parent = window.location.pathname.split('/').pop();
        getAllBatch();
        if (
            parent != 'dinas' &&
            parent != 'free' &&
            parent !== 'dinas_platinum'
        ) {
            getMateriSKD(['twk', 'tiu', 'tkp']);
        } else if (parent == 'dinas' || parent == 'dinas_platinum') {
            getMateriSKD(['twk_dinas', 'tiu_dinas', 'tkp_dinas']);
        }
    }

    handleDelete = (doc_id) => {
        const { delMateriSKD } = this.props;
        Swal.fire({
            title: 'Peringatan',
            text: 'Apakah kamu yakin ingin menghapus materi ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            icon: 'warning'
        }).then((res) => {
            if (res.isConfirmed) {
                delMateriSKD(doc_id);
            } else {
                Swal.close();
            }
        });
    };

    getTableRowNumber = (index) => {
        const { page, limit } = this.state;
        return page * limit + index + 1;
    };

    renderData = () => {
        const { materi } = this.props;

        const { search, page, rowsPerPage } = this.state;
        let parent = window.location.pathname.split('/').pop();
        let dataMateri = materi
            .filter((item) =>
                parent == 'platinum'
                    ? item.type == parent || item.type == 'premium'
                    : parent == 'dinas_platinum'
                    ? item.type == parent || item.type == 'dinas'
                    : item.type == parent
            )
            .filter((item) =>
                Object.keys(item).some((key) =>
                    search.includes(key)
                        ? false
                        : item[key]?.toString().toLowerCase().includes(search)
                )
            )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return dataMateri.length > 0 ? (
            dataMateri.map((item, index) => (
                <TableRow key={index} hover>
                    <TableCell className='px-0' align='center'>
                        {this.getTableRowNumber(index)}
                    </TableCell>

                    <TableCell className='px-0' colSpan={2}>
                        {item.title}
                    </TableCell>

                    <TableCell className='px-0' align='center'>
                        {item.category.replace('_', ' ').toUpperCase()}
                    </TableCell>

                    <TableCell className='px-0'>
                        {item.materi_file != null ? (
                            <a
                                target='_blank'
                                href={item.materi_file}
                                className='text-primary'
                            >
                                lihat
                            </a>
                        ) : (
                            <a
                                target='_blank'
                                href={item.materi_video}
                                className='text-primary'
                            >
                                lihat
                            </a>
                        )}
                    </TableCell>

                    <TableCell className='px-0'>
                        {formatTanggal(item.created_at)}
                    </TableCell>

                    <TableCell className='px-0' align='center'>
                        <IconButton
                            title='Edit'
                            onClick={() => this.handleModalEdit(item)}
                        >
                            <Icon color='primary'>edit</Icon>
                        </IconButton>
                        <IconButton
                            title='delete'
                            onClick={() => this.handleDelete(item.doc_id)}
                        >
                            <Icon color='error'>delete</Icon>
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))
        ) : (
            <TableRow hover>
                <TableCell colSpan={7} align='center'>
                    Not Have Data
                </TableCell>
            </TableRow>
        );
    };

    handleSearch = (val) => {
        this.setState({
            search: val.target.value
        });
    };

    handleModalAdd = () => {
        this.setState((prefState) => ({
            modalAdd: !prefState.modalAdd
        }));
    };

    handleCloseEdit = () => {
        this.setState({
            modalEdit: false
        });
    };

    handleModalEdit = (item) => {
        const {
            bg_color,
            category,
            description,
            title,
            category_desc,
            doc_id,
            materi_file,
            materi_video,
            thumbnail,
            batch_materi
        } = item;
        this.setState({
            edit_materi: {
                bg_color: bg_color,
                category: category,
                description: description,
                title: title,
                category_desc: category_desc,
                doc_id: doc_id,
                materi_file: materi_file,
                materi_video: materi_video,
                thumbnail: thumbnail,
                batch_materi: batch_materi
            },
            modalEdit: true
        });
    };

    render() {
        const { batch, materi } = this.props;
        let parent = window.location.pathname.split('/').pop();
        let dataMateri = materi.filter((item) =>
            parent == 'platinum'
                ? item.type == parent || item.type == 'premium'
                : parent == 'dinas_platinum'
                ? item.type == parent || item.type == 'dinas'
                : item.type == parent
        );

        const { search, rowsPerPage, page, modalAdd, modalEdit, edit_materi } =
            this.state;

        if (parent == 'free') {
            return <h5>Tidak bisa menambah materi untuk paket gratis</h5>;
        }
        return (
            <div>
                <Card className='p-5 overflow-auto' elevation={6}>
                    <div className='flex justify-between'>
                        <Button
                            variant='outlined'
                            color='primary'
                            className='mb-5'
                            onClick={this.handleModalAdd}
                            title='Edit'
                        >
                            Tambah
                        </Button>
                        <FormSearch
                            value={search}
                            handleSearch={this.handleSearch}
                        />
                    </div>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='text-white'
                                        align='center'
                                    >
                                        No.
                                    </TableCell>
                                    <TableCell
                                        className='text-white'
                                        colSpan={2}
                                    >
                                        Judul
                                    </TableCell>
                                    <TableCell
                                        className='text-white'
                                        align='center'
                                    >
                                        Kategory
                                    </TableCell>
                                    <TableCell className='text-white'>
                                        Materi Link
                                    </TableCell>
                                    <TableCell className='text-white'>
                                        Tgl Dibuat
                                    </TableCell>
                                    <TableCell
                                        className='text-white'
                                        align='center'
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>

                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={dataMateri.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                    </div>
                </Card>
                <AddMateri
                    open={modalAdd}
                    handleClose={this.handleModalAdd}
                    parent={parent}
                />
                {modalEdit && (
                    <EditMateri
                        open={modalEdit}
                        handleClose={this.handleCloseEdit}
                        bg_color={edit_materi.bg_color}
                        category={edit_materi.category}
                        description={edit_materi.description}
                        title={edit_materi.title}
                        category_desc={edit_materi.category_desc}
                        doc_id={edit_materi.doc_id}
                        materi_file={edit_materi.materi_file}
                        batch_materi={edit_materi.batch_materi}
                        materi_video={edit_materi.materi_video}
                        thumbnail={edit_materi.thumbnail}
                    />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        materi: state.skd.materi,
        batch: state.batch.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMateriSKD: (params) => dispatch(getMateriSkd(params)),
        delMateriSKD: (doc_id) => dispatch(delMateriSkd(doc_id)),
        getAllBatch: () => dispatch(getAllBatch())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Materi);
