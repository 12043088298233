import FirebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_INFO = 'GET_INFO';
export const GET_TRYOUT_SKD = 'GET_TRYOUT_SKD';
export const GET_TRYOUT_SKB = 'GET_TRYOUT_SKB';
export const GET_TRYOUT_UK = 'GET_TRYOUT_UK';
export const GET_TRYOUT_PPPK = 'GET_TRYOUT_PPPK';
export const RESET_REDUCER = 'RESET_REDUCER';

export const getInfo = () => {
    return async (dispatch) => {
        FirebaseAuthService.firestore
            .collection('info')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach((res) => {
                    let item = res.data();
                    item.doc_id = res.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_INFO,
                    payload: data
                });
            });
    };
};

export const getTryoutSKD = () => {
    return async (dispatch) => {
        FirebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('type', 'in', ['skd_gratis', 'skd_premium', 'skd_platinum'])
            .get()
            .then((doc) => {
                let data = doc.size;
                dispatch({
                    type: GET_TRYOUT_SKD,
                    payload: data
                });
            });
    };
};

export const getTryoutSKB = () => {
    return async (dispatch) => {
        FirebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('type', 'in', ['skb'])
            .get()
            .then((doc) => {
                let data = doc.size;
                dispatch({
                    type: GET_TRYOUT_SKB,
                    payload: data
                });
            });
    };
};

export const getTryoutUK = () => {
    return async (dispatch) => {
        FirebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('type', '==', 'uk')
            .get()
            .then((doc) => {
                let data = doc.size;
                dispatch({
                    type: GET_TRYOUT_UK,
                    payload: data
                });
            });
    };
};

export const getTryoutPPPK = () => {
    return async (dispatch) => {
        FirebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('type', '==', 'pppk')
            .get()
            .then((doc) => {
                let data = doc.size;
                dispatch({
                    type: GET_TRYOUT_PPPK,
                    payload: data
                });
            });
    };
};

export const resetReducer = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_REDUCER
        });
    };
};
