import { Button, Card, CircularProgress, Dialog, Grid, MenuItem } from '@material-ui/core';
import { editBatchPPPK } from 'app/redux/actions/PPPKActions';
import React, { Component, Fragment } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

class EditMateri extends Component {
    constructor() {   
        super();
       let segments = window.location.pathname.split('/').filter(Boolean);
       let parent = segments[segments.indexOf('pppkbimbel') + 1];
        this.state = {
            title: '',
            submit: false,
            parent_id: parent,
            doc_id: ''
        };
    }

    componentDidMount() {
        const {
            title,
            doc_id
        } = this.props;
        this.setState({
            title: title,
            doc_id: doc_id
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const {
            title,
            parent_id,
            doc_id
        } = this.state;
        const params = {
            title: title,
            parent_id: parent_id,
            doc_id: doc_id,
        };
        const { handleClose, editMateri } = this.props;
        handleClose();
        editMateri(params);
        this.setState({
            submit: false
        });
    };

   

    render() {
        let {
            title,
            submit,
        } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Edit Nama Batch PPPK</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    label='Nama Batch'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    className='w-full'
                                />
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editMateri: (params) => dispatch(editBatchPPPK(params))
    };
};
export default connect(null, mapDispatchToProps)(EditMateri);
