import {
    Box,
    Button,
    Card,
    FormControl,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import { formatRupiah, formatTanggal } from 'app/redux/actions/UserActions';
import { deleteVoucher, getAllVoucher } from 'app/redux/actions/VoucherActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import AddVoucherDialog from './AddVoucherDialog';
import EditVoucher from './EditVoucher';

class Voucher extends Component {
    constructor() {
        super();
        this.state = {
            search: '',
            rowsPerPage: 20,
            page: 0,
            query: '',
            shouldOpenCreateDialog: false,
            shouldOpenEditorDialog: false
        };
    }
    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };
    componentDidMount() {
        this.updatePageData();
    }
    updatePageData = async () => {
        const { getAllVoucher } = this.props;
        await getAllVoucher();
    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    handleChangeSelect = (e) => {
        if (e.target.value == 'SEMUA') {
            this.setState({
                status: ''
            });
        } else {
            this.setState({
                status: e.target.value
            });
        }
    };
    handleChangeSelectPaket = (e) => {
        if (e.target.value == 'SEMUA') {
            this.setState({
                paket: ''
            });
        } else {
            this.setState({
                paket: e.target.value
            });
        }
    };
    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenCreateDialog: false
        });
        this.updatePageData();
    };

    handleDelete = (uid) => {
        const { deleteVoucher } = this.props;
        Swal.fire({
            title: 'Hapus Voucher?',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonText: 'Iya',
            showCancelButton: true,
            cancelButtonText: 'Batal'
        }).then(async (res) => {
            if (res.isConfirmed) {
                await deleteVoucher(uid);
            } else {
                Swal.close();
            }
        });
    };

    renderData = () => {
        const { voucher } = this.props;
        const { rowsPerPage, page, query } = this.state;

        return voucher?.length > 0 ? (
            voucher
                .sort((a, b) => (a.unique_id < b.unique_id ? 1 : -1))
                .filter((item) =>
                    Object.keys(item).some((key) =>
                        query.includes(key)
                            ? false
                            : item[key].toString().toLowerCase().includes(query)
                    )
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                    return (
                        <TableRow hover key={index}>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                {index + 1}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                {item.id}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 font-poppins'
                                colSpan={4}
                            >
                                {item.description}
                            </TableCell>
                            <TableCell
                                className='py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                <small
                                    className={`border-radius-4 text-white px-4 py-2 ${
                                        item.is_active == 1
                                            ? 'bg-secondary'
                                            : 'bg-primary'
                                    }`}
                                >
                                    {item.is_active == 1
                                        ? 'Aktif'
                                        : 'Tidak Aktif'}
                                </small>
                            </TableCell>
                            <TableCell
                                className='py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                {formatRupiah(item.discount, 'Rp')}
                            </TableCell>
                            <TableCell
                                className='py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                {formatTanggal(item.created_at)}
                            </TableCell>
                            <TableCell
                                className='px-0  flex'
                                colSpan={2}
                                align='center'
                            >
                                <IconButton
                                    onClick={() =>
                                        this.setState({
                                            uid: item,
                                            shouldOpenEditorDialog: true
                                        })
                                    }
                                    title='Edit'
                                >
                                    <Icon color='primary'>edit</Icon>
                                </IconButton>
                                <IconButton
                                    onClick={() =>
                                        this.handleDelete(item.doc_id)
                                    }
                                    title='delete'
                                >
                                    <Icon color='error'>delete</Icon>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    );
                })
        ) : (
            <TableRow hover>
                <TableCell colSpan={11} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };
    render() {
        const { query, shouldOpenCreateDialog, shouldOpenEditorDialog } =
            this.state;
        const { voucher } = this.props;
        return (
            <div className='m-sm-30'>
                <h2 style={{ fontSize: '25px' }}>Daftar Voucher</h2>
                <div className='flex items-center justify-between mt-10 mb-3'>
                    <Button
                        variant='outlined'
                        color='primary'
                        className='mb-5'
                        onClick={() =>
                            this.setState({
                                shouldOpenCreateDialog: true
                            })
                        }
                        title='Tambah'
                    >
                        Tambah
                    </Button>
                    <FormControl
                        className='faqDesc'
                        style={{
                            boxShadow: '0px 0px 2px #43434359',
                            marginBottom: '15px',
                            borderRadius: '5px',
                            maxHeight: '45px'
                        }}
                    >
                        <Box
                            component='fieldset'
                            borderColor='transparent'
                            style={{ paddingLeft: '0px' }}
                            className='flex'
                        >
                            <IconButton title='Cari' style={{ padding: '5px' }}>
                                <Icon color='action'>search</Icon>
                            </IconButton>
                            <input
                                onChange={this.handleSearch}
                                placeholder='Cari..'
                                name='query'
                                value={query}
                                style={{
                                    minHeight: '25px',
                                    border: '0px',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    outline: 0
                                }}
                            ></input>
                        </Box>
                    </FormControl>
                </div>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='crud-table min-w-750'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No.
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Kode Voucher
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        colSpan={4}
                                    >
                                        Deskripsi
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Diskon
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Tgl Dibuat
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                        align='center'
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>
                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[20, 50, 100]}
                            component='div'
                            count={voucher?.length}
                            rowsPerPage={this.state.rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={this.state.page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                    </div>
                </Card>

                {shouldOpenCreateDialog && (
                    <AddVoucherDialog
                        handleClose={this.handleDialogClose}
                        open={shouldOpenCreateDialog}
                        uid={this.state.uid}
                    />
                )}
                {shouldOpenEditorDialog && (
                    <EditVoucher
                        handleClose={this.handleDialogClose}
                        open={shouldOpenEditorDialog}
                        uid={this.state.uid}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        voucher: state.voucher.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllVoucher: () => dispatch(getAllVoucher()),
        deleteVoucher: (id) => dispatch(deleteVoucher(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
