import firebaseAuthService from '../../services/firebase/firebaseAuthService';
import _ from 'lodash';
import axios from 'axios';
export const GET_HASIL_SKD = 'GET_HASIL_SKD';
export const GET_NILAI_SKD = 'GET_NILAI_SKD';
export const GET_HASIL_UK = 'GET_HASIL_UK';
export const GET_HASIL_SKB = 'GET_HASIL_SKB';
export const GET_HASIL_LAT_UK = 'GET_HASIL_LAT_UK';
export const GET_HASIL_LAT_SKB = 'GET_HASIL_LAT_SKB';
export const LOADING_HASIL = 'LOADING_HASIL';
export const GET_DETRANK_SKD = 'GET_DETRANK_SKD';

export const getDetRankingSKD = (tryout) => {
    return async (dispatch) => {
        const uid = localStorage.getItem('uid');
        axios.defaults.headers.common['Authorization'] = uid;
        const res = await axios
            .get(
                `${process.env.REACT_APP_API_URL}scpns/admin/ranking/${tryout}`
            )
            .catch((err) => {
                return Promise.reject(err);
            });
        if (res.data.code === 0) {
            dispatch({
                type: GET_DETRANK_SKD,
                payload: res.data.data
            });
        } else {
            dispatch({
                type: GET_DETRANK_SKD,
                payload: []
            });
        }
    };
};

export const getHasilSKD = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('id_user', '==', uid)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_HASIL_SKD,
                    payload: data
                });
            });
    };
};

export const getHasilUK = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        firebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('id_user', '==', uid)
            .where('type', '==', 'uk')
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                for (let i = 0; i < data.length; i++) {
                    firebaseAuthService.firestore
                        .collection('users_tryout_result')
                        .doc(data[i].doc_id)
                        .collection('answer')
                        .where('point', '==', 1)
                        .get()
                        .then(function (res) {
                            data[i].nilai = res.size;
                            dispatch({
                                type: GET_HASIL_UK,
                                payload: data
                            });
                        });
                }
            });
    };
};

export const getHasilLatUK = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .where('id_user', '==', uid)
            .where('type', '==', 'uk')
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                for (let i = 0; i < data.length; i++) {
                    firebaseAuthService.firestore
                        .collection('users_exercise_result')
                        .doc(data[i].doc_id)
                        .collection('answer')
                        .where('point', '==', 1)
                        .get()
                        .then(function (res) {
                            data[i].nilai = res.size;
                            dispatch({
                                type: GET_HASIL_LAT_UK,
                                payload: data
                            });
                        });
                }
            });
    };
};

export const getHasilRankingSKD = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        await firebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('id_user', '==', uid)
            .where('type', 'in', ['skd_gratis', 'skd_premium', 'skd_dinas'])
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
            });
        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });
        data = handleRedundant(data);
        for (let i = 0; i < data.length; i++) {
            let allTryout = [];
            await firebaseAuthService.firestore
                .collection('users_tryout_result')
                .where('type', '==', data[i].type)
                .get()
                .then((res) => {
                    let dataTryout = [];
                    res.forEach(function (doc) {
                        let item = doc.data();
                        item.doc_id = doc.id;
                        dataTryout.push(item);
                    });
                    allTryout = dataTryout.sort(
                        (a, b) => parseFloat(b.point) - parseFloat(a.point)
                    );
                });
            data[i].ranking =
                allTryout.findIndex((item) => item.doc_id == data[i].doc_id) +
                1;
        }
        dispatch({
            type: GET_HASIL_SKD,
            payload: data
        });
    };
};

export const handleRedundant = (data) => {
    let value = [];
    for (let i = 0; i < data.length; i++) {
        let check = value.filter(
            (item) =>
                item.tryout == data[i].tryout &&
                item.id_user == data[i].id_user &&
                item.type == data[i].type
        );

        if (check.length <= 0) {
            value.push(data[i]);
        }
    }
    return value;
};

export const getDetailRankingSKD = (kode, limit, startAfter) => {
    return async (dispatch) => {
        dispatch({
            type: LOADING_HASIL,
            payload: true
        });
        let data = [];
        let userTryoutRef = firebaseAuthService.firestore.collection(
            'users_tryout_result'
        );
        let userData = firebaseAuthService.firestore.collection('users');

        if (startAfter) userTryoutRef = userTryoutRef.startAfter(startAfter);

        await userTryoutRef
            .where('tryout', '==', kode)
            // .where('id_user', '==', 'zMD9JTLvicZXjEGe8e80rhkVw712')
            // .orderBy('point')
            // .limit(limit)
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
            });

        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });

        for (let i = 0; i < data.length; i++) {
            let user = null;
            await userData
                .doc(data[i].id_user)
                .get()
                .then((res) => {
                    if (res.exists) {
                        user = res.data();
                    }
                });
            data[i].user = user;
            console.log('==============>> data ke', i);
            console.log('==============>> dari', data.length);
        }
        data = data.sort((a, b) => parseFloat(b.point) - parseFloat(a.point));
        data = handleRedundant(data);
        dispatch({
            type: GET_HASIL_SKD,
            payload: data
        });
    };
};

// LATIHAN
export const getHasilLatihanSKD = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .where('id_user', '==', uid)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_HASIL_SKD,
                    payload: data
                });
            });
    };
};

export const getHasilRankingPPPK = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        await firebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('id_user', '==', uid)
            .where('type', 'in', ['pppk'])
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
            });
        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });
        data = handleRedundant(data);
        for (let i = 0; i < data.length; i++) {
            let allTryout = [];
            await firebaseAuthService.firestore
                .collection('users_tryout_result')
                .where('type', '==', data[i].type)
                .get()
                .then((res) => {
                    let dataTryout = [];
                    res.forEach(function (doc) {
                        let item = doc.data();
                        item.doc_id = doc.id;
                        dataTryout.push(item);
                    });
                    allTryout = dataTryout.sort(
                        (a, b) => parseFloat(b.point) - parseFloat(a.point)
                    );
                });
            data[i].ranking =
                allTryout.findIndex((item) => item.doc_id == data[i].doc_id) +
                1;
        }
        dispatch({
            type: GET_HASIL_SKD,
            payload: data
        });
    };
};

export const getHasilSKB = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        firebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('id_user', '==', uid)
            .where('type', '==', 'skb')
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                for (let i = 0; i < data.length; i++) {
                    firebaseAuthService.firestore
                        .collection('users_tryout_result')
                        .doc(data[i].doc_id)
                        .collection('answer')
                        .where('point', '==', 1)
                        .get()
                        .then(function (res) {
                            data[i].nilai = res.size;
                            dispatch({
                                type: GET_HASIL_UK,
                                payload: data
                            });
                        });
                }
            });
    };
};

export const getHasilLatSKB = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .where('id_user', '==', uid)
            .where('type', '==', 'lat_skb')
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                for (let i = 0; i < data.length; i++) {
                    firebaseAuthService.firestore
                        .collection('users_exercise_result')
                        .doc(data[i].doc_id)
                        .collection('answer')
                        .where('point', '==', 1)
                        .get()
                        .then(function (res) {
                            data[i].nilai = res.size;
                            dispatch({
                                type: GET_HASIL_LAT_SKB,
                                payload: data
                            });
                        });
                }
            });
    };
};

// HASIL RANKING SKB
export const getHasilRankingSKB = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        await firebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('id_user', '==', uid)
            .where('type', '==', 'skb')
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;

                    firebaseAuthService.firestore
                        .collection('users_tryout_result')
                        .doc(doc.id)
                        .collection('answer')
                        .where('point', '==', 1)
                        .get()
                        .then(function (res) {
                            item.nilai = res.size;
                        });

                    data.push(item);
                });
            });
        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });
        data = data.sort((a, b) =>
            parseFloat(b.nilai) > parseFloat(a.nilai) ? 1 : -1
        );
        data = handleRedundant(data);
        let allTryout = [];
        for (let i = 0; i < data.length; i++) {
            await firebaseAuthService.firestore
                .collection('users_tryout_result')
                .where('type', '==', 'skb')
                .get()
                .then((res) => {
                    let dataTryout = [];

                    res.forEach(function (doc) {
                        let item = doc.data();
                        item.doc_id = doc.id;

                        firebaseAuthService.firestore
                            .collection('users_tryout_result')
                            .doc(doc.id)
                            .collection('answer')
                            .where('point', '==', 1)
                            .get()
                            .then(function (res) {
                                item.nilai = res.size;
                            });

                        dataTryout.push(item);
                    });
                    allTryout = dataTryout.sort((a, b) =>
                        parseFloat(a.nilai) > parseFloat(b.nilai) ? 1 : -1
                    );
                });
            data[i].ranking = allTryout.findIndex(
                (item) => item.doc_id == data[i].doc_id
            );
        }
        dispatch({
            type: GET_HASIL_SKD,
            payload: data
        });
    };
};

// DETAIL HASIL RANKING SKB
export const getDetailRankingSKB = (kode) => {
    return async (dispatch) => {
        let data = [];
        await firebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('tryout', '==', kode)
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
            });

        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });

        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });
        for (let i = 0; i < data.length; i++) {
            let user = null;
            let nilai = 0;
            await firebaseAuthService.firestore
                .collection('users')
                .doc(data[i].id_user)
                .get()
                .then((res) => {
                    if (res.exists) {
                        user = res.data();
                    }
                });
            data[i].user = user;
            await firebaseAuthService.firestore
                .collection('users_tryout_result')
                .doc(data[i].doc_id)
                .collection('answer')
                .where('point', '==', 1)
                .get()
                .then(function (res) {
                    nilai = res.size;
                });
            data[i].nilai = nilai;
        }
        data = data.sort((a, b) => parseFloat(b.nilai) - parseFloat(a.nilai));
        // console.log('dattttttttt', data);
        data = handleRedundant(data);
        dispatch({
            type: GET_HASIL_SKB,
            payload: data
        });
    };
};

// HASIL RANKING UK
export const getHasilRankingUK = () => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        let data = [];
        await firebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('id_user', '==', uid)
            .where('type', '==', 'uk')
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;

                    firebaseAuthService.firestore
                        .collection('users_tryout_result')
                        .doc(doc.id)
                        .collection('answer')
                        .where('point', '==', 1)
                        .get()
                        .then(function (res) {
                            item.nilai = res.size;
                        });

                    data.push(item);
                });
            });
        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });
        data = data.sort((a, b) => parseFloat(b.point) - parseFloat(a.point));
        data = handleRedundant(data);
        let allTryout = [];
        for (let i = 0; i < data.length; i++) {
            await firebaseAuthService.firestore
                .collection('users_tryout_result')
                .where('type', '==', 'uk')
                .get()
                .then((res) => {
                    let dataTryout = [];

                    res.forEach(function (doc) {
                        let item = doc.data();
                        item.doc_id = doc.id;

                        firebaseAuthService.firestore
                            .collection('users_tryout_result')
                            .doc(doc.id)
                            .collection('answer')
                            .where('point', '==', 1)
                            .get()
                            .then(function (res) {
                                item.nilai = res.size;
                            });

                        dataTryout.push(item);
                    });
                    allTryout = dataTryout.sort(
                        (a, b) => parseFloat(b.nilai) - parseFloat(a.nilai)
                    );
                });
            data[i].ranking =
                allTryout.findIndex((item) => item.doc_id == data[i].doc_id) +
                1;
        }
        dispatch({
            type: GET_HASIL_SKD,
            payload: data
        });
    };
};

// DETAIL HASIL RANKING UK
export const getDetailRankingUK = (kode) => {
    return async (dispatch) => {
        let data = [];
        await firebaseAuthService.firestore
            .collection('users_tryout_result')
            .where('tryout', '==', kode)
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
            });

        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });

        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });
        for (let i = 0; i < data.length; i++) {
            let user = null;
            let nilai = 0;
            await firebaseAuthService.firestore
                .collection('users')
                .doc(data[i].id_user)
                .get()
                .then((res) => {
                    if (res.exists) {
                        user = res.data();
                    }
                });
            data[i].user = user;
            await firebaseAuthService.firestore
                .collection('users_tryout_result')
                .doc(data[i].doc_id)
                .collection('answer')
                .where('point', '==', 1)
                .get()
                .then(function (res) {
                    nilai = res.size;
                });
            data[i].nilai = nilai;
        }
        data = data.sort((a, b) => parseFloat(b.nilai) - parseFloat(a.nilai));
        // console.log('dattttttttt', data);
        data = handleRedundant(data);
        dispatch({
            type: GET_HASIL_UK,
            payload: data
        });
    };
};

// DETAIL HASIL LATIHAN RANKING SKB & UK
export const getLatihanRankingSKB = (kode) => {
    return async (dispatch) => {
        let data = [];
        await firebaseAuthService.firestore
            .collection('users_exercise_result')
            .where('exercise', '==', kode)
            .get()
            .then((res) => {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
            });

        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });

        data = data.filter((item) => {
            if (item.finish) {
                return true;
            } else {
                const now = new Date().getTime();
                const waktuKerjakan =
                    new Date(item.created_at?.replace(' ', 'T')).getTime() +
                    item.expires_time * 60000;
                return waktuKerjakan - now <= 0;
            }
        });
        for (let i = 0; i < data.length; i++) {
            let user = null;
            let nilai = 0;
            await firebaseAuthService.firestore
                .collection('users')
                .doc(data[i].id_user)
                .get()
                .then((res) => {
                    if (res.exists) {
                        user = res.data();
                    }
                });
            data[i].user = user;
            await firebaseAuthService.firestore
                .collection('users_exercise_result')
                .doc(data[i].doc_id)
                .collection('answer')
                .where('point', '==', 1)
                .get()
                .then(function (res) {
                    nilai = res.size;
                });
            data[i].nilai = nilai;
        }
        data = data.sort((a, b) => parseFloat(b.nilai) - parseFloat(a.nilai));
        // console.log('dattttttttt', data);
        data = handleRedundant(data);
        dispatch({
            type: GET_HASIL_SKB,
            payload: data
        });
    };
};
