import Swal from 'sweetalert2';
import axios from 'axios';
import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_WIDRAW_BELUM = 'GET_WIDRAW_BELUM';
export const GET_WIDRAW_SUDAH = 'GET_WIDRAW_SUDAH';
export const GET_WIDRAW_BATAL = 'GET_WIDRAW_BATAL';
export const EDIT_WIDRAW = 'EDIT_WIDRAW';
export const GET_INVOICE = 'GET_INVOICE';

export const getAllKomisiBelum =  () => (dispatch) => {
 
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    axios
        .get('https://ranking.sahabatcpns.com/api/v1/scpns/komisi/belum')
        .then((res) => {
   
            dispatch({
                type: GET_WIDRAW_BELUM,
                payload: res.data.data.data
            });
        });
    // return async (dispatch) => {
    //     let data = [];
    //     await firebaseAuthService.firestore
    //         .collection('widraw_komisi')
    //         .get()
    //         .then((res) => {
    //             res.forEach(function (doc) {
    //                 let item = doc.data();
    //                 item.doc_id = doc.id;
    //                 data.push(item);
    //             });
    //         });
    //     for (let i = 0; i < data.length; i++) {
    //         let user = null;
    //         await getUserDetail(data[i].id_user).then((res) => {
    //             if (res) {
    //                 user = res;
    //             }
    //         });
    //         data[i].user = user;
    //     }
    //     dispatch({
    //         type: GET_WIDRAW,
    //         payload: data
    //     });
    // };
};



export const getAllKomisiSudah = () => (dispatch) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    axios
        .get('https://ranking.sahabatcpns.com/api/v1/scpns/komisi/sudah')
        .then((res) => {
            dispatch({
                type: GET_WIDRAW_SUDAH,
                payload: res.data.data.data
            });
        });
  
};


export const getAllKomisiBatal = () => (dispatch) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    axios
        .get('https://ranking.sahabatcpns.com/api/v1/scpns/komisi/batal')
        .then((res) => {
            dispatch({
                type: GET_WIDRAW_BATAL,
                payload: res.data.data.data
            });
        });
};

export const getInvoice = () => {
    return async (dispatch) => {
        let data = [];
        firebaseAuthService.firestore
            .collection('invoice')
            .where('status', '==', 'SETTLED')
            .get()
            .then(function (res) {
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
            });
        for (let i = 0; i < data.length; i++) {
            let user = null;
            await getUserDetail(data[i].id_user).then((res) => {
                if (res) {
                    user = res;
                }
            });
            data[i].user = user;
        }
        dispatch({
            type: GET_INVOICE,
            payload: data || []
        });
    };
};

export const getUserDetail = async (kode) => {
    let result = null;
    await firebaseAuthService.firestore
        .collection('users')
        .doc(kode)
        .get()
        .then((res) => {
            result = res.data();
        });
    return result;
};

export const updateKomisi = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('widraw_komisi')
            .doc(doc_id)
            .update(params)
            .then(() => {
                if (params.status == 3) {
                    firebaseAuthService.firestore
                        .collection('users')
                        .doc(params.id_user)
                        .get()
                        .then((res) => {
                            let komisi = res.data().komisi;
                            komisi =
                                parseInt(komisi) - parseInt(params.nominal);
                            firebaseAuthService.firestore
                                .collection('users')
                                .doc(params.id_user)
                                .update({ komisi: komisi });
                        });
                       
                        
                }
              
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Penarikan komisi berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
              
                dispatch({
                    type: EDIT_WIDRAW,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Penarikan komisi gagal diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
