import LatihanSoal from '../SKD/latihan/LatihanSoal';
import SKDDetail from '../SKD/SKDDetail';
import SKDTable from '../EventSKD/EventSKDTable';
import TryoutRanking from '../SKD/tryout/TryoutRanking';
import TryoutSoal from '../EventSKD/tryout/TryoutSoal';
import TryoutSoalSkdEdit from '../EventSKD/tryout/TryoutSoalSkdEdit';
import TryoutSoalBC from '../SKD/tryout/TryoutSoalBC';
import TryoutSoalSkdTambah from '../EventSKD/tryout/TryoutSoalSkdTambah';

const EventSKDRoutes = [
    {
        path: '/skd/tryout/ranking/:id',
        component: TryoutRanking
    },
    {
        path: '/event/tryout/soal/tambah',
        component: TryoutSoalSkdTambah
    },
    {
        path: '/event/tryout/soal/skd/edit',
        component: TryoutSoalSkdEdit
    },
    {
        path: '/skd/tryout/soalbc/:id',
        component: TryoutSoalBC
    },
    {
        path: '/event/tryout/soal/:id',
        component: TryoutSoal
    },
    {
        path: '/skd/latihan/soal/:id',
        component: LatihanSoal
    },
    {
        path: '/skd/:id',
        component: SKDDetail
    },
    {
        path: '/event',
        component: SKDTable
    }
];

export default EventSKDRoutes;
