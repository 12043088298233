import {
    GET_PASSING_GRADE,
    GET_PASSING_GRADE_PPPK,
    GET_ALL_PASSING_GRADE,
    GET_DETAIL_PASSING_GRADE
} from '../actions/PassingGradeActions';

const initialState = {
    data: {
        twk: 0,
        tiu: 0,
        tkp: 0
    },
    pppk: {
        min_correct: 0
    },
    all: [],
    detail: {}
};

const PassingGradeReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PASSING_GRADE: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_PASSING_GRADE_PPPK: {
            return {
                ...state,
                pppk: action.payload
            };
        }
        case GET_ALL_PASSING_GRADE: {
            return {
                ...state,
                all: action.payload
            };
        }
        case GET_DETAIL_PASSING_GRADE: {
            return {
                ...state,
                detail: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default PassingGradeReducer;
