import {
    Button,
    Grid,
    Card,
    CircularProgress,
    MenuItem,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    Switch,
    FormControlLabel,
    TableHead,
    TableRow
} from '@material-ui/core';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonOpacity } from 'app/components';

import {
    getBundleSKB,
    addBundleSKB,
    updateBundleSKB
} from '../../../redux/actions/SKBActions';

class ListBundle extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        shouldOpenEditorDialog: false,
        shouldOpenCreateDialog: false,
        shouldOpenConfirmationDialog: false,
        query: '',
        loading: false,
        formasi_id: Math.floor(Math.random() * Math.floor(3000)),
        is_active: '',
        bundle_benefit: [],
        name: '',
        price: '',
        bundle_expired_month: '',
        id: 0,
        form_benefit: {
            status: 1,
            benefit: '',
            index: '',
            isNew: true,
            error: ''
        },
        bundle_id: '',
        add: false,
        info: true
    };

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenConfirmationDialog: false,
            shouldOpenCreateDialog: false
        });
        this.updatePageData();
    };

    componentDidMount() {
        this.updatePageData();
    }

    componentDidUpdate(prevProps, prevState) {
        let { bundle } = this.props;
        let { id } = this.state;
        if (Object.keys(bundle).length > 0 && bundle.id != id) {
            this.setState({
                id: bundle.id,
                formasi_id: bundle.formasi_id,
                is_active: bundle.is_active,
                bundle_benefit: bundle.benefit,
                name: bundle.name,
                price: bundle.price,
                bundle_expired_month: bundle.bundle_expired_month,
                bundle_id: bundle.doc_id
            });
        }
    }

    updatePageData = async () => {
        let id = window.location.pathname.split('/').pop();
        await this.props.getBundleSKB(id);
    };

    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeType = (event) => {
        let value = event.target.value;
        if (value == 'offline') {
            this.setState({
                type: value,
                bimble_platform: '',
                bimble_link: ''
            });
        } else if (value == 'online') {
            this.setState({
                type: value,
                bimble_address: '',
                bimble_maps: {
                    lat: '',
                    lng: ''
                }
            });
        }
    };

    handleEditBenefit = (item, index) => {
        let benefit = item.benefit;
        let status = item.status;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                benefit: benefit,
                status: status,
                isNew: false,
                index: index
            }
        }));
    };

    handleChangeBenefit = (event) => {
        let value = event.target.value;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                benefit: value
            }
        }));
    };

    handleStatusBenefit = (event) => {
        let checked = event.target.checked;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                status: checked ? 1 : 0
            }
        }));
    };

    handleDeleteBenefit = (idx) => {
        const { bundle_benefit } = this.state;
        let allBenefit = bundle_benefit.filter((item, index) => index != idx);
        this.setState({
            bundle_benefit: allBenefit
        });
    };

    handleFormBenefit = () => {
        const {
            form_benefit: { benefit, isNew, index, status },
            bundle_benefit
        } = this.state;
        if (benefit.length > 0) {
            if (isNew) {
                let allBenefit = bundle_benefit.concat({
                    status: status,
                    benefit: benefit
                });
                this.setState({
                    bundle_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            } else {
                let allBenefit = bundle_benefit;
                allBenefit[index] = {
                    status: status,
                    benefit: benefit
                };
                this.setState({
                    bundle_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            }
        } else {
            this.setState((prefState) => ({
                form_benefit: {
                    ...prefState.form_benefit,
                    error: 'Tolong isi semua inputan'
                }
            }));
        }
    };

    submitForm = async () => {
        this.setState({ loading: true });
        let {
            bundle_benefit,
            formasi_id,
            bundle_expired_month,
            is_active,
            name,
            price,
            bundle_id
        } = this.state;
        const { updateBundleSKB } = this.props;
        let parent_id = window.location.pathname.split('/').pop();
        let params = {
            benefit: bundle_benefit,
            formasi_id: formasi_id,
            bundle_expired_month: bundle_expired_month,
            is_active: is_active,
            name: name,
            price: price,
            type: 'formasi',
            created_at: new Date(),
            updated_at: new Date()
        };
        await updateBundleSKB(params, parent_id, bundle_id);
        this.setState({ add: false, loading: false });
        await this.updatePageData();
    };

    addBundleForm = async () => {
        this.setState({ loading: true });
        let {
            bundle_benefit,
            formasi_id,
            bundle_expired_month,
            is_active,
            name,
            price
        } = this.state;
        const { addBundleSKB } = this.props;
        let parent_id = window.location.pathname.split('/').pop();
        let bundle_id =
            'BUNDLE' + Math.floor(Math.random() * Math.floor(10000000000000));
        let params = {
            benefit: bundle_benefit,
            formasi_id: formasi_id,
            bundle_expired_month: bundle_expired_month,
            is_active: is_active,
            name: name,
            price: price,
            type: 'formasi',
            created_at: new Date(),
            updated_at: new Date()
        };
        await addBundleSKB(params, parent_id, bundle_id);
        this.setState({ add: false, loading: false });
        await this.updatePageData();
    };

    render() {
        let {
            formasi_id,
            is_active,
            bundle_benefit,
            name,
            price,
            bundle_expired_month,
            loading,
            form_benefit,
            add,
            info
        } = this.state;
        let { bundle } = this.props;
        return (
            <div>
                {bundle != '' ? (
                    <>
                        <Card
                            elevation={0}
                            className={`px-4 py-2 card-bundle secondary shadow bg-white pt-3 pt-3`}
                        >
                            <h4 className='mb-5'>Detail Bundle</h4>
                            <ValidatorForm
                                ref='form'
                                onSubmit={this.submitForm}
                            >
                                <Grid container spacing={4}>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='ID'
                                            onChange={this.handleChange}
                                            type='text'
                                            name='formasi_id'
                                            value={formasi_id}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Nama Bundle'
                                            onChange={this.handleChange}
                                            type='text'
                                            name='name'
                                            value={name}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} className='pb-0'>
                                        <SelectValidator
                                            className='mb-4 w-full'
                                            label='Status'
                                            onChange={this.handleChange}
                                            name='is_active'
                                            value={is_active}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        >
                                            <MenuItem value='1'>Aktif</MenuItem>
                                            <MenuItem value='0'>
                                                Nonaktif
                                            </MenuItem>
                                        </SelectValidator>
                                    </Grid>
                                </Grid>
                                <Grid className='mb-2' container spacing={4}>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Expired Bundle'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='bundle_expired_month'
                                            value={bundle_expired_month}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Harga'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='price'
                                            value={price}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                                <h5 className='mt-0'>Benefit Bundle</h5>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <div className='w-full overflow-auto bg-white'>
                                            <Table className='crud-table'>
                                                <TableHead className='bg-primary'>
                                                    <TableRow>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            align='center'
                                                        >
                                                            No
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            colSpan={2}
                                                        >
                                                            Benefit
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            align='center'
                                                        >
                                                            Status
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            align='center'
                                                        ></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bundle_benefit.length >
                                                    0 ? (
                                                        bundle_benefit.map(
                                                            (item, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                >
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-medium'
                                                                        align='center'
                                                                    >
                                                                        {index +
                                                                            1}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-medium'
                                                                        colSpan={
                                                                            2
                                                                        }
                                                                    >
                                                                        {
                                                                            item.benefit
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-medium'
                                                                        align='center'
                                                                    >
                                                                        <div
                                                                            className={`badge-status-benefit ${
                                                                                item.status ==
                                                                                1
                                                                                    ? 'bg-green'
                                                                                    : 'bg-error'
                                                                            }`}
                                                                        >
                                                                            {item.status ==
                                                                            1 ? (
                                                                                <Icon>
                                                                                    check
                                                                                </Icon>
                                                                            ) : (
                                                                                <Icon>
                                                                                    close
                                                                                </Icon>
                                                                            )}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                        align='center'
                                                                    >
                                                                        <IconButton
                                                                            title='edit'
                                                                            onClick={() =>
                                                                                this.handleEditBenefit(
                                                                                    item,
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon color='primary'>
                                                                                border_color
                                                                            </Icon>
                                                                        </IconButton>
                                                                        <IconButton
                                                                            title='delete'
                                                                            onClick={() =>
                                                                                this.handleDeleteBenefit(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon color='error'>
                                                                                delete
                                                                            </Icon>
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-bold'
                                                                align='center'
                                                                colSpan={5}
                                                            >
                                                                Tidak ada
                                                                benefit paket
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <h6 className='mt-0'>
                                            {form_benefit.isNew
                                                ? 'Tambah Benfit'
                                                : 'Edit Benfit'}
                                        </h6>
                                        <TextValidator
                                            className='w-full'
                                            label='Benefit'
                                            onChange={this.handleChangeBenefit}
                                            type='text'
                                            name='benefit'
                                            value={form_benefit.benefit}
                                        />
                                        {form_benefit.error.length > 0 ? (
                                            <small className='text-error'>
                                                {form_benefit.error}
                                            </small>
                                        ) : null}
                                        <div className='flex items-center justify-between'>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            form_benefit.status ==
                                                            1
                                                        }
                                                        name='Status'
                                                        onChange={
                                                            this
                                                                .handleStatusBenefit
                                                        }
                                                        color='primary'
                                                    />
                                                }
                                                label='Status benefit'
                                                className='mt-1'
                                            />
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={this.handleFormBenefit}
                                                className='elevation-z0 mt-1 w-full max-w-195'
                                            >
                                                {form_benefit.isNew
                                                    ? 'Tambah Benefit'
                                                    : 'Edit Benefit'}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className='border-bottom-1 mt-3'></div>
                                <div className='flex flex-space-between flex-middle mt-3'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                    >
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            'Update'
                                        )}
                                    </Button>
                                </div>
                            </ValidatorForm>
                        </Card>
                    </>
                ) : (
                    <>
                        {info == true && (
                            <div className='py-4 w-full text-center'>
                                <h5 className='m-0'>Bundle Belum Dibuat</h5>
                                <p className='m-0'>Buat Bundle Sekarang</p>
                                <div className='my-4 w-full text-center'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type='button'
                                        onClick={() =>
                                            this.setState({
                                                add: true,
                                                info: false
                                            })
                                        }
                                    >
                                        Buat Bundle
                                    </Button>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {add == true && (
                    <>
                        <Card
                            elevation={0}
                            className={`px-4 py-2 card-bundle secondary shadow bg-white pt-3 pt-3`}
                        >
                            <h4 className='mb-5'>Tambah Bundle</h4>
                            <ValidatorForm
                                ref='form'
                                onSubmit={this.addBundleForm}
                            >
                                <Grid container spacing={4}>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='ID'
                                            onChange={this.handleChange}
                                            type='text'
                                            name='formasi_id'
                                            value={formasi_id}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Nama Bundle'
                                            onChange={this.handleChange}
                                            type='text'
                                            name='name'
                                            value={name}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} className='pb-0'>
                                        <SelectValidator
                                            className='mb-4 w-full'
                                            label='Status'
                                            onChange={this.handleChange}
                                            name='is_active'
                                            value={is_active}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        >
                                            <MenuItem value='1'>Aktif</MenuItem>
                                            <MenuItem value='0'>
                                                Nonaktif
                                            </MenuItem>
                                        </SelectValidator>
                                    </Grid>
                                </Grid>
                                <Grid className='mb-2' container spacing={4}>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Expired Bundle'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='bundle_expired_month'
                                            value={bundle_expired_month}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Harga'
                                            onChange={this.handleChange}
                                            type='number'
                                            name='price'
                                            value={price}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                                <h5 className='mt-0'>Benefit Bundle</h5>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <div className='w-full overflow-auto bg-white'>
                                            <Table className='crud-table'>
                                                <TableHead className='bg-primary'>
                                                    <TableRow>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            align='center'
                                                        >
                                                            No
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            colSpan={2}
                                                        >
                                                            Benefit
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            align='center'
                                                        >
                                                            Status
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            align='center'
                                                        ></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bundle_benefit.length >
                                                    0 ? (
                                                        bundle_benefit.map(
                                                            (item, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                >
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-medium'
                                                                        align='center'
                                                                    >
                                                                        {index +
                                                                            1}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-medium'
                                                                        colSpan={
                                                                            2
                                                                        }
                                                                    >
                                                                        {
                                                                            item.benefit
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-medium'
                                                                        align='center'
                                                                    >
                                                                        <div
                                                                            className={`badge-status-benefit ${
                                                                                item.status ==
                                                                                1
                                                                                    ? 'bg-green'
                                                                                    : 'bg-error'
                                                                            }`}
                                                                        >
                                                                            {item.status ==
                                                                            1 ? (
                                                                                <Icon>
                                                                                    check
                                                                                </Icon>
                                                                            ) : (
                                                                                <Icon>
                                                                                    close
                                                                                </Icon>
                                                                            )}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                        align='center'
                                                                    >
                                                                        <IconButton
                                                                            title='edit'
                                                                            onClick={() =>
                                                                                this.handleEditBenefit(
                                                                                    item,
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon color='primary'>
                                                                                border_color
                                                                            </Icon>
                                                                        </IconButton>
                                                                        <IconButton
                                                                            title='delete'
                                                                            onClick={() =>
                                                                                this.handleDeleteBenefit(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon color='error'>
                                                                                delete
                                                                            </Icon>
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-bold'
                                                                align='center'
                                                                colSpan={5}
                                                            >
                                                                Tidak ada
                                                                benefit paket
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <h6 className='mt-0'>
                                            {form_benefit.isNew
                                                ? 'Tambah Benfit'
                                                : 'Edit Benfit'}
                                        </h6>
                                        <TextValidator
                                            className='w-full'
                                            label='Benefit'
                                            onChange={this.handleChangeBenefit}
                                            type='text'
                                            name='benefit'
                                            value={form_benefit.benefit}
                                        />
                                        {form_benefit.error.length > 0 ? (
                                            <small className='text-error'>
                                                {form_benefit.error}
                                            </small>
                                        ) : null}
                                        <div className='flex items-center justify-between'>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            form_benefit.status ==
                                                            1
                                                        }
                                                        name='Status'
                                                        onChange={
                                                            this
                                                                .handleStatusBenefit
                                                        }
                                                        color='primary'
                                                    />
                                                }
                                                label='Status benefit'
                                                className='mt-1'
                                            />
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={this.handleFormBenefit}
                                                className='elevation-z0 mt-1 w-full max-w-195'
                                            >
                                                {form_benefit.isNew
                                                    ? 'Tambah Benefit'
                                                    : 'Edit Benefit'}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className='border-bottom-1 mt-3'></div>
                                <div className='flex flex-space-between flex-middle mt-3'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                    >
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            'Simpan'
                                        )}
                                    </Button>
                                </div>
                            </ValidatorForm>
                        </Card>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        bundle: state.skb.bundle
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getBundleSKB: (id) => dispatch(getBundleSKB(id)),
        updateBundleSKB: (params, id, doc_id) =>
            dispatch(updateBundleSKB(params, id, doc_id)),
        addBundleSKB: (params, id, doc_id) =>
            dispatch(addBundleSKB(params, id, doc_id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListBundle);
