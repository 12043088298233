import {
    GET_LIST_USER,
    GET_TRYOUT_SKB_BUNDLE_USER,
    GET_TRYOUT_SKB_USER,
    GET_UK_BUNDLE_USER,
    GET_UK_USER,
} from '../actions/ManagementUserActions';

const initialState = {
    list: [],
    detail: null,
    skb: [],
    skb_bundle: [],
    uk: [],
    uk_bundle: []
};

const ManagementUserReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_LIST_USER: {
            return {
                ...state,
                list: action.payload
            };
        }
        case GET_TRYOUT_SKB_USER: {
            return {
                ...state,
                skb: action.payload
            };
        }
        case GET_TRYOUT_SKB_BUNDLE_USER:
            return {
                ...state,
                skb_bundle: action.payload
            };
        case GET_UK_USER:
            return {
                ...state,
                uk: action.payload
            };
        case GET_UK_BUNDLE_USER:
            return {
                ...state,
                uk_bundle: action.payload
            };
        default: {
            return state;
        }
    }
};

export default ManagementUserReducer;
