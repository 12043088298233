import { ADD_BATCH, DELETE_BATCH, GET_ALL_BATCH, SET_LOADING } from '../actions/BatchActions';

const initialState = {
    data: [],
    loading: false
};

const BatchReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case GET_ALL_BATCH: {
            return {
                ...state,
                data: action.payload
            };
        }
        case ADD_BATCH: {
            return {
                ...state,
                loading: false
            };
        }
        case DELETE_BATCH: {
            let data = state.data.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                data: data
            };
        }
        default: {
            return state;
        }
    }
};

export default BatchReducer;
