import { Button, Card, CircularProgress, Dialog, Grid, MenuItem } from '@material-ui/core';
import { editMateriPPPK } from 'app/redux/actions/PPPKActions';
import React, { Component, Fragment } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

class EditMateri extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            bg_color: '#ff4646',
            category: '',
            created_at: '',
            description: '',
            materi_file: '',
            title: '',
            submit: false,
            category_desc: '',
            parent_id: parent,
            doc_id: '',
            materi_file_before: '',
            materi_video: '',
            format_materi: 'file'
        };
    }

    componentDidMount() {
        const {
            bg_color,
            category,
            description,
            title,
            doc_id,
            materi_file,
            materi_video
        } = this.props;
        let format_materi = materi_file != null ? 'file' : 'video';
        this.setState({
            bg_color: bg_color,
            category: category,
            description: description,
            title: title,
            doc_id: doc_id,
            materi_file_before: materi_file,
            materi_video: materi_video,
            format_materi: format_materi
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const {
            bg_color,
            category,
            description,
            materi_file,
            title,
            parent_id,
            materi_file_before,
            doc_id,
            materi_video
        } = this.state;
        const params = {
            bg_color: bg_color,
            category: category,
            description: description,
            materi_file: materi_file,
            title: title,
            parent_id: parent_id,
            materi_file_before: materi_file_before,
            doc_id: doc_id,
            materi_video: materi_video
        };
        const { handleClose, editMateri } = this.props;
        handleClose();
        editMateri(params);
        this.setState({
            submit: false
        });
    };

    getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    };

    isPDF = (filename) => {
        var ext = this.getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'pdf':
                return true;
        }
        return false;
    };

    handleChangeFile = (e) => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        if (this.isPDF(e.target.files[0].name)) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = () => {
                this.setState({
                    materi_file: reader.result
                });
            };
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid File Format',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    handleChangeBG = (val) => {
        this.setState({
            bg_color: val
        });
    };

    handleChangeFormat = (val) => {
        let value = val.target.value;
        this.setState({
            format_materi: value,
            materi_file: '',
            materi_video: ''
        });
    };

    render() {
        let {
            title,
            bg_color,
            description,
            submit,
            format_materi,
            materi_video
        } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Edit Materi PPPK Bimbel</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    label='Nama Materi'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    className='w-full'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-6 w-full'
                                    label='Deskripsi Materi'
                                    multiline
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Format Materi'
                                    onChange={this.handleChangeFormat}
                                    name='format_materi'
                                    value={format_materi}
                                >
                                    <MenuItem value='file'>File</MenuItem>
                                    <MenuItem value='video'>Video</MenuItem>
                                </SelectValidator>
                            </Grid>
                            {format_materi == 'file' ? (
                                <Grid item sm={6} xs={12}>
                                    <small>File materi</small>
                                    <br />
                                    <input
                                        type='file'
                                        name='materi_file'
                                        id='materi_file'
                                        className='mb-6'
                                        accept='application/pdf'
                                        onChange={this.handleChangeFile}
                                        className='mt-2'
                                    />
                                </Grid>
                            ) : (
                                <Grid item sm={6} xs={12}>
                                    <TextValidator
                                        label='Materi Video'
                                        onChange={this.handleChange}
                                        type='text'
                                        name='materi_video'
                                        value={materi_video}
                                        validators={['required']}
                                        errorMessages={['Field is Required']}
                                        className='w-full'
                                    />
                                </Grid>
                            )}
                            <Grid item sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <Card
                                            className='flex items-end justify-between card-materi m-auto'
                                            style={{
                                                backgroundColor: bg_color
                                            }}
                                        >
                                            <div className='hiasan w-full h-full flex justify-end flex-column items-end'>
                                                <div className='item-1'></div>
                                                <div className='item-2'></div>
                                                <div className='item-3'></div>
                                                <div className='item-4'></div>
                                            </div>
                                            <h4>{title}</h4>
                                        </Card>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <label>Background Cover</label>
                                        <div className='flex w-full mt-2 justify-between items-start flex-wrap'>
                                            <button
                                                className='btn-warna mr-1 mb-2 merah'
                                                onClick={() =>
                                                    this.handleChangeBG(
                                                        '#ff4646'
                                                    )
                                                }
                                                type='button'
                                            >
                                                Merah
                                            </button>
                                            <button
                                                className='btn-warna mr-1 mb-2 kuning'
                                                onClick={() =>
                                                    this.handleChangeBG(
                                                        '#ffd151'
                                                    )
                                                }
                                                type='button'
                                            >
                                                Kuning
                                            </button>
                                            <button
                                                className='btn-warna mr-1 mb-2 hijau'
                                                onClick={() =>
                                                    this.handleChangeBG(
                                                        '#16c79a'
                                                    )
                                                }
                                                type='button'
                                            >
                                                Hijau
                                            </button>
                                            <button
                                                className='btn-warna mr-1 mb-2 ungu'
                                                onClick={() =>
                                                    this.handleChangeBG(
                                                        '#a79dff'
                                                    )
                                                }
                                                type='button'
                                            >
                                                Ungu
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editMateri: (params) => dispatch(editMateriPPPK(params))
    };
};
export default connect(null, mapDispatchToProps)(EditMateri);
