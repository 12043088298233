import {
    Button,
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import { FormSearch } from 'app/components';
import {
    delTryoutSkd,
    getTryoutSKD,
    resetReducer
} from 'app/redux/actions/SKDActions';
import { formatTanggal } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';
import AddTryout from './AddTryout';
import EditTryout from './EditTryout';
import {
    deleteTryoutFromPaket,
    getTryoutPaketList
} from 'app/redux/actions/PaketTryoutActions';

class TryoutBundle extends Component {
    constructor() {
        super();
        this.state = {
            rowsPerPage: 10,
            page: 0,
            modalAdd: false,
            modalEdit: false,
            shouldOpenEditorDialog: false,
            search: '',
            parent: '',
            shouldOpenCreateDialog: false,
            itemToEdit: null,
            jenis_paket: ''
        };
    }

    componentDidMount() {
        this.getData();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    getData() {
        const { getTryoutList, resetReducer } = this.props;

        const { location } = this.props;
        const { jenis_paket } = location.state || {};
        this.setState({
            jenis_paket: jenis_paket
        });
        resetReducer();
        let parent = window.location.pathname.split('/').pop();
        this.setState({ parent: parent });
        getTryoutList(parent);
    }

    handleDelete = (tryout_id, paket_id) => {
        const { delTryoutPaket } = this.props;

        Swal.fire({
            title: 'Peringatan',
            text: 'Apakah kamu yakin ingin menghapus tryout ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            icon: 'warning'
        }).then((result) => {
            if (result.isConfirmed) {
                delTryoutPaket(paket_id, tryout_id);
            } else {
                Swal.close();
            }
        });
    };

    renderData = () => {
        const { tryout = [] } = this.props;
        const { search, page, rowsPerPage, parent } = this.state;

        let dataTryout =
            tryout != undefined
                ? tryout
                      .filter((item) =>
                          Object.keys(item).some((key) =>
                              search.includes(key)
                                  ? false
                                  : item[key]
                                        .toString()
                                        .toLowerCase()
                                        .includes(search)
                          )
                      )
                      .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                      )
                : [];
        return dataTryout.length > 0 ? (
            dataTryout.map((item, index) => (
                <TableRow key={index} hover>
                    <TableCell className='px-0' align='center'>
                        {index + 1}
                    </TableCell>

                    <TableCell className='px-0' colSpan={2}>
                        {item.title}
                    </TableCell>

                    <TableCell className='px-0'>
                        {item.description.length > 20
                            ? item.description.substr(0, 20) + '...'
                            : item.description}
                    </TableCell>

                    <TableCell className='px-0'>
                        <p className='m-0 p-0'>
                            <b>Jml Soal</b> : {item.jml_soal}
                        </p>
                        <p className='m-0 p-0'>
                            <b>Durasi :</b> {item.expires_time}
                        </p>
                    </TableCell>

                    <TableCell className='px-0'>
                        {formatTanggal(item.created_at)}
                    </TableCell>

                    <TableCell className='px-0' align='center' colSpan={2}>
                        <Link
                            to={{
                                pathname: '/bundle/tryout/soal/' + item.doc_id,
                                state: {
                                    SKDinfo: parent
                                }
                            }}
                        >
                            <IconButton title='List Soal'>
                                <Icon color='secondary'>view_list</Icon>
                            </IconButton>
                        </Link>
                        {/**
                        <Link to={'/skd/tryout/ranking/' + item.doc_id}>
                            <IconButton title='Ranking Nasional'>
                                <Icon color='secondary'>emoji_events</Icon>
                            </IconButton>
                        </Link>
                         */}
                        <IconButton
                            title='Edit'
                            onClick={() => this.handleModalEdit(item)}
                        >
                            <Icon color='inherit'>edit</Icon>
                        </IconButton>
                        <IconButton
                            title='delete'
                            onClick={() =>
                                this.handleDelete(item.doc_id, parent)
                            }
                        >
                            <Icon color='error'>delete</Icon>
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))
        ) : (
            <TableRow hover>
                <TableCell colSpan={8} align='center'>
                    Not Have Data
                </TableCell>
            </TableRow>
        );
    };

    handleSearch = (val) => {
        this.setState({
            search: val.target.value
        });
    };

    handleModalAdd = () => {
        this.setState((prefState) => ({
            shouldOpenCreateDialog: !prefState.shouldOpenCreateDialog
        }));
    };

    handleCloseEdit = () => {
        this.setState({
            shouldOpenEditorDialog: false
        });
    };

    handleModalEdit = (item) => {
        this.setState({
            shouldOpenEditorDialog: true,
            itemToEdit: item
        });
    };

    render() {
        const { tryout } = this.props;
        const {
            search,
            rowsPerPage,
            page,
            parent,
            shouldOpenCreateDialog,
            shouldOpenEditorDialog,
            itemToEdit,
            jenis_paket
        } = this.state;
        const shouldShowButton = !(
            jenis_paket === 'satuan' && tryout.length > 0
        );
        return (
            <Card className='p-5 overflow-auto' elevation={6}>
                <div className='flex justify-between'>
                    {shouldShowButton && (
                        <Button
                            variant='outlined'
                            color='primary'
                            className='mb-5'
                            onClick={this.handleModalAdd}
                            title='Edit'
                        >
                            Tambah
                        </Button>
                    )}
                    <FormSearch
                        value={search}
                        handleSearch={this.handleSearch}
                    />
                </div>
                <div className='w-full overflow-auto bg-white'>
                    <Table className='bimble-table'>
                        <TableHead className='bg-primary'>
                            <TableRow>
                                <TableCell
                                    className='text-white'
                                    align='center'
                                >
                                    No.
                                </TableCell>
                                <TableCell className='text-white' colSpan={2}>
                                    Judul
                                </TableCell>
                                <TableCell className='text-white'>
                                    Deskripsi
                                </TableCell>
                                <TableCell className='text-white'>
                                    Detail
                                </TableCell>
                                <TableCell className='text-white'>
                                    Tgl Dibuat
                                </TableCell>
                                <TableCell
                                    className='text-white'
                                    align='center'
                                    colSpan={2}
                                ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{this.renderData()}</TableBody>
                    </Table>

                    <TablePagination
                        className='px-16'
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={tryout.length}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage={'From'}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next page'
                        }}
                        backIconButtonText='Previous page'
                        nextIconButtonText='Next page'
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.setRowsPerPage}
                    />
                </div>
                {shouldOpenCreateDialog && (
                    <AddTryout
                        open={shouldOpenCreateDialog}
                        handleClose={this.handleModalAdd}
                        parent={parent}
                    />
                )}
                {shouldOpenEditorDialog && (
                    <EditTryout
                        open={shouldOpenEditorDialog}
                        handleClose={this.handleCloseEdit}
                        params={itemToEdit}
                        parent={parent}
                    />
                )}
            </Card>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        tryout: state.TryoutBundle.tryout
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTryoutList: (params) => dispatch(getTryoutPaketList(params)),
        delTryoutPaket: (paket_id, tryout_id) =>
            dispatch(deleteTryoutFromPaket(paket_id, tryout_id)),
        resetReducer: () => dispatch(resetReducer())
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TryoutBundle)
);
