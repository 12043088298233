import { Button, CircularProgress, Dialog, Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatRupiah } from 'app/redux/actions/UserActions';
import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import rules from '../../../config/formValidation';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    productTable: {
        '& thead': {
            '& th:first-child': {
                paddingLeft: 16
            }
        },
        '& td': {
            borderBottom: 'none'
        },
        '& td:first-child': {
            paddingLeft: '16px !important'
        }
    }
}));
const ModalKeranjangSKB = ({
    paket,
    harga,
    topping,
    masa_aktif,
    handleClose,
    modal,
    handleSubmitKode,
    handleChange,
    kode_voucher,
    voucher,
    loading_voucher,
    voucher_error,
    voucher_description,
    handleBeli,
    submitBeli
}) => {
    const classes = useStyles();
    return (
        <Dialog
            onClose={handleClose}
            open={modal}
            maxWidth="xs"
            fullWidth={true}
        >
            <div
                className="bg-primary flex items-center justify-center flex-column p-5 text-white"
                style={{ height: 95 }}
            >
                <h5 className="title-card text-white text-center">{paket}</h5>
                <h4 className="title-card">
                    {formatRupiah(harga - voucher, 'Rp')}
                </h4>
            </div>
            <div className="px-8 py-5">
                {topping.map((item, index) => (
                    <div className="flex items-center py-2" key={index}>
                        {item.status ? (
                            <img
                                src="/assets/images/logos/checked.svg"
                                alt="checked"
                                className="img-status-benefit"
                            />
                        ) : (
                            <img
                                src="/assets/images/logos/unchecked.svg"
                                alt="unchecked"
                                className="img-status-benefit"
                            />
                        )}
                        <p className="m-0 ml-2 font-medium">{item.benefit}</p>
                    </div>
                ))}
                <div className="border-bottom-2 mt-4"></div>
                <h6 className="mt-3">
                    Masa aktif:{' '}
                    {masa_aktif >= 12
                        ? masa_aktif / 12 + ' tahun'
                        : masa_aktif + ' bulan'}
                </h6>
                <h6 className="mb-2 mt-3">Kode voucher</h6>
                <ValidatorForm
                    onSubmit={handleSubmitKode}
                    className="flex items-center justify-between"
                >
                    <Grid container spacing={1}>
                        <Grid item lg={7} md={7} sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-0"
                                label="Kode Voucher"
                                onChange={handleChange}
                                type="text"
                                name="kode_voucher"
                                value={kode_voucher || ''}
                                validators={['required']}
                                errorMessages={[rules.required]}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={5}
                            md={5}
                            sm={12}
                            xs={12}
                            className="items-end justify-end flex"
                        >
                            <Button
                                variant="outlined"
                                type="submit"
                                color="primary"
                                className="flex items-center w-full"
                            >
                                {loading_voucher ? (
                                    <CircularProgress
                                        color="primary"
                                        size={24}
                                    />
                                ) : (
                                    <p className="m-0 text-primary font-semibold">
                                        Gunakan Kode
                                    </p>
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
                {voucher_error && (
                    <p className="mt-2 font-medium text-error">
                        Kode voucher salah.
                    </p>
                )}
                {voucher_description && (
                    <p className="mt-2 font-medium text-green">
                        {voucher_description}
                    </p>
                )}
                <div className="border-bottom-2 mt-4"></div>
                <div className="flex justify-end items-center mt-5">
                    <Button
                        variant="contained"
                        onClick={handleBeli}
                        color="primary"
                        disabled={submitBeli}
                        className={`flex items-center ${
                            submitBeli ? 'button-purple opacity' : ''
                        }`}
                    >
                        {submitBeli ? (
                            <CircularProgress color="primary" size={24} />
                        ) : (
                            <>
                                <Icon fontSize="small">shopping_cart</Icon>
                                <p className="m-0 text-white font-semibold">
                                    Beli Sekarang
                                </p>
                            </>
                        )}
                    </Button>
                    <Button
                        variant="contained"
                        color="default"
                        className="flex items-center ml-4"
                        onClick={handleClose}
                    >
                        <p className="m-0 text-white font-semibold">Batal</p>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ModalKeranjangSKB;
