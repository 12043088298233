import {
    Card,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { CardPenghasilan } from 'app/components';
import { getInvoice } from 'app/redux/actions/KomisiActions';
import { getListUser } from 'app/redux/actions/ManagementUserActions';
import { formatRupiah, formatTanggal } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class DetailKomisi extends Component {
    constructor() {
        super();
        this.state = {
            edit_widraw: {
                id_user: '',
                nominal: '',
                status: '',
                doc_id: '',
                user: null
            },
            rowsPerPage: 10,
            page: 0,
            modalKomisiReferral: false
        };
    }

    componentDidMount() {
        this.getData();
    }

    componentWillReceiveProps(nextProps) {}

    getData() {
        const { getListUser } = this.props;
        getListUser();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    renderData = (invoice) => {
        return invoice?.length > 0 ? (
            invoice.map((item, index) => {
                console.log(item.komisi);
                return item.komisi != undefined ? (
                    <TableRow hover key={index}>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins'
                            align='center'
                        >
                            {index + 1}
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins'>
                            {item.name}
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins'>
                            {formatRupiah(item.komisi, 'Rp')}
                        </TableCell>
                    </TableRow>
                ) : (
                    ''
                );
            })
        ) : (
            <TableRow hover>
                <TableCell colSpan={3} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };

    render() {
        const { dataUser } = this.props;
        dataUser.sort((a, b) => (a.komisi < b.komisi ? 1 : -1));

        return (
            <div className='m-sm-30'>
                <div className='flex items-center justify-between mt-10 mb-3'>
                    <h2 style={{ fontSize: '25px' }}>Komisi Per User</h2>
                </div>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Nama
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Total Komisi
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData(dataUser)}</TableBody>
                        </Table>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        invoice: state.invoice.data,
        dataUser: state.managementUser.list
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoice: () => dispatch(getInvoice()),
        getListUser: () => dispatch(getListUser())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailKomisi);
