import {
    GET_DETAIL_TRYOUT,
    GET_SOAL,
    RESET_KERJAKAN_TRYOUT,
    UPD_QUESTION_TRYOUT_SKD,
    GET_SUB_CATEGORY
} from '../actions/KerjakanTryoutActions';
import { GET_DETAIL_LATIHAN_PPPK, UPD_SOAL_LATIHAN_PPPK } from '../actions/PPPKActions';
import { GET_DETAIL_LATIHAN_SKD, RESET_KERJAKAN_LATIHAN, UPD_SOAL_LATIHAN_SKD } from '../actions/SKDActions';

const initialState = {
    tryout: {
        description: '',
        duration: '',
        jml_soal: '',
        title: ''
    },
    exercise: {
        description: '',
        duration: '',
        jml_soal: '',
        title: '',
        category: '',
        expires_time: 0
    },
    soal: [],
    subCategory: []
};

const KerjakanSKDReducers = function (state = initialState, action) {
    switch (action.type) {
        case GET_DETAIL_LATIHAN_SKD: {
            return {
                ...state,
                exercise: action.payload
            };
        }
        case GET_DETAIL_TRYOUT: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case GET_SOAL: {
            return {
                ...state,
                soal: action.payload
            };
        }
        case GET_SUB_CATEGORY: {
            return {
                ...state,
                subCategory: action.payload
            };
        }
        case RESET_KERJAKAN_TRYOUT: {
            return {
                tryout: {
                    description: '',
                    duration: '',
                    jml_soal: '',
                    title: ''
                },
                exercise: {
                    description: '',
                    duration: '',
                    jml_soal: '',
                    title: ''
                },
                soal: [],
                detail_jawaban: {},
                jawaban: []
            };
        }
        case RESET_KERJAKAN_LATIHAN: {
            return {
                exercise: {
                    description: '',
                    duration: '',
                    jml_soal: '',
                    title: ''
                },
                tryout: {
                    description: '',
                    duration: '',
                    jml_soal: '',
                    title: ''
                },
                soal: [],
                detail_jawaban: {},
                jawaban: []
            };
        }
        case GET_DETAIL_LATIHAN_PPPK: {
            return {
                ...state,
                exercise: action.payload
            };
        }
        case UPD_QUESTION_TRYOUT_SKD: {
            let updSoal = action.payload.params;
            let soal = state.soal;
            let indexSoal = soal.findIndex(
                (item) => item.doc_id == action.payload.doc_id
            );
            soal[indexSoal] = {
                ...soal[indexSoal],
                ...updSoal
            };
            return {
                ...state,
                soal: soal
            };
        }
        case UPD_SOAL_LATIHAN_SKD: {
            let updSoal = action.payload.params;
            let soal = state.soal;
            let indexSoal = soal.findIndex(
                (item) => item.doc_id == action.payload.doc_id
            );
            soal[indexSoal] = {
                ...soal[indexSoal],
                ...updSoal
            };
            return {
                ...state,
                soal: soal
            };
        }
        case UPD_SOAL_LATIHAN_PPPK: {
            let updSoal = action.payload.params;
            let soal = state.soal;
            let indexSoal = soal.findIndex(
                (item) => item.doc_id == action.payload.doc_id
            );
            soal[indexSoal] = {
                ...soal[indexSoal],
                ...updSoal
            };
            return {
                ...state,
                soal: soal
            };
        }
        default: {
            return state;
        }
    }
};

export default KerjakanSKDReducers;
