import { Card } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';

const CardTryoutAdmin = ({ children, title, subtitle, duration }) => {
    return (
        <Card
            elevation={0}
            className='h-full'
            style={{ background: 'transparent' }}
        >
            <div className='flex justify-between'>
                <h4 className='mb-4'>{title}</h4>
                {subtitle && (
                    <div className='card-subtitle mb-4'>{subtitle}</div>
                )}
                <div className='flex items-center'>
                    Durasi :<h4 className='m-0 ml-2'>{duration} Menit</h4>
                </div>
            </div>
            <div className='bordered mb-5'></div>
            {children}
        </Card>
    );
};

export default CardTryoutAdmin;
