import {
    LOGIN_ERROR,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    RESET_PASSWORD,
    SIGN_UP
} from '../actions/LoginActions';

const initialState = {
    success: false,
    loading: true,
    error: {
        username: null,
        password: null
    },
    signup: false
};

const LoginReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_LOADING: {
            return {
                ...state,
                loading: action.data
            };
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false
            };
        }
        case RESET_PASSWORD: {
            return {
                ...state,
                success: true,
                loading: false
            };
        }
        case LOGIN_ERROR: {
            return {
                success: false,
                loading: false,
                error: action.data
            };
        }
        case SIGN_UP: {
            return {
                signup: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default LoginReducer;
