import axios from 'axios';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_DETAIL_TRYOUT = 'GET_DETAIL_TRYOUT';
export const GET_SOAL = 'GET_SOAL';
export const RESET_KERJAKAN_TRYOUT = 'RESET_KERJAKAN_TRYOUT';
export const GET_DETAIL_TRYOUT_PPPK = 'GET_DETAIL_TRYOUT_PPPK';
export const GET_SOAL_PPPK = 'GET_SOAL_PPPK';
export const GET_DETAIL_TRYOUT_UK = 'GET_DETAIL_TRYOUT_UK';
export const GET_SOAL_UK = 'GET_SOAL_UK';
export const GET_DETAIL_TRYOUT_SKB = 'GET_DETAIL_TRYOUT_SKB';
export const GET_SOAL_SKB = 'GET_SOAL_SKB';
export const GET_USERS_TRYOUT = 'GET_USERS_TRYOUT';
export const UPD_QUESTION_TRYOUT_SKD = 'UPD_QUESTION_TRYOUT_SKD';
export const UPD_QUESTION_TRYOUT_PPPK = 'UPD_QUESTION_TRYOUT_PPPK';
export const GET_SUB_CATEGORY = 'GET_SUB_CATEGORY';
export const GET_SOAL_BUMN = 'GET_SOAL_BUMN';
export const GET_DETAIL_TRYOUT_BUMN = 'GET_DETAIL_TRYOUT_BUMN';
export const UPD_QUESTION_TRYOUT_BUMN = 'UPD_QUESTION_TRYOUT_BUMN';


export const getDetailTryout = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_TRYOUT,
                    payload: data
                });
            });
    };
};

export const shuffleArray = (array) => {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const rearrangeSKD = (array) => {
    let all = [];
    let datatwk = array
        .filter((item) => item.category == 'twk')
        // .sort((a, b) => (a.subcategory < b.subcategory ? -1 : 1))
        .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
    for (let i = 0; i < datatwk.length; i++) {
        all.push(datatwk[i]);
    }
    let datatiu = array
        .filter((item) => item.category == 'tiu')
        // .sort((a, b) => (a.subcategory < b.subcategory ? -1 : 1))
        .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
    for (let i = 0; i < datatiu.length; i++) {
        all.push(datatiu[i]);
    }
    let datatkp = array
        .filter((item) => item.category == 'tkp')
        // .sort((a, b) => (a.subcategory < b.subcategory ? -1 : 1))
        .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
    for (let i = 0; i < datatkp.length; i++) {
        all.push(datatkp[i]);
    }

    return all;
};

export const getSoalTryoutSKD = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(kode)
            .collection('question')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL,
                    payload: rearrangeSKD(data)
                });
            });
    };
};

export const getDetailTryoutPPPK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_TRYOUT_PPPK,
                    payload: data
                });
            });
    };
};

export const getSoalTryoutPPPK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .doc(kode)
            .collection('question')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL_PPPK,
                    payload: data.sort((a, b) =>
                        parseInt(a.id) > parseInt(b.id) ? 1 : -1
                    )
                });
            });
    };
};

export const getDetailTryoutBUMN = (kode) => {
  
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_bumn')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_TRYOUT_BUMN,
                    payload: data
                });
            });
    };
};


export const getSoalTryoutBUMN = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_bumn')
            .doc(kode)
            .collection('question')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL_BUMN,
                    payload: data.sort((a, b) =>
                        parseInt(a.id) > parseInt(b.id) ? 1 : -1
                    )
                });
            });
    };
};

export const addQuestionTryoutBUMN = (params, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_bumn')
            .doc(doc_id)
            .collection('question')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutBUMN(doc_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};


export const delQuestionTryoutBUMN = (tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_bumn')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutBUMN(tryout_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updQuestionTryoutBUMN = (params, tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_bumn')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: UPD_QUESTION_TRYOUT_BUMN,
                        payload: { params, doc_id }
                    });
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getDetailTryoutUK = (parent, kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('tryout')
            .doc(kode)
            .get()
            .then((res) => {
                if (res.exists) {
                    let data = res.data();
                    data.doc_id = kode;
                    dispatch({
                        type: GET_DETAIL_TRYOUT_UK,
                        payload: data
                    });
                }
            });
    };
};

export const getSoalTryoutUK = (parent, kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('tryout')
            .doc(kode)
            .collection('question')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL_UK,
                    payload: data
                });
            });
    };
};

export const getUsersTryout = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_tryout')
            .doc(kode)
            .get()
            .then((doc) => {
                let data = doc.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_USERS_TRYOUT,
                    payload: data
                });
            });
    };
};

export const getDetailTryoutSKB = (parent, kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('tryout')
            .doc(kode)
            .get()
            .then((res) => {
                if (res.exists) {
                    let data = res.data();
                    data.doc_id = kode;
                    dispatch({
                        type: GET_DETAIL_TRYOUT_SKB,
                        payload: data
                    });
                }
            });
    };
};

export const getSoalTryoutSKB = (parent, kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(parent)
            .collection('tryout')
            .doc(kode)
            .collection('question')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL_SKB,
                    payload: data.sort((a, b) =>
                        parseInt(a.id) > parseInt(b.id) ? 1 : -1
                    )
                });
            });
    };
};

export const addQuestionTryoutSKD = (params, doc_id, soalTryout) => {
    let length =
        soalTryout.filter((item) => item.category == params.category).length +
        1;
    params.id = length;
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(doc_id)
            .collection('question')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutSKD(doc_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const delQuestionTryoutSKD = (tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutSKD(tryout_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updQuestionTryoutSKD = (params, tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: UPD_QUESTION_TRYOUT_SKD,
                        payload: { params, doc_id }
                    });
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const importQuestionSKD = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}scpns/question_skd/import`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil diimport',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    if (params.type == 'pppk') {
                        dispatch(getSoalTryoutPPPK(params.doc_id));
                    } else {
                        dispatch(getSoalTryoutSKD(params.doc_id));
                    }
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const templaceQuestionSKD = () => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}scpns/question_skd/download`,
                {
                    responseType: 'blob'
                }
            )
            .then((res) => {
                saveAs(res.data, 'template_skd_pppk.csv');
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Berhasil Download',
                    icon: 'success',
                    timer: 2000
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal download',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const addQuestionTryoutPPPK = (params, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .doc(doc_id)
            .collection('question')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutPPPK(doc_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const delQuestionTryoutPPPK = (tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutPPPK(tryout_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updQuestionTryoutPPPK = (params, tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: UPD_QUESTION_TRYOUT_PPPK,
                        payload: { params, doc_id }
                    });
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getSubCategory = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('subcategory')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SUB_CATEGORY,
                    payload: data
                });
            });
    };
};
