import {
    DEL_LATIHAN_BUMN,
    DEL_MATERI_BUMN,
    GET_LATIHAN_BUMN,
    GET_MATERI_BUMN,
    GET_MATERI_BUMN_DETAIL,
    GET_BUMN_MANDIRI,
    GET_BUMN_BIMBEL,
    GET_REKAMAN,
} from '../actions/BumnActions';

const initialState = {
    data: [],
    materi: [],
    detail: {},
    latihan: [],
    dataBimbel: [],
    rekaman: []
};

const BumnReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_BUMN_BIMBEL: {
            return {
                ...state,
                dataBimbel: action.payload
            };
        }
        case GET_BUMN_MANDIRI: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_REKAMAN: {
            return {
                ...state,
                rekaman: action.payload
            };
        }
        case GET_MATERI_BUMN: {
            return {
                ...state,
                materi: action.payload
            };
        }
        case GET_MATERI_BUMN_DETAIL: {
            return {
                ...state,
                detail: action.payload
            };
        }
        case GET_LATIHAN_BUMN: {
            return {
                ...state,
                latihan: action.payload
            };
        }
        case DEL_MATERI_BUMN: {
            let materi = state.materi.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                materi: materi
            };
        }
        case DEL_MATERI_BUMN: {
            let rekaman = state.rekaman.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                rekaman: rekaman
            };
        }
        case DEL_LATIHAN_BUMN: {
            let latihan = state.latihan.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                latihan: latihan
            };
        }
        default: {
            return state;
        }
    }
};

export default BumnReducer;
