import { Button, CircularProgress, Grid } from '@material-ui/core';
import { getMyTryoutSKB, getMyTryoutSKBBundle } from 'app/redux/actions/SKBActions';
import { getMyTryoutUK, getMyTryoutUKBundle } from 'app/redux/actions/UKActions';
import { Breadcrumb, SimpleCard } from 'matx';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import rules from '../../../config/formValidation';
import { changePassword } from '../../redux/actions/UserActions';

class EditPassword extends Component {
    constructor() {
        super();
        this.state = {
            menu: 1,
            full_name: '',
            email: '',
            no_wa: '',
            password: '',
            konfirmasi_password: '',
            loadingEditProfile: false,
            loadingEditPassword: false,
            password_lama: '',
            skd_user: {},
            skd_kedinasan: {},
            pppk: {},
            alamat: '',
            jenis_kelamin: ''
        };
    }
    handleChangeMenu = (menu) => {
        this.setState({
            menu
        });
    };

    handleChange = ({ target: { name, value } }) => {
        this.setState((prefState) => ({
            ...prefState,
            [name]: value
        }));
    };
    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
        const { name, email, phone, alamat, jenis_kelamin } = this.props.user;

        this.setState({
            full_name: name,
            email: email,
            no_wa: phone ? phone.slice(3, phone.length) : '',
            alamat: alamat,
            jenis_kelamin: jenis_kelamin
        });
    }
    handleEditPassword = () => {
        this.setState(
            {
                loadingEditPassword: true
            },
            this.sendEditPassword
        );
    };
    sendEditPassword = () => {
        const { password, password_lama } = this.state;
        const params = {
            password_lama,
            password
        };
        changePassword(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil edit password',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                this.setState({
                    loadingEditPassword: false
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: 'Gagal edit password',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
                this.setState({
                    loadingEditPassword: false
                });
            });
    };
    render() {
        const {
            password_lama,
            password,
            konfirmasi_password,
            loadingEditPassword
        } = this.state;
        return (
            <div className="m-sm-30">
                <SimpleCard noPadding>
                    <div
                        className="thumbnail-profile w-full"
                        style={{
                            backgroundImage:
                                'url(/assets/images/thumbnail/thumbnail-profile.png)'
                        }}
                    ></div>
                    <div className="px-8 py-5">
                        <ValidatorForm
                            onSubmit={this.handleEditPassword}
                            className="mt-4"
                        >
                            <Grid container spacing={2} justify="center">
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-4 w-full"
                                        label="Password Lama"
                                        onChange={this.handleChange}
                                        name="password_lama"
                                        type="password"
                                        value={password_lama || ''}
                                        validators={['required']}
                                        errorMessages={[rules.required]}
                                    />
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-4 w-full"
                                        label="Password"
                                        onChange={this.handleChange}
                                        name="password"
                                        type="password"
                                        value={password || ''}
                                        validators={[
                                            'required',
                                            'minStringLength: 8'
                                        ]}
                                        errorMessages={[
                                            rules.required,
                                            'minimal panjang karakter, 8 karakter'
                                        ]}
                                    />
                                    <TextValidator
                                        variant="outlined"
                                        className="mb-4 w-full"
                                        label="Konfirmasi Password"
                                        onChange={this.handleChange}
                                        name="konfirmasi_password"
                                        type="password"
                                        value={konfirmasi_password || ''}
                                        validators={[
                                            'isPasswordMatch',
                                            'required'
                                        ]}
                                        errorMessages={[
                                            rules.isPasswordMatch,
                                            rules.required
                                        ]}
                                    />
                                    <Button
                                        variant="contained"
                                        disabled={loadingEditPassword}
                                        color="primary"
                                        type="submit"
                                        className={`elevation-z0 ${
                                            loadingEditPassword
                                                ? 'button-purple opacity'
                                                : ''
                                        }`}
                                    >
                                        {loadingEditPassword ? (
                                            <CircularProgress
                                                color="primary"
                                                size={24}
                                            />
                                        ) : (
                                            'Edit Password'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </div>
                </SimpleCard>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMyTryoutSKB: () => dispatch(getMyTryoutSKB()),
        getMyTryoutSKBBundle: () => dispatch(getMyTryoutSKBBundle()),
        getMyTryoutUK: () => dispatch(getMyTryoutUK()),
        getMyTryoutUKBundle: () => dispatch(getMyTryoutUKBundle())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPassword);
