/* eslint-disable react-hooks/exhaustive-deps */
import {
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    setAuthLoadingStatus
} from 'app/redux/actions/LoginActions';
import React, { Children, Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getNavigationByUser } from '../redux/actions/NavigationAction';
import { setUserData } from '../redux/actions/UserActions';
import firebaseAuthService from '../services/firebase/firebaseAuthService';

const checkFirebaseAuth = (dispatch, setUserData, history) => {
    firebaseAuthService.checkAuthStatus(async (user) => {
        if (user) {
            const tokenResult = await user?.getIdTokenResult();
            const isAdmin = tokenResult.claims.admin;

            if (isAdmin) {
                await firebaseAuthService.firestore
                    .collection('admin')
                    .doc(user.uid)
                    .get()
                    .then(async function (doca) {
                        firebaseAuthService.firestore
                            .collection('admin')
                            .doc(user.uid)
                            .onSnapshot(async function (doc) {
                                if (doc.exists) {
                                    localStorage.setItem('uid', user.uid);
                                    await dispatch(
                                        setUserData({
                                            userId: user.uid,
                                            role: 'ADMIN',
                                            emailVerified: user.emailVerified,
                                            ...doc.data()
                                        })
                                    );
                                    dispatch({
                                        type: LOGIN_SUCCESS
                                    });
                                    if (
                                        user.emailVerified != undefined &&
                                        !user.emailVerified
                                    ) {
                                        history.push('/verifikasi_email');
                                    }
                                } else {
                                    dispatch(setUserData(null));
                                    dispatch({
                                        type: LOGIN_SUCCESS
                                    });
                                }
                            });
                    });
            } else {
                dispatch(setUserData(null));
                dispatch({
                    type: LOGIN_ERROR
                });
            }
        } else {
            dispatch(setUserData(null));
            dispatch({
                type: LOGIN_ERROR
            });
        }
        return dispatch(setAuthLoadingStatus(false));
    });
};

const Auth = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setAuthLoadingStatus(true));
        checkFirebaseAuth(dispatch, setUserData, history);
    }, [setUserData, getNavigationByUser]);

    return <Fragment>{children}</Fragment>;
};

export default Auth;
