import React, { useState, useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Swal from 'sweetalert2';
import { Icon, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { delTryoutPaket } from 'app/redux/actions/PaketTryoutActions';
import { useDispatch, connect } from 'react-redux';

const CardTable = ({ data, handleModalEdit, query }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dispatch = useDispatch();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = useCallback(
        (doc_id) => {
            Swal.fire({
                title: 'Peringatan',
                text: 'Apakah kamu yakin ingin menghapus tryout ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                icon: 'warning'
            }).then((res) => {
                if (res.isConfirmed) {
                    dispatch(delTryoutPaket(doc_id));
                } else {
                    Swal.close();
                }
            });
        },
        [dispatch]
    );

    const renderData = () => {
        return data
            .filter((item) =>
                item.title.toLowerCase().includes(query.toLowerCase())
            )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
                <TableRow key={row.id}>
                    <TableCell align='center'>
                        {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell colSpan={1}>{row.title}</TableCell>
                    <TableCell>{row.jenis_paket}</TableCell>
                    <TableCell>{row.harga}</TableCell>
                    <TableCell>{row.harga_discount}</TableCell>
                    <TableCell align='center'>
                        {row.status === 'aktif' ? 'Aktif' : 'Tidak Aktif'}
                    </TableCell>
                    <TableCell align='center' colSpan={1}>
                        <Link
                            to={{
                                pathname: `/bundle/detail/${row.doc_id}`,
                                state: { jenis_paket: row.jenis_paket }
                            }}
                        >
                            <IconButton title='Lihat Detail'>
                                <Icon color='secondary'>find_in_page</Icon>
                            </IconButton>
                        </Link>
                        <IconButton
                            title='Edit'
                            onClick={() => handleModalEdit(row)}
                        >
                            <Icon color='inherit'>edit</Icon>
                        </IconButton>
                        <IconButton
                            title='delete'
                            onClick={() => handleDelete(row.doc_id)}
                        >
                            <Icon color='error'>delete</Icon>
                        </IconButton>
                    </TableCell>
                </TableRow>
            ));
    };

    return (
        <>
            <Table className='bimble-table'>
                <TableHead className='bg-primary'>
                    <TableRow>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                            align='center'
                        >
                            No
                        </TableCell>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                            colSpan={1}
                        >
                            Nama Paket
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                            Jenis Paket
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                            Harga
                        </TableCell>
                        <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                            Diskon
                        </TableCell>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                            align='center'
                        >
                            Status
                        </TableCell>
                        <TableCell
                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                            align='center'
                            colSpan={1}
                        >
                            Aksi
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{renderData()}</TableBody>
            </Table>
            <TablePagination
                className='px-16'
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={data.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={'From'}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous page'
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next page'
                }}
                backIconButtonText='Previous page'
                nextIconButtonText='Next page'
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};
export default connect()(CardTable);
