import { Card } from '@material-ui/core';
import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';

const ChartTWKDetail = ({ data: { nasionalisme, integritas, bela, pilar, bahasa } }) => {
	let data = {
		labels: ["Nasionalisme", "Integritas", "Bela Negara", "Pilar Negara", "Bahasa Indonesia"],
		datasets: [
			{
				data: [nasionalisme, integritas, bela, pilar, bahasa],
				backgroundColor: [
					"rgba(0, 183, 194, 0.4)",
					"rgba(22, 199, 154, 0.4)",
					"rgba(255, 226, 39, 0.4)",
					"rgba(255, 136, 75, 0.4)",
					"rgba(105,48,195,0.4)",
				],
				borderColor: [
					"rgba(0, 183, 194, 1)",
					"rgba(22, 199, 154, 1)",
					"rgba(255, 226, 39, 1)",
					"rgba(255, 136, 75, 1)",
					"rgba(105,48,195,1)",
				],
				borderWidth: 1,
			},
		],
	};
	let option = {
		legend: false,
	};
	return (
		<Card className="text-center py-6 border-radius-4 h-full px-10" elevation={3}>
			<h5 className="font-bold">TWK - Tes Wawancara Kebangsaan</h5>
			<h6 className="font-bold mb-4">Jawban Benar</h6>
			<HorizontalBar data={data} options={option} />
		</Card>
	);
};

export default ChartTWKDetail;
