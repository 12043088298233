import {
    GET_DETAIL_TRYOUT_SKB,
    GET_SOAL_SKB,
    GET_USERS_TRYOUT,
    RESET_KERJAKAN_TRYOUT,
} from '../actions/KerjakanTryoutActions';

const initialState = {
    tryout: {
        description: '',
        duration: '',
        jml_soal: '',
        title: ''
    },
    soal: [],
    users_tryout: []
};

const KerjakanSKBReducers = function (state = initialState, action) {
    switch (action.type) {
        case GET_DETAIL_TRYOUT_SKB: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case GET_SOAL_SKB: {
            return {
                ...state,
                soal: action.payload
            };
        }
        case RESET_KERJAKAN_TRYOUT: {
            return {
                tryout: {
                    description: '',
                    duration: '',
                    jml_soal: '',
                    title: ''
                },
                soal: [],
                detail_jawaban: {},
                jawaban: []
            };
        }
        case GET_USERS_TRYOUT: {
            return {
                ...state,
                users_tryout: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default KerjakanSKBReducers;
