import {
    GET_DETAIL_PEMBAHASAN,
    GET_SOAL_PEMBAHASAN,
    SEARCH_PEMBAHASAN,
    SEARCH_PEMBAHASAN_DINAS,
} from '../actions/PembahasanSKDActions';

const initialState = {
	data: [],
	data_ori: [],
	detail: {},
};

const PembahasanSKDReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_SOAL_PEMBAHASAN: {
			return {
				...state,
				data: action.payload,
				data_ori: action.payload,
			};
		}
		case GET_DETAIL_PEMBAHASAN: {
			return {
				...state,
				detail: action.payload,
			};
		}
		case SEARCH_PEMBAHASAN: {
			return {
				...state,
				data: state.data_ori.filter((item) => item.title.toLowerCase().includes(action.payload.key.toLowerCase())),
			};
		}
		case SEARCH_PEMBAHASAN_DINAS: {
			return {
				...state,
				data: state.data_ori.filter((item) => item.title.toLowerCase().includes(action.payload.key.toLowerCase()) && item.type == "dinas"),
			};
		}
		default: {
			return state;
		}
	}
};

export default PembahasanSKDReducer;
