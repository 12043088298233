import {
    Button,
    Card,
    Dialog,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { ButtonUploadThumbnail } from 'app/components';
import { addQuestionTryout } from 'app/redux/actions/UKActions';
import { RichTextEditor } from 'matx';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddTryoutDialog extends Component {
    state = {
        title: '',
        description: '',
        statusButton: false,
        buttonText: 'Tambah',
        isActive: false,
        is_active: '1',
        formasi_id: Math.floor(Math.random() * Math.floor(3000)),
        duration: 0,
        price: 0,
        question_count: 0,
        expired_month: 12,
        question_text: '',
        pembahasan: '',
        preview_file: '',
        question_img: '',
        answer: [],
        form_answer: {
            value: 0,
            text: '',
            index: '',
            isNew: true,
            isImg: false,
            error: '',
            img: '',
            img_preview: ''
        },
        qt_code: '',
        pembahasan_img: '',
        preview_file_pembahasan: '',
        isImg: false,
        preview_img_answer: '',
        video_pembahasan: '',
        totalSoal: 0
    };

    componentDidMount() {
        const { doc_id, parent } = this.props.params;
        const { totalSoal } = this.props;
        this.setState({
            doc_id: doc_id,
            parent: parent,
            totalSoal: totalSoal
        });
    }

    handleChange = (event, source) => {
        event.persist();
        if (source === 'switch') {
            // console.log(event.target);
            this.setState({ isActive: event.target.checked });
            return;
        }

        if (source === 'status') {
            if (event.target.value === 'SUSPEND') {
                this.setState({
                    users_status: 0,
                    users_status_value: 'SUSPEND'
                });
                return;
            } else {
                this.setState({
                    users_status: 1,
                    users_status_value: 'ACTIVE'
                });
                return;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeSelect = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = async () => {
        this.setState({
            statusButton: true,
            buttonText: 'Loading...'
        });
        let {
            answer,
            question_text,
            pembahasan,
            question_img,
            doc_id,
            parent,
            pembahasan_img,
            video_pembahasan,
            totalSoal
        } = this.state;
        let allAnswer = [];
        for (let i = 0; i < answer.length; i++) {
            const data = {
                opt: Math.abs((i + 10).toString()).toString(16),
                value: answer[i].value,
                text: answer[i].text,
                img: answer[i].img
            };
            allAnswer.push(data);
        }
        let params = {
            option: allAnswer,
            question_text: question_text,
            pembahasan: pembahasan,
            question_img: question_img,
            pembahasan_img: pembahasan_img,
            video_pembahasan: video_pembahasan,
            created_at: new Date(),
            id: totalSoal + 1
        };
        await this.props.addQuestionTryout(params, parent, doc_id);
        await this.props.handleClose();
    };

    handleUploadThumbnail = (file, path) => {
        this.setState({
            question_img: file,
            preview_file: path
        });
    };

    handleUploadThumbnailAnswer = (file, path) => {
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                img_preview: path,
                img: file
            },
            preview_img_answer: path
        }));
    };

    handleUploadPembahasan = (file, path) => {
        this.setState({
            pembahasan_img: file,
            preview_file_pembahasan: path
        });
    };

    handleEditAnswer = (item, index) => {
        let value = item.value;
        let text = item.text;
        let img = item.img;
        let preview_img = item.img_preview;
        let isImg = false;

        if (img.length > 0) {
            isImg = true;
        } else {
            isImg = false;
        }
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                value: value,
                text: text,
                isNew: false,
                index: index,
                img_preview: preview_img,
                img: img
            },
            preview_img_answer: preview_img,
            isImg: isImg
        }));
    };

    handleChangeTextOpt = (event) => {
        let value = event.target.value;
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                text: value
            }
        }));
    };

    handleValueBenefit = (event) => {
        let checked = event.target.checked;
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                value: checked ? 1 : 0
            }
        }));
    };

    handleDeleteAnswer = (idx) => {
        const { answer } = this.state;
        let allAnswer = answer.filter((item, index) => index != idx);
        this.setState({
            answer: allAnswer
        });
    };

    handleFormAnswer = () => {
        const {
            form_answer: { text, isNew, index, value, img, img_preview },
            answer
        } = this.state;
        let opt = answer.length;
        if (text.length > 0 || img.length > 0) {
            if (isNew) {
                let allAnswer = answer.concat({
                    value: value,
                    text: text,
                    img: img,
                    img_preview: img_preview,
                    opt: Math.abs((opt + 10).toString()).toString(16)
                });
                this.setState({
                    answer: allAnswer,
                    form_answer: {
                        value: 1,
                        text: '',
                        index: '',
                        isNew: true,
                        error: '',
                        img: '',
                        img_preview: ''
                    },
                    preview_img_answer: ''
                });
            } else {
                let allAnswer = answer;
                allAnswer[index] = {
                    value: value,
                    text: text,
                    img: img,
                    img_preview: img_preview,
                    opt: Math.abs((index + 10).toString()).toString(16)
                };
                this.setState({
                    answer: allAnswer,
                    form_answer: {
                        value: 1,
                        text: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            }
        } else {
            this.setState((prefState) => ({
                form_answer: {
                    ...prefState.form_answer,
                    error: 'Tolong isi semua inputan'
                }
            }));
        }
    };

    handleDelImg = () => {
        this.setState({
            question_img: '',
            preview_file: ''
        });
    };

    handleDelImgPembahasan = () => {
        this.setState({
            pembahasan_img: '',
            preview_file_pembahasan: ''
        });
    };

    handleDelImgAnswer = () => {
        this.setState((prefState) => ({
            form_answer: {
                ...prefState.form_answer,
                img: '',
                img_preview: ''
            },
            preview_img_answer: ''
        }));
    };

    handleChangeMenu = (menu) => {
        let status = true;
        if (menu == 1) {
            status = false;
        } else {
            status = true;
        }
        this.setState({
            isImg: status
        });
    };

    handleChangeQuestion = (val) => {
        this.setState({
            question_text: val
        });
    };

    handleChangePembahasan = (val) => {
        this.setState({
            pembahasan: val
        });
    };

    render() {
        let {
            question_text,
            pembahasan,
            preview_file,
            answer,
            form_answer,
            preview_file_pembahasan,
            isImg,
            preview_img_answer,
            video_pembahasan
        } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
                <h5 className='m-5'>Tambah Pertanyaan</h5>
                <div className='p-5' style={{ minWidth: '500px' }}>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={4}>
                            <Grid item sm={12} xs={12} className='pb-0'>
                                <InputLabel id='demo-simple-select-helper-label'>
                                    Gambar Untuk Soal (optional)
                                </InputLabel>
                                <div
                                    style={{ width: 100, height: 100 }}
                                    className='relative mt-3'
                                >
                                    {preview_file && (
                                        <img
                                            src={preview_file}
                                            width='100%'
                                            height='100%'
                                            style={{ objectFit: 'cover' }}
                                        />
                                    )}
                                    <ButtonUploadThumbnail
                                        uploadFoto={this.handleUploadThumbnail}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} className='mt-2'>
                            <Grid item sm={12} xs={12}>
                                <h6>Pertanyaan</h6>
                                <RichTextEditor
                                    name='question_text'
                                    content={question_text || ''}
                                    handleContentChange={
                                        this.handleChangeQuestion
                                    }
                                    placeholder='Pertanyaan'
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <h6>Pembahasan</h6>
                                <RichTextEditor
                                    name='pembahasan'
                                    content={pembahasan || ''}
                                    handleContentChange={
                                        this.handleChangePembahasan
                                    }
                                    placeholder='Pembahasan'
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Link Video Pembahasan'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='video_pembahasan'
                                    value={video_pembahasan}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12} className='pb-0'>
                                <InputLabel id='demo-simple-select-helper-label'>
                                    Gambar Untuk Pembahasan (optional)
                                </InputLabel>
                                <div
                                    style={{ width: 100, height: 100 }}
                                    className='relative mt-3'
                                >
                                    {preview_file_pembahasan && (
                                        <img
                                            src={preview_file_pembahasan}
                                            width='100%'
                                            height='100%'
                                            style={{ objectFit: 'cover' }}
                                        />
                                    )}
                                    <ButtonUploadThumbnail
                                        uploadFoto={this.handleUploadPembahasan}
                                        value={preview_file_pembahasan}
                                        handleDelFile={
                                            this.handleDelImgPembahasan
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <h5 className='mt-10'>Option Jawaban</h5>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <div className='w-full overflow-auto bg-white'>
                                    <Table className='crud-table'>
                                        <TableHead className='bg-primary'>
                                            <TableRow>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    Option
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    colSpan={2}
                                                >
                                                    Teks Jawaban
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    Benar/Salah
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                ></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {answer.length > 0 ? (
                                                answer.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-medium'
                                                            align='center'
                                                        >
                                                            {Math.abs(
                                                                (
                                                                    index + 10
                                                                ).toString()
                                                            ).toString(16)}
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-medium'
                                                            colSpan={2}
                                                        >
                                                            {item.text &&
                                                                item.text}
                                                            {item.img_preview && (
                                                                <img
                                                                    src={
                                                                        item.img_preview
                                                                    }
                                                                    alt='...'
                                                                    style={{
                                                                        maxWidth:
                                                                            '100px'
                                                                    }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-medium'
                                                            align='center'
                                                        >
                                                            <div
                                                                className={`badge-status-benefit ${
                                                                    item.value ==
                                                                    1
                                                                        ? 'bg-green'
                                                                        : 'bg-error'
                                                                }`}
                                                            >
                                                                {item.value ==
                                                                1 ? (
                                                                    <Icon>
                                                                        check
                                                                    </Icon>
                                                                ) : (
                                                                    <Icon>
                                                                        close
                                                                    </Icon>
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            align='center'
                                                        >
                                                            <IconButton
                                                                title='edit'
                                                                onClick={() =>
                                                                    this.handleEditAnswer(
                                                                        item,
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Icon color='primary'>
                                                                    border_color
                                                                </Icon>
                                                            </IconButton>
                                                            <IconButton
                                                                title='delete'
                                                                onClick={() =>
                                                                    this.handleDeleteAnswer(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Icon color='error'>
                                                                    delete
                                                                </Icon>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        className='px-2 py-2 text-center font-poppins font-bold'
                                                        align='center'
                                                        colSpan={5}
                                                    >
                                                        Tidak ada benefit paket
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <h6 className='mt-0'>
                                    {form_answer.isNew
                                        ? 'Tambah Option'
                                        : 'Edit Option'}
                                </h6>
                                <Card style={{ borderRadius: 5 }}>
                                    <Grid container>
                                        <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                        >
                                            <div className='flex items-center'>
                                                <Button
                                                    onClick={() =>
                                                        this.handleChangeMenu(1)
                                                    }
                                                    variant={
                                                        isImg == false
                                                            ? 'contained'
                                                            : null
                                                    }
                                                    color='primary'
                                                    className='elevation-z0 no-border'
                                                    style={{ borderRadius: 5 }}
                                                >
                                                    Teks
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        this.handleChangeMenu(2)
                                                    }
                                                    variant={
                                                        isImg == true
                                                            ? 'contained'
                                                            : null
                                                    }
                                                    color='primary'
                                                    className='elevation-z0 no-border'
                                                    style={{ borderRadius: 5 }}
                                                >
                                                    Gambar
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Card>
                                {isImg == true ? (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            xs={12}
                                            className='pb-0'
                                        >
                                            <div
                                                style={{
                                                    width: 100,
                                                    height: 100
                                                }}
                                                className='relative mt-3'
                                            >
                                                {preview_img_answer && (
                                                    <img
                                                        src={preview_img_answer}
                                                        width='100%'
                                                        height='100%'
                                                        style={{
                                                            objectFit: 'cover',
                                                            maxWidth: '100px'
                                                        }}
                                                    />
                                                )}
                                                <ButtonUploadThumbnail
                                                    uploadFoto={
                                                        this
                                                            .handleUploadThumbnailAnswer
                                                    }
                                                    value={preview_img_answer}
                                                    handleDelFile={
                                                        this.handleDelImgAnswer
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <TextValidator
                                            className='w-full'
                                            label='Teks Jawaban'
                                            onChange={this.handleChangeTextOpt}
                                            type='text'
                                            name='text'
                                            value={form_answer.text}
                                        />
                                    </>
                                )}
                                {form_answer.error.length > 0 ? (
                                    <small className='text-error'>
                                        {form_answer.error}
                                    </small>
                                ) : null}
                                <div className='flex items-center justify-between'>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={form_answer.value == 1}
                                                name='value'
                                                onChange={
                                                    this.handleValueBenefit
                                                }
                                                color='primary'
                                            />
                                        }
                                        label='Jawaban Soal ?'
                                        className='mt-1'
                                    />
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={this.handleFormAnswer}
                                        className='elevation-z0 mt-1 w-full max-w-195'
                                    >
                                        {form_answer.isNew
                                            ? 'Tambah Option'
                                            : 'Edit Option'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={this.state.statusButton}
                                color='primary'
                                type='submit'
                            >
                                {this.state.buttonText}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        tryout: state.uk.tryout
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addQuestionTryout: (params, id, doc_id) =>
            dispatch(addQuestionTryout(params, id, doc_id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTryoutDialog);
