import { ADD_BUKU, DELETE_BUKU, EDIT_BUKU, GET_BUKU, SET_LOADING } from '../actions/BukuActions';

const initialState = {
    data: [],
    loading: false
};

const BukuReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case GET_BUKU: {
            return {
                data: action.payload,
                loading: false
            };
        }
        case DELETE_BUKU: {
            return {
                data: state.data.filter(
                    (item) => item.doc_id != action.payload
                ),
                loading: false
            };
        }
        case ADD_BUKU: {
            return {
                data: state.data.concat(action.payload),
                loading: false
            };
        }
        case EDIT_BUKU: {
            let data = state.data.filter(
                (item) => item.doc_id != action.payload.doc_id
            );
            return {
                data: data.concat(action.payload),
                loading: false
            };
        }
        default: {
            return state;
        }
    }
};

export default BukuReducer;
