import { GET_ALL_GROUP } from '../actions/GroupActions';

const initialState = {
    data: []
};

const GroupReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_GROUP: {
            return {
                data: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default GroupReducer;
