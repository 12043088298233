import 'katex/dist/katex.min.css';
import 'mathquill4quill/mathquill4quill.css';
import 'react-quill/dist/quill.snow.css';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { createRef, useEffect } from 'react';
import ReactQuill from 'react-quill';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    editor: {
        '& .ql-container': {
            minHeight: 250,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            '& p,code': {
                fontSize: 16
            }
        },
        '& .ql-toolbar': {
            background: 'white',
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5
        }
    }
}));

const RichTextEditor = ({ content, placeholder, handleContentChange }) => {
    const classes = useStyles();
    const reactQuill = createRef();
    // React.useEffect(() => {
    //     const enableMathQuillFormulaAuthoring = mathquill4quill({
    //         Quill,
    //         katex
    //         // MathQuill,
    //     });
    //     enableMathQuillFormulaAuthoring(reactQuill.current.editor, {
    //         operators: [
    //             ['\\sqrt[n]{x}', '\\nthroot'],
    //             ['\\frac{x}{y}', '\\frac'],
    //             ['\\sqrt{y}', '\\sqrt'],
    //             ['\\int_a^b', '\\int'],
    //             ['\\pm', '\\pm'],
    //             ['\\pi', '\\pi'],
    //             ['\\f{x}dx', '\\f'],
    //             ['\\sum_a^bd', '\\sum']
    //         ],
    //         displayHistory: true, // defaults to false
    //         historyCacheKey: '__mavka_quill_formula_history_cachekey_', // optional
    //         historySize: 3 // optional (defaults to 10)
    //     });
    // }, []);

    return (
        <ReactQuill
            // ref={reactQuill}
            className={classes.editor}
            theme='snow'
            onChange={handleContentChange}
            value={content}
            modules={RichTextEditor.modules}
            formats={RichTextEditor.formats}
            placeholder={placeholder}
        />
    );
};

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RichTextEditor.modules = {
    // formula: true,
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ['bold', 'italic', 'underline', 'strike'],

        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        ['clean', 'image']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: true
    }
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
RichTextEditor.formats = [
    'align',
    'background',
    'bold',
    'blockquote',
    'bullet',
    'color',
    'code',
    'code-block',
    'clean',
    'direction',
    'font',
    'header',
    'italic',
    'indent',
    'image',
    'list',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'video'
];

/*
 * PropType validation
 */
RichTextEditor.propTypes = {
    placeholder: PropTypes.string
};

export default RichTextEditor;
