import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_ALL_BATCH = 'GET_ALL_BATCH';
export const ADD_BATCH = 'ADD_BATCH';
export const EDIT_BATCH_TO_PPPK_BIMBEL = 'EDIT_BATCH_TO_PPPK_BIMBEL';
export const DELETE_TAHUN_TO_PPPK_BIMBEL = 'DELETE_TAHUN_TO_PPPK_BIMBEL';
export const SET_LOADING = 'SET_LOADING';
export const GET_DEFAULT_BATCH = 'GET_DEFAULT_BATCH';
export const GET_WEB_SETTING = 'GET_WEB_SETTING';
export const ADD_TAHUN_TO_PPPK_BIMBEL = 'ADD_TAHUN_TO_PPPK_BIMBEL';
export const GET_ALL_TAHUN_TO_PPPK_BIMBEL = 'GET_ALL_TAHUN_TO_PPPK_BIMBEL';

export const getAllTahun = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk_bimbel_to')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    item.created_at = item.created_at;
                    data.push(item);
                });
                dispatch({
                    type: GET_ALL_TAHUN_TO_PPPK_BIMBEL,
                    payload: data
                });
            });
    };
};

export const deleteTahunTo = (doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('pppk_bimbel_to')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tahun Tryout berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_TAHUN_TO_PPPK_BIMBEL,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Vocuher gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editTahunTo = (params) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('pppk_bimbel_to')
            .doc(params.doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tahun TO berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getAllTahun());
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Batch gagal diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};


export const updateDefaultBatch = (params) => {
    return (dispatch) => {
       dispatch({
           type: SET_LOADING,
           payload: true
       });
        firebaseAuthService.firestore
            .collection('web_setting')
            .doc('batch')
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Ubah Batch berhasil',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: EDIT_BATCH_TO_PPPK_BIMBEL,
                    payload: params
                });
            })
            .catch((err) => {
              debugger
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Ubah Batch gagal',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getDefaultBatch = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('web_setting')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_WEB_SETTING,
                    payload: data
                });
            });
    };
};


export const addTahunToPPPKBimbel = (params) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('pppk_bimbel_to')
            .doc(params.doc_id)
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tahun Tryout berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getAllTahun());
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Voucher gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};