import { Button, Dialog, Fab, Icon } from '@material-ui/core';
import { UploadForm } from 'app/components';
import { templaceQuestionSKD } from 'app/redux/actions/KerjakanTryoutActions';
import { addQuestionTryout, downloadTemplateQuestion, importQuestion } from 'app/redux/actions/SKBActions';
import { importSoalLatihanSKD } from 'app/redux/actions/SKDActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { saveAs } from 'file-saver';

class ImportQuestionDialog extends Component {
    state = {
        file: '',
        doc_id: '',
        disabled: false,
        text: 'Import',
        bg: 'bg-primary'
    };

    componentDidMount() {
        const { id } = this.props.params;
        this.setState({
            doc_id: id
        });
    }

    handleChangeInputProfile = (event, source) => {
        this.setState({
            file_preview: event.preview,
            file: event.url
        });
    };

    downloadTemplate = () => {
        this.props.downloadTemplateQuestion();
    };

    handleImportQuestion = () => {
        this.setState(
            {
                disabled: true,
                text: 'Sedang mengimport...',
                bg: 'bg-light-dark'
            },
            this.sendImport
        );
    };

    sendImport = () => {
        const { importQuestion, handleClose } = this.props;
        let { doc_id, file } = this.state;
        let params = {
            file: file,
            type: 'latihan',
            collection: 'exercise_skd',
            doc_id: doc_id
        };
        importQuestion(params);
        handleClose();
        this.setState({
            disabled: false,
            text: 'Import',
            bg: 'bg-primary'
        });
    };

    render() {
        let { open, handleClose } = this.props;
        let { disabled, text, bg } = this.state;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
                <div
                    className='flex m-5 items-center'
                    style={{ justifyContent: 'space-between' }}
                >
                    <h5>Import Pertanyaan</h5>
                    <Fab
                        className='capitalize'
                        color='primary'
                        component='span'
                        variant='extended'
                        size='small'
                        onClick={this.downloadTemplate}
                    >
                        <div className='flex items-center'>
                            <Icon className='pr-8'>file_download</Icon>
                            <span>Download Template File</span>
                        </div>
                    </Fab>
                </div>
                <div className='p-5' style={{ minWidth: '500px' }}>
                    <div>
                        <UploadForm
                            handleChange={this.handleChangeInputProfile}
                        />
                        {this.state.file && (
                            <Button
                                variant='contained'
                                className={`${bg} text-white`}
                                onClick={this.handleImportQuestion}
                                disabled={disabled}
                            >
                                {text}
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        tryout: state.skb.tryout
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addQuestionTryout: (params, id, doc_id) =>
            dispatch(addQuestionTryout(params, id, doc_id)),
        downloadTemplateQuestion: () => dispatch(templaceQuestionSKD()),
        importQuestion: (params) => dispatch(importSoalLatihanSKD(params))
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportQuestionDialog);
