import {
    Card,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button
} from '@material-ui/core';
import { getDetRankingSKD } from 'app/redux/actions/HasilSKDActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

class TryoutRanking extends Component {
    constructor() {
        super();
        this.state = {
            limit: 500,
            loadMore: true,
            recentLoad: 0,
            result: []
        };
    }
    async componentDidMount() {
        const { id } = this.props.match.params;
        const { getDetRankingSKD } = this.props;
        getDetRankingSKD(id);
        // console.log('EEEEEEEEEEEEEEEEEEEEEE', _.last(this.state.result))
    }

    loadMore = async () => {
        let { limit } = this.state;
        const { id } = this.props.match.params;
        const { getDetailRankingSKD } = this.props;
        this.setState({
            limit: limit + 5
        });

        await getDetailRankingSKD(
            id,
            this.state.limit,
            _.last(this.state.result)
        );
    };

    render() {
        const { detRank, loading } = this.props.hasilSKD;
        const { loadMore } = this.state;
        return (
            <div className='m-sm-30'>
                <h2 style={{ fontSize: '25px' }} className='mb-3'>
                    Ranking Nasional
                </h2>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='crud-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Ranking
                                    </TableCell>
                                    <TableCell className='py-2 text-center font-poppins font-bold text-white'>
                                        Nama
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Skor TWK
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Skor TIU
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Skor TKP
                                    </TableCell>
                                    <TableCell
                                        className='py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Skor Akhir
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow hover>
                                        <TableCell align='center' colSpan={6}>
                                            <CircularProgress
                                                size={25}
                                                color='primary'
                                            />
                                        </TableCell>
                                    </TableRow>
                                ) : detRank?.ranking?.length > 0 ? (
                                    detRank?.ranking?.map((item, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell
                                                className={`py-2 text-center font-poppins font-semibold ${
                                                    index < 9
                                                        ? 'text-green'
                                                        : 'text-error'
                                                }`}
                                                align='center'
                                            >
                                                {index == 0 ? (
                                                    <img
                                                        src='/assets/images/logos/medal-gold.svg'
                                                        className='img-medal'
                                                        alt='medal-gold'
                                                    />
                                                ) : index == 1 ? (
                                                    <img
                                                        src='/assets/images/logos/medal-silver.svg'
                                                        className='img-medal'
                                                        alt='medal-silver'
                                                    />
                                                ) : index == 2 ? (
                                                    <img
                                                        src='/assets/images/logos/medal-bronze.svg'
                                                        className='img-medal'
                                                        alt='medal-bronze'
                                                    />
                                                ) : (
                                                    index + 1
                                                )}
                                            </TableCell>
                                            <TableCell className='py-2 text-center font-poppins'>
                                                {item.users_name != null
                                                    ? item.users_name
                                                    : 'Sahabat CPNS'}
                                            </TableCell>
                                            <TableCell
                                                className='py-2 text-center font-poppins'
                                                align='center'
                                            >
                                                {item.twk_point}
                                            </TableCell>
                                            <TableCell
                                                className='py-2 text-center font-poppins'
                                                align='center'
                                            >
                                                {item.tiu_point}
                                            </TableCell>
                                            <TableCell
                                                className='py-2 text-center font-poppins'
                                                align='center'
                                            >
                                                {item.tkp_point}
                                            </TableCell>
                                            <TableCell
                                                className='py-2 text-center font-poppins'
                                                align='center'
                                            >
                                                {item.point}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow hover>
                                        <TableCell
                                            className='py-2 text-center font-poppins'
                                            align='center'
                                            colSpan={6}
                                        >
                                            Data tidak ditemukan
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        {/* {data.length > 0 && loadMore ? (
                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='button'
                                    onClick={this.loadMore}
                                    className='m-2'
                                >
                                    Load More
                                </Button>
                            </div>
                        ) : (
                            ''
                        )} */}
                    </div>
                </Card>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        hasilSKD: state.hasilSKD
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetRankingSKD: (kode) => dispatch(getDetRankingSKD(kode))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TryoutRanking);
