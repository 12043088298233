import { EDIT_WEB_SETTING, GET_WEB_SETTING } from '../actions/WebSettingActions';

const initialState = {
    data: []
};

const WebSettingReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_WEB_SETTING: {
            return {
                data: action.payload
            };
        }
        case EDIT_WEB_SETTING: {
            let data = state.data;
            let indexData = state.data.findIndex(
                (item) => item.doc_id == action.payload.doc_id
            );
            if (indexData >= 0) {
                data[indexData] = {
                    ...data[indexData],
                    ...action.payload.params
                };
            }
            return {
                data: data
            };
        }
        default: {
            return state;
        }
    }
};

export default WebSettingReducer;
