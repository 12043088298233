import LaporanSoal from './LaporanSoal';

const LaporanSoalRoutes = [
	{
		path: "/laporan_soal",
		component: LaporanSoal,
	},
];

export default LaporanSoalRoutes;
