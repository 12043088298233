import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_ALL_BATCH = 'GET_ALL_BATCH';
export const ADD_BATCH = 'ADD_BATCH';
export const EDIT_BATCH = 'EDIT_BATCH';
export const DELETE_BATCH = 'DELETE_BATCH';
export const SET_LOADING = 'SET_LOADING';
export const GET_DEFAULT_BATCH = 'GET_DEFAULT_BATCH';
export const GET_WEB_SETTING = 'GET_WEB_SETTING';

export const getAllBatch = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('batchSKD')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    item.created_at = item.created_at;
                    data.push(item);
                });
                dispatch({
                    type: GET_ALL_BATCH,
                    payload: data
                });
            });
    };
};

export const addBatch = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        // debugger
        firebaseAuthService.firestore
            .collection('batchSKD')
            .doc(id)
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Batch berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                getAllBatch();
                dispatch({
                    type: ADD_BATCH,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Voucher gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteVoucher = (doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('voucher')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Voucher berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_BATCH,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Vocuher gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updateBatch = (params, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('batchSKD')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Batch berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: EDIT_BATCH,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Batch gagal diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};


export const updateDefaultBatch = (params) => {
    return (dispatch) => {
       dispatch({
           type: SET_LOADING,
           payload: true
       });
        firebaseAuthService.firestore
            .collection('web_setting')
            .doc('batch')
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Ubah Batch berhasil',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: EDIT_BATCH,
                    payload: params
                });
            })
            .catch((err) => {
              debugger
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Ubah Batch gagal',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getDefaultBatch = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('web_setting')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_WEB_SETTING,
                    payload: data
                });
            });
    };
};