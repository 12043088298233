import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_BUKU = 'GET_BUKU';
export const DELETE_BUKU = 'DELETE_BUKU';
export const ADD_BUKU = 'ADD_BUKU';
export const EDIT_BUKU = 'EDIT_BUKU';
export const SET_LOADING = 'SET_LOADING';

export const getBuku = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('book')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_BUKU,
                    payload: data
                });
            });
    };
};

export const handleDelBuku = (doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('book')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Buku berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_BUKU,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Buku gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const handleAddBuku = (params) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('book')
            .add(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Buku berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                params.doc_id = res.id;
                dispatch({
                    type: ADD_BUKU,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Buku gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const handleEditBuku = (params, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('book')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Buku berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                params.doc_id = doc_id;
                dispatch({
                    type: EDIT_BUKU,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Buku gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
