import {
    GET_TRYOUT_SKD_EVENT,
    RESET_REDUCER,
    DEL_TRYOUT_SKD_EVENT,
    GET_PAKET_EVENT
} from '../actions/EventSKDActions';

const initialState = {
    data: [],
    materi: [],
    latihan: [],
    soal: [],
    tryout: [],
    paket: [],
};

const BundleSKDReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PAKET_EVENT: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case GET_TRYOUT_SKD_EVENT: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case DEL_TRYOUT_SKD_EVENT: {
            let tryout = state.tryout.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                tryout: tryout
            };
        }
        default: {
            return state;
        }
    }
};

export default BundleSKDReducer;
