import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_TRYOUT_GRATIS = 'GET_TRYOUT_GRATIS';
export const GET_TRYOUT_PREMIUM = 'GET_TRYOUT_PREMIUM';
export const GET_TRYOUT_PLATINUM = 'GET_TRYOUT_PLATINUM';
export const GET_TRYOUT_DINAS = 'GET_TRYOUT_DINAS';
export const GET_TRYOUT_PPPK_BY_CAT = 'GET_TRYOUT_PPPK_BY_CAT';
export const DEL_TRYOUT_PPPK = 'DEL_TRYOUT_PPPK';
export const GET_TRYOUT_BUMN_BY_CAT = 'GET_TRYOUT_BUMN_BY_CAT';
;
export const getTryoutGratis = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .where('type', '==', 'gratis')
            .get()
            .then(async (res) => {
                let data = [];
                res.forEach((doc) => {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_GRATIS,
                    payload: data
                });
            });
    };
};

export const getTryoutPremium = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .where('type', '==', 'premium')
            .get()
            .then(async (res) => {
                let data = [];
                res.forEach((doc) => {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_PREMIUM,
                    payload: data
                });
            });
    };
};

export const getTryoutPlatinum = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .where('type', '==', 'platinum')
            .get()
            .then(async (res) => {
                let data = [];
                res.forEach((doc) => {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_PLATINUM,
                    payload: data
                });
            });
    };
};

export const getTryoutDinas = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .where('type', '==', 'dinas')
            .get()
            .then(async (res) => {
                let data = [];
                res.forEach((doc) => {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_DINAS,
                    payload: data
                });
            });
    };
};

export const getTryoutPPPKByCat = (category) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .where('category', '==', category)
            .get()
            .then(async (res) => {
                let data = [];
                res.forEach((doc) => {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                console.log(data)
                dispatch({
                    type: GET_TRYOUT_PPPK_BY_CAT,
                    payload: data
                });
            });
    };
};

export const addTryoutPPPK = (params, type) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil disimpan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutPPPKByCat(type));
                });
                return true;
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal menyimpan Tryout',
                    icon: 'error',
                    timer: 2000
                });
                return false;
            });
    };
};


export const addTryoutBumn = (params, type) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_bumn')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil disimpan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutBumnByCat(type));
                });
                return true;
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal menyimpan Tryout',
                    icon: 'error',
                    timer: 2000
                });
                return false;
            });
    };
};

export const getTryoutBumnByCat = (category) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_bumn')
            .where('category', '==', category)
            .get()
            .then(async (res) => {
                let data = [];
                res.forEach((doc) => {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_TRYOUT_BUMN_BY_CAT,
                    payload: data
                });
            });
    };
};

export const delTryoutPPPK = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_TRYOUT_PPPK,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updTryoutPPPK = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutPPPKByCat(params.category));
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};


export const updTryoutBUMN = (params, doc_id) => {
  
    return  (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_bumn')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutBumnByCat(params.category));
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
