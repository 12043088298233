import {
    GET_BUNDLE_SKB,
    GET_DETAIL_SKB,
    GET_MATERI_SKB_DETAIL,
    GET_MY_EXERCISE,
    GET_MY_EXERCISE_DETAIL,
    GET_MY_TRYOUT,
    GET_MY_TRYOUT_BUNDLE,
    GET_MY_TRYOUT_BUNDLE_DETAIL,
    GET_MY_TRYOUT_DETAIL,
    GET_SKB,
    GET_SP_SKB_DETAIL,
    GET_TRYOUT_SKB,
    GET_USERS_MATERI,
    GET_USERS_MATERI_LIST,
    GET_USERS_SP,
    GET_USERS_SP_LIST,
    LOADING,
    GET_EXERCISE_SKB
} from '../actions/SKBActions';

const initialState = {
    data: [],
    detail_skb: {
        name: ''
    },
    bundle: [],
    tryout: [],
    loading: false,
    my_skb: [],
    my_skb_bundle: [],
    my_skb_bundle_detail: [],
    my_skb_exercise: [],
    detail_tryout: [],
    detail_exercise: [],
    materi_skb: [],
    materi_users: [],
    materi_detail: {
        created_at: '',
        desctiption: '',
        doc_id: '',
        materi_file: '',
        pdf: '',
        title: ''
    },
    sp_skb: [],
    sp_users: [],
    sp_detail: {
        created_at: '',
        desctiption: '',
        doc_id: '',
        materi_file: '',
        pdf: '',
        title: ''
    },
    exercise: []
};

const SKBReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case GET_SKB: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_DETAIL_SKB: {
            return {
                ...state,
                detail_skb: action.payload
            };
        }
        case GET_BUNDLE_SKB: {
            return {
                ...state,
                bundle: action.payload
            };
        }
        case GET_TRYOUT_SKB: {
            return {
                ...state,
                tryout: action.payload
            };
        }

        case GET_MY_TRYOUT: {
            return {
                ...state,
                my_skb: action.payload
            };
        }
        case GET_MY_TRYOUT_BUNDLE: {
            return {
                ...state,
                my_skb_bundle: action.payload
            };
        }
        case GET_MY_EXERCISE: {
            return {
                ...state,
                my_skb_exercise: action.payload
            };
        }
        case GET_MY_TRYOUT_BUNDLE_DETAIL: {
            return {
                ...state,
                my_skb_bundle_detail: action.payload
            };
        }
        case GET_MY_TRYOUT_DETAIL: {
            return {
                ...state,
                detail_tryout: action.payload
            };
        }
        case GET_MY_EXERCISE_DETAIL: {
            return {
                ...state,
                detail_exercise: action.payload
            };
        }
        case GET_USERS_MATERI: {
            return {
                ...state,
                materi_users: action.payload
            };
        }
        case GET_USERS_MATERI_LIST: {
            return {
                ...state,
                materi_skb: action.payload
            };
        }
        case GET_MATERI_SKB_DETAIL: {
            return {
                ...state,
                materi_detail: action.payload
            };
        }
        case GET_USERS_SP: {
            return {
                ...state,
                sp_users: action.payload
            };
        }
        case GET_USERS_SP_LIST: {
            return {
                ...state,
                sp_skb: action.payload
            };
        }
        case GET_SP_SKB_DETAIL: {
            return {
                ...state,
                sp_detail: action.payload
            };
        }
        case GET_EXERCISE_SKB: {
            return {
                ...state,
                exercise: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default SKBReducer;
