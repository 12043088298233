import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import React, { Component } from 'react';
import {
    TextValidator,
    ValidatorForm,
    SelectValidator
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class LaporanSoalDialog extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            submit: false,
            parent_id: parent,
            category: '',
            deskripsi: '',
            category_masalah: '',
            nama_paket: '',
            parent_id: '',
            soal: '',
            title: '',
            tryout: '',
            exercise: false,
            id_quis: ''
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    componentWillMount() {
        this.setState({
            category_masalah: this.props.uid.category_masalah,
            deskripsi_masalah: this.props.uid.deskripsi_masalah,
            status: this.props.uid.status,
            nama_paket: this.props.uid.nama_paket,
            parent_id: this.props.uid.parent_id,
            soal: this.props.uid.soal,
            title: this.props.uid.title,
            tryout: this.props.uid.tryout,
            no_soal: this.props.uid.no_soal,
            exercise: this.props.uid.exercise,
            id_quis: this.props.uid.id_quis
        });
    }

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const { handleLaporkan } = this.props;
        const { handleClose } = this.props;

        handleClose();
        handleLaporkan(this.state);
    };

    handleChangePassGrade = (event, index) => {};

    handleChangeNamePass = (event, index) => {};

    render() {
        let {
            submit,
            category,
            deskripsi,
            category_masalah,
            deskripsi_masalah,
            status,
            nama_paket,
            parent_id,
            soal,
            title,
            tryout,
            no_soal,
            exercise,
            id_quis
        } = this.state;
        let { open, handleClose, passing_grade } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Laporkan Soal</h4>
                    {/* <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={12} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Pilih Jenis Laporan'
                                    onChange={this.handleChange}
                                    name='category'
                                    value={category}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='Soal Tidak Tepat'>
                                        Soal Tidak Tepat
                                    </MenuItem>
                                    <MenuItem value='Salah Ketik Pada Soal'>
                                        Salah Ketik Pada Soal
                                    </MenuItem>
                                    <MenuItem value='Pilihan Jawaban Tidak Sesuai'>
                                        Pilihan Jawaban Tidak Sesuai
                                    </MenuItem>
                                </SelectValidator>
                            </Grid>

                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    label='Pesan'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='deskripsi'
                                    value={deskripsi}
                                    validators={[
                                        'required',
                                        'maxStringLength:25'
                                    ]}
                                    errorMessages={[
                                        'Field is Required',
                                        'Maximum 25 Character'
                                    ]}
                                    className='w-full'
                                />
                            </Grid>
                        </Grid>
                        
                    </ValidatorForm> */}

                    <Grid container spacing={2} className='mb-4'>
                        <Grid item sm={6} xs={12} className='pb-0'>
                            <h5>Jenis Laporan</h5>
                            <p className='text-16'>{category_masalah}</p>
                        </Grid>
                        <Grid item sm={6} xs={12} className='pb-0'>
                            <h5>Nama Paket</h5>
                            <p className='text-16'>{nama_paket}</p>
                        </Grid>
                        <Grid item sm={6} xs={12} className='pb-0'>
                            <h5>Soal yang di laporkan</h5>
                            <div
                                className='soal mb-5'
                                dangerouslySetInnerHTML={{ __html: soal }}
                            ></div>
                        </Grid>
                        <Grid item sm={6} xs={12} className='pb-0'>
                            <h5>Nomor Soal</h5>
                            <div
                                className='soal mb-5'
                                dangerouslySetInnerHTML={{ __html: no_soal }}
                            ></div>
                        </Grid>
                        <Grid item sm={12} xs={12} className='pb-0'>
                            <h5>Isi Laporan</h5>
                            <p className='text-16'>{deskripsi_masalah}</p>
                        </Grid>
                    </Grid>

                    <div className='flex flex-space-between flex-middle'>
                        {exercise ? (
                            <Link
                                to={'/exercise/skb/' + parent_id + '/' + tryout}
                            >
                                <Button
                                    variant='contained'
                                    disabled={submit}
                                    color='primary'
                                    type='submit'
                                >
                                    Kunjungi Soal
                                </Button>
                            </Link>
                        ) : (
                            <Link
                                to={'/tryout/skb/' + parent_id + '/' + tryout}
                            >
                                <Button
                                    variant='contained'
                                    disabled={submit}
                                    color='primary'
                                    type='submit'
                                >
                                    Kunjungi Soal
                                </Button>
                            </Link>
                        )}

                        <Button onClick={handleClose}>Batal</Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

// const mapStateToProps = (state) => {
//     return {
//         passing_grade: state.passingGrade.detail
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//         editTryout: (params, doc_id) => dispatch(updTryoutPPPK(params, doc_id)),
//         getAllPasingGrade: () => dispatch(getAllPasingGrade()),
//         getDetailPasingGrade: (type) => dispatch(getDetailPasingGrade(type))
//     };
// };
export default connect()(LaporanSoalDialog);
