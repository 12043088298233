import axios from 'axios';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_LAPORAN_SOAL = 'GET_LAPORAN_SOAL';
export const DELETE_LAPORAN_SOAL = 'DELETE_LAPORAN_SOAL';
;

export const getLaporSoal = () => {
    return async (dispatch) => {
        let uid = localStorage.getItem('uid');
        firebaseAuthService.firestore
            .collection('laporan_soal')
            .where('id_user', '==', uid)
            .onSnapshot(function (res) {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_LAPORAN_SOAL,
                    payload: data
                });
            });
    };
};

export const getAllLaporSoal = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('laporan_soal')
            .onSnapshot(function (res) {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
               let all = data.sort((a, b) =>
                   a.parent_id < b.parent_id ? 1 : -1
               );
              //  console.log(data)
               for (let i = 0; i < data.length; i++) {
                 console.log(data[i].parent_id);
                   firebaseAuthService.firestore
                       .collection('formasi_skb')
                        .doc(data[i].parent_id)
                      //  .doc('skb_ners')
                       .get()
                       .then((res) => {
                           console.log(data[i]);
                           let item = res.data();
                           data[i].nama_paket = item ? item.name : '';

                           //  if (data[i].parent_id === 'skb_ners') {
                           //      console.log('asdasdasdasdas', item.name);
                           //      debugger
                           //  }
                           dispatch({
                               type: GET_LAPORAN_SOAL,
                               payload: data
                           });
                       });
               }
            });
    };
};

export const deleteLaporSoal = (doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('laporan_soal')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Laporan Soal berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_LAPORAN_SOAL,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'SKB gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};