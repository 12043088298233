import axios from 'axios';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_INFORMASI = 'GET_INFORMASI';
export const SET_LOADING = 'SET_LOADING';
export const EDIT_INFORMASI = 'EDIT_INFORMASI';
export const ADD_BIMBEL = 'ADD_BIMBEL';
export const DELETE_BIMBEL = 'DELETE_BIMBEL';

export const getInformasi = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('informasi')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                console.log(data);
                dispatch({
                    type: GET_INFORMASI,
                    payload: data
                });
            });
    };
};

export const beliBimbel = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return axios
        .post(`${process.env.REACT_APP_API_URL}scpns/bimble/pay`, params)
        .then((res) => {
            return res.data.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const addBimbel = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}scpns/bimbel/add`, params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getInformasi());
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const delBimbel = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('bimbel')
            .doc(doc_id)
            .delete()
            .then(() => {
                dispatch({
                    type: DELETE_BIMBEL,
                    payload: doc_id
                });
            });
    };
};

export const editPromo = (params) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('informasi')
            .doc(params.doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Informasi berhasil diedit',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch(getInformasi());
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Informasi gagal diedit',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// export const getCategory = () => {
//     return async (dispatch) => {
//         firebaseAuthService.firestore
//             .collection('subcategory')
//             .get()
//             .then((res) => {
//                 let data = [];
//                 res.forEach(function (doc) {
//                     let item = doc.data();
//                     item.doc_id = doc.id;
//                     data.push(item);
//                 });
//                 dispatch({
//                     type: GET_INFORMASI,
//                     payload: data
//                 });
//             });
//     };
// };

// export const delCategory = (doc_id) => {
//     return async (dispatch) => {
//         firebaseAuthService.firestore
//             .collection('subcategory')
//             .doc(doc_id)
//             .delete()
//             .then(() => {
//                 dispatch({
//                     type: DELETE_BIMBEL,
//                     payload: doc_id
//                 });
//             });
//     };
// };

// export const addCategory = (params, id) => {
//     return (dispatch) => {
//         firebaseAuthService.firestore
//             .collection('subcategory')
//             .doc(id)
//             .set(params)
//             .then((res) => {
//                 Swal.fire({
//                     title: 'Berhasil',
//                     text: 'Category berhasil disimpan',
//                     showConfirmButton: false,
//                     icon: 'success',
//                     timer: 2000
//                 });
//                 getCategory();
//                 dispatch({
//                     type: ADD_BIMBEL,
//                     payload: params
//                 });
//             })
//             .catch((err) => {
//                 console.log('error', err);
//                 Swal.fire({
//                     title: 'Gagal',
//                     text: 'Category gagal disimpan',
//                     showConfirmButton: false,
//                     icon: 'error',
//                     timer: 2000
//                 });
//             });
//     };
// };
