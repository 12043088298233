import { Button, Icon } from '@material-ui/core';
import history from 'history.js';
import React from 'react';

const ButtonSolid = ({ text, icon, href, className, iconSize, onClick, variant, width, disabled, target, iconRight }) => {
	const handleClick = () => {
		if (onClick == undefined) {
			handleHref();
		} else {
			onClick();
		}
	};
	const handleHref = () => {
		if (target != undefined) {
			window.open(href, target);
		} else {
			history.push(href);
		}
	};
	return (
		<Button
			variant="contained"
			onClick={handleClick}
			className={`font-bold elevation-z0 button-try mt-3 ${variant} px-4 ${className} ${width} ${disabled ? "opacity" : ""} `}
			target={target}
			disabled={disabled}
			style={{width: 'auto'}}
		>
			{icon && (
				<Icon className={text != undefined ? "mr-4" : ""} fontSize={iconSize}>
					{icon}
				</Icon>
			)}
			{text && <span className="text-button">{text}</span>}
			{iconRight && <Icon fontSize={iconSize}>{iconRight}</Icon>}
		</Button>
	);
};

export default ButtonSolid;
