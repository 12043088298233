export const GET_SOAL_EDIT = 'GET_SOAL_EDIT';
export const SOAL_TRYOUT_SKD = 'SOAL_TRYOUT_SKD';
export const SET_SOAL_TRYOUT_SKD = 'SET_SOAL_TRYOUT_SKD';

export const soalTryoutSKD = (data) => (dispatch) => {
    dispatch({
        type: SET_SOAL_TRYOUT_SKD,
        data: data
    });
};
