import '../fake-db';
import '../index.css';
import '../styles/components/_badge.css';
import '../styles/components/_button.css';
import '../styles/components/_card.css';
import '../styles/components/_form.css';
import '../styles/components/_sweetalert.css';
import '../styles/components/_table.css';

import history from 'history.js';
import { MatxSuspense } from 'matx';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router, Switch } from 'react-router-dom';
import GlobalCss from 'styles/GlobalCss';

import AppContext from './appContext';
import Auth from './auth/Auth';
import AuthGuard from './auth/AuthGuard';
import MatxLayout from './MatxLayout/MatxLayoutSFC';
import MatxTheme from './MatxLayout/MatxTheme/MatxTheme';
import { store, persistor } from './redux/Store';
import routes from './RootRoutes';
import sessionRoutes from './views/sessions/SessionRoutes';

/* eslint-disable react-hooks/exhaustive-deps */
const App = () => {
    return (
        <AppContext.Provider value={{ routes }}>
            <Provider store={store}>
                {persistor ? (
                    <PersistGate loading={null} persistor={persistor}>
                        <MatxTheme>
                            <GlobalCss>
                                <Router history={history}>
                                    <Auth>
                                        <MatxSuspense>
                                            <Switch>
                                                {sessionRoutes.map(
                                                    (item, ind) => (
                                                        <AuthGuard
                                                            key={ind}
                                                            path={item.path}
                                                            component={
                                                                item.component
                                                            }
                                                            isPrivate={false}
                                                        />
                                                    )
                                                )}
                                                <AuthGuard
                                                    path='/'
                                                    component={MatxLayout}
                                                    isPrivate={true}
                                                />
                                            </Switch>
                                        </MatxSuspense>
                                    </Auth>
                                </Router>
                            </GlobalCss>
                        </MatxTheme>
                    </PersistGate>
                ) : (
                    <MatxTheme>
                        <GlobalCss>
                            <Router history={history}>
                                <Auth>
                                    <MatxSuspense>
                                        <Switch>
                                            {sessionRoutes.map((item, ind) => (
                                                <AuthGuard
                                                    key={ind}
                                                    path={item.path}
                                                    component={item.component}
                                                    isPrivate={false}
                                                />
                                            ))}
                                            <AuthGuard
                                                path='/'
                                                component={MatxLayout}
                                                isPrivate={true}
                                            />
                                        </Switch>
                                    </MatxSuspense>
                                </Auth>
                            </Router>
                        </GlobalCss>
                    </MatxTheme>
                )}
            </Provider>
        </AppContext.Provider>
    );
};

export default App;
