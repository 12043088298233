import {
    ADD_ANSWER,
    DEL_ANSWER,
    GET_DETAIL_EXERCISE_UK,
    GET_DETAIL_JAWABAN,
    GET_DETAIL_JAWABAN_UK,
    GET_JAWABAN,
    GET_SOAL_UK,
    GET_USERS_EXERCISE,
    RESET_KERJAKAN_EXERCISE,
} from '../actions/KerjakanLatihanUKActions';

const initialState = {
	exercise: {
		description: "",
		duration: "",
		jml_soal: "",
		title: "",
	},
	soal: [],
	detail_jawaban: {},
	detail_jawaban_uk: [],
	jawaban: [],
	users_exercise: {},
};

const KerjakanLatihanUKReducers = function (state = initialState, action) {
	switch (action.type) {
		case GET_DETAIL_EXERCISE_UK: {
			return {
				...state,
				exercise: action.payload,
			};
		}
		case GET_SOAL_UK: {
			return {
				...state,
				soal: action.payload,
			};
		}
		case GET_JAWABAN: {
			return {
				...state,
				jawaban: action.payload,
			};
		}
		case GET_DETAIL_JAWABAN: {
			return {
				...state,
				detail_jawaban: action.payload,
			};
		}
		case GET_DETAIL_JAWABAN_UK: {
			return {
				...state,
				detail_jawaban_uk: action.payload,
			};
		}
		case ADD_ANSWER: {
			let jawaban = state.jawaban.filter((item) => item.doc_id != action.payload.jawaban.doc_id);
			return {
				...state,
				jawaban: jawaban.concat(action.payload.jawaban),
				detail_jawaban: {
					...state.detail_jawaban,
					...action.payload.detailJawaban,
				},
			};
		}
		case DEL_ANSWER: {
			let jawaban = state.jawaban.filter((item) => item.doc_id != action.payload.kode);
			return {
				...state,
				jawaban: jawaban,
				detail_jawaban: {
					...state.detail_jawaban,
					...action.payload.detailJawaban,
				},
			};
		}
		case RESET_KERJAKAN_EXERCISE: {
			return {
				exercise: {
					description: "",
					duration: "",
					jml_soal: "",
					title: "",
				},
				soal: [],
				detail_jawaban: [],
				users_exercise: {},
				detail_jawaban_uk: [],
				jawaban: [],
			};
		}
		case GET_USERS_EXERCISE: {
			return {
				...state,
				users_exercise: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};

export default KerjakanLatihanUKReducers;
