import {
    Button,
    Grid,
    Card,
    CircularProgress,
    MenuItem
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {
    CardTryoutAdmin,
    EditorJawaban,
    EditorPertanyaan,
    ButtonUploadThumbnail
} from 'app/components';
import {
    ValidatorForm,
    TextValidator,
    SelectValidator
} from 'react-material-ui-form-validator';
import {
    setDefaultSettings,
    setLayoutSettings
} from 'app/redux/actions/LayoutActions';
import { merge } from 'lodash';
import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { isMdScreen } from 'utils';
import { getTanggal } from 'app/redux/actions/UserActions';

import { soalTryoutSKD } from 'app/redux/actions/SoalTryoutSKDActions';
import {
    addQuestionTryoutSKD,
    getSubCategory
} from 'app/redux/actions/KerjakanTryoutActions';
import { addQuestionTryoutBundle } from 'app/redux/actions/PaketTryoutActions';

let subOptionTWK = [
    { label: 'Integritas', value: 'integritas' },
    { label: 'Bela Negara', value: 'bela_negara' },
    { label: 'Pilar Negara', value: 'pilar_negara' },
    { label: 'Bahasa Indonesia', value: 'bahasa_indonesia' }
];
let subOptionTIU = [
    { label: 'Verbal', value: 'verbal' },
    { label: 'Numerik', value: 'numerik' },
    { label: 'Figural', value: 'figural' }
];
let subOptionTKP = [
    { label: 'Pelayanan Publik', value: 'pelayanan_publik' },
    { label: 'Jejaring Kerja', value: 'jejaring_kerja' },
    { label: 'Teknologi Informasi', value: 'teknologi_informasi' },
    { label: 'Sosial Budaya', value: 'sosial_budaya' },
    { label: 'Profesionalisme', value: 'profesionalisme' }
];

class TryoutSoalBundleTambah extends Component {
    constructor() {
        super();
        this.state = {
            no_soal: 0,
            category: '',
            subcategory: '',
            subOption: [],
            submit: false,
            preview_file: '',
            question_img: '',
            question_text: '',
            pembahasan_img: '',
            video_pembahasan: '',
            pembahasan: '',
            doc_id: '',
            params_id: '',
            option: Array(5).fill({ img: '', opt: '', value: '', text: '' }),
            soalTryout: []
        };
    }

    handleSidenavToggle = () => {
        let {
            settings: {
                layout1Settings: {
                    leftSidebar: { mode }
                }
            }
        } = this.props;

        this.updateSidebarMode({
            mode: mode === 'compact' ? 'full' : 'compact'
        });
    };

    updateSidebarMode = (sidebarSettings) => {
        let { settings, setLayoutSettings, setDefaultSettings } = this.props;
        const updatedSettings = merge({}, settings, {
            layout1Settings: {
                leftSidebar: {
                    ...sidebarSettings
                }
            }
        });

        setLayoutSettings(updatedSettings);
        setDefaultSettings(updatedSettings);
    };

    componentDidMount() {
        this.getData();
        this.handleSidebar();
    }

    getData = async () => {
        const { soalTryoutSKDEdit, subcategoryData, getSubCategory } =
            this.props;
        await getSubCategory();
        this.setState({
            soalTryout: soalTryoutSKDEdit.soalTryout,
            params_id: soalTryoutSKDEdit.params.id
        });
    };

    componentWillUnmount() {
        this.handleSidebar();
    }
    handleSidebar = () => {
        let sideMode;
        if (isMdScreen()) {
            sideMode = 'close';
        } else {
            sideMode = 'compact';
        }
        this.updateSidebarMode({ mode: sideMode });
    };
    handleChangeSelect = (event) => {
        const category = event.target.value;
        this.setState({
            category: category
        });
    };
    handleChange = (event, source) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleContentChange = (index, newContent) => {
        this.setState((prevState) => {
            const updatedOption = prevState.editData.option.map((o, idx) =>
                idx === index ? { ...o, text: newContent } : o
            );
            return {
                editData: {
                    ...prevState.editData,
                    option: updatedOption
                }
            };
        });
    };
    handleUploadImage = (file, path) => {
        this.setState({
            question_img: file,
            preview_file: path
        });
    };

    renderEditorPertanyaan = () => {
        const { question_text } = this.state;

        return (
            <Grid item sm={12} xs={12} className='mt-5 mb-5'>
                <h6>Pertanyaan</h6>
                <EditorPertanyaan
                    content={question_text}
                    initialValue={question_text}
                    onContentChange={this.handleChangePertanyaan}
                />
            </Grid>
        );
    };

    renderEditorPembahasan = () => {
        const { pembahasan } = this.state;
        return (
            <Grid item sm={12} xs={12} className='mt-5 mb-5'>
                <h6>Pembahasan</h6>
                <EditorPertanyaan
                    content={pembahasan}
                    onContentChange={this.handleChangePembahasan}
                />
            </Grid>
        );
    };
    handleContentChange = (index, newContent) => {
        this.setState((prevState) => {
            const updatedOption = prevState.option.map((o, idx) =>
                idx === index ? { ...o, text: newContent } : o
            );
            return {
                ...prevState,
                option: updatedOption
            };
        });
    };

    handleValueChange = (index, newContent) => {
        this.setState((prevState) => {
            const updatedOption = prevState.option.map((o, idx) =>
                idx === index ? { ...o, value: newContent } : o
            );
            return {
                ...prevState,
                option: updatedOption
            };
        });
    };

    generateEditorList = () => {
        const { option } = this.state;
        const options = Array.isArray(option) ? option : [];
        return options.map((opt, index) => {
            const abjad = String.fromCharCode(97 + index).toUpperCase();
            return (
                <Grid item sm={12} xs={12} key={index} className='mt-5 mb-5'>
                    <h6>Jawaban {abjad}</h6>
                    <EditorJawaban
                        content={opt.text || ''}
                        value={opt.value || 0}
                        onContentChange={(newContent) =>
                            this.handleContentChange(index, newContent)
                        }
                        onChangeJawaban={(newValue) =>
                            this.handleValueChange(index, newValue)
                        }
                    />
                </Grid>
            );
        });
    };

    handleChangePertanyaan = (val) => {
        if (val !== '') {
            this.setState({
                question_text: val
            });
        }
    };

    handleChangePembahasan = (val) => {
        if (val !== '') {
            this.setState({
                pembahasan: val
            });
        }
    };

    submitForm = () => {
        this.setState(
            {
                submit: false
            },
            this.sendSubmit
        );
    };
    handleUploadImgPembahasan = (file, path) => {
        this.setState({
            pembahasan_img: file,
            preview_file_pembahasan: path
        });
    };
    handleDelImg = () => {
        this.setState({
            question_img: '',
            preview_file: ''
        });
    };

    handleDelImgPembahasan = () => {
        this.setState({
            pembahasan_img: '',
            preview_file_pembahasan: ''
        });
    };

    sendSubmit = () => {
        const {
            question_img,
            subcategory,
            pembahasan_img,
            video_pembahasan,
            category,
            option,
            pembahasan,
            doc_id,
            params_id,
            question_text,
            soalTryout
        } = this.state;
        let now = getTanggal();
        const { addQuestionTryout, history } = this.props;
        let allAnswer = [];
        for (let i = 0; i < option.length; i++) {
            const data = {
                opt: Math.abs((i + 10).toString()).toString(16),
                value: option[i].value === '' ? 0 : option[i].value,
                text: option[i].text,
                img: option[i].img
            };
            allAnswer.push(data);
        }
        let params = {
            category: category,
            option: allAnswer,
            pembahasan: pembahasan,
            question_img: question_img,
            question_text: question_text,
            subcategory: subcategory,
            pembahasan_img: pembahasan_img,
            video_pembahasan: video_pembahasan,
            created_at: now
        };
        addQuestionTryout(params, params_id, soalTryout);

        history.push('/bundle/tryout/soal/' + params_id);
    };

    render() {
        const {
            subcategoryData,
            soalTryoutSKDEdit: { title_soal, durasi_soal, no_soal }
        } = this.props;
        const {
            submit,
            editData,
            category,
            subcategory,
            subOption,
            preview_file,
            question_img,
            video_pembahasan,
            pembahasan_img
        } = this.state;
        return (
            <div className='m-sm-30'>
                <div id='top-element'>
                    <CardTryoutAdmin
                        title={title_soal}
                        duration={durasi_soal}
                    ></CardTryoutAdmin>
                    <h6>Nomer Soal {no_soal}</h6>
                </div>
                <div className='p-5'>
                    <Card style={{ borderRadius: 5 }} className='mb-5 p-5'>
                        <ValidatorForm ref='form' onSubmit={this.submitForm}>
                            <Grid container spacing={4} className='mb-4'>
                                <Grid item sm={12} xs={12}>
                                    {this.renderEditorPertanyaan()}
                                </Grid>

                                <Grid item sm={12} xs={12}>
                                    {this.generateEditorList()}
                                </Grid>

                                <Grid item sm={12} xs={12}>
                                    {this.renderEditorPembahasan()}
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextValidator
                                        className='mb-5'
                                        label='Link Video Pembahasan'
                                        onChange={this.handleChange}
                                        type='text'
                                        name='video_pembahasan'
                                        value={video_pembahasan}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6} xs={12}>
                                            <SelectValidator
                                                className='mb-4 w-full'
                                                label='Kategory Pertanyaan'
                                                onChange={
                                                    this.handleChangeSelect
                                                }
                                                name='category'
                                                value={category}
                                                validators={['required']}
                                                errorMessages={[
                                                    'Field is Required'
                                                ]}
                                            >
                                                <MenuItem value={'twk'}>
                                                    TWK
                                                </MenuItem>
                                                <MenuItem value={'tiu'}>
                                                    TIU
                                                </MenuItem>
                                                <MenuItem value={'tkp'}>
                                                    TKP
                                                </MenuItem>
                                            </SelectValidator>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <SelectValidator
                                                className='mb-4 w-full'
                                                label='Kategory Pertanyaan'
                                                onChange={this.handleChange}
                                                name='subcategory'
                                                value={subcategory}
                                                validators={['required']}
                                                errorMessages={[
                                                    'Field is Required'
                                                ]}
                                            >
                                                {subcategoryData
                                                    .filter(
                                                        (item) =>
                                                            item.category ==
                                                            category
                                                    )
                                                    .map((item, index) => (
                                                        <MenuItem
                                                            value={item.id}
                                                            key={index}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </SelectValidator>
                                        </Grid>

                                        <Grid
                                            item
                                            sm={12}
                                            xs={12}
                                            className='pb-0'
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item sm={6} xs={6}>
                                                    <small>
                                                        Gambar Soal (optional)
                                                    </small>
                                                    <div
                                                        style={{
                                                            width: 100,
                                                            height: 100
                                                        }}
                                                        className='relative mt-3'
                                                    >
                                                        {preview_file && (
                                                            <img
                                                                src={
                                                                    preview_file
                                                                }
                                                                width='100%'
                                                                height='100%'
                                                                style={{
                                                                    objectFit:
                                                                        'cover'
                                                                }}
                                                            />
                                                        )}
                                                        <ButtonUploadThumbnail
                                                            uploadFoto={
                                                                this
                                                                    .handleUploadImage
                                                            }
                                                            value={question_img}
                                                            handleDelFile={
                                                                this
                                                                    .handleDelImg
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                    <small>
                                                        Gambar Pembahasan
                                                        (optional)
                                                    </small>
                                                    <div
                                                        style={{
                                                            width: 100,
                                                            height: 100
                                                        }}
                                                        className='relative mt-3'
                                                    >
                                                        {pembahasan_img && (
                                                            <img
                                                                src={
                                                                    pembahasan_img
                                                                }
                                                                width='100%'
                                                                height='100%'
                                                                style={{
                                                                    objectFit:
                                                                        'cover'
                                                                }}
                                                            />
                                                        )}
                                                        <ButtonUploadThumbnail
                                                            uploadFoto={
                                                                this
                                                                    .handleUploadImgPembahasan
                                                            }
                                                            value={
                                                                pembahasan_img
                                                            }
                                                            handleDelFile={
                                                                this
                                                                    .handleDelImgPembahasan
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <div className='flex flex-space-between flex-middle mt-4'>
                                        <Button
                                            variant='contained'
                                            disabled={submit}
                                            color='primary'
                                            type='submit'
                                        >
                                            {submit ? (
                                                <CircularProgress size={25} />
                                            ) : (
                                                'Simpan'
                                            )}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    settings: state.layout.settings,
    detailTryout: state.TryoutBundle.tryout_detail,
    soalTryout: state.TryoutBundle.soal,
    soalTryoutSKDEdit: state.SoalTryoutSKD.dataTryout,
    subcategoryData: state.kerjakanSKD.subCategory
});

const mapDispatchToProps = (dispatch) => {
    return {
        soalTryoutSKD: (data) => dispatch(soalTryoutSKD(data)),
        getSubCategory: () => dispatch(getSubCategory()),
        setLayoutSettings: (data) => dispatch(setLayoutSettings(data)),
        setDefaultSettings: (data) => dispatch(setDefaultSettings(data)),
        addQuestionTryout: (params, id, doc_id) =>
            dispatch(addQuestionTryoutBundle(params, id, doc_id))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TryoutSoalBundleTambah)
);
