import {
    GET_BUNDLE_UK,
    GET_DETAIL_UK,
    GET_MATERI_UK_DETAIL,
    GET_MY_EXERCISE_DETAIL,
    GET_MY_EXERCISE_UK,
    GET_MY_TRYOUT_DETAIL,
    GET_MY_TRYOUT_UK,
    GET_MY_TRYOUT_UK_BUNDLE,
    GET_MY_TRYOUT_UK_BUNDLE_DETAIL,
    GET_SP_UK_DETAIL,
    GET_TRYOUT_UK,
    GET_UK,
    GET_USERS_MATERI,
    GET_USERS_MATERI_LIST,
    GET_USERS_SP,
    GET_USERS_SP_LIST,
    LOADING,
    GET_EXERCISE_UK
} from '../actions/UKActions';

const initialState = {
    data: [],
    my_uk: [],
    my_uk_bundle: [],
    my_uk_bundle_detail: [],
    my_uk_exercise: [],
    detail_tryout: [],
    detail_exercise: [],
    detail_uk: {
        name: ''
    },
    bundle: [],
    tryout: [],
    loading: false,
    materi_uk: [],
    materi_users: [],
    materi_detail: {
        created_at: '',
        desctiption: '',
        doc_id: '',
        materi_file: '',
        pdf: '',
        title: ''
    },
    sp_uk: [],
    sp_users: [],
    sp_detail: {
        created_at: '',
        desctiption: '',
        doc_id: '',
        materi_file: '',
        pdf: '',
        title: ''
    },
    exercise: []
};

const UKReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case GET_UK: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_DETAIL_UK: {
            return {
                ...state,
                detail_uk: action.payload
            };
        }
        case GET_BUNDLE_UK: {
            return {
                ...state,
                bundle: action.payload
            };
        }
        case GET_TRYOUT_UK: {
            return {
                ...state,
                tryout: action.payload
            };
        }
        case GET_MY_TRYOUT_UK: {
            return {
                ...state,
                my_uk: action.payload
            };
        }
        case GET_MY_TRYOUT_UK_BUNDLE: {
            return {
                ...state,
                my_uk_bundle: action.payload
            };
        }
        case GET_MY_EXERCISE_UK: {
            return {
                ...state,
                my_uk_exercise: action.payload
            };
        }
        case GET_MY_TRYOUT_UK_BUNDLE_DETAIL: {
            return {
                ...state,
                my_uk_bundle_detail: action.payload
            };
        }
        case GET_MY_TRYOUT_DETAIL: {
            return {
                ...state,
                detail_tryout: action.payload
            };
        }
        case GET_MY_EXERCISE_DETAIL: {
            return {
                ...state,
                detail_exercise: action.payload
            };
        }
        case GET_USERS_MATERI: {
            return {
                ...state,
                materi_users: action.payload
            };
        }
        case GET_USERS_MATERI_LIST: {
            return {
                ...state,
                materi_uk: action.payload
            };
        }
        case GET_MATERI_UK_DETAIL: {
            return {
                ...state,
                materi_detail: action.payload
            };
        }
        case GET_USERS_SP: {
            return {
                ...state,
                sp_users: action.payload
            };
        }
        case GET_USERS_SP_LIST: {
            return {
                ...state,
                sp_uk: action.payload
            };
        }
        case GET_SP_UK_DETAIL: {
            return {
                ...state,
                sp_detail: action.payload
            };
        }
        case GET_EXERCISE_UK: {
            return {
                ...state,
                exercise: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default UKReducer;
