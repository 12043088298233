import LatihanSoal from '../SKD/latihan/LatihanSoal';
import SKDDetail from '../SKD/SKDDetail';
import SKDBundleTable from './SKDBundleTable';
import TryoutRanking from '../SKD/tryout/TryoutRanking';
import TryoutSoalSkdEdit from '../SKD/tryout/TryoutSoalSkdEdit';
import TryoutSoalBC from '../SKD/tryout/TryoutSoalBC';
import TryoutSoalSkdTambah from '../SKD/tryout/TryoutSoalSkdTambah';
import PaketBundleDetail from '../SKD/PaketBundleDetail';
import TryoutSoal from './tryout/TryoutSoal';
import TryoutSoalBundleTambah from './tryout/TryoutSoalBundleTambah';
import TryoutSoalBundleEdit from './tryout/TryoutSoalBundleEdit';

const BundleSKDRoutes = [
    // {
    //     path: '/skd/tryout/ranking/:id',
    //     component: TryoutRanking
    // },
    {
        path: '/bundle/tryout/soal/tambah',
        component: TryoutSoalBundleTambah
    },
    {
        path: '/bundle/tryout/soal/skd/edit',
        component: TryoutSoalBundleEdit
    },
    // {
    //     path: '/skd/tryout/soalbc/:id',
    //     component: TryoutSoalBC
    // },
    {
        path: '/bundle/tryout/soal/:id',
        component: TryoutSoal
    },
    // {
    //     path: '/skd/latihan/soal/:id',
    //     component: LatihanSoal
    // },
    {
        path: '/bundle/detail/:id',
        component: PaketBundleDetail
    },
    {
        path: '/bundle',
        component: SKDBundleTable
    }
];

export default BundleSKDRoutes;
