import NotifikasiView from './NotifikasiView';

const NotifikasiRoutes = [
    {
        path: '/notifikasi',
        component: NotifikasiView
    }
];

export default NotifikasiRoutes;
