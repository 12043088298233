import AdminTable from "./AdminTable";

const AdminRoutes = [
    {
        path: '/admin',
        component: AdminTable
    }
];

export default AdminRoutes;
