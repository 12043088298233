import { Card, Icon, Link } from '@material-ui/core';
import React from 'react';

const CardKomisi = ({ komisi, handleUbah }) => {
    return (
        <Card
            className='flex items-center border-radius-4 py-4 px-4 mb-4  card-referral bg-gradient-primary'
            elevation={3}
        >
            <div className='text-white'>
                <Icon>account_balance_wallet</Icon>
            </div>
            <div className='ml-5'>
                <h6 className='font-bold text-white'>{komisi}%</h6>
                <p className='m-0 text-white'>
                    Persentase komisi yang diterima user.{' '}
                    <Link
                        onClick={handleUbah}
                        className='text-primary underline cursor-pointer'
                    >
                        Ubah
                    </Link>
                </p>
            </div>
        </Card>
    );
};

export default CardKomisi;
