import { Button, Dialog } from '@material-ui/core';
import { UploadImageForm } from 'app/components';
import { addImages } from 'app/redux/actions/GambarActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { saveAs } from 'file-saver';

class AddGambar extends Component {
    state = {
        file: '',
        doc_id: '',
        parent: '',
        disabled: false,
        text: 'Import',
        bg: 'bg-primary',
        files: []
    };

    componentDidMount() {}

    handleChangeInputProfile = (event, source, name) => {
        console.log('event', event);
        let { files } = this.state;
        if (event.url == '') {
            this.setState(
                {
                    files: []
                },
                () => {
                    console.log(this.state.files);
                }
            );
        }
        if (event.name != undefined) {
            let allFiles = files.concat({
                file_preview: event.preview,
                file: event.url,
                name: event.name
            });

            this.setState(
                {
                    files: allFiles
                },
                () => {
                    console.log(this.state.files);
                }
            );
        }
    };

    handleImportQuestion = async () => {
        this.setState({
            disabled: true,
            text: 'Sedang menyimpan...',
            bg: 'bg-light-dark'
        });
        let { files } = this.state;
        let params = {
            file: files
        };
        await this.props.addImages(params);
        setTimeout(() => {
            this.props.handleClose();
        }, 2500);
    };

    render() {
        let { open, handleClose } = this.props;
        let { disabled, text, bg } = this.state;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h5>Tambah Gambar</h5>
                    <div>
                        <UploadImageForm
                            handleChange={this.handleChangeInputProfile}
                        />
                        {this.state.files.length > 0 && (
                            <Button
                                variant='contained'
                                className={`${bg} text-white`}
                                onClick={this.handleImportQuestion}
                                disabled={disabled}
                            >
                                {text}
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        tryout: state.skb.tryout
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addImages: (params, parent, doc_id) =>
            dispatch(addImages(params, parent, doc_id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddGambar);
