import React from 'react';
import { Redirect } from 'react-router-dom';

import AdminRoutes from './views/admin/AdminRoutes';
import bimbelRoutes from './views/bimbel/bimbelRoutes';
import CategoryRoutes from './views/category/categoryRoutes';
import dashboardRoutes from './views/dashboard/DashboardRoutes';
import GambarRoutes from './views/gambar/GambarRoutes';
import komisiRoutes from './views/komisi/komisiRoutes';
import InformasiRoutes from './views/informasi/InformasiRoutes';
import PenghasilanRoutes from './views/Penghasilan/PenghasilanRoutes';
import profileRoutes from './views/profile/profileRoutes';
import promoRoutes from './views/promo/promoRoutes';
import RekomendasiBukuRoutes from './views/rekomendasi-buku/RekomendasiBukuRoutes';
import SKBRoutes from './views/SKB/SKBRoutes';
import SKDRoutes from './views/SKD/SKDRoutes';
import BundleSKDRoutes from './views/BundleSKD/BundleSKDRoutes';
import EventSKDRoutes from './views/EventSKD/EventSKDRoutes';
import TransaksiRoutes from './views/transaksi/TransaksiRoutes';
import UKRoutes from './views/UK/UKRoutes';
import UserRoutes from './views/user/UserRoutes';
import VoucherRoutes from './views/voucher/VoucherRoutes';
import PPPKRoutes from './views/PPPK/PPPKRoutes';
import PGRoutes from './views/passing_grade/PGRoutes';
import groupRoutes from './views/group/groupRoutes';
import LaporanSoalRoutes from './views/laporan_soal/LaporanSoalRoutes';
import BumnRoutes from './views/bumn/BumnRoutes';
import BatchRoutes from './views/batch/BatchRoutes';
import NewsRoutes from './views/news/promoRoutes';
import NotifikasiRoutes from './views/notifikasi/notifikasiRoutes';
// import PPGRoutes from './views/ppg/PPGRoutes'

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/dashboard' />
    }
];

const errorRoute = [
    {
        component: () => <Redirect to='/session/404' />
    }
];

const routes = [
    ...dashboardRoutes,
    ...profileRoutes,
    ...NewsRoutes,
    ...promoRoutes,
    ...BumnRoutes,
    // ...PPGRoutes,
    ...UserRoutes,
    ...NotifikasiRoutes,
    ...RekomendasiBukuRoutes,
    ...AdminRoutes,
    ...bimbelRoutes,
    ...TransaksiRoutes,
    ...BatchRoutes,
    ...VoucherRoutes,
    ...InformasiRoutes,
    ...LaporanSoalRoutes,
    ...UKRoutes,
    ...SKBRoutes,
    ...SKDRoutes,
    ...BundleSKDRoutes,
    ...PPPKRoutes,
    ...PGRoutes,
    ...GambarRoutes,
    ...CategoryRoutes,
    ...groupRoutes,
    ...redirectRoute,
    ...komisiRoutes,
    ...PenghasilanRoutes,
    ...EventSKDRoutes,
    ...errorRoute
];

export default routes;
