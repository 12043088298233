import {
    Button,
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import { FormSearch } from 'app/components';
import {
    delSoalPPPK,
    getPembahasan
} from 'app/redux/actions/PembahasanBumnActions';
import { formatTanggal } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import AddSoal from './AddSoal';
import EditSoal from './EditSoal';

class Soal extends Component {
    constructor() {
        super();
        this.state = {
            rowsPerPage: 10,
            page: 0,
            modalAdd: false,
            modalEdit: false,
            search: '',
            edit_soal: {
                description: '',
                title: '',
                doc_id: '',
                materi_file: ''
            },
            parent: ''
        };
    }

    componentDidMount() {
        const { params } = this.props;
        let parent = params.id;
        this.setState(
            {
                parent: parent
            },
            () => {
                this.getData();
            }
        );
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    getData() {
        const { getPembahasan } = this.props;
        const { parent } = this.state;
        const{ params } = this.props;
        getPembahasan(params == undefined ? '' : params.id);
    }

    handleDelete = (doc_id) => {
        const { delSoalPPPK } = this.props;
        Swal.fire({
            title: 'Peringatan',
            text: 'Apakah kamu yakin ingin menghapus soal dan pembahasan ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            icon: 'warning'
        }).then((res) => {
            if (res.isConfirmed) {
                delSoalPPPK(doc_id);
            } else {
                Swal.close();
            }
        });
    };

    renderData = () => {
        const { pembahasanBumn } = this.props;
        const { search, page, rowsPerPage } = this.state;
        let dataSoal = pembahasanBumn
            .filter((item) =>
                Object.keys(item).some((key) =>
                    search.includes(key)
                        ? false
                        : item[key].toString().toLowerCase().includes(search)
                )
            )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        return dataSoal.length > 0 ? (
            dataSoal.map((item, index) => (
                <TableRow key={index}>
                    <TableCell className='px-0' align='center'>
                        {index + 1}
                    </TableCell>

                    <TableCell className='px-0' colSpan={2}>
                        {item.title}
                    </TableCell>

                    <TableCell className='px-0'>
                        <a
                            target='_blank'
                            href={item.materi_file}
                            className='text-primary'
                        >
                            lihat
                        </a>
                    </TableCell>

                    <TableCell className='px-0'>
                        {formatTanggal(item.created_at)}
                    </TableCell>

                    <TableCell className='px-0' align='center'>
                        <IconButton
                            title='Edit'
                            onClick={() => this.handleModalEdit(item)}
                        >
                            <Icon color='primary'>edit</Icon>
                        </IconButton>
                        <IconButton
                            title='delete'
                            onClick={() => this.handleDelete(item.doc_id)}
                        >
                            <Icon color='error'>delete</Icon>
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))
        ) : (
            <TableRow>
                <TableCell colSpan={6} align='center'>
                    Not Have Data
                </TableCell>
            </TableRow>
        );
    };

    handleSearch = (val) => {
        this.setState({
            search: val.target.value
        });
    };

    handleModalAdd = () => {
        this.setState((prefState) => ({
            modalAdd: !prefState.modalAdd
        }));
    };

    handleCloseEdit = () => {
        this.setState({
            modalEdit: false
        });
    };

    handleModalEdit = (item) => {
        const { description, title, doc_id, materi_file, type } = item;
        this.setState({
            edit_soal: {
                description: description,
                title: title,
                doc_id: doc_id,
                materi_file: materi_file,
                type: type
            },
            modalEdit: true
        });
    };

    render() {
        const { pembahasanBumn, params } = this.props;
        const {
            search,
            rowsPerPage,
            page,
            modalAdd,
            modalEdit,
            edit_soal,
            type
        } = this.state;
        return (
            <div>
                <Card className='p-5 overflow-auto' elevation={6}>
                    <div className='flex justify-between'>
                        <Button
                            variant='outlined'
                            color='primary'
                            className='mb-5'
                            onClick={this.handleModalAdd}
                            title='Edit'
                        >
                            Tambah
                        </Button>
                        <FormSearch
                            value={search}
                            handleSearch={this.handleSearch}
                        />
                    </div>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='text-white'
                                        align='center'
                                    >
                                        No.
                                    </TableCell>
                                    <TableCell
                                        className='text-white'
                                        colSpan={2}
                                    >
                                        Judul
                                    </TableCell>
                                    <TableCell className='text-white'>
                                        Materi Link
                                    </TableCell>
                                    <TableCell className='text-white'>
                                        Tgl Dibuat
                                    </TableCell>
                                    <TableCell
                                        className='text-white'
                                        align='center'
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>

                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={pembahasanBumn.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                    </div>
                </Card>
                <AddSoal
                    open={modalAdd}
                    handleClose={this.handleModalAdd}
                    match={params}
                />
                {modalEdit && (
                    <EditSoal
                        open={modalEdit}
                        handleClose={this.handleCloseEdit}
                        description={edit_soal.description}
                        title={edit_soal.title}
                        doc_id={edit_soal.doc_id}
                        materi_file={edit_soal.materi_file}
                        type={edit_soal.type}
                    />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        pembahasanBumn: state.pembahasanBumn.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPembahasan: (parent) => dispatch(getPembahasan(parent)),
        delSoalPPPK: (doc_id) => dispatch(delSoalPPPK(doc_id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Soal);
