import {
    Box,
    Card,
    FormControl,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Button
} from '@material-ui/core';
import { getBumnMandiri } from 'app/redux/actions/BumnActions';
import { formatRupiah } from 'app/redux/actions/UserActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddBumnDialog from './AddBumnDialog';

import EditBumn from './EditBumn';

class BumnTable extends Component {
    constructor() {
        super();
        this.state = {
            modalEdit: false,
            shouldOpenCreateDialog: false,
            edit_bumn: {
                benefit: [],
                discount: 0,
                expired_month: 0,
                title: '',
                price: 0,
                is_active: 0,
                doc_id: '',
                description: '',
                type: '',
                group_link: ''
            },
            rowsPerPage: 10,
            page: 0,
            query: ''
        };
    }

    componentDidMount() {
        const { getBumnMandiri } = this.props;
        getBumnMandiri();
    }

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleDetail = (doc_id, type) => {
        const { history } = this.props;
        history.push('/detail/bumn/' + doc_id );
    };

    renderData = () => {
        const { data } = this.props.bumn;
        const { page, rowsPerPage, query } = this.state;
        return data.length > 0 ? (
            data
                .filter((item) => item.title.toLowerCase().includes(query))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                    return (
                        <TableRow hover key={index}>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                {index + 1}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                colSpan={2}
                            >
                                {item.title}
                            </TableCell>
                            <TableCell className='px-2 py-2 text-center font-poppins'>
                                {formatRupiah(item.price, 'Rp')}
                            </TableCell>
                            <TableCell className='px-2 py-2 text-center font-poppins'>
                                {formatRupiah(item.discount, 'Rp')}
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                <small
                                    className={`border-radius-4 text-white px-4 py-2 ${
                                        item.is_active
                                            ? 'bg-secondary'
                                            : 'bg-primary'
                                    }`}
                                >
                                    {item.is_active ? 'Aktif' : 'Tidak Aktif'}
                                </small>
                            </TableCell>
                            <TableCell
                                className='px-2 py-2 text-center font-poppins'
                                align='center'
                            >
                                <IconButton
                                    title='detail'
                                    onClick={() =>
                                        this.handleDetail(
                                            item.doc_id,
                                            item.type
                                        )
                                    }
                                >
                                    <Icon color='action'>visibility</Icon>
                                </IconButton>
                                <IconButton
                                    title='edit'
                                    onClick={() => this.handleEdit(item)}
                                >
                                    <Icon color='primary'>border_color</Icon>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    );
                })
        ) : (
            <TableRow hover>
                <TableCell colSpan={6} align='center'>
                    Data Tidak Ditemukan
                </TableCell>
            </TableRow>
        );
    };

    handleEdit = (item) => {
        const {
            benefit,
            discount,
            expired_month,
            title,
            price,
            is_active,
            doc_id,
            description,
            type,
            group_link
        } = item;
        this.setState({
            edit_bumn: {
                benefit: benefit,
                discount: discount,
                expired_month: expired_month,
                title: title,
                price: price,
                is_active: is_active,
                doc_id: doc_id,
                description: description,
                type: type,
                group_link: group_link
            },
            modalEdit: true
        });
    };

    handleCloseEdit = () => {
        this.setState({
            modalEdit: false,
            shouldOpenCreateDialog: false
        });
    };

    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    render() {
        const {
            modalEdit,
            edit_bumn,
            rowsPerPage,
            page,
            query,
            shouldOpenCreateDialog
        } = this.state;
        const { bumn } = this.props;

        return (
            <div className='m-sm-30'>
                <h2 style={{ fontSize: '25px' }} className='mb-3'>
                    List Paket bumn Mandiri
                </h2>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button
                        variant='outlined'
                        color='primary'
                        className='mb-5'
                        onClick={() =>
                            this.setState({
                                shouldOpenCreateDialog: true
                            })
                        }
                        title='Edit'
                    >
                        Tambah
                    </Button>
                    <FormControl
                        className='faqDesc'
                        style={{
                            boxShadow: '0px 0px 2px #43434359',
                            marginBottom: '15px',
                            borderRadius: '5px',
                            maxHeight: '45px'
                        }}
                    >
                        <Box
                            component='fieldset'
                            borderColor='transparent'
                            style={{ paddingLeft: '0px' }}
                            className='flex'
                        >
                            <IconButton title='Cari' style={{ padding: '5px' }}>
                                <Icon color='action'>search</Icon>
                            </IconButton>
                            <input
                                onChange={this.handleSearch}
                                placeholder='Cari..'
                                name='query'
                                value={query}
                                style={{
                                    minHeight: '25px',
                                    border: '0px',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    outline: 0
                                }}
                            ></input>
                        </Box>
                    </FormControl>
                </div>
                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        No
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Nama Paket
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Harga
                                    </TableCell>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        Diskon
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        align='center'
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.renderData()}</TableBody>
                        </Table>
                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={bumn.data.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />
                        {modalEdit && (
                            <EditBumn
                                benefit={edit_bumn.benefit}
                                discount={edit_bumn.discount}
                                expired_month={edit_bumn.expired_month}
                                title={edit_bumn.title}
                                price={edit_bumn.price}
                                is_active={edit_bumn.is_active}
                                doc_id={edit_bumn.doc_id}
                                open={modalEdit}
                                description={edit_bumn.description}
                                type={edit_bumn.type}
                                group_link={edit_bumn.group_link}
                                handleClose={this.handleCloseEdit}
                            />
                        )}
                        {shouldOpenCreateDialog && (
                            <AddBumnDialog
                                open={shouldOpenCreateDialog}
                                handleClose={this.handleCloseEdit}
                            />
                        )}
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        bumn: state.bumn
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBumnMandiri: () => dispatch(getBumnMandiri())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BumnTable);
