import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_ALL_VOUCHER = 'GET_ALL_VOUCHER';
export const ADD_VOUCHER = 'ADD_VOUCHER';
export const EDIT_VOUCHER = 'EDIT_VOUCHER';
export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const SET_LOADING = 'SET_LOADING';

export const getAllVoucher = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('voucher')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    item.created_at = item.created_at;
                    data.push(item);
                });
                dispatch({
                    type: GET_ALL_VOUCHER,
                    payload: data
                });
            });
    };
};

export const addVoucher = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('voucher')
            .doc(id)
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Voucher berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                getAllVoucher();
                dispatch({
                    type: ADD_VOUCHER,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Voucher gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const deleteVoucher = (doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('voucher')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Voucher berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_VOUCHER,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Vocuher gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updateVoucher = (params, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('voucher')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Voucher berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: EDIT_VOUCHER,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Voucher gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
