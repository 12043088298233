import {
    DELETE_BIMBEL,
    GET_INFORMASI,
    EDIT_INFORMASI
} from '../actions/InformasiActions';

const initialState = {
    data: [],
    loading: false
};

const InformasiReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_INFORMASI: {
            return {
                ...state,
                data: action.payload
            };
        }
        case DELETE_BIMBEL: {
            let bimbel = state.data.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                data: bimbel
            };
        }
        default: {
            return state;
        }
    }
};

export default InformasiReducer;
