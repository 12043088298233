import React, { Component } from 'react';
import {
    Dialog,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import {
    ValidatorForm,
    TextValidator,
    SelectValidator
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { addSP, getTryoutUK } from 'app/redux/actions/UKActions';
import { ButtonUploadFile } from 'app/components';

class AddSPDialog extends Component {
    state = {
        title: '',
        description: '',
        materi_file: '',
        preview_file: '',
        statusButton: false,
        buttonText: 'Tambah',
        isActive: false,
        formasi_id: 0
    };

    componentDidMount() {
        let id = window.location.pathname.split('/').pop();
        this.props.getTryoutUK(id);
    }

    handleChange = (event, source) => {
        event.persist();
        if (source === 'switch') {
            console.log(event.target);
            this.setState({ isActive: event.target.checked });
            return;
        }

        if (source === 'status') {
            if (event.target.value === 'SUSPEND') {
                this.setState({
                    users_status: 0,
                    users_status_value: 'SUSPEND'
                });
                return;
            } else {
                this.setState({
                    users_status: 1,
                    users_status_value: 'ACTIVE'
                });
                return;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeSelect = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = async () => {
        this.setState({
            statusButton: true,
            buttonText: 'Loading...'
        });
        let { title, description, materi_file, formasi_id } = this.state;
        let parent_id = window.location.pathname.split('/').pop();
        let params = {
            title: title,
            description: description,
            materi_file: materi_file,
            formasi_id: formasi_id,
            created_at: new Date(),
            parent_id: parent_id
        };
        await this.props.addSP(params);
        await this.props.handleClose();
    };

    handleUploadThumbnail = (file, path) => {
        this.setState({
            materi_file: file,
            preview_file: path
        });
    };

    render() {
        let {
            title,
            description,
            materi_file,
            preview_file,
            formasi_id
        } = this.state;
        let { open, handleClose, tryout } = this.props;
        return (
            <Dialog onClose={handleClose} open={open}>
                <div className='p-5' style={{ minWidth: '500px' }}>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid className='mb-10' container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Judul *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid className='mb-10' container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Deskripsi *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid className='mb-10' container spacing={4}>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Soal dan Pembahasan Untuk Tryout'
                                    onChange={this.handleChange}
                                    name='formasi_id'
                                    value={formasi_id}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value={0} disabled>
                                        <em>Pilih</em>
                                    </MenuItem>
                                    {tryout.map((item) => (
                                        <MenuItem value={item.formasi_id}>
                                            {item.title}
                                        </MenuItem>
                                    ))}
                                </SelectValidator>
                            </Grid>

                            <Grid item sm={6} xs={12} className='pb-0'>
                                <InputLabel id='demo-simple-select-helper-label'>
                                    Soal dan Pembahasan File *
                                </InputLabel>
                                <div
                                    style={{ width: 100, height: 100 }}
                                    className='relative'
                                >
                                    {preview_file && (
                                        <img
                                            src='/assets/images/pdf.jpg'
                                            width='100%'
                                            height='100%'
                                            style={{ objectFit: 'cover' }}
                                        />
                                    )}
                                    <ButtonUploadFile
                                        uploadFoto={this.handleUploadThumbnail}
                                    />
                                </div>
                            </Grid>
                            <div className='w-100 mb-16'>
                                <label color='gray'> * Required </label>
                            </div>
                        </Grid>

                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={this.state.statusButton}
                                color='primary'
                                type='submit'
                            >
                                {this.state.buttonText}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        tryout: state.uk.tryout
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTryoutUK: (params) => dispatch(getTryoutUK(params)),
        addSP: (params) => dispatch(addSP(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSPDialog);
