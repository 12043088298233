import { Card, Icon } from '@material-ui/core';
import { formatTanggal } from 'app/redux/actions/UserActions';
import React from 'react';

const CardPaketProfile = ({ title, doc_id, expired }) => {
    return (
        <Card
            elevation={0}
            className={`px-6 py-4 h-full card-paket-profile text-center card-ranking-tertinggi flex items-center`}
        >
            <Icon>verified_user</Icon>
            <div className="ml-3 text-left">
                <h5 className="mb-0">{title}</h5>
                {expired != undefined ? (
                    <p className="m-0">
                        {doc_id == 'free'
                            ? 'Berlaku sampai seterusnya'
                            : formatTanggal(expired)}
                    </p>
                ) : null}
            </div>
        </Card>
    );
};

export default CardPaketProfile;
