import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import { ButtonUploadThumbnail } from 'app/components';
import { EditRekamanPPPK } from 'app/redux/actions/PPPKActions';
import React, { Component, Fragment } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

class EditMateri extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            bg_color: '#ff4646',
            category: '',
            created_at: '',
            description: '',
            materi_file: '',
            title: '',
            submit: false,
            category_desc: '',
            parent_id: parent,
            doc_id: '',
            materi_file_before: '',
            materi_video: '',
            format_materi: 'file',
            thumbnail: '',
            thumbnail_old: '',
            thumbnail_preview: '',
            type: '',
            batch: ''
        };
    }

    componentDidMount() {
        const {
            bg_color,
            category,
            description,
            title,
            category_desc,
            doc_id,
            materi_file,
            thumbnail,
            materi_video,
            type,
            batch_video
        } = this.props;
        let format_materi = materi_file != null ? 'file' : 'video';
        this.setState({
            bg_color: bg_color,
            category: category,
            description: description,
            title: title,
            category_desc: category_desc,
            doc_id: doc_id,
            materi_file_before: materi_file,
            materi_video: materi_video,
            format_materi: format_materi,
            thumbnail_preview: thumbnail,
            thumbnail_old: thumbnail,
            type: type,
            batch: batch_video
        });

        console.log(this.props);
    }
    handleChangeBatch = (val) => {
        let value = val.target.value;
        this.setState({
            batch: value
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const {
            bg_color,
            category,
            description,
            materi_file,
            title,
            parent_id,
            category_desc,
            materi_file_before,
            doc_id,
            materi_video,
            thumbnail,
            type,
            batch
        } = this.state;
        const params = {
            bg_color: bg_color,
            category: category,
            description: description,
            materi_file: materi_file,
            title: title,
            parent_id: parent_id,
            category_desc: category_desc,
            materi_file_before: materi_file_before,
            doc_id: doc_id,
            materi_video: materi_video,
            thumbnail_preview: thumbnail,
            thumbnail_old: thumbnail,
            type: type,
            batch: batch
        };
        const { handleClose, editMateri } = this.props;
        handleClose();
        // console.log(params);
        editMateri(params);
        this.setState({
            submit: false
        });
    };

    getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    };

    isPDF = (filename) => {
        var ext = this.getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'pdf':
                return true;
        }
        return false;
    };

    handleChangeFile = (e) => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        if (this.isPDF(e.target.files[0].name)) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onloadend = () => {
                this.setState({
                    materi_file: reader.result
                });
            };
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid File Format',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    handleChangeBG = (val) => {
        this.setState({
            bg_color: val
        });
    };

    handleUploadThumbnail = (file, path) => {
        this.setState({
            thumbnail: file,
            thumbnail_preview: path
        });
    };

    handleChangeCategory = (val) => {
        let value = val.target.value;
        let category_desc = '';
        if (value == 'twk_dinas' || value == 'twk') {
            category_desc = 'Wawasan Kebangsaan';
        } else if (value == 'tiu_dinas' || value == 'tiu') {
            category_desc = 'Intelegensi Umum';
        } else if (value == 'tkp_dinas' || value == 'tkp') {
            category_desc = 'Karakteristik Umum';
        }
        this.setState({
            type: value,
            category_desc: category_desc
        });
    };

    handleChangeFormat = (val) => {
        let value = val.target.value;
        this.setState({
            format_materi: value,
            materi_file: '',
            materi_video: ''
        });
    };

    render() {
        let {
            title,
            bg_color,
            type,
            description,
            submit,
            parent_id,
            format_materi,
            materi_video,
            thumbnail_preview,
            batch
        } = this.state;
        let { open, handleClose, batchData } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Edit Rekaman SKD</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    label='Nama Rekaman'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    className='w-full'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Batch'
                                    onChange={this.handleChangeBatch}
                                    name='Tipe Batch'
                                    value={batch}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    {batchData.length > 0 ? (
                                        batchData.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item.id}
                                            >
                                                {item.title}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Kategory Materi'
                                    onChange={this.handleChangeCategory}
                                    name='category'
                                    value={type}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem
                                        value={
                                            parent_id == 'dinas' ||
                                            parent_id == 'dinas_platinum'
                                                ? 'twk_dinas'
                                                : 'twk'
                                        }
                                    >
                                        TWK
                                    </MenuItem>
                                    <MenuItem
                                        value={
                                            parent_id == 'dinas' ||
                                            parent_id == 'dinas_platinum'
                                                ? 'tiu_dinas'
                                                : 'tiu'
                                        }
                                    >
                                        TIU
                                    </MenuItem>
                                    <MenuItem
                                        value={
                                            parent_id == 'dinas' ||
                                            parent_id == 'dinas_platinum'
                                                ? 'tkp_dinas'
                                                : 'tkp'
                                        }
                                    >
                                        TKP
                                    </MenuItem>
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-6 w-full'
                                    label='Deskripsi Materi'
                                    multiline
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            {/*  */}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextValidator
                                label='Link Rekaman Video'
                                onChange={this.handleChange}
                                type='text'
                                name='materi_video'
                                value={materi_video}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                className='w-full'
                            />
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        batchData: state.batch.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        editMateri: (params) => dispatch(EditRekamanPPPK(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditMateri);
