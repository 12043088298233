import Voucher from './Voucher';

const VoucherRoutes = [
    {
        path: '/voucher',
        component: Voucher
    }
];

export default VoucherRoutes;
