import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import { addTahunPremium } from 'app/redux/actions/TahunToPremium';
import React, { Component, Fragment } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

class TambahTO extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            category: '',
            created_at: '',
            description: '',
            title: '',
            submit: false,
            parent_id: '',
            materi_video: '',
            type: '',
            tahun: ''
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const { description, title, parent_id, materi_video, type, tahun } =
            this.state;
        const params = {
            doc_id: uuidv4(),
            title: title,
            type: 'premium'
        };
        const { handleClose, tambahTahun } = this.props;
        handleClose();
        tambahTahun(params);
        this.setState({
            submit: false
        });
    };


    componentDidMount() {
        const { match } = this.props;

        this.setState({
            parent_id: match
        });
    }

    render() {
        let { title, submit } = this.state;

        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Tahun Tryout</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    label='Nama tahun Tryout'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    className='w-full'
                                />
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        tambahTahun: (params) => dispatch(addTahunPremium(params))
    };
};

const mapStateToProps = (state) => {
    return {
        batchData: state.batch.data
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TambahTO);
