import { ADD_KERANJANG, DEL_KERANJANG, GET_KERANJANG } from '../actions/KeranjangActions';

const initialState = {
	data: [],
	harga: 0,
};

const KeranjangReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_KERANJANG: {
			return {
				data: action.payload.data,
				harga: action.payload.harga,
			};
		}
		case ADD_KERANJANG: {
			return {
				data: [...state.data, action.payload],
				harga: parseInt(state.harga) + action.payload.harga,
			};
		}
		case DEL_KERANJANG: {
			return {
				data: state.data.filter((item) => item.doc_id != action.payload),
			};
		}
		default: {
			return state;
		}
	}
};

export default KeranjangReducer;
