import {
    Button,
    CircularProgress,
    Dialog,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { ButtonUploadThumbnail } from 'app/components';
import { addBimbel, getBimbel } from 'app/redux/actions/BimbelActions';
import React, { Component, Fragment } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class ModalAddBimbel extends Component {
    constructor() {
        super();
        let date = new Date();

        let tahun = date.getFullYear();
        let bulan = date.getMonth() + 1;
        let tanggal = date.getDate();
        let jam = date.getHours();
        let menit = date.getMinutes();
        if (bulan < 10) {
            bulan = '0' + bulan;
        }
        if (tanggal < 10) {
            tanggal = '0' + tanggal;
        }
        if (jam < 10) {
            jam = '0' + jam;
        }
        if (menit < 10) {
            menit = '0' + menit;
        }
        let tanggalSekarang =
            tahun + '-' + bulan + '-' + tanggal + ' ' + jam + ':' + menit;
        this.state = {
            bimble_address: '',
            bimble_benefit: [],
            bimble_discount: 0,
            bimble_expired_month: 0,
            bimble_link: '',
            bimble_maps: {
                lat: '',
                lng: ''
            },
            bimble_name: '',
            bimble_platform: '',
            bimble_price: 0,
            bimble_schedule: tanggalSekarang,
            bimble_tutor: '',
            thumbnail: '',
            type: 'online',
            thumbnail_preview: '',
            form_benefit: {
                status: 1,
                benefit: '',
                index: '',
                isNew: true,
                error: ''
            },
            now: tanggalSekarang,
            defaultScheduleVal: tanggalSekarang.replace(' ', 'T')
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeType = (event) => {
        let value = event.target.value;
        if (value == 'offline') {
            this.setState({
                type: value,
                bimble_platform: '',
                bimble_link: ''
            });
        } else if (value == 'online') {
            this.setState({
                type: value,
                bimble_address: '',
                bimble_maps: {
                    lat: '',
                    lng: ''
                }
            });
        }
    };

    handleChangeDate = (event) => {
        let tanggal = new Date(event.target.value);
        let tahun = tanggal.getFullYear();
        let bulan = tanggal.getMonth() + 1;
        let hari = tanggal.getDate();
        let jam = tanggal.getHours();
        let menit = tanggal.getMinutes();
        bulan = bulan < 10 ? '0' + bulan : bulan;
        hari = hari < 10 ? '0' + hari : hari;
        jam = jam < 10 ? '0' + jam : jam;
        menit = menit < 10 ? '0' + menit : menit;
        this.setState({
            bimble_schedule:
                tahun + '-' + bulan + '-' + hari + ' ' + jam + ':' + menit
        });
    };

    submitForm = () => {
        let {
            bimble_address,
            bimble_benefit,
            bimble_discount,
            bimble_expired_month,
            bimble_link,
            bimble_maps,
            bimble_name,
            bimble_platform,
            bimble_price,
            bimble_schedule,
            bimble_tutor,
            thumbnail,
            type
        } = this.state;
        const { addBimbel, handleClose, getBimbel } = this.props;
        let params = {
            bimble_address: bimble_address,
            bimble_benefit: bimble_benefit,
            bimble_discount: bimble_discount,
            bimble_expired_month: bimble_expired_month,
            bimble_link: bimble_link,
            bimble_maps_lat: bimble_maps.lat,
            bimble_maps_lng: bimble_maps.lng,
            bimble_name: bimble_name,
            bimble_platform: bimble_platform,
            bimble_price: bimble_price,
            bimble_schedule: bimble_schedule,
            bimble_tutor: bimble_tutor,
            thumbnail: thumbnail,
            type: type
        };
        handleClose();
        addBimbel(params);
    };

    handleChangeLatMaps = (e) => {
        let value = e.target.value;
        this.setState((prefState) => ({
            bimble_maps: {
                ...prefState.bimble_maps,
                lat: value
            }
        }));
    };

    handleChangeLngMaps = (e) => {
        let value = e.target.value;
        this.setState((prefState) => ({
            bimble_maps: {
                ...prefState.bimble_maps,
                lng: value
            }
        }));
    };

    handleUploadThumbnail = (file, path) => {
        this.setState({
            thumbnail: file,
            thumbnail_preview: path
        });
    };

    handleChangeBenefit = (event) => {
        let value = event.target.value;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                benefit: value
            }
        }));
    };

    handleStatusBenefit = (event) => {
        let checked = event.target.checked;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                status: checked ? 1 : 0
            }
        }));
    };

    handleFormBenefit = () => {
        const {
            form_benefit: { benefit, isNew, index, status },
            bimble_benefit
        } = this.state;
        if (benefit.length > 0) {
            if (isNew) {
                let allBenefit = bimble_benefit.concat({
                    status: status,
                    benefit: benefit
                });
                this.setState({
                    bimble_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            } else {
                let allBenefit = bimble_benefit;
                allBenefit[index] = {
                    status: status,
                    benefit: benefit
                };
                this.setState({
                    bimble_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            }
        } else {
            this.setState((prefState) => ({
                form_benefit: {
                    ...prefState.form_benefit,
                    error: 'Tolong isi semua inputan'
                }
            }));
        }
    };

    handleEditBenefit = (item, index) => {
        let benefit = item.benefit;
        let status = item.status;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                benefit: benefit,
                status: status,
                isNew: false,
                index: index
            }
        }));
    };

    handleDeleteBenefit = (i) => {
        const { bimble_benefit } = this.state;
        let allBenefit = bimble_benefit.filter((item, index) => index != i);
        this.setState({
            bimble_benefit: allBenefit
        });
    };

    render() {
        let {
            bimble_address,
            bimble_benefit,
            bimble_discount,
            bimble_expired_month,
            bimble_link,
            bimble_maps,
            bimble_name,
            bimble_platform,
            bimble_price,
            bimble_schedule,
            bimble_tutor,
            thumbnail_preview,
            type,
            form_benefit,
            defaultScheduleVal
        } = this.state;
        let { open, handleClose, loadingBimbel } = this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth='md'
                fullWidth={true}
            >
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Paket Bimbel</h4>
                    <ValidatorForm ref='form' onSubmit={this.submitForm}>
                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='mb-4 w-full'
                                    label='Tipe Bimbel'
                                    onChange={this.handleChangeType}
                                    name='type'
                                    value={type || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='online'>Online</MenuItem>
                                    <MenuItem value='offline'>Offline</MenuItem>
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <div
                                    style={{ width: 100, height: 100 }}
                                    className='relative'
                                >
                                    {thumbnail_preview && (
                                        <img
                                            src={thumbnail_preview}
                                            width='100%'
                                            height='100%'
                                            style={{ objectFit: 'cover' }}
                                        />
                                    )}
                                    <ButtonUploadThumbnail
                                        uploadFoto={this.handleUploadThumbnail}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className='mb-2' container spacing={4}>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Judul Bimbel'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='bimble_name'
                                    value={bimble_name}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Tutor Bimbel'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='bimble_tutor'
                                    value={bimble_tutor}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Harga Paket Bimbel'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='bimble_price'
                                    value={bimble_price}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Diskon Paket Bimbel'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='bimble_discount'
                                    value={bimble_discount}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Masa aktif paket bimbel (bulan)'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='bimble_expired_month'
                                    value={bimble_expired_month}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Jadwal Bimbel'
                                    onChange={this.handleChangeDate}
                                    type='datetime-local'
                                    name='bimble_schedule'
                                    defaultValue={defaultScheduleVal}
                                />
                            </Grid>
                            {type == 'online' ? (
                                <Fragment>
                                    <Grid item sm={6} xs={12}>
                                        <SelectValidator
                                            className='mb-4 w-full'
                                            label='Platform Bimbel'
                                            onChange={this.handleChange}
                                            name='bimble_platform'
                                            value={bimble_platform || ''}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        >
                                            <MenuItem value='zoom'>
                                                Zoom
                                            </MenuItem>
                                            <MenuItem value='google-meet'>
                                                Google Meet
                                            </MenuItem>
                                        </SelectValidator>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='w-full'
                                            label='Link Bimbel'
                                            onChange={this.handleChange}
                                            type='text'
                                            name='bimble_link'
                                            value={bimble_link}
                                        />
                                    </Grid>
                                </Fragment>
                            ) : null}
                            {type == 'offline' ? (
                                <Fragment>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className='mb-6 w-full'
                                            label='Alamat Bimbel'
                                            multiline
                                            onChange={this.handleChange}
                                            type='text'
                                            name='bimble_address'
                                            value={bimble_address || ''}
                                            validators={['required']}
                                            errorMessages={[
                                                'Field is Required'
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item sm={6} xs={6}>
                                                <TextValidator
                                                    className='w-full'
                                                    label='Lokasi Bimbel - Latitude'
                                                    onChange={
                                                        this.handleChangeLatMaps
                                                    }
                                                    type='text'
                                                    name='lat'
                                                    value={bimble_maps.lat}
                                                    validators={['required']}
                                                    errorMessages={[
                                                        'Field is Required'
                                                    ]}
                                                />
                                            </Grid>
                                            <Grid item sm={6} xs={6}>
                                                <TextValidator
                                                    className='w-full'
                                                    label='Lokasi Bimbel - Langitude'
                                                    onChange={
                                                        this.handleChangeLngMaps
                                                    }
                                                    type='text'
                                                    name='lng'
                                                    value={bimble_maps.lng}
                                                    validators={['required']}
                                                    errorMessages={[
                                                        'Field is Required'
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            ) : null}
                        </Grid>
                        <h5 className='mt-0'>Benefit Paket</h5>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <div className='w-full overflow-auto bg-white'>
                                    <Table className='crud-table'>
                                        <TableHead className='bg-primary'>
                                            <TableRow>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    No
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    colSpan={2}
                                                >
                                                    Benefit
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                ></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bimble_benefit.length > 0 ? (
                                                bimble_benefit.map(
                                                    (item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                align='center'
                                                            >
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                colSpan={2}
                                                            >
                                                                {item.benefit}
                                                            </TableCell>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                align='center'
                                                            >
                                                                <div
                                                                    className={`badge-status-benefit ${
                                                                        item.status ==
                                                                        1
                                                                            ? 'bg-green'
                                                                            : 'bg-error'
                                                                    }`}
                                                                >
                                                                    {item.status ==
                                                                    1 ? (
                                                                        <Icon>
                                                                            check
                                                                        </Icon>
                                                                    ) : (
                                                                        <Icon>
                                                                            close
                                                                        </Icon>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                align='center'
                                                            >
                                                                <IconButton
                                                                    title='edit'
                                                                    onClick={() =>
                                                                        this.handleEditBenefit(
                                                                            item,
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon color='primary'>
                                                                        border_color
                                                                    </Icon>
                                                                </IconButton>
                                                                <IconButton
                                                                    title='delete'
                                                                    onClick={() =>
                                                                        this.handleDeleteBenefit(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon color='error'>
                                                                        delete
                                                                    </Icon>
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        className='px-2 py-2 text-center font-poppins font-bold'
                                                        align='center'
                                                        colSpan={5}
                                                    >
                                                        Tidak ada benefit paket
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <h6 className='mt-0'>
                                    {form_benefit.isNew
                                        ? 'Tambah Benfit'
                                        : 'Edit Benfit'}
                                </h6>
                                <TextValidator
                                    className='w-full'
                                    label='Benefit'
                                    onChange={this.handleChangeBenefit}
                                    type='text'
                                    name='benefit'
                                    value={form_benefit.benefit}
                                />
                                {form_benefit.error.length > 0 ? (
                                    <small className='text-error'>
                                        {form_benefit.error}
                                    </small>
                                ) : null}
                                <div className='flex items-center justify-between'>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    form_benefit.status == 1
                                                }
                                                name='Status'
                                                onChange={
                                                    this.handleStatusBenefit
                                                }
                                                color='primary'
                                            />
                                        }
                                        label='Status benefit'
                                        className='mt-1'
                                    />
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={this.handleFormBenefit}
                                        className='elevation-z0 mt-1 w-full max-w-195'
                                    >
                                        {form_benefit.isNew
                                            ? 'Tambah Benefit'
                                            : 'Edit Benefit'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='border-bottom-1 mt-3'></div>
                        <div className='flex flex-space-between flex-middle mt-3'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                {loadingBimbel ? (
                                    <CircularProgress />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Batal</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingBimbel: state.bimbel.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addBimbel: (params) => dispatch(addBimbel(params)),
        getBimbel: () => dispatch(getBimbel())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddBimbel);
