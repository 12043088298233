import React from 'react';

const UserRoutes = [
    {
        path: '/user/detail/:id',
        component: React.lazy(() => import('./UserDetail'))
    },
    {
        path: '/user',
        component: React.lazy(() => import('./UserTable'))
    }
];

export default UserRoutes;
