import {
    Button,
    Card,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    FormControl,
    Box
} from '@material-ui/core';
import shortid from 'shortid';
import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { ConfirmationDialog } from 'matx';

import { getSPList, deleteSP } from 'app/redux/actions/UKActions';
import AddSPDialog from './AddSPDialog';
import EditSPDialog from './EditSPDialog';

class SoalPembahasanList extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            rowsPerPage: 10,
            page: 0,
            shouldOpenEditorDialog: false,
            shouldOpenCreateDialog: false,
            shouldOpenConfirmationDialog: false,
            query: ''
        };
    }
    componentDidMount() {
        this.updatePageData();
    }

    updatePageData() {
        const { getSPList } = this.props;
        let parent = window.location.pathname.split('/').pop();
        getSPList(parent);
    }

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenConfirmationDialog: false,
            shouldOpenCreateDialog: false
        });
        this.updatePageData();
    };

    handleConfirmationResponse = async () => {
        let parent_id = window.location.pathname.split('/').pop();
        await this.props.deleteSP(parent_id, this.state.doc_id);
        this.handleDialogClose();
    };

    render() {
        const { sp_uk } = this.props;
        const {
            query,
            rowsPerPage,
            page,
            shouldOpenEditorDialog,
            shouldOpenCreateDialog,
            shouldOpenConfirmationDialog
        } = this.state;
        let parent = window.location.pathname.split('/').pop();
        return (
            <div>
                <Card className='p-5 overflow-auto' elevation={6}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Button
                            variant='outlined'
                            color='primary'
                            className='mb-5'
                            onClick={() =>
                                this.setState({
                                    shouldOpenCreateDialog: true
                                })
                            }
                            title='Edit'
                        >
                            Tambah
                        </Button>
                        <FormControl
                            className='faqDesc'
                            style={{
                                boxShadow: '0px 0px 2px #43434359',
                                marginBottom: '15px',
                                borderRadius: '5px',
                                maxHeight: '45px'
                            }}
                        >
                            <Box
                                component='fieldset'
                                borderColor='transparent'
                                style={{ paddingLeft: '0px' }}
                                className='flex'
                            >
                                <IconButton
                                    title='Cari'
                                    style={{ padding: '5px' }}
                                >
                                    <Icon color='error'>search</Icon>
                                </IconButton>
                                <input
                                    onChange={this.handleSearch}
                                    placeholder='Cari..'
                                    name='query'
                                    value={query}
                                    style={{
                                        minHeight: '25px',
                                        border: '0px',
                                        backgroundColor: 'transparent',
                                        width: '100%',
                                        outline: 0
                                    }}
                                ></input>
                            </Box>
                        </FormControl>
                    </div>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell className='px-2 py-2 text-center font-poppins font-bold text-white'>
                                        No.
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Judul
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={4}
                                    >
                                        Deskripsi
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={3}
                                    >
                                        Materi Link
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Tgl Dibuat
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sp_uk.length > 0 ? (
                                    sp_uk
                                        .filter((item) =>
                                            Object.keys(item).some((key) =>
                                                query.includes(key)
                                                    ? false
                                                    : item[key]
                                                          .toString()
                                                          .toLowerCase()
                                                          .includes(query)
                                            )
                                        )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((datUsers, index) => (
                                            <TableRow key={shortid.generate()}>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                >
                                                    {index + 1}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.title}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={4}
                                                >
                                                    {datUsers.description}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={3}
                                                >
                                                    <a
                                                        target='_blank'
                                                        href={
                                                            datUsers.materi_file
                                                        }
                                                    >
                                                        {datUsers.materi_file
                                                            .length > 27
                                                            ? datUsers.materi_file.substring(
                                                                  0,
                                                                  27
                                                              ) + '...'
                                                            : datUsers.materi_file}
                                                    </a>
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.created_at}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    colSpan={2}
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            this.setState({
                                                                uid: datUsers,
                                                                shouldOpenEditorDialog: true
                                                            })
                                                        }
                                                        title='Edit'
                                                    >
                                                        <Icon color='primary'>
                                                            edit
                                                        </Icon>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() =>
                                                            this.setState({
                                                                doc_id:
                                                                    datUsers.doc_id,
                                                                shouldOpenConfirmationDialog: true
                                                            })
                                                        }
                                                        title='delete'
                                                    >
                                                        <Icon color='error'>
                                                            delete
                                                        </Icon>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={12} align='center'>
                                            Not Have Data
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={sp_uk.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />

                        {shouldOpenEditorDialog && (
                            <EditSPDialog
                                handleClose={this.handleDialogClose}
                                open={shouldOpenEditorDialog}
                                uid={this.state.uid}
                            />
                        )}
                        {shouldOpenCreateDialog && (
                            <AddSPDialog
                                handleClose={this.handleDialogClose}
                                open={shouldOpenCreateDialog}
                                uid={this.state.uid}
                            />
                        )}
                        {shouldOpenConfirmationDialog && (
                            <ConfirmationDialog
                                open={shouldOpenConfirmationDialog}
                                onConfirmDialogClose={this.handleDialogClose}
                                onYesClick={this.handleConfirmationResponse}
                                text='Hapus Data Ini ?'
                            />
                        )}
                    </div>
                </Card>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        sp_uk: state.uk.sp_uk
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSPList: (parent) => dispatch(getSPList(parent)),
        deleteSP: (parent, id) => dispatch(deleteSP(parent, id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SoalPembahasanList);
