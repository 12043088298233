import {
    Box,
    Button,
    Card,
    FormControl,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { ConfirmationDialog } from 'matx';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';

import { deleteAdmin, getAllAdmin } from '../../redux/actions/AdminActions';
import AddAdminDialog from './AddAdminDialog';
import UsersTableDialog from './UsersTableDialog';

class AdminTable extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        shouldOpenEditorDialog: false,
        shouldOpenCreateDialog: false,
        shouldOpenConfirmationDialog: false,
        query: ''
    };

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenConfirmationDialog: false,
            shouldOpenCreateDialog: false
        });
        this.updatePageData();
    };

    handleDeleteUser = (data) => {
        this.setState({
            data,
            shouldOpenConfirmationDialog: true
        });
    };

    handleConfirmationResponse = async () => {
        await this.props.deleteAdmin(this.state.data);
        this.handleDialogClose();
    };

    componentDidMount() {
        this.updatePageData();
    }

    componentDidUpdate(prevProps, prevState) {}

    updatePageData = async () => {
        await this.props.getAllAdmin();
    };

    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    render() {
        let {
            rowsPerPage,
            page,
            shouldOpenCreateDialog,
            shouldOpenEditorDialog,
            shouldOpenConfirmationDialog,
            query
        } = this.state;
        let { admin = [], user } = this.props;
        let uid = localStorage.getItem('uid');
        return (
            <div className='m-sm-30'>
                <h2 className='mt-10 mb-5' style={{ fontSize: '25px' }}>
                    List Admin
                </h2>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button
                        variant='outlined'
                        color='primary'
                        className='mb-5'
                        onClick={() =>
                            this.setState({
                                shouldOpenCreateDialog: true
                            })
                        }
                        title='Edit'
                    >
                        Tambah
                    </Button>
                    <FormControl
                        className='faqDesc'
                        style={{
                            boxShadow: '0px 0px 2px #43434359',
                            marginBottom: '15px',
                            borderRadius: '5px',
                            maxHeight: '45px'
                        }}
                    >
                        <Box
                            component='fieldset'
                            borderColor='transparent'
                            style={{ paddingLeft: '0px' }}
                            className='flex'
                        >
                            <IconButton title='Cari' style={{ padding: '5px' }}>
                                <Icon color='error'>search</Icon>
                            </IconButton>
                            <input
                                onChange={this.handleSearch}
                                placeholder='Cari..'
                                name='query'
                                value={query}
                                style={{
                                    minHeight: '25px',
                                    border: '0px',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    outline: 0
                                }}
                            ></input>
                        </Box>
                    </FormControl>
                </div>

                <Card className='p-5 overflow-auto' elevation={6}>
                    <TableContainer>
                        <Table
                            className='crud-table'
                            style={{ whiteSpace: 'pre', minWidth: '750px' }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>Name</TableCell>
                                    <TableCell colSpan={2}>Email</TableCell>
                                    <TableCell colSpan={2}>Token</TableCell>
                                    {user.is_super == '1' && (
                                        <TableCell>Action</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {admin.length > 0 ? (
                                    admin
                                        .filter((item) =>
                                            Object.keys(item).some((key) =>
                                                query.includes(key)
                                                    ? false
                                                    : item[key]
                                                          .toString()
                                                          .toLowerCase()
                                                          .includes(query)
                                            )
                                        )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .filter((item) => item.id !== uid)
                                        .map((datUsers, index) => (
                                            <TableRow key={shortid.generate()}>
                                                <TableCell
                                                    className='px-0'
                                                    lign='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.name}
                                                </TableCell>

                                                <TableCell
                                                    className='px-0'
                                                    align='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.email}
                                                </TableCell>

                                                <TableCell
                                                    className='px-0'
                                                    align='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.id.substring(
                                                        0,
                                                        5
                                                    )}
                                                    ****
                                                </TableCell>

                                                {user.is_super == '1' && (
                                                    <TableCell className='px-0 border-none'>
                                                        <IconButton
                                                            onClick={() =>
                                                                this.setState({
                                                                    uid: datUsers,
                                                                    shouldOpenEditorDialog: true
                                                                })
                                                            }
                                                            title='Edit'
                                                        >
                                                            <Icon color='primary'>
                                                                edit
                                                            </Icon>
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() =>
                                                                this.handleDeleteUser(
                                                                    datUsers
                                                                )
                                                            }
                                                            title='delete'
                                                        >
                                                            <Icon color='error'>
                                                                delete
                                                            </Icon>
                                                        </IconButton>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align='center'>
                                            Not Have Data
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={admin.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />

                        {shouldOpenEditorDialog && (
                            <UsersTableDialog
                                handleClose={this.handleDialogClose}
                                open={shouldOpenEditorDialog}
                                uid={this.state.uid}
                            />
                        )}
                        {shouldOpenCreateDialog && (
                            <AddAdminDialog
                                handleClose={this.handleDialogClose}
                                open={shouldOpenCreateDialog}
                                uid={this.state.uid}
                            />
                        )}
                        {shouldOpenConfirmationDialog && (
                            <ConfirmationDialog
                                open={shouldOpenConfirmationDialog}
                                onConfirmDialogClose={this.handleDialogClose}
                                onYesClick={this.handleConfirmationResponse}
                                text='Hapus Data Ini ?'
                            />
                        )}
                    </TableContainer>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        admin: state.admin.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllAdmin: () => dispatch(getAllAdmin()),
        deleteAdmin: (params) => dispatch(deleteAdmin(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminTable);
