import { GET_LAPORAN_SOAL } from '../actions/LaporanSoalActions';

const initialState = {
    data: []
};

const LaporanReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_LAPORAN_SOAL: {
            return {
                data: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default LaporanReducer;
