import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { getLatihanRankingSKB } from 'app/redux/actions/HasilSKDActions';
import { getPassingGradeUKSKB } from 'app/redux/actions/PassingGradeActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class LatihanRanking extends Component {
    componentDidMount() {
        const { tryout } = this.props.match.params;
        const { getLatihanRankingSKB, getPassingGradeUKSKB } = this.props;
        getLatihanRankingSKB(tryout);
        getPassingGradeUKSKB();
    }

    render() {
        const { hasilSKD, passingGrade } = this.props;
        return (
            <div className='m-sm-30'>
                <h2 className='mt-10 mb-5' style={{ fontSize: '25px' }}>
                    Ranking Nasional
                </h2>
                <div className='w-full overflow-auto'>
                    <Table className='crud-table'>
                        <TableHead className='bg-primary'>
                            <TableRow>
                                <TableCell
                                    className='py-2 text-center font-poppins font-bold text-white'
                                    align='center'
                                >
                                    Ranking
                                </TableCell>
                                <TableCell className='py-2 text-center font-poppins font-bold text-white'>
                                    Nama
                                </TableCell>
                                <TableCell
                                    className='py-2 text-center font-poppins font-bold text-white'
                                    align='center'
                                >
                                    Skor Benar
                                </TableCell>
                                <TableCell
                                    className='py-2 text-center font-poppins font-bold text-white'
                                    align='center'
                                >
                                    Skor Salah
                                </TableCell>
                                <TableCell
                                    className='py-2 text-center font-poppins font-bold text-white'
                                    align='center'
                                >
                                    Skor Akhir
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hasilSKD.data.length <= 0 ? (
                                <TableCell
                                    className='py-2 text-center font-poppins'
                                    align='center'
                                    colSpan={5}
                                >
                                    Data Kosong
                                </TableCell>
                            ) : (
                                hasilSKD.data.map((item, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell
                                            className={`py-2 text-center font-poppins font-semibold ${
                                                index < 9
                                                    ? 'text-green'
                                                    : 'text-error'
                                            }`}
                                            align='center'
                                        >
                                            {index == 0 ? (
                                                <img
                                                    src='/assets/images/logos/medal-gold.svg'
                                                    className='img-medal'
                                                    alt='medal-gold'
                                                />
                                            ) : index == 1 ? (
                                                <img
                                                    src='/assets/images/logos/medal-silver.svg'
                                                    className='img-medal'
                                                    alt='medal-silver'
                                                />
                                            ) : index == 2 ? (
                                                <img
                                                    src='/assets/images/logos/medal-bronze.svg'
                                                    className='img-medal'
                                                    alt='medal-bronze'
                                                />
                                            ) : (
                                                index + 1
                                            )}
                                        </TableCell>
                                        <TableCell className='py-2 text-center font-poppins'>
                                            {item.user != null
                                                ? item.user.name
                                                : 'Sahabat CPNS'}
                                        </TableCell>
                                        <TableCell
                                            className='py-2 text-center font-poppins'
                                            align='center'
                                        >
                                            {item.nilai}
                                        </TableCell>
                                        <TableCell
                                            className='py-2 text-center font-poppins'
                                            align='center'
                                        >
                                            {passingGrade.total_soal -
                                                Number(item.nilai)}
                                        </TableCell>
                                        <TableCell
                                            className='py-2 text-center font-poppins'
                                            align='center'
                                        >
                                            {Number(item.nilai)}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        hasilSKD: state.hasilSKD,
        passingGrade: state.passingGrade.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLatihanRankingSKB: (kode) => dispatch(getLatihanRankingSKB(kode)),
        getPassingGradeUKSKB: () => dispatch(getPassingGradeUKSKB())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LatihanRanking);
