import { ADD_TAHUN_TO_PPPK_BIMBEL, DELETE_TAHUN_TO_PPPK_BIMBEL, GET_ALL_TAHUN_TO_PPPK_BIMBEL, SET_LOADING } from '../actions/TahunToPPPKBimbelActions';

const initialState = {
    data: [],
    loading: false
};

const TahunToPPPKBimbelReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case GET_ALL_TAHUN_TO_PPPK_BIMBEL: {
            return {
                ...state,
                data: action.payload
            };
        }
        case ADD_TAHUN_TO_PPPK_BIMBEL: {
            return {
                ...state,
                loading: false
            };
        }
        case DELETE_TAHUN_TO_PPPK_BIMBEL: {
            let data = state.data.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                data: data
            };
        }
        default: {
            return state;
        }
    }
};

export default TahunToPPPKBimbelReducer;
