import React from 'react';

const RekomendasiBuku = [
    {
        path: '/rekomendasi_buku',
        component: React.lazy(() => import('./RekomendasiBukuTable'))
    }
];

export default RekomendasiBuku;
