import Group from './Group';

const groupRoutes = [
    {
        path: '/link',
        component: Group
    }
];

export default groupRoutes;
