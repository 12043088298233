import Transaksi from './Transaksi';

const TransaksiRoutes = [
	{
		path: "/transaksi",
		component: Transaksi,
	},
];

export default TransaksiRoutes;
