import axios from 'axios';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_SKD = 'GET_SKD';
export const UPDATE_SKD = 'UPDATE_SKD';
export const GET_MATERI_SKD = 'GET_MATERI_SKD';
export const ADD_MATERI_SKD = 'ADD_MATERI_SKD';
export const DEL_MATERI_SKD = 'DEL_MATERI_SKD';
export const GET_SOAL = 'GET_SOAL';
export const ADD_SOAL = 'ADD_SOAL';
export const DEL_SOAL = 'DEL_SOAL';
export const RESET_REDUCER = 'RESET_REDUCER';
export const GET_TRYOUT_SKD = 'GET_TRYOUT_SKD';
export const ADD_TRYOUT_SKD = 'ADD_TRYOUT_SKD';
export const DEL_TRYOUT_SKD = 'DEL_TRYOUT_SKD';
export const UPD_TRYOUT_SKD = 'UPD_TRYOUT_SKD';
export const GET_LATIHAN_SKD = 'GET_LATIHAN_SKD';
export const ADD_LATIHAN_SKD = 'ADD_LATIHAN_SKD';
export const DEL_LATIHAN_SKD = 'DEL_LATIHAN_SKD';
export const UPD_LATIHAN_SKD = 'UPD_LATIHAN_SKD';

export const UPD_SOAL_LATIHAN_SKD = 'UPD_SOAL_LATIHAN_SKD';

export const GET_DETAIL_LATIHAN_SKD = 'GET_DETAIL_LATIHAN_SKD';
export const GET_DETAIL_JAWABAN = 'GET_DETAIL_JAWABAN';
export const GET_JAWABAN = 'GET_JAWABAN';
export const ADD_ANSWER = 'ADD_ANSWER';
export const DEL_ANSWER = 'DEL_ANSWER';
export const RESET_KERJAKAN_LATIHAN = 'RESET_KERJAKAN_LATIHAN';

export const shuffleArray = (array) => {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const getSKD = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('plan')
            .orderBy('plan_position', 'asc')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                console.log('========>>', data);
                dispatch({
                    type: GET_SKD,
                    payload: data
                });
            });
    };
};

export const editPaketSKD = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('plan')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSKD());
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const getMateriSkd = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('materi_skd')
            .where('category', 'in', params)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_MATERI_SKD,
                    payload: data
                });
            });
    };
};

export const addMateriSKD = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi_skd/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    if (params.parent_id != 'dinas') {
                        dispatch(getMateriSkd(['twk', 'tiu', 'tkp']));
                    } else {
                        dispatch(
                            getMateriSkd([
                                'twk_dinas',
                                'tiu_dinas',
                                'tkp_dinas'
                            ])
                        );
                    }
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const delMateriSkd = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('materi_skd')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_MATERI_SKD,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editMateriSKD = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi_skd/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    if (params.parent_id != 'dinas') {
                        dispatch(getMateriSkd(['twk', 'tiu', 'tkp']));
                    } else {
                        dispatch(
                            getMateriSkd([
                                'twk_dinas',
                                'tiu_dinas',
                                'tkp_dinas'
                            ])
                        );
                    }
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const getSoalSkd = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('soal_pembahasan_skd')
            .where('type', 'in', params)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_SOAL,
                    payload: data
                });
            });
    };
};

export const resetReducer = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_REDUCER
        });
    };
};

export const addSoalSKD = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/soal_skd/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    if (params.parent_id != 'dinas') {
                        dispatch(getSoalSkd(['twk', 'tiu', 'tkp']));
                    } else {
                        dispatch(
                            getSoalSkd(['twk_dinas', 'tiu_dinas', 'tkp_dinas'])
                        );
                    }
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const editSoalSKD = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/soal_skd/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    if (params.parent_id != 'dinas') {
                        dispatch(getSoalSkd(['twk', 'tiu', 'tkp']));
                    } else {
                        dispatch(
                            getSoalSkd(['twk_dinas', 'tiu_dinas', 'tkp_dinas'])
                        );
                    }
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const delSoalSkd = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('soal_pembahasan_skd')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_SOAL,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getTryoutSKD = (type) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .where('type', 'in', type)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                data.sort((a, b) =>
                    a.title.localeCompare(b.title, undefined, {
                        sensitivity: 'base'
                    })
                );

                dispatch({
                    type: GET_TRYOUT_SKD,
                    payload: data
                });
            });
    };
};

export const addTryoutSKD = (params, type) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil disimpan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutSKD(type == 'free' ? 'gratis' : type));
                });
                return true;
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal menyimpan Tryout',
                    icon: 'error',
                    timer: 2000
                });
                return false;
            });
    };
};

export const addTryoutSKDPlatinum = (params, type) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil disimpan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutSKD(['platinum']));
                });
                return true;
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal menyimpan Tryout',
                    icon: 'error',
                    timer: 2000
                });
                return false;
            });
    };
};

export const addTryoutSKDPremium = (params, type) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil disimpan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutSKD(['premium']));
                });
                return true;
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal menyimpan Tryout',
                    icon: 'error',
                    timer: 2000
                });
                return false;
            });
    };
};

export const delTryoutSkd = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_TRYOUT_SKD,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updTryoutSkd = (params, doc_id) => {
    let type =
        params.type == 'platinum' ? ['platinum', 'premium'] : [params.type];
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutSKD(type));
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updTryoutSkdPremiun = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutSKD(['premium']));
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updTryoutSkdPlatinum = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Tryout berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getTryoutSKD(['platinum']));
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Tryout gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getLatihanSKD = (type) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .where('category', 'in', type)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_LATIHAN_SKD,
                    payload: data
                });
            });
    };
};

export const getLatihanSKDPlatinum = (type) => {
    // console.log('dataaaaaaas',type)
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .where('category', 'in', type)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                data.sort((a, b) =>
                    a.title.localeCompare(b.title, undefined, {
                        sensitivity: 'base'
                    })
                );
                console.log('taaaas', data);
                dispatch({
                    type: GET_LATIHAN_SKD,
                    payload: data
                });
            });
    };
};

export const addLatihanSKD = (params, type) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil disimpan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    if (type != 'dinas') {
                        dispatch(getLatihanSKD(['twk', 'tiu', 'tkp']));
                    } else {
                        dispatch(
                            getLatihanSKD([
                                'twk_dinas',
                                'tiu_dinas',
                                'tkp_dinas'
                            ])
                        );
                    }
                });
                return true;
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal menyimpan Latihan',
                    icon: 'error',
                    timer: 2000
                });
                return false;
            });
    };
};

export const delLatihanSkd = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_LATIHAN_SKD,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updLatihanSkd = (params, doc_id, type) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    if (type != 'dinas') {
                        dispatch(getLatihanSKD(['twk', 'tiu', 'tkp']));
                    } else {
                        dispatch(
                            getLatihanSKD([
                                'twk_dinas',
                                'tiu_dinas',
                                'tkp_dinas'
                            ])
                        );
                    }
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getSoalLatihanSKD = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .doc(kode)
            .collection('question')
            .orderBy('id', 'asc')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL,
                    payload: data
                });
            });
    };
};

export const getDetailLatihanSKD = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_LATIHAN_SKD,
                    payload: data
                });
            });
    };
};

export const delQuestionLatihanSKD = (tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalLatihanSKD(tryout_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const importSoalLatihanSKD = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}scpns/question_skd/import`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil diimport',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalLatihanSKD(params.doc_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const addQuestionLatihanSKD = (params, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .doc(doc_id)
            .collection('question')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalLatihanSKD(doc_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updQuestionLatihanSKD = (params, tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_skd')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: UPD_SOAL_LATIHAN_SKD,
                        payload: { params, doc_id }
                    });
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
