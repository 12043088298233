import Kelas from './Kelas';

const bimbelRoutes = [
    {
        path: '/bimbel',
        component: Kelas
    }
];

export default bimbelRoutes;
