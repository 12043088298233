import {
    Button,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Icon,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { editTryoutPaket } from 'app/redux/actions/PaketTryoutActions';
import { getTanggal } from 'app/redux/actions/UserActions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class ModalEditPaketEventTryout extends Component {
    state = {
        id: '',
        doc_id: '',
        name: '',
        status: 'aktif',
        created_at: '',
        diskon: '1',
        statusButton: false,
        buttonText: 'Tambah',
        group_link: '',
        isActive: false,
        plan_price: 0,
        plan_discount: 0,
        description: '',
        jenis_paket: 'tryout',
        level_paket: 'standart',
        expired_month: 0,
        benefit: [],
        plan_benefit: [],
        deskripsi: '',
        form_benefit: {
            status: 1,
            benefit: '',
            index: '',
            isNew: true,
            error: ''
        }
    };
    componentDidMount() {
        const { params } = this.props;
        this.setData(params);
    }
    setData = (params) => {
        this.setState({
            name: params.title,
            status: params.status,
            plan_benefit: params.plan_benefit,
            level_paket: params.level_paket,
            jenis_paket: params.jenis_paket,
            id: params.id,
            plan_discount: params.harga_discount,
            plan_price: params.harga,
            doc_id: params.doc_id,
            deskripsi: params.deskripsi,
            created_at: params.created_at,
            expired_month: params.expired_month,
            group_link: params.group_link
        });
    };
    handleChangeBenefit = (event) => {
        let value = event.target.value;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                benefit: value
            }
        }));
    };

    handleChange = (event, source) => {
        event.persist();
        if (source === 'switch') {
            console.log(event.target);
            this.setState({ isActive: event.target.checked });
            return;
        }

        if (source === 'status') {
            if (event.target.value === 'SUSPEND') {
                this.setState({
                    users_status: 0,
                    users_status_value: 'SUSPEND'
                });
                return;
            } else {
                this.setState({
                    users_status: 1,
                    users_status_value: 'ACTIVE'
                });
                return;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeSelect = (event, source) => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = async () => {
        let now = getTanggal();
        this.setState({
            statusButton: true,
            buttonText: 'Loading...'
        });
        let {
            name,
            status,
            plan_price,
            plan_discount,
            jenis_paket,
            level_paket,
            plan_benefit,
            deskripsi,
            group_link,
            doc_id,
            expired_month
        } = this.state;
        let params = {
            title: name,
            harga: parseInt(plan_price, 10),
            harga_discount: parseInt(plan_discount, 10),
            jenis_paket: jenis_paket,
            level_paket: level_paket,
            plan_benefit: plan_benefit,
            group_link: group_link,
            deskripsi: deskripsi,
            status: status,
            expired_month: expired_month,
            updated_at: now
        };
        await this.props.editTryoutPaket(params, doc_id);
        await this.props.handleClose();
    };

    handleFormBenefit = () => {
        const {
            form_benefit: { benefit, isNew, index, status },
            plan_benefit
        } = this.state;
        if (benefit.length > 0) {
            if (isNew) {
                let allBenefit = plan_benefit.concat({
                    status: status,
                    benefit: benefit
                });
                this.setState({
                    plan_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            } else {
                let allBenefit = plan_benefit;
                allBenefit[index] = {
                    status: status,
                    benefit: benefit
                };
                this.setState({
                    plan_benefit: allBenefit,
                    form_benefit: {
                        status: 1,
                        benefit: '',
                        index: '',
                        isNew: true,
                        error: ''
                    }
                });
            }
        } else {
            this.setState((prefState) => ({
                form_benefit: {
                    ...prefState.form_benefit,
                    error: 'Tolong isi semua inputan'
                }
            }));
        }
    };

    handleEditBenefit = (item, index) => {
        let benefit = item.benefit;
        let status = item.status;
        this.setState((prefState) => ({
            form_benefit: {
                ...prefState.form_benefit,
                benefit: benefit,
                status: status,
                isNew: false,
                index: index
            }
        }));
    };

    handleDeleteBenefit = (i) => {
        const { plan_benefit } = this.state;
        let allBenefit = plan_benefit.filter((item, index) => index != i);
        this.setState({
            plan_benefit: allBenefit
        });
    };

    render() {
        let {
            name,
            status,
            diskon,
            group_link,
            plan_price,
            plan_discount,
            deskripsi,
            level_paket,
            jenis_paket,
            benefit,
            form_benefit,
            plan_benefit,
            expired_month
        } = this.state;
        let { open, handleClose } = this.props;

        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth='md'
                fullWidth={true}
            >
                <div className='p-5'>
                    <h4 className='mb-5'>Edit Paket Tryout</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid className='mb-10' container spacing={1}>
                            <Grid item sm={12} xs={12}>
                                <p className='mt-5 font-bold text-12'>
                                    Detail Paket
                                </p>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    label='Nama Paket *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='name'
                                    value={name}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    label='Group Link *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='group_link'
                                    value={group_link}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    label='Deskripsi *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='deskripsi'
                                    value={deskripsi}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>

                            <Grid item sm={12} xs={12}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel id='demo-simple-select-helper-label'>
                                        Jenis Paket
                                    </InputLabel>
                                    <Select
                                        labelId='demo-simple-select-helper-label'
                                        id='demo-simple-select-helper'
                                        value={jenis_paket}
                                        name='jenis_paket'
                                        onChange={this.handleChangeSelect}
                                    >
                                        <MenuItem value={'satuan'}>
                                            Satuan
                                        </MenuItem>
                                        <MenuItem value={'bundel'}>
                                            Bundel
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel id='demo-simple-select-helper-label'>
                                        Status
                                    </InputLabel>
                                    <Select
                                        labelId='demo-simple-select-helper-label'
                                        id='demo-simple-select-helper'
                                        value={status}
                                        name='status'
                                        onChange={this.handleChangeSelect}
                                    >
                                        <MenuItem value={'aktif'}>
                                            Aktif
                                        </MenuItem>
                                        <MenuItem value={'tidak_aktif'}>
                                            Tidak Aktif
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <p className='mt-5 font-bold text-12'>
                                    Pengaturan Masa Berlaku
                                </p>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Mssa Berlaku Dalam Bulan'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='expired_month'
                                    value={expired_month}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <p className='mt-5 font-bold text-12'>
                                    Pengaturan Harga
                                </p>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Harga Paket'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='plan_price'
                                    value={plan_price}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Diskon Paket'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='plan_discount'
                                    value={plan_discount}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <p className='mt-5 font-bold text-12'>
                                    Pengaturan Benefit
                                </p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <div className='w-full overflow-auto bg-white'>
                                    <Table className='crud-table'>
                                        <TableHead className='bg-primary'>
                                            <TableRow>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    No
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    colSpan={2}
                                                >
                                                    Benefit
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                ></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {plan_benefit.length > 0 ? (
                                                plan_benefit.map(
                                                    (item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                align='center'
                                                            >
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                colSpan={2}
                                                            >
                                                                {item.benefit}
                                                            </TableCell>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-medium'
                                                                align='center'
                                                            >
                                                                <div
                                                                    className={`badge-status-benefit ${
                                                                        item.status ==
                                                                        1
                                                                            ? 'bg-green'
                                                                            : 'bg-error'
                                                                    }`}
                                                                >
                                                                    {item.status ==
                                                                    1 ? (
                                                                        <Icon>
                                                                            check
                                                                        </Icon>
                                                                    ) : (
                                                                        <Icon>
                                                                            close
                                                                        </Icon>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                                align='center'
                                                            >
                                                                <IconButton
                                                                    title='edit'
                                                                    onClick={() =>
                                                                        this.handleEditBenefit(
                                                                            item,
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon color='primary'>
                                                                        border_color
                                                                    </Icon>
                                                                </IconButton>
                                                                <IconButton
                                                                    title='delete'
                                                                    onClick={() =>
                                                                        this.handleDeleteBenefit(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon color='error'>
                                                                        delete
                                                                    </Icon>
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        className='px-2 py-2 text-center font-poppins font-bold'
                                                        align='center'
                                                        colSpan={5}
                                                    >
                                                        Tidak ada benefit paket
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <h6 className='mt-0'>
                                    {form_benefit.isNew
                                        ? 'Tambah Benfit'
                                        : 'Edit Benefit'}
                                </h6>
                                <TextValidator
                                    className='w-full'
                                    label='Benefit'
                                    onChange={this.handleChangeBenefit}
                                    type='text'
                                    name='benefit'
                                    value={form_benefit.benefit}
                                />
                                {form_benefit.error.length > 0 ? (
                                    <small className='text-error'>
                                        {form_benefit.error}
                                    </small>
                                ) : null}
                                <div className='flex items-center justify-between'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={this.handleFormBenefit}
                                        className='elevation-z0 mt-1 w-full max-w-195'
                                    >
                                        {form_benefit.isNew
                                            ? 'Tambah Benefit'
                                            : 'Edit Benefit'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={this.state.statusButton}
                                color='primary'
                                type='submit'
                            >
                                {this.state.buttonText}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    editTryoutPaket: PropTypes.func.isRequired
    // getAllProyecto: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { editTryoutPaket })(
    ModalEditPaketEventTryout
);
