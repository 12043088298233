import {
    GET_HASIL_LAT_SKB,
    GET_HASIL_LAT_UK,
    GET_HASIL_SKB,
    GET_HASIL_SKD,
    GET_HASIL_UK,
    LOADING_HASIL,
    GET_DETRANK_SKD
} from '../actions/HasilSKDActions';

const initialState = {
    data: [],
    detRank: [],
    loading: false
};

const hasilSKDReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_HASIL_SKD: {
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        }
        case GET_DETRANK_SKD: {
            return {
                ...state,

                detRank: action.payload
            };
        }
        case GET_HASIL_LAT_UK: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_HASIL_UK: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_HASIL_SKB: {
            return {
                ...state,
                data: action.payload
            };
        }
        case GET_HASIL_LAT_SKB: {
            return {
                ...state,
                data: action.payload
            };
        }
        case LOADING_HASIL: {
            return {
                ...state,
                loading: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default hasilSKDReducer;
