import axios from 'axios';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const LOADING = 'LOADING';
export const GET_BUMN_MANDIRI = 'GET_BUMN_MANDIRI';
export const GET_BUMN_BIMBEL = 'GET_BUMN_BIMBEL';
export const ADD_BUMN = 'ADD_BUMN';
export const EDIT_SKB = 'EDIT_SKB';
export const DELETE_BUMN = 'DELETE_BUMN';
export const DEL_MATERI_BUMN = 'DEL_MATERI_BUMN';
export const GET_DETAIL_SKB = 'GET_DETAIL_SKB';
export const GET_BUNDLE_SKB = 'GET_BUNDLE_SKB';
export const GET_TRYOUT_SKB = 'GET_TRYOUT_SKB';
export const GET_MY_TRYOUT = 'GET_MY_TRYOUT';
export const GET_MY_TRYOUT_BUNDLE = 'GET_MY_TRYOUT_BUNDLE';
export const GET_MY_TRYOUT_BUNDLE_DETAIL = 'GET_MY_TRYOUT_BUNDLE_DETAIL';
export const GET_MY_TRYOUT_DETAIL = 'GET_MY_TRYOUT_DETAIL';
export const GET_MY_EXERCISE_DETAIL = 'GET_MY_EXERCISE_DETAIL';
export const GET_MY_EXERCISE = 'GET_MY_EXERCISE';
export const GET_MATERI_SKB_DETAIL = 'GET_MATERI_SKB_DETAIL';
export const GET_USERS_MATERI_LIST = 'GET_USERS_MATERI_LIST';
export const GET_USERS_MATERI = 'GET_USERS_MATERI';
export const GET_USERS_SP = 'GET_USERS_SP';
export const GET_USERS_SP_LIST = 'GET_USERS_SP_LIST';
export const GET_SP_SKB_DETAIL = 'GET_SP_SKB_DETAIL';
export const GET_HASIL_LAT_SKB = 'GET_HASIL_LAT_SKB';
export const GET_EXERCISE_SKB = 'GET_EXERCISE_SKB';
export const GET_MATERI_BUMN = 'GET_MATERI_BUMN';
export const GET_MATERI_BUMN_DETAIL = 'GET_MATERI_BUMN_DETAIL';
export const GET_LATIHAN_BUMN = 'GET_LATIHAN_BUMN';
export const DEL_LATIHAN_BUMN = 'DEL_LATIHAN_BUMN';
export const GET_REKAMAN = 'GET_REKAMAN';
export const GET_SOAL = 'GET_SOAL';
export const GET_DETAIL_LATIHAN_BUMN = 'GET_DETAIL_LATIHAN_BUMN';
export const DEL_REKAMAN_BUMN = 'DEL_REKAMAN_BUMN';
export const UPD_SOAL_LATIHAN_PPPK = 'UPD_SOAL_LATIHAN_PPPK';
;
// PAKETTT
export const getBumnMandiri = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_bumn')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_BUMN_MANDIRI,
                    payload: data
                });
            });
    };
};


export const getBumnBimbel = () => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('bumn_bimbel')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_BUMN_BIMBEL,
                    payload: data
                });
            });
    };
};

export const addPaketBumn = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_bumn')
            .doc(params.title.replaceAll(' ', '_'))
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Paket berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getBumnMandiri());
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Paket gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};


export const addPaketBumnBimbel = (params, id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('bumn_bimbel')
            .doc(params.title.replaceAll(' ', '_'))
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Paket berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getBumnBimbel());
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Paket gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editPaketBumnBimbel = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('bumn_bimbel')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getBumnBimbel());
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const addQuestionLatihanBumn = (params, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .doc(doc_id)
            .collection('question')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalLatihanBUMN(doc_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updQuestionLatihanBumn = (params, tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: UPD_SOAL_LATIHAN_PPPK,
                        payload: { params, doc_id }
                    });
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};


export const delQuestionLatihanBumn = (tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalLatihanBUMN(tryout_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};



export const editPaketBumn = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_bumn')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Data paket berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getBumnMandiri());
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Data paket gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};


export const deleteBumn = (doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'SKB berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: DELETE_BUMN,
                    payload: doc_id
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'SKB gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// BUNDLEEE
export const getBundleSKB = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(kode)
            .collection('bundle')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_BUNDLE_SKB,
                    payload: data.length > 0 ? data[0] : data
                });
            });
    };
};

export const addBundleSKB = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(id)
            .collection('bundle')
            .doc(doc_id)
            .set(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Bundle berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_BUMN,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Bundle gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const getRekamanBumn = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('rekaman_video')
            .where('category', '==', params)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_REKAMAN,
                    payload: data
                });
            });
    };
};

export const delRekamanBumn = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('rekaman_video')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Rekaman berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_REKAMAN_BUMN,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Rekaman gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const addRekamanBumn = (params) => {
    const uid = localStorage.getItem('uid');
    console.log(params);
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/rekaman/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Rekaman berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getRekamanBumn(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const updateBundleSKB = (params, id, doc_id) => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: true
        });
        firebaseAuthService.firestore
            .collection('formasi_skb')
            .doc(id)
            .collection('bundle')
            .doc(doc_id)
            .update(params)
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Bundle berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_BUMN,
                    payload: params
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

// MATERII
export const getMateriBumn = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('materi_bumn')
            .where('category', '==', params)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_MATERI_BUMN,
                    payload: data
                });
            });
    };
};
export const addMateriBumn = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi_bumn/add`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil ditambahkan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getMateriBumn(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const delMateriBumn = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('materi_bumn')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_MATERI_BUMN,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const editMateri = (params) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `https://materi.sahabatcpns.com/api/v1/scpns/materi_bumn/edit`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Materi berhasil Diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getMateriBumn(params.parent_id));
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Materi gagal Diedit',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const downloadTemplateQuestion = () => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .get(`${process.env.REACT_APP_API_URL}scpns/question/download`, {
                responseType: 'blob'
            })
            .then((res) => {
                saveAs(res.data, 'template_skb_uk_pppk.csv');
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Berhasil Download',
                    icon: 'success',
                    timer: 2000
                });
                dispatch({
                    type: ADD_BUMN,
                    payload: res.data
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'gagal download',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const importQuestion = (params, parent, doc_id) => {
    const uid = localStorage.getItem('uid');
    axios.defaults.headers.common['Authorization'] = uid;
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}scpns/question/import/${parent}/${doc_id}`,
                params
            )
            .then((res) => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Soal berhasil diimport',
                    icon: 'success',
                    timer: 2000
                });
                return res.data.data;
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Soal gagal ditambahkan',
                    icon: 'error',
                    timer: 2000
                });
                return Promise.reject(error);
            });
    };
};

export const updLatihanBUMN = (params, doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil diedit',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getLatihanBUMN(params.category));
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan gagal diedit',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
export const delLatihanBUMN = (doc_id) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil dihapus',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: DEL_LATIHAN_BUMN,
                        payload: doc_id
                    });
                });
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Latihan gagal dihapus',
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};


export const delQuestionLatihanPPPK = (tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalLatihanBUMN(tryout_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
export const addLatihanBUMN = (params) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Latihan berhasil disimpan',
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getLatihanBUMN(params.category));
                });
                return true;
            })
            .catch(() => {
                Swal.fire({
                    title: 'Gagal',
                    text: 'Gagal menyimpan Latihan',
                    icon: 'error',
                    timer: 2000
                });
                return false;
            });
    };
};

export const getLatihanBUMN = (params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .where('category', '==', params)
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });

                dispatch({
                    type: GET_LATIHAN_BUMN,
                    payload: data
                });
            });
    };
};

export const getSoalLatihanBUMN = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .doc(kode)
            .collection('question')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                console.log(data)
                dispatch({
                    type: GET_SOAL,
                    payload: shuffleArray(data)
                });
            });
    };
};

export const getDetailLatihanBUMN = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('exercise_bumn')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_LATIHAN_BUMN,
                    payload: data
                });
            });
    };
};
export const shuffleArray = (array) => {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};
