import {
    GET_SOAL_EDIT,
    SOAL_TRYOUT_SKD,
    SET_SOAL_TRYOUT_SKD
} from '../actions/SoalTryoutSKDActions';

const initialState = {
    dataTryout: {}
};

const SoalTryOutSKDReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_SOAL_TRYOUT_SKD: {
            return {
                dataTryout: action.data
            };
        }
        default: {
            return state;
        }
    }
};

export default SoalTryOutSKDReducer;
