import {
    Box,
    Button,
    Card,
    FormControl,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Snackbar
} from '@material-ui/core';
import { ConfirmationDialog } from 'matx';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import shortid from 'shortid';

import { deleteGambar, getGambar } from '../../redux/actions/GambarActions';
import AddGambar from './AddGambar';
// import AddSKBDialog from './AddSKBDialog';
// import EditSKB from './EditSKB';

class GambarTable extends Component {
    state = {
        rowsPerPage: 10,
        page: 0,
        shouldOpenEditorDialog: false,
        shouldOpenCreateDialog: false,
        shouldOpenConfirmationDialog: false,
        query: '',
        openInfo: false,
        vertical: 'bottom',
        horizontal: 'center'
    };

    setPage = (page) => {
        this.setState({ page });
    };

    setRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    handleDialogClose = () => {
        this.setState({
            shouldOpenEditorDialog: false,
            shouldOpenConfirmationDialog: false,
            shouldOpenCreateDialog: false
        });
        this.updatePageData();
    };

    handleConfirmationResponse = async () => {
        await this.props.deleteGambar(this.state.uid_delete);
        this.handleDialogClose();
    };

    componentDidMount() {
        this.updatePageData();
    }

    componentDidUpdate(prevProps, prevState) {}

    updatePageData = async () => {
        await this.props.getGambar();
    };

    handleSearch = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    handleCopy = (data) => {
        navigator.clipboard.writeText(data);
        this.setState({
            openInfo: true
        });

        setTimeout(() => {
            this.setState({
                openInfo: false
            });
        }, 2000);
    };

    handleCloseSnack = () => {
        this.setState({
            openInfo: false
        });
    };

    render() {
        let {
            rowsPerPage,
            page,
            shouldOpenCreateDialog,
            shouldOpenEditorDialog,
            shouldOpenConfirmationDialog,
            query,
            openInfo,
            vertical,
            horizontal
        } = this.state;
        let { gambar } = this.props;
        return (
            <div className='m-sm-30'>
                <h2 className='mt-10 mb-5' style={{ fontSize: '25px' }}>
                    Resource Gambar
                </h2>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button
                        variant='outlined'
                        color='primary'
                        className='mb-5'
                        onClick={() =>
                            this.setState({
                                shouldOpenCreateDialog: true
                            })
                        }
                        title='Edit'
                    >
                        Tambah
                    </Button>
                    <FormControl
                        className='faqDesc'
                        style={{
                            boxShadow: '0px 0px 2px #43434359',
                            marginBottom: '15px',
                            borderRadius: '5px',
                            maxHeight: '45px'
                        }}
                    >
                        <Box
                            component='fieldset'
                            borderColor='transparent'
                            style={{ paddingLeft: '0px' }}
                            className='flex'
                        >
                            <IconButton title='Cari' style={{ padding: '5px' }}>
                                <Icon color='action'>search</Icon>
                            </IconButton>
                            <input
                                onChange={this.handleSearch}
                                placeholder='Cari..'
                                name='query'
                                value={query}
                                style={{
                                    minHeight: '25px',
                                    border: '0px',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    outline: 0
                                }}
                            ></input>
                        </Box>
                    </FormControl>
                </div>

                <Card className='p-0 border-radius-0'>
                    <div className='w-full overflow-auto bg-white'>
                        <Table className='bimble-table'>
                            <TableHead className='bg-primary'>
                                <TableRow>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                        align='left'
                                    >
                                        Gambar
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Nama Gambar
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Link
                                    </TableCell>
                                    <TableCell
                                        className='px-2 py-2 text-center font-poppins font-bold text-white'
                                        colSpan={2}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gambar.length > 0 ? (
                                    gambar
                                        .filter((item) =>
                                            Object.keys(item).some((key) =>
                                                query.includes(key)
                                                    ? false
                                                    : item[key]
                                                          .toString()
                                                          .toLowerCase()
                                                          .includes(query)
                                            )
                                        )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((datUsers, index) => (
                                            <TableRow key={shortid.generate()}>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    align='left'
                                                    colSpan={2}
                                                >
                                                    <img
                                                        src={datUsers.image}
                                                        alt='...'
                                                        style={{
                                                            maxWidth: 150
                                                        }}
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={2}
                                                >
                                                    {datUsers.name}
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    lign='left'
                                                    colSpan={2}
                                                >
                                                    <a
                                                        onClick={() =>
                                                            this.handleCopy(
                                                                datUsers.image
                                                            )
                                                        }
                                                        className='text-primary'
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        Salin Link
                                                    </a>
                                                </TableCell>

                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins'
                                                    colSpan={2}
                                                >
                                                    {/* <IconButton
                                                        onClick={() =>
                                                            this.setState({
                                                                uid: datUsers,
                                                                shouldOpenEditorDialog: true
                                                            })
                                                        }
                                                        title='Edit'
                                                    >
                                                        <Icon color='primary'>
                                                            edit
                                                        </Icon>
                                                    </IconButton> */}
                                                    <IconButton
                                                        onClick={() =>
                                                            this.setState({
                                                                uid_delete:
                                                                    datUsers.doc_id,
                                                                shouldOpenConfirmationDialog: true
                                                            })
                                                        }
                                                        title='delete'
                                                    >
                                                        <Icon color='error'>
                                                            delete
                                                        </Icon>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align='center'>
                                            Not Have Data
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <TablePagination
                            className='px-16'
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={gambar.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={'From'}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next page'
                            }}
                            backIconButtonText='Previous page'
                            nextIconButtonText='Next page'
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.setRowsPerPage}
                        />

                        {/* {shouldOpenEditorDialog && (
                            <EditSKB
                                handleClose={this.handleDialogClose}
                                open={shouldOpenEditorDialog}
                                uid={this.state.uid}
                            />
                        )} */}
                        {shouldOpenCreateDialog && (
                            <AddGambar
                                handleClose={this.handleDialogClose}
                                open={shouldOpenCreateDialog}
                                uid={this.state.uid}
                            />
                        )}
                        {shouldOpenConfirmationDialog && (
                            <ConfirmationDialog
                                open={shouldOpenConfirmationDialog}
                                onConfirmDialogClose={this.handleDialogClose}
                                onYesClick={this.handleConfirmationResponse}
                                text='Hapus Data Ini ?'
                            />
                        )}
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            open={openInfo}
                            autoHideDuration={2000}
                            onClose={this.handleCloseSnack}
                            anchorOrigin={{ vertical, horizontal }}
                            message='Link Di Copy'
                            key={vertical + horizontal}
                            action={
                                <React.Fragment>
                                    <IconButton
                                        size='small'
                                        aria-label='close'
                                        color='inherit'
                                        onClick={this.handleCloseSnack}
                                    >
                                        <Icon color='error'>close</Icon>
                                    </IconButton>
                                </React.Fragment>
                            }
                        />
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        gambar: state.gambar.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getGambar: () => dispatch(getGambar()),
        deleteGambar: (params) => dispatch(deleteGambar(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(GambarTable);
