import React from 'react';

const BadgeDiskon = ({ content, color }) => {
	return (
		<div className={`badge-custom border-radius-4 text-center ${color} w-max-content`}>
			<h6 className="m-0 font-semibold">{content}</h6>
		</div>
	);
};

export default BadgeDiskon;
