import GambarTable from './GambarTable';

const GambarRoutes = [
    {
        path: '/image',
        component: GambarTable
    }
];

export default GambarRoutes;
