import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_USER_BIMBEL = "GET_USER_BIMBEL";
export const GET_USER_SKB = "GET_USER_SKB";
export const GET_USER_UK = "GET_USER_UK";
export const GET_USER_BUNDLE_UK = "GET_USER_BUNDLE_UK";

export const getUserBimbel = () => {
	let uid = localStorage.getItem("uid");
	return async (dispatch) => {
		let data = [];
		await firebaseAuthService.firestore
			.collection("users_bimbel")
			.where("id_user", "==", uid)
			.get()
			.then(async (res) => {
				res.forEach((doc) => {
					let item = doc.data();
					item.detail_bimble = doc.data().bimble;
					item.bimble = doc.data().bimble.id;
					item.doc_id = doc.id;
					data.push(item);
				});
			});
		for (let i = 0; i < data.length; i++) {
			let detail_bimble = null;
			await data[i].detail_bimble.get().then((res) => {
				detail_bimble = res.data();
			});
			data[i].detail_bimble = detail_bimble;
		}
		dispatch({
			type: GET_USER_BIMBEL,
			payload: data,
		});
	};
};

export const getUserSKB = () => {
	let uid = localStorage.getItem("uid");
	return async (dispatch) => {
		firebaseAuthService.firestore
			.collection("users_tryout")
			.where("id_user", "==", uid)
			.where("buy_from", "in", ["sub_bundle_skb", 'skb', 'bundle'])
			.onSnapshot(async function(res) {
				let data = [];
				res.forEach((doc) => {
					let item = doc.data();
					item.doc_id = doc.id;
					data.push(item);
				});
				dispatch({
					type: GET_USER_SKB,
					payload: data,
				});
			});
	};
};

export const getUserUK = () => {
	let uid = localStorage.getItem("uid");
	return async (dispatch) => {
		firebaseAuthService.firestore
			.collection("users_tryout")
			.where("id_user", "==", uid)
			.where("buy_from", "==", "uk")
			.onSnapshot(async function(res) {
				let data = [];
				res.forEach((doc) => {
					let item = doc.data();
					item.doc_id = doc.id;
					data.push(item);
				});
				dispatch({
					type: GET_USER_UK,
					payload: data,
				});
			});
	};
};

export const getUserBUK = () => {
	let uid = localStorage.getItem("uid");
	return async (dispatch) => {
		firebaseAuthService.firestore
			.collection("users_tryout")
			.where("id_user", "==", uid)
			.where("buy_from", "in", ["sub_bundle_uk", 'bundle_uk'])
			.onSnapshot(async (res) => {
				let data = [];
				res.forEach((doc) => {
					let item = doc.data();
					item.doc_id = doc.id;
					data.push(item);
				});
				dispatch({
					type: GET_USER_BUNDLE_UK,
					payload: data,
				});
			});
	};
};
