import history from 'history.js';
import Swal from 'sweetalert2';

import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_DETAIL_EXERCISE = 'GET_DETAIL_EXERCISE';
export const GET_SOAL = 'GET_SOAL';
export const GET_JAWABAN = 'GET_JAWABAN';
export const GET_DETAIL_JAWABAN = 'GET_DETAIL_JAWABAN';
export const ADD_ANSWER = 'ADD_ANSWER';
export const DEL_ANSWER = 'DEL_ANSWER';
export const RESET_KERJAKAN_EXERCISE = 'RESET_KERJAKAN_EXERCISE';
export const GET_DETAIL_EXERCISE_PPPK = 'GET_DETAIL_EXERCISE_PPPK';
export const GET_SOAL_PPPK = 'GET_SOAL_PPPK';
export const GET_DETAIL_EXERCISE_UK = 'GET_DETAIL_EXERCISE_UK';
export const GET_SOAL_UK = 'GET_SOAL_UK';
export const GET_USERS_EXERCISE = 'GET_USERS_EXERCISE';
export const GET_DETAIL_JAWABAN_UK = 'GET_DETAIL_JAWABAN_UK';

export const getDetailExercise = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_EXERCISE,
                    payload: data
                });
            });
    };
};

export const shuffleArray = (array) => {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};

export const getSoalExerciseSKD = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_skd')
            .doc(kode)
            .collection('question')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL,
                    payload: data
                });
            });
    };
};

export const getDetailJawabanLatihanUK = (kode, tryout) => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .where('exercise', '==', kode)
            .where('id_user', '==', uid)
            .get()
            .then((doc) => {
                if (!doc.empty) {
                    let data = [];
                    doc.forEach(function (as) {
                        let item = as.data();
                        item.doc_id = as.id;
                        data.push(item);
                    });
                    dispatch(getJawabanLatihanUK(data[0].doc_id));
                    dispatch(getUsersExercise(tryout));
                    dispatch({
                        type: GET_DETAIL_JAWABAN_UK,
                        payload: data
                    });
                }
            });
    };
};

export const getJawabanLatihanUK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .doc(kode)
            .collection('answer')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_JAWABAN,
                    payload: data
                });
            });
    };
};

export const addExerciseSKDResult = (params) => {
    return firebaseAuthService.firestore
        .collection('users_tryout_result')
        .add(params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });
};

export const addLatihanUKResult = (params, exercise) => {
    return firebaseAuthService.firestore
        .collection('users_exercise_result')
        .add(params)
        .then((res) => {
            firebaseAuthService.firestore
                .collection('users_exercise')
                .doc(exercise)
                .update({
                    last_exercise: res.id
                });
            return res.id;
        })
        .catch((err) => {
            return err;
        });
};

export const handleJawab = (params, resultParams, tryout, soal) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_tryout_result')
            .doc(tryout)
            .collection('answer')
            .doc(soal)
            .set(params)
            .then(() => {
                firebaseAuthService.firestore
                    .collection('users_tryout_result')
                    .doc(tryout)
                    .update(resultParams);

                let data = params;
                data.doc_id = soal;
                dispatch({
                    type: ADD_ANSWER,
                    payload: { jawaban: data, detailJawaban: resultParams }
                });
            });
    };
};

export const handleJawabUK = (params, resultParams, tryout, soal) => {
    return async (dispatch) => {
        console.log('jawabb');
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .doc(tryout)
            .collection('answer')
            .doc(soal)
            .set(params)
            .then(() => {
                // firebaseAuthService.firestore.collection("users_tryout_result").doc(tryout).update(resultParams);

                let data = params;
                data.doc_id = soal;
                dispatch({
                    type: ADD_ANSWER,
                    payload: { jawaban: data, detailJawaban: resultParams }
                });
            });
    };
};

export const handleDelJawab = (tryout, kode, resultParams) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .doc(tryout)
            .collection('answer')
            .doc(kode)
            .delete()
            .then(() => {
                // firebaseAuthService.firestore.collection("users_exercise_result").doc(tryout).update(resultParams);
                dispatch({
                    type: DEL_ANSWER,
                    payload: { kode: kode, detailJawaban: resultParams }
                });
            });
    };
};

export const handleSelesai = (tryout, params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_tryout_result')
            .doc(tryout)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Terimakasih telah menyelesaikan tugas?',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                }).then((res) => {
                    dispatch(handleResetExerciseKerjakan());
                    history.push('/skd/tryout/hasil');
                });
            });
    };
};

export const handleResetExerciseKerjakan = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_KERJAKAN_EXERCISE,
            data: null
        });
    };
};

export const detailHasilExercise = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_tryout_result')
            .doc(kode)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    data.doc_id = kode;
                    dispatch(getJawabanLatihanUK(kode));
                    dispatch(getSoalExerciseSKD(data.tryout));
                    dispatch(getDetailExercise(data.tryout));
                    dispatch({
                        type: GET_DETAIL_JAWABAN,
                        payload: data
                    });
                }
            });
    };
};

export const getDetailExercisePPPK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_EXERCISE_PPPK,
                    payload: data
                });
            });
    };
};

export const getSoalExercisePPPK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('tryout_pppk')
            .doc(kode)
            .collection('question')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL_PPPK,
                    payload: data
                });
            });
    };
};

export const detailHasilExercisePPPK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_tryout_result')
            .doc(kode)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    data.doc_id = kode;
                    dispatch(getJawabanLatihanUK(kode));
                    dispatch(getSoalExercisePPPK(data.tryout));
                    dispatch(getDetailExercisePPPK(data.tryout));
                    dispatch({
                        type: GET_DETAIL_JAWABAN,
                        payload: data
                    });
                }
            });
    };
};

export const getDetailExerciseUK = (parent, kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('exercise')
            .doc(kode)
            .get()
            .then((res) => {
                if (res.exists) {
                    let data = res.data();
                    data.doc_id = kode;
                    dispatch({
                        type: GET_DETAIL_EXERCISE_UK,
                        payload: data
                    });
                }
            });
    };
};

export const getSoalExerciseUK = (parent, kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('uji_kompetensi')
            .doc(parent)
            .collection('exercise')
            .doc(kode)
            .collection('question')
            .onSnapshot((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL_UK,
                    payload: data
                });
            });
    };
};

export const detailHasilExerciseUK = (kode, parent = '') => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .doc(kode)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    data.doc_id = kode;
                    dispatch(getJawabanLatihanUK(kode));
                    dispatch(getSoalExerciseUK(parent, data.exercise));
                    // dispatch(getDetailExerciseUK(parent, kode));
                    dispatch({
                        type: GET_DETAIL_JAWABAN,
                        payload: data
                    });
                }
            });
    };
};

export const getUsersExercise = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise')
            .doc('Qy6fpxmyoslW3q9H4sEN')
            .get()
            .then((doc) => {
                let data = doc.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_USERS_EXERCISE,
                    payload: data
                });
            })
            .catch((err) => {
                console.log('cannot get data', err);
            });
    };
};

export const handleSelesaiUK = (tryout, params) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .doc(tryout)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Terimakasih telah menyelesaikan tugas?',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                }).then((res) => {
                    dispatch(handleResetExerciseKerjakan());
                    history.push('/uk_lat/hasil');
                });
            });
    };
};

export const handleBatalLatihanUK = (tryout) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .doc(tryout)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Pembatalan pengerjaan telah berhasil?',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    dispatch(handleResetExerciseKerjakan());
                    history.push('/uk_lat/hasil');
                });
            });
    };
};

export const getDetailJawabanExerciseUK = (kode, tryout) => {
    let uid = localStorage.getItem('uid');
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .doc(tryout)
            .get()
            .then((doc) => {
                // if (!doc.empty) {
                // let data = [];
                // doc.forEach(function (as) {
                let item = doc.data();
                item.doc_id = doc.id;
                // 	data.push(item);
                // });
                dispatch(getJawabanExerciseUK(tryout));
                // dispatch(getUsersExercise(tryout));
                dispatch({
                    type: GET_DETAIL_JAWABAN,
                    payload: item
                });
                // }
            });
    };
};

export const getJawabanExerciseUK = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('users_exercise_result')
            .doc(kode)
            .collection('answer')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_JAWABAN,
                    payload: data
                });
            });
    };
};
