import {
    Button,
    CircularProgress,
    Dialog,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    FormControl,
    InputLabel
} from '@material-ui/core';
import { editNews } from 'app/redux/actions/NewsActions';
import { editInformasi } from 'app/redux/actions/NotifikasiActions';
import { editPromo } from 'app/redux/actions/PromoActions';
import React, { Component, Fragment } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class ModalEditNews extends Component {
    constructor() {
        super();

        this.state = {
            title: '',
            desc: '',
            list: [],
            link: '',
            thumbnail: '',
            thumbnail_preview: '',
            status: '',
            formRef: null,
            form_benefit: {
                status: 1,
                point: '',
                index: '',
                isNew: true,
                error: ''
            },
            thumbnail_old: '',
            doc_id: ''
        };
    }

    componentDidMount() {
        const { desc, link, list, title, thumbnail, doc_id, status } =
            this.props;
        this.setState({
            title: title,
            desc: desc,
            link: link ? link : '',
            doc_id: doc_id,
            status: status
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    submitForm = () => {
        let { desc, link, list, title, thumbnail, doc_id, status } = this.state;
        const { editPromo, handleClose } = this.props;
        let params = {
            title: title,
            link: link ? link : '',
            doc_id: doc_id,
            status: status
        };
        editPromo(params);
        handleClose();
    };

    render() {
        let {
            title,
            desc,
            link,
            list,
            thumbnail_preview,
            form_benefit,
            status,
            formRef
        } = this.state;
        let { open, handleClose, loadingBimbel } = this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth='md'
                fullWidth={true}
            >
                <div className='p-5'>
                    <h4 className='mb-5'>Edit Notifikasi</h4>
                    <ValidatorForm ref={formRef} onSubmit={this.submitForm}>
                        <Grid className='mb-10' container spacing={1}>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Teks Notifikasi'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>

                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Link Notifikasi'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='link'
                                    value={link}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={status}
                                        name='status'
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value={true}>Aktif</MenuItem>
                                        <MenuItem value={false}>
                                            Tidak Aktif
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/*
                        <Grid className='mb-2' container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Link Berita'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='link'
                                    value={link}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                        </Grid>
                         */}
                        {/*<h5 className='mt-0'>Point Berita</h5>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <div className='w-full overflow-auto bg-white'>
                                    <Table className='crud-table'>
                                        <TableHead className='bg-primary'>
                                            <TableRow>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    No
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    colSpan={2}
                                                >
                                                    Point Berita
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                >
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                    align='center'
                                                ></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {list?.length > 0 ? (
                                                list?.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-medium'
                                                            align='center'
                                                        >
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-medium'
                                                            colSpan={2}
                                                        >
                                                            {item.point}
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-medium'
                                                            align='center'
                                                        >
                                                            <div
                                                                className={`badge-status-benefit ${
                                                                    item.status ==
                                                                    1
                                                                        ? 'bg-green'
                                                                        : 'bg-error'
                                                                }`}
                                                            >
                                                                {item.status ==
                                                                1 ? (
                                                                    <Icon>
                                                                        check
                                                                    </Icon>
                                                                ) : (
                                                                    <Icon>
                                                                        close
                                                                    </Icon>
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell
                                                            className='px-2 py-2 text-center font-poppins font-bold text-white'
                                                            align='center'
                                                        >
                                                            <IconButton
                                                                title='edit'
                                                                onClick={() =>
                                                                    this.handleEditBenefit(
                                                                        item,
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Icon color='primary'>
                                                                    border_color
                                                                </Icon>
                                                            </IconButton>
                                                            <IconButton
                                                                title='delete'
                                                                onClick={() =>
                                                                    this.handleDeleteBenefit(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Icon color='error'>
                                                                    delete
                                                                </Icon>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        className='px-2 py-2 text-center font-poppins font-bold'
                                                        align='center'
                                                        colSpan={5}
                                                    >
                                                        Tidak ada Point Berita
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <h6 className='mt-0'>
                                    {form_benefit.isNew
                                        ? 'Tambah Point Berita'
                                        : 'Edit Point Berita'}
                                </h6>
                                <TextValidator
                                    className='w-full'
                                    label='Point'
                                    onChange={this.handleChangeBenefit}
                                    type='text'
                                    name='point'
                                    value={form_benefit.point}
                                />
                                {form_benefit.error.length > 0 ? (
                                    <small className='text-error'>
                                        {form_benefit.error}
                                    </small>
                                ) : null}
                                <div className='flex items-center justify-between'>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    form_benefit.status == 1
                                                }
                                                name='Status'
                                                onChange={
                                                    this.handleStatusBenefit
                                                }
                                                color='primary'
                                            />
                                        }
                                        label='Status Point Berita'
                                        className='mt-1'
                                    />
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={this.handleFormBenefit}
                                        className='elevation-z0 mt-1 w-full max-w-195'
                                    >
                                        {form_benefit.isNew
                                            ? 'Tambah Point Berita'
                                            : 'Edit Point Berita'}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>*/}
                        <div className='border-bottom-1 mt-3'></div>
                        <div className='flex flex-space-between flex-middle mt-3'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                {loadingBimbel ? (
                                    <CircularProgress />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Batal</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingBimbel: state.bimbel.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editPromo: (params) => dispatch(editInformasi(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditNews);
