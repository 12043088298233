import firebaseAuthService from '../../services/firebase/firebaseAuthService';

export const GET_SOAL_PEMBAHASAN = "GET_SOAL_PEMBAHASAN";
export const GET_DETAIL_PEMBAHASAN = "GET_DETAIL_PEMBAHASAN";
export const SEARCH_PEMBAHASAN = "SEARCH_PEMBAHASAN";
export const SEARCH_PEMBAHASAN_DINAS = "SEARCH_PEMBAHASAN_DINAS";

export const getPembahasan = () => {
	return async (dispatch) => {
		firebaseAuthService.firestore
			.collection("soal_pembahasan_skd")
			.get()
			.then((res) => {
				let data = [];
				res.forEach(function (doc) {
					let item = doc.data();
					item.doc_id = doc.id;
					data.push(item);
				});
				dispatch({
					type: GET_SOAL_PEMBAHASAN,
					payload: data,
				});
			});
	};
};

export const getPembahasanDetail = (kode) => {
	return async (dispatch) => {
		firebaseAuthService.firestore
			.collection("soal_pembahasan_skd")
			.doc(kode)
			.get()
			.then((res) => {
				let data = res.data();
				data.doc_id = kode;
				dispatch({
					type: GET_DETAIL_PEMBAHASAN,
					payload: data,
				});
			});
	};
};

export const searchPembahasan = (params) => {
	return async (dispatch) => {
		if (params.type == "dinas") {
			dispatch({
				type: SEARCH_PEMBAHASAN_DINAS,
				payload: params,
			});
		} else {
			dispatch({
				type: SEARCH_PEMBAHASAN,
				payload: params,
			});
		}
	};
};
