import { Button, Card, CircularProgress, Dialog, Grid, MenuItem } from '@material-ui/core';

import { addRekamanBumn } from 'app/redux/actions/BumnActions';
import React, { Component, Fragment } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddMateri extends Component {
    constructor() {
        super();
        let parent = window.location.pathname.split('/').pop();
        this.state = {
            category: '',
            created_at: '',
            description: '',
            title: '',
            submit: false,
            parent_id: '',
            materi_video: ''
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
      this.setState(
          {
              submit: true
          },
          this.sendSubmit
      );
    };

    sendSubmit = () => {
        const {
            description,
            title,
            parent_id,
            materi_video
        } = this.state;
        const params = {
            category: parent_id,
            description: description,
            title: title,
            parent_id: parent_id,
            materi_video: materi_video
        };
        const { handleClose, addMateri } = this.props;
        handleClose();
        addMateri(params);
        this.setState({
            submit: false
        });
    };

    getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    };

    componentDidMount() {
        const { match } = this.props;
        // console.log(match);
        this.setState({
            parent_id: match.id
        });
    }

    render() {
        let {
            title,
            description,
            submit,
            materi_video
        } = this.state;
        
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Rekaman BUMN</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    label='Nama Rekaman'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    className='w-full'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-6 w-full'
                                    label='Deskripsi Rekaman'
                                    multiline
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            
                                <Grid item sm={6} xs={12}>
                                    <TextValidator
                                        label='Link Rekaman Video'
                                        onChange={this.handleChange}
                                        type='text'
                                        name='materi_video'
                                        value={materi_video}
                                        validators={['required']}
                                        errorMessages={['Field is Required']}
                                        className='w-full'
                                    />
                                </Grid>
                            </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addMateri: (params) => dispatch(addRekamanBumn(params))
    };
};
export default connect(null, mapDispatchToProps)(AddMateri);
