import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import { addNewTryoutToPaket } from 'app/redux/actions/PaketTryoutActions';
import { getTanggal } from 'app/redux/actions/UserActions';
import React, { Component, Fragment } from 'react';
import {
    TextValidator,
    ValidatorForm,
    SelectValidator
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddTryout extends Component {
    constructor() {
        super();
        this.state = {
            description: '',
            expires_time: '',
            jml_soal: '',
            title: '',
            submit: false,
            parent_id: '',
            is_shuffle: 0,
            total_soal_twk: 0,
            total_soal_tiu: 0,
            total_soal_tkp: 0,
            display: '1',
            ranking_active: '0'
        };
    }

    componentDidMount() {
        const { parent } = this.props;
        this.setParentId(parent);
    }

    setParentId = (params) => {
        this.setState({
            parent_id: params
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = () => {
        this.setState(
            {
                submit: true
            },
            this.sendSubmit
        );
    };

    sendSubmit = () => {
        const {
            description,
            expires_time,
            jml_soal,
            parent_id,
            title,
            is_shuffle,
            display,
            total_soal_tkp,
            total_soal_tiu,
            total_soal_twk,
            ranking_active
        } = this.state;
        let now = getTanggal();
        const params = {
            id_paket: parent_id,
            description: description,
            expires_time: expires_time,
            title: title,
            jml_soal: jml_soal,
            created_at: now,
            updated_at: now,
            is_shuffle: is_shuffle,
            display: display,
            ranking_active: ranking_active,
            total_soal_tkp: parseInt(total_soal_tkp, 10),
            total_soal_tiu: parseInt(total_soal_tiu, 10),
            total_soal_twk: parseInt(total_soal_twk, 10)
        };
        const { handleClose, addTryoutToPaket } = this.props;
        handleClose();
        addTryoutToPaket(parent_id, params);
        this.setState({
            submit: false
        });
    };

    render() {
        let {
            submit,
            description,
            expires_time,
            jml_soal,
            title,
            is_shuffle,
            display,
            total_soal_tkp,
            total_soal_tiu,
            total_soal_twk,
            ranking_active
        } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
                <div className='p-5'>
                    <h4 className='mb-5'>Tambah Tryout</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                className='d-flex items-end'
                            >
                                <TextValidator
                                    label='Nama Tryout'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='title'
                                    value={title}
                                    validators={[
                                        'required',
                                        'maxStringLength:100'
                                    ]}
                                    errorMessages={[
                                        'Field is Required',
                                        'Maximum 100 Character'
                                    ]}
                                    className='w-full'
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='mb-6 w-full'
                                    label='Deskripsi Tryout'
                                    multiline
                                    onChange={this.handleChange}
                                    type='text'
                                    name='description'
                                    value={description || ''}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Jumlah Soal'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='jml_soal'
                                    value={jml_soal}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Durasi Pengerjaan (menit)'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='expires_time'
                                    value={expires_time}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='w-full'
                                    label='Random Soal'
                                    onChange={this.handleChange}
                                    name='is_shuffle'
                                    value={is_shuffle}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Aktif</MenuItem>
                                    <MenuItem value='0'>Nonaktif</MenuItem>
                                </SelectValidator>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <SelectValidator
                                    className='w-full'
                                    label='Tampilkan Tryout ?'
                                    onChange={this.handleChange}
                                    name='display'
                                    value={display}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Iya</MenuItem>
                                    <MenuItem value='0'>Tidak</MenuItem>
                                </SelectValidator>
                            </Grid>
                            <Grid container spacing={2} className='mb-4'>
                                <Grid item sm={12} xs={12}>
                                    <SelectValidator
                                        className='w-full'
                                        label='Sistem Ranking Ujian ?'
                                        onChange={this.handleChange}
                                        name='ranking_active'
                                        value={ranking_active}
                                        validators={['required']}
                                        errorMessages={['Field is Required']}
                                    >
                                        <MenuItem value='1'>Iya</MenuItem>
                                        <MenuItem value='0'>Tidak</MenuItem>
                                    </SelectValidator>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Total Soal TIU'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='total_soal_tiu'
                                    value={total_soal_tiu}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Total Soal TWK'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='total_soal_twk'
                                    value={total_soal_twk}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='w-full'
                                    label='Total Soal TKP'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='total_soal_tkp'
                                    value={total_soal_tkp}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                />
                            </Grid>
                        </Grid>
                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={submit}
                                color='primary'
                                type='submit'
                            >
                                {submit ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addTryoutToPaket: (params, type) =>
            dispatch(addNewTryoutToPaket(params, type))
    };
};
export default connect(null, mapDispatchToProps)(AddTryout);
