import { Button, Dialog, Grid } from '@material-ui/core';
import { addAdmin } from 'app/redux/actions/AdminActions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class AddAdminDialog extends Component {
    state = {
        name: '',
        email: '',
        password: '',
        statusButton: false,
        buttonText: 'Tambah',
        isActive: false
    };

    handleChange = (event, source) => {
        event.persist();
        if (source === 'switch') {
            console.log(event.target);
            this.setState({ isActive: event.target.checked });
            return;
        }

        if (source === 'status') {
            if (event.target.value === 'SUSPEND') {
                this.setState({
                    users_status: 0,
                    users_status_value: 'SUSPEND'
                });
                return;
            } else {
                this.setState({
                    users_status: 1,
                    users_status_value: 'ACTIVE'
                });
                return;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleFormSubmit = async () => {
        this.setState({
            statusButton: true,
            buttonText: 'Loading...'
        });

        await this.props.addAdmin(this.state);
        await this.props.handleClose();
    };

    render() {
        let { name, email, password } = this.state;
        let { open, handleClose } = this.props;
        return (
            <Dialog onClose={handleClose} open={open}>
                <div className='p-5' style={{ minWidth: '500px' }}>
                    <h4 className='mb-5'>Tambah Admin</h4>
                    <ValidatorForm ref='form' onSubmit={this.handleFormSubmit}>
                        <Grid className='mb-4' container spacing={4}>
                            <Grid item sm={12} xs={12}>
                                <TextValidator
                                    className='mb-5'
                                    label='Email *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='email'
                                    value={email}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                                <TextValidator
                                    className='mb-5'
                                    label='Nama *'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='name'
                                    value={name}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                                <TextValidator
                                    className='mb-5'
                                    label='Password *'
                                    onChange={this.handleChange}
                                    type='password'
                                    name='password'
                                    value={password}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>

                        <div className='flex flex-space-between flex-middle'>
                            <Button
                                variant='contained'
                                disabled={this.state.statusButton}
                                color='primary'
                                type='submit'
                            >
                                {this.state.buttonText}
                            </Button>
                            <Button onClick={() => this.props.handleClose()}>
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    addAdmin: PropTypes.func.isRequired
    // getAllProyecto: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { addAdmin })(AddAdminDialog);
