import { ADD_TAHUN_TO_PREMIUM, DELETE_TAHUN_TO_PREMIUM, GET_ALL_TAHUN_TO_PREMIUM, SET_LOADING } from '../actions/TahunToPremium';

const initialState = {
    data: [],
    loading: false
};

const TahunToPremiumReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case GET_ALL_TAHUN_TO_PREMIUM: {
            return {
                ...state,
                data: action.payload
            };
        }
        case ADD_TAHUN_TO_PREMIUM: {
            return {
                ...state,
                loading: false
            };
        }
        case DELETE_TAHUN_TO_PREMIUM: {
            let data = state.data.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                data: data
            };
        }
        default: {
            return state;
        }
    }
};

export default TahunToPremiumReducer;
