import Informasi from './Informasi';

const InformasiRoutes = [
    {
        path: '/informasi',
        component: Informasi
    }
];

export default InformasiRoutes;
