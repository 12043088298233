import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Dialog,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { editEventTryout } from 'app/redux/actions/EventSKDActions';
import { CalendarIcon } from 'app/components/Icons';
import { getTanggal } from 'app/redux/actions/UserActions';

const ModalEditPaket = ({ open, handleClose, params }) => {
    const tomorrow = dayjs().add(1, 'day');
    const [name, setName] = useState('');
    const [docId, setDocId] = useState('');
    const [jumlahSoal, setJumlahSoal] = useState('');
    const [expiresTime, setExpiresTime] = useState('');
    const [shuffle, setShuffle] = useState('0');
    const [status, setStatus] = useState('tidak_aktif');
    const [groupLink, setGroupLink] = useState('');
    const [buttonText, setButtonText] = useState('Ubah');
    const [deskripsi, setDeskripsi] = useState('');
    const [tanggalMulai, setTanggalMulai] = useState(tomorrow.toISOString());
    const [tanggalSelesai, setTanggalSelesai] = useState(
        tomorrow.toISOString()
    );
    const [waktuMulai, setWaktuMulai] = useState(dayjs());
    const [waktuSelesai, setWaktuSelesai] = useState(dayjs());
    const isMounted = useRef(true);
    const [dateError, setDateError] = useState(false);
    const [statusButton, setStatusButton] = useState(false);
    const [jumlahKuota, setjumlahKuota] = useState();
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'name') setName(value);
        if (name === 'group_link') setGroupLink(value);
        if (name === 'deskripsi') setDeskripsi(value);
        if (name === 'jumlahSoal') setJumlahSoal(value);
        if (name === 'expiresTime') setExpiresTime(value);
        if (name === 'shuffle') setShuffle(value);
        if (name === 'status') setStatus(value);
        if (name === 'jumlahKuota') setjumlahKuota(value);
    };

    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        if (name === 'status') setStatus(value);
        if (name === 'shuffle') setShuffle(value);
    };

    const handleFormSubmit = async () => {
        setStatusButton(true);
        setButtonText('Loading...');
        let now = getTanggal();

        let params = {
            title: name,
            status,
            deskripsi,
            expires_time: parseInt(expiresTime, 10),
            is_shuffle: parseInt(shuffle, 10),
            jml_soal: parseInt(jumlahSoal, 10),
            group_link: groupLink,
            tanggal_mulai: dayjs(tanggalMulai).format('DD-MM-YYYY'),
            tanggal_selesai: dayjs(tanggalSelesai).format('DD-MM-YYYY'),
            updated_at: now,
            jml_kuota: parseInt(jumlahKuota, 10),
            waktu_mulai: dayjs(waktuMulai).format('HH:mm'),
            waktu_selesai: dayjs(waktuSelesai).format('HH:mm')
        };

        try {
            await dispatch(editEventTryout(params, docId));
            if (isMounted.current) {
                // Hanya update state jika komponen masih terpasang
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Event berhasil diubah',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                });
                handleClose();
            }
        } catch (err) {
            console.error('Error editing event tryout:', err);
            if (isMounted.current) {
                // Hanya update state jika komponen masih terpasang
                Swal.fire({
                    title: 'Gagal',
                    text: 'Event gagal diubah',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            }
        } finally {
            if (isMounted.current) {
                // Hanya update state jika komponen masih terpasang
                setStatusButton(false);
                setButtonText('Ubah');
            }
        }
    };

    const handleSearchChangeTgl = (value, type) => {
        const formattedDate = dayjs(value).format('YYYY-MM-DD');
        if (type === 'mulai') {
            setTanggalMulai(formattedDate);
        } else if (type === 'selesai') {
            setTanggalSelesai(formattedDate);
        }
    };
         const handleChangeWaktuMulai = (newValue) => {
             setWaktuMulai(newValue);
         };
         const handleChangeWaktuSelesai = (newValue) => {
             setWaktuSelesai(newValue);
         };

    useEffect(() => {
        if (params) {
            const {
                doc_id,
                title,
                jml_soal,
                expires_time,
                is_shuffle,
                status,
                group_link,
                deskripsi,
                tanggal_mulai,
                tanggal_selesai,
                waktu_mulai,
                waktu_selesai,
                jml_kuota
            } = params;
            setName(title || '');
            setDocId(doc_id || '');
            setJumlahSoal(parseInt(jml_soal, 10) || 0);
            setExpiresTime(expires_time || '');
            setShuffle(parseInt(is_shuffle, 10) || 0);
            setStatus(status || 'tidak_aktif');
            setGroupLink(group_link || '');
            setjumlahKuota(jml_kuota || 0);
            setDeskripsi(deskripsi || '');
            setTanggalMulai(
                dayjs(tanggal_mulai, 'DD-MM-YYYY').format('YYYY-MM-DD') ||
                    tomorrow.toISOString()
            );
            setTanggalSelesai(
                dayjs(tanggal_selesai, 'DD-MM-YYYY').format('YYYY-MM-DD') ||
                    tomorrow.toISOString()
            );
            setWaktuMulai(dayjs(waktu_mulai, 'HH:mm') || dayjs());
            setWaktuSelesai(dayjs(waktu_selesai, 'HH:mm') || dayjs());
        }
    }, [params]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth='md'
            fullWidth={true}
            disablePortal
            disableEnforceFocus
        >
            <div className='p-5'>
                <h4 className='mb-5'>Edit Tryout Event</h4>
                <ValidatorForm ref={formRef} onSubmit={handleFormSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Detail Paket
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label='Nama Tryout *'
                                onChange={handleChange}
                                type='text'
                                name='name'
                                value={name}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label='Link Group Tryout *'
                                onChange={handleChange}
                                type='text'
                                name='group_link'
                                value={groupLink}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label='Deskripsi Tryout *'
                                onChange={handleChange}
                                type='text'
                                name='deskripsi'
                                value={deskripsi}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label='Jumlah Soal *'
                                onChange={handleChange}
                                type='number'
                                name='jumlahSoal'
                                value={jumlahSoal}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label='Durasi Tryout (Dalam Menit) *'
                                onChange={handleChange}
                                type='number'
                                name='expiresTime'
                                value={expiresTime}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id='demo-simple-select-helper-label'>
                                    Soal Acak
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-helper-label'
                                    id='demo-simple-select-helper'
                                    value={shuffle}
                                    name='shuffle'
                                    onChange={handleChangeSelect}
                                >
                                    <MenuItem value={'1'}>Acak</MenuItem>
                                    <MenuItem value={'0'}>Tidak Acak</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Pengaturan Kuota
                            </p>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                label='Jumlah Kuota*'
                                onChange={handleChange}
                                type='number'
                                name='jumlahKuota'
                                value={jumlahKuota}
                                validators={['required']}
                                errorMessages={['Field is Required']}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <p className='mt-5 font-bold text-12'>
                                Pengaturan Waktu Ujian Tryout
                            </p>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['DatePicker']}
                                    disablePortal
                                    sx={{
                                        p: 0,
                                        overflow: 'none',
                                        width: '100%'
                                    }}
                                >
                                    <DatePicker
                                        sx={{
                                            width: '100%'
                                        }}
                                        label='Waktu Mulai'
                                        size='small'
                                        required={true}
                                        disablePast
                                        inputFormat='YYYY/MM/DD'
                                        variant='outlined'
                                        onChange={(value) =>
                                            handleSearchChangeTgl(
                                                value,
                                                'mulai'
                                            )
                                        }
                                        components={{
                                            OpenPickerIcon: CalendarIcon
                                        }}
                                        views={['year', 'month', 'day']}
                                        slotProps={{
                                            textField: {
                                                helperText: dateError ? (
                                                    <span
                                                        style={{
                                                            color: '#D32F2F'
                                                        }}
                                                    >
                                                        Enter a valid Date
                                                    </span>
                                                ) : (
                                                    'MM/DD/YYYY'
                                                )
                                            }
                                        }}
                                        format='YYYY/MM/DD'
                                        value={dayjs(tanggalMulai)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['TimePicker']}
                                    disablePortal
                                    sx={{
                                        p: 0,
                                        overflow: 'none',
                                        width: '100%'
                                    }}
                                >
                                    <TimePicker
                                        sx={{
                                            width: '100%'
                                        }}
                                        variant='outlined'
                                        ampm={false}
                                        inputFormat='HH:mm'
                                        onChange={handleChangeWaktuMulai}
                                        dateFormat='HH:mm'
                                        label='Waktu Mulai'
                                        value={waktuMulai}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['DatePicker']}
                                    disablePortal
                                    sx={{
                                        p: 0,
                                        overflow: 'none',
                                        width: '100%'
                                    }}
                                >
                                    <DatePicker
                                        sx={{
                                            width: '100%'
                                        }}
                                        FocusTrap
                                        label='Waktu Selesai'
                                        size='small'
                                        required={true}
                                        disablePast
                                        inputFormat='YYYY/MM/DD'
                                        variant='outlined'
                                        onChange={(value) =>
                                            handleSearchChangeTgl(
                                                value,
                                                'selesai'
                                            )
                                        }
                                        components={{
                                            OpenPickerIcon: CalendarIcon
                                        }}
                                        views={['year', 'month', 'day']}
                                        slotProps={{
                                            textField: {
                                                helperText: dateError ? (
                                                    <span
                                                        style={{
                                                            color: '#D32F2F'
                                                        }}
                                                    >
                                                        Enter a valid Date
                                                    </span>
                                                ) : (
                                                    'MM/DD/YYYY'
                                                )
                                            }
                                        }}
                                        format='YYYY/MM/DD'
                                        value={dayjs(tanggalSelesai)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['TimePicker']}
                                    disablePortal
                                    sx={{
                                        p: 0,
                                        overflow: 'none',
                                        width: '100%'
                                    }}
                                >
                                    <TimePicker
                                        sx={{
                                            width: '100%'
                                        }}
                                        variant='outlined'
                                        ampm={false}
                                        inputFormat='HH:mm'
                                        onChange={handleChangeWaktuSelesai}
                                        dateFormat='HH:mm'
                                        label='Waktu Selesai'
                                        value={waktuSelesai}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id='demo-simple-select-helper-label'>
                                    Status
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-helper-label'
                                    id='demo-simple-select-helper'
                                    value={status}
                                    name='status'
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'aktif'}>Aktif</MenuItem>
                                    <MenuItem value={'tidak_aktif'}>
                                        Tidak Aktif
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className='mt-5'>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={statusButton}
                            >
                                {buttonText}
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default ModalEditPaket;
