import {
    GET_INFO,
    GET_TRYOUT_PPPK,
    GET_TRYOUT_SKB,
    GET_TRYOUT_SKD,
    GET_TRYOUT_UK,
    RESET_REDUCER
} from '../actions/DashboardActions';

const initialState = {
    info: [],
    skd: 0,
    skb: 0,
    uk: 0,
    pppk: 0
};

const DashboardReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_INFO: {
            return {
                ...state,
                info: action.payload
            };
        }
        case GET_TRYOUT_SKD: {
            return {
                ...state,
                skd: action.payload
            };
        }
        case GET_TRYOUT_SKB: {
            return {
                ...state,
                skb: action.payload
            };
        }
        case GET_TRYOUT_UK: {
            return {
                ...state,
                uk: action.payload
            };
        }
        case GET_TRYOUT_PPPK: {
            return {
                ...state,
                pppk: action.payload
            };
        }
        case RESET_REDUCER: {
            console.log('REDURE RESETTTT');
            return {
                ...state,
                info: [],
                skd: 0,
                skb: 0,
                uk: 0,
                pppk: 0
            };
        }
        default: {
            return state;
        }
    }
};

export default DashboardReducer;
