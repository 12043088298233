import axios from 'axios';
import Swal from 'sweetalert2';
import firebaseAuthService from '../../services/firebase/firebaseAuthService';
export const GET_TRYOUT_SKD_EVENT = 'GET_TRYOUT_SKD_EVENT';
export const DEL_TRYOUT_SKD_EVENT = 'DEL_TRYOUT_SKD_EVENT';
export const GET_PAKET_EVENT = 'GET_PAKET_EVENT';
export const RESET_REDUCER = 'RESET_REDUCER';
export const LOADING = 'LOADING';
export const ADD_EVENT_TRYOUT = 'ADD_EVENT_TRYOUT';
export const DEL_TRYOUT_EVENT = 'DEL_TRYOUT_EVENT';
export const GET_DETAIL_TRYOUT_EVENT = 'GET_DETAIL_TRYOUT_EVENT';
export const GET_SOAL_EVENT = 'GET_SOAL_EVENT';
export const SET_SOAL_TRYOUT_EVENT = 'SET_SOAL_TRYOUT_EVENT';
export const UPD_QUESTION_TRYOUT_SKD_EVENT = 'UPD_QUESTION_TRYOUT_SKD_EVENT';

export const rearrangeSKD = (array) => {
    let all = [];
    let datatwk = array
        .filter((item) => item.category == 'twk')
        // .sort((a, b) => (a.subcategory < b.subcategory ? -1 : 1))
        .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
    for (let i = 0; i < datatwk.length; i++) {
        all.push(datatwk[i]);
    }
    let datatiu = array
        .filter((item) => item.category == 'tiu')
        // .sort((a, b) => (a.subcategory < b.subcategory ? -1 : 1))
        .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
    for (let i = 0; i < datatiu.length; i++) {
        all.push(datatiu[i]);
    }
    let datatkp = array
        .filter((item) => item.category == 'tkp')
        // .sort((a, b) => (a.subcategory < b.subcategory ? -1 : 1))
        .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
    for (let i = 0; i < datatkp.length; i++) {
        all.push(datatkp[i]);
    }

    return all;
};

export const getTryoutEventSKD = (type) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('event_skd_tryout')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                data.sort((a, b) =>
                    a.title.localeCompare(b.title, undefined, {
                        sensitivity: 'base'
                    })
                );

                dispatch({
                    type: GET_TRYOUT_SKD_EVENT,
                    payload: data
                });
            });
    };
};

export const getPaketEventSKD = (type) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('event_skd_soal')
            .get()
            .then((res) => {
                let data = [];
                res.forEach(function (doc) {
                    let item = doc.data();
                    item.doc_id = doc.id;
                    data.push(item);
                });
                data.sort((a, b) =>
                    a.title.localeCompare(b.title, undefined, {
                        sensitivity: 'base'
                    })
                );
                dispatch({
                    type: GET_PAKET_EVENT,
                    payload: data
                });
            });
    };
};

export const addEventTryout = (params) => {
    return async (dispatch) => {
        try {
            // Add new document to Firestore
            await firebaseAuthService.firestore
                .collection('event_skd_soal')
                .doc()
                .set(params);

            // Dispatch action to fetch updated data (if necessary)
            await dispatch(getPaketEventSKD());

            // Log or return params as needed
            console.log('Event tryout added successfully:', params);
            return params;
        } catch (err) {
            // Handle errors
            console.log('Error adding event tryout:', err);
            throw err; // Rethrow error to be handled elsewhere if needed
        }
    };
};
export const editEventTryout = (params, docId) => {
    return async (dispatch) => {
        try {
            // Update Firestore document
            await firebaseAuthService.firestore
                .collection('event_skd_soal')
                .doc(docId)
                .update(params);

            // Dispatch action to fetch updated data (if necessary)
            await dispatch(getPaketEventSKD());

            // Log or return params as needed
            console.log('Event tryout edited successfully:', params);
            return params;
        } catch (err) {
            // Handle errors
            console.log('Error editing event tryout:', err);
            throw err; // Rethrow error to be handled elsewhere if needed
        }
    };
};

export const delTryoutEvent = (doc_id) => {
    return async (dispatch) => {
        try {
            await firebaseAuthService.firestore
                .collection('event_skd_soal')
                .doc(doc_id)
                .delete();

            Swal.fire({
                title: 'Berhasil',
                text: 'Tryout berhasil dihapus',
                icon: 'success',
                timer: 2000
            });
            // Memanggil kembali daftar paket event SKD
            await dispatch(getPaketEventSKD());

            // Dispatch aksi penghapusan tryout
            dispatch({
                type: DEL_TRYOUT_EVENT,
                payload: doc_id
            });
        } catch (error) {
            Swal.fire({
                title: 'Gagal',
                text: 'Tryout gagal dihapus',
                icon: 'error',
                timer: 2000
            });

            console.error('Error deleting tryout event:', error);
        }
    };
};
export const getDetailTryoutEvent = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('event_skd_soal')
            .doc(kode)
            .get()
            .then((res) => {
                let data = res.data();
                data.doc_id = kode;
                dispatch({
                    type: GET_DETAIL_TRYOUT_EVENT,
                    payload: data
                });
            });
    };
};
export const delQuestionTryoutSKDEvent = (tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('event_skd_soal')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .delete()
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil dihapus',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutEvent(tryout_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal dihapus',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};
export const getSoalTryoutEvent = (kode) => {
    return async (dispatch) => {
        firebaseAuthService.firestore
            .collection('event_skd_soal')
            .doc(kode)
            .collection('question')
            .get()
            .then((doc) => {
                let data = [];
                doc.forEach(function (as) {
                    let item = as.data();
                    item.doc_id = as.id;
                    data.push(item);
                });
                dispatch({
                    type: GET_SOAL_EVENT,
                    payload: rearrangeSKD(data)
                });
            });
    };
};

export const soalTryoutEvent = (data) => (dispatch) => {
    dispatch({
        type: SET_SOAL_TRYOUT_EVENT,
        data: data
    });
};
export const addQuestionTryoutSKDEvent = (params, doc_id, soalTryout) => {
    let length =
        soalTryout.filter((item) => item.category == params.category).length +
        1;
    params.id = length;
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('event_skd_soal')
            .doc(doc_id)
            .collection('question')
            .add(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil disimpan',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch(getSoalTryoutEvent(doc_id));
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal disimpan',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};

export const updQuestionTryoutSKDEvent = (params, tryout_id, doc_id) => {
    return (dispatch) => {
        firebaseAuthService.firestore
            .collection('event_skd_soal')
            .doc(tryout_id)
            .collection('question')
            .doc(doc_id)
            .update(params)
            .then(() => {
                Swal.fire({
                    title: 'Berhasil',
                    text: 'Pertanyaan berhasil diupdate',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 2000
                }).then(() => {
                    dispatch({
                        type: UPD_QUESTION_TRYOUT_SKD_EVENT,
                        payload: { params, doc_id }
                    });
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Gagal',
                    text: 'Pertanyaan gagal diupdate',
                    showConfirmButton: false,
                    icon: 'error',
                    timer: 2000
                });
            });
    };
};