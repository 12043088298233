import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    InputLabel,
    MenuItem
} from '@material-ui/core';
import { ButtonUploadThumbnail } from 'app/components';
import {
    updateKomisi,
    getAllKomisiBelum
} from 'app/redux/actions/KomisiActions';
import React, { Component } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { connect } from 'react-redux';

class ModalEditWidraw extends Component {
    constructor() {
        super();
        this.state = {
            id_user: '',
            nominal: '',
            status: '',
            doc_id: '',
            user: null,
            img: '',
            preview_file: '',
            alasan: ''
        };
    }

    componentDidMount() {
        let {
            id_user,
            nominal,
            doc_id,
            status,
            user,
            alasan,
            bukti
        } = this.props;
        this.setState({
            id_user: id_user,
            nominal: nominal,
            status: status,
            doc_id: doc_id,
            user: user,
            alasan: alasan,
            preview_file: bukti
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    submitForm = async () => {
        let { status, doc_id, id_user, nominal, img, alasan } = this.state;
        const { updateKomisi, handleClose, getAllKomisiBelum } = this.props;
        let params = {
            status: status,
            id_user: id_user,
            nominal: nominal,
            bukti: img || '',
            alasan: alasan || ''
        };
        handleClose();
       const update =  await updateKomisi(params, doc_id);

       setTimeout(() => {
          getAllKomisiBelum();
       }, 3000);

       
    };

    handleUploadThumbnail = (file, path) => {
        this.setState({
            img: file,
            preview_file: path
        });
    };

    handleDelImg = () => {
        this.setState({
            img: '',
            preview_file: ''
        });
    };

    render() {
        let { nominal, status, user, preview_file, alasan } = this.state;
        let { open, handleClose, loadingBimbel } = this.props;
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth='sm'
                fullWidth={true}
            >
                <div className='p-5'>
                    <h4 className='mb-5'>Edit Penarikan Komisi</h4>
                    <ValidatorForm ref='form' onSubmit={this.submitForm}>
                        <Grid container spacing={4} className='mb-4'>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <TextValidator
                                    className='w-full'
                                    label='Nama'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='name'
                                    value={user?.name}
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <TextValidator
                                    className='w-full'
                                    label='Nominal'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='nominal'
                                    value={nominal}
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={12} xs={12} className='pb-0 mt-2'>
                                <span>Detail Bank</span>
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <TextValidator
                                    className='w-full'
                                    label='Bank'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='bank'
                                    value={user?.bank_name}
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <TextValidator
                                    className='w-full'
                                    label='Cabang Bank'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='cabang_bank'
                                    value={user?.bank_branch}
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <TextValidator
                                    className='w-full'
                                    label='Nomor Rekening'
                                    onChange={this.handleChange}
                                    type='number'
                                    name='nomor_rekening'
                                    value={user?.bank_account_number}
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <TextValidator
                                    className='w-full'
                                    label='Pemilik Bank'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='pemilik_bank'
                                    value={user?.bank_account_name}
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <TextValidator
                                    className='w-full'
                                    label='Kota Tempat Bank'
                                    onChange={this.handleChange}
                                    type='text'
                                    name='tempat_bank'
                                    value={user?.bank_city}
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={12} xs={12} className='pb-0 mt-2'>
                                <span>Status Penarikan</span>
                            </Grid>
                            <Grid item sm={6} xs={12} className='pb-0'>
                                <SelectValidator
                                    className='w-full'
                                    label='Status Penarikan'
                                    onChange={this.handleChange}
                                    name='status'
                                    value={status}
                                    validators={['required']}
                                    errorMessages={['Field is Required']}
                                >
                                    <MenuItem value='1'>Pending</MenuItem>
                                    <MenuItem value='2'>Ditolak</MenuItem>
                                    <MenuItem value='3'>Sukses</MenuItem>
                                </SelectValidator>
                            </Grid>
                            {status == 3 && (
                                <Grid item sm={6} xs={12} className='pb-0'>
                                    <InputLabel id='demo-simple-select-helper-label'>
                                        Bukti Transfer
                                    </InputLabel>
                                    <div
                                        style={{ width: 100, height: 100 }}
                                        className='relative mt-3'
                                    >
                                        {preview_file && (
                                            <img
                                                src={preview_file}
                                                width='100%'
                                                height='100%'
                                                style={{ objectFit: 'cover' }}
                                            />
                                        )}
                                        <ButtonUploadThumbnail
                                            uploadFoto={
                                                this.handleUploadThumbnail
                                            }
                                            value={preview_file}
                                            handleDelFile={this.handleDelImg}
                                        />
                                    </div>
                                </Grid>
                            )}

                            {status == 2 && (
                                <Grid item sm={6} xs={12} className='pb-0'>
                                    <TextValidator
                                        className='w-full'
                                        label='Alasan'
                                        onChange={this.handleChange}
                                        type='text'
                                        name='alasan'
                                        value={alasan}
                                        validators={['required']}
                                        errorMessages={['Field is Required']}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <div className='flex flex-space-between flex-middle mt-5'>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                {loadingBimbel ? (
                                    <CircularProgress />
                                ) : (
                                    'Simpan'
                                )}
                            </Button>
                            <Button onClick={handleClose}>Batal</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateKomisi: (params, id) => dispatch(updateKomisi(params, id)),
        getAllKomisiBelum: () => dispatch(getAllKomisiBelum()),
        
    };
};

export default connect(null, mapDispatchToProps)(ModalEditWidraw);
