import { Card, Icon } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const CardPenghasilan = ({ nominal, title, icon, color, url }) => {
    return (
        <Card
            className={`flex items-center border-radius-4 py-4 px-4 mb-4  card-referral bg-gradient-${color}`}
            elevation={3}
            style={{ minHeight: '105px' }}
        >
            <div className='text-white'>
                <Icon>{icon || 'account_balance_wallet'}</Icon>
            </div>
            <div
                className='ml-5 flex'
                style={{ justifyContent: 'space-between' }}
            >
                <div>
                    <h5 className='font-bold text-white'>{title}</h5>
                    <h6 className='m-0 text-white mb-3'>{nominal}</h6>

                    {url && (
                        <Link to={url} className='text-primary cursor-pointer'>
                            Detail
                        </Link>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default CardPenghasilan;
