import {
    DEL_TRYOUT_PPPK,
    GET_TRYOUT_DINAS,
    GET_TRYOUT_GRATIS,
    GET_TRYOUT_PLATINUM,
    GET_TRYOUT_PPPK_BY_CAT,
    GET_TRYOUT_PREMIUM,
    GET_TRYOUT_BUMN_BY_CAT
} from '../actions/UserTryoutActions';

const initialState = {
    gratis: [],
    premium: [],
    platinum: [],
    dinas: [],
    pppk: [],
    bumn: []
};

const UserTryoutReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_TRYOUT_GRATIS: {
            return {
                ...state,
                gratis: action.payload
            };
        }
        case GET_TRYOUT_PREMIUM: {
            return {
                ...state,
                premium: action.payload
            };
        }
        case GET_TRYOUT_DINAS: {
            return {
                ...state,
                dinas: action.payload
            };
        }
        case GET_TRYOUT_PPPK_BY_CAT: {
            return {
                ...state,
                pppk: action.payload
            };
        }
        case GET_TRYOUT_BUMN_BY_CAT: {
            return {
                ...state,
                bumn: action.payload
            };
        }
        case GET_TRYOUT_PLATINUM: {
            return {
                ...state,
                platinum: action.payload
            };
        }
        case DEL_TRYOUT_PPPK: {
            let pppk = state.pppk.filter(
                (item) => item.doc_id != action.payload
            );
            return {
                ...state,
                pppk: pppk
            };
        }
        default: {
            return state;
        }
    }
};

export default UserTryoutReducer;
