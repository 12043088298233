import { Card } from '@material-ui/core';
import React, { Component } from 'react';

const CardRanking = ({ ranking }) => {
	return (
		<Card className="flex items-center border-radius-4 py-4 px-4 mb-4 card-ranking-tertinggi" elevation={3}>
			<div className="ranking text-center mr-2 px-3">
				<h1 className="font-bold m-0 text-primary text-center">{ranking}</h1>
			</div>
			<div className="text">
				<h6 className="font-bold mb-2">Ranking tertinggi Kamu</h6>
				<p className="m-0">Yuk terus belajar</p>
			</div>
		</Card>
	);
};

export default CardRanking;
